import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
// import "react-phone-number-input/style.css";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import './phone-input.css'
import {
  Grid,
  Typography,
  Button,
  Box,
  Container,
  CardMedia,
  Card,
  CardContent,
  Backdrop,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import toast, { Toaster } from "react-hot-toast";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "723px",
    borderRadius: "10px",
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ApplyNow = (props) => {
  const [loader, setLoader] = useState(false);
  const [url, setUrl] = useState("");
  const [number, setNumber] = useState("");
  const [rcb, setRcb] = useState({
    Firstname: "",
    Lastname: "",
    Middlename: "",
    email: "",
    mobile: "",
  });
  useEffect(() => {
    if (props.category == 9 || props.category == 13 || props.category == 14 || props.category == 21) {
      const data = btoa(`vinayak@accredian.com&${props.category}`)
      setUrl(`https://xlri.accredian.com/login?cat=${data}`);
    } else if (props.category == 19) {
      setUrl("https://spjain.accredian.com/login");
    } else if (props.category == 17) {
      setUrl("https://adani.accredian.com/login");
    } else if (props.category == 18 || props.category == 20 || props.category == 22 || props.category == 24 || props.category == 25) {
      setUrl("https://iimv.accredian.com/");
    } else if (props.category == 2 || props.category == 4) {
      setUrl("https://iitg.accredian.com/login");
    }
  }, []);
  const rcbhandleChange = (e) => {
    setRcb({ ...rcb, [e.target.name]: e.target.value });
  };
  const LoaderClose = () => {
    setLoader(false);
  };
  const LoaderOpen = () => {
    setLoader(true);
  };
  function convertEmailToLowerCase(email) {
    // Split the email string into username and domain
    var parts = email.split("@");

    // Convert the username to lowercase
    var username = parts[0].toLowerCase();
    var last = parts[1].toLowerCase();

    // Return the modified email
    return username + "@" + last;
  }
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const submitForm = (e) => {
    e.preventDefault();
    if (number) {
      LoaderOpen();
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/payment-signup/`,
        data: {
          // type: "sign_up",
          email: convertEmailToLowerCase(rcb.email),
          firstname: capitalizeFirstLetter(rcb.Firstname.replace(/\s/g, "")),
          // middlename: "",
          lastname: capitalizeFirstLetter(rcb.Lastname.replace(/\s/g, "")),
          category_id: props.category,
          university: props.university,
          course: props.course,
          mobile: number,
          url: url,
          type: "ApplyNow"
        },
      }).then(function (response) {
        LoaderClose();
        props.handleClose();
        if (response.data.status == 200) {
          setRcb({
            Firstname: "",
            Lastname: "",
            Middlename: "",
            email: "",
          });
          Swal.fire({
            title: "Success",
            text: "Welcome! We have emailed you your login details. Please use those login details to continue your application on next page.",
            icon: "success",
            confirmButtonText: "Resume Application",
          }).then((conf) => {
            if (conf.isConfirmed) {
              window.open(url, "_blank");
            }
          });
        } else if (response.data.status == 302) {
          setRcb({
            Firstname: "",
            Lastname: "",
            Middlename: "",
            email: "",
          });
          // Swal.fire({
          //     title: "error",
          //     text: "Something went wrong Please try again later",
          //     icon: "error",
          //     // confirmButtonText: "OK",

          //   })
          Swal.fire({
            title: "Success",
            text: "Hello! Account with this email already exists. We had emailed you login details when you registered with us last. Please check your email for these details to continue your application on next page.",
            icon: "success",
            confirmButtonText: "Resume Application",
          }).then((conf) => {
            if (conf.isConfirmed) {
              window.open(url, "_blank");
            }
          });
        } else {
          setRcb({
            Firstname: "",
            Lastname: "",
            Middlename: "",
            email: "",
          });
          Swal.fire({
            title: "error",
            text: "We are experiencing heavy load. Please try again later.",
            icon: "error",
            // confirmButtonText: "OK",
          });
        }
      });
    }
    else {
      toast.error("Please enter your mobile number", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

  };
  return (
    <>
      <BootstrapDialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <IconButton
          aria-label="close"
          onClick={props.handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box>
          <Grid lg={12} sm={12} md={12} xs={12} container>
            <Grid lg={5.5} md={5} sm={5}>
              <Box>
                <CardMedia
                  component="img"
                  image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/apply-now-illustration.webp"
                  alt="apply now"
                  sx={{
                    width: "100%",
                    height: "350px",
                    // height:"100%",382px
                    mt: 7,
                    //  ?my:,
                    display: {
                      xs: "none",
                      lg: "block",
                      sm: "block",
                      md: "block",
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid lg={5.5} xs={12} sm={6} md={6}>
              <Box>
                <Typography
                  sx={{
                    fontSize: "1.3rem",

                    mx: 1,
                    marginBottom: "0.7rem !important",
                    fontWeight: "bold",
                    pt: 3,
                    ml: 3,
                  }}
                >
                  Enter your details to start the application
                </Typography>
              </Box>
              <Box mt={4}>
                <form onSubmit={submitForm}>
                  <TextField
                    id="name"
                    label="First name"
                    size="small"
                    textAlign="canter"
                    sx={{
                      pb: 3,
                      ml: 3,
                      width: { lg: "100%", xs: "85%", sm: "100%", md: "100%" },
                      borderRadius: "5px",
                    }}
                    onChange={rcbhandleChange}
                    value={rcb.Firstname}
                    type="text"
                    name="Firstname"
                    required={true}
                  />
                  <TextField
                    id="name"
                    label="Last name"
                    size="small"
                    textAlign="canter"
                    sx={{
                      pb: 3,
                      ml: 3,
                      width: { lg: "100%", xs: "85%", sm: "100%", md: "100%" },
                      borderRadius: "5px",
                    }}
                    onChange={rcbhandleChange}
                    value={rcb.Lastname}
                    type="text"
                    name="Lastname"
                    required={true}
                  />
                  <TextField
                    id="newemail"
                    label="Email"
                    size="small"
                    textAlign="canter"
                    sx={{
                      pb: 3,
                      ml: 3,
                      width: { lg: "100%", xs: "85%", sm: "100%", md: "100%" },
                      borderRadius: "5px",
                    }}
                    type="email"
                    name="email"
                    onChange={rcbhandleChange}
                    value={rcb.email}
                    required={true}
                  />
                  <PhoneInput
                    inputStyle={{
                      width: "100%",
                      lineHeight: "17px",
                      fontSize: "14px",
                      outline: "none",
                      padding: "12px",
                      paddingLeft: "55px",
                      borderRadius: "4px",

                    }}
                    inputClass="applyNow"
                    containerStyle={{
                      marginLeft: "24px"
                    }}

                    enableSearch={true}
                    country={'in'}
                    inputProps={{
                      name: "mobile",
                      required: true,
                      autoFocus: true
                    }}
                    // defaultCountry=""
                    size="small"

                    placeholder="Enter your phone number"
                    value={number}
                    onChange={setNumber}

                  />
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      mb: 3,
                      ml: 3,
                      mt: 2,
                      // backgroundColor: "#ff8c1a",
                      textTransform: "none",
                      p: 1,
                      width: { lg: "100%", xs: "85%", sm: "100%", md: "100%" },
                    }}
                  >
                    Start Application
                  </Button>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </BootstrapDialog>
      <Toaster />
    </>
  );
};
export default ApplyNow;
