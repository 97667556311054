import React, { useEffect, useState } from "react";
import { Typography, Button, Box, Container, Grid, CardMedia, Backdrop, } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from '@mui/material/CircularProgress';
import { useMutation } from "@tanstack/react-query";
import { signup } from "../../services/user.service";
import { Wrapper } from "../Wrappers";
const Signup = () => {
  const [user, setUser] = useState({ fristname: "", lastname: "", email: "", password: "" });
  let navigate = useNavigate();
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const [open, setOpen] = useState(false);

  const toasterOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  }

  const { mutate, isPending } = useMutation({
    mutationFn: (body) => signup(body),
    onSuccess: (res) => {
      console.log(res);
      toast.success(res.data.feedback, toasterOptions);
      const timeoutId = setTimeout(() => {
        navigate(`/Login`);
      }, 3000);
      return () => {
        clearTimeout(timeoutId);
      };
    },
    onError: (error) => {
      console.log(error);
      if (error.response.data && error.response.data.message === "warning") {
        toast.warn(error.response.data.feedback, toasterOptions);
      } else {
        toast.error(error.response.data.feedback, toasterOptions);
      }
    },
    onSettled: () => {
      setUser({
        fristname: "",
        lastname: "",
        email: "",
        password: ""
      });
      setOpen(false);
    }
  })

  const submitForm = async (e) => {
    e.preventDefault()

    const sandData = {
      firstname: user.fristname,
      lastname: user.lastname,
      email: user.email,
    };

    mutate(sandData)
  };

  useEffect(() => {
    setOpen(isPending);
  }, [isPending])

  return (
    <Wrapper>
      <Box sx={{ pt: 5, pb: 3 }}>
        <Container>
          <Box
            sx={{
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              p: 5,
              borderRadius: 5,
              mx: { lg: 20 },
              width: { lg: "65%" },
            }}
          >
            <Typography mb={2} fontWeight="bold" fontSize={25} sx={{ textAlign: { xs: "center", lg: "left" } }}>Sign up</Typography>
            <Grid container>
              <Grid lg={6} xs={12}>
                <Box >
                  <form onSubmit={submitForm}>
                    <Box mb={2}>
                      {" "}
                      <TextField

                        label="First Name"
                        variant="standard"
                        type="text"
                        name="fristname"
                        onChange={handleChange}
                        value={user.fristname}
                        fullWidth
                        required={true}
                      />
                    </Box>
                    <Box mb={2}>
                      {" "}
                      <TextField

                        label="Last Name"
                        variant="standard"
                        type="text"
                        name="lastname"
                        onChange={handleChange}
                        value={user.lastname}
                        fullWidth
                        required={true}
                      />
                    </Box>
                    <Box mb={2}>
                      {" "}
                      <TextField

                        label="Email"
                        variant="standard"
                        type="email"
                        name="email"
                        onChange={handleChange}
                        value={user.email}
                        fullWidth
                        required={true}
                      />
                    </Box>
                    {/* <Box mb={2}>
                    <TextField
                 
                      label="Password"
                      variant="standard"
                      type="password"
                      name="password"
                      onChange={handleChange}
                      value={user.password}
                      fullWidth
                    />
                  </Box> */}
                    <Box pt={2} display="flex">
                      <Box>  <Button variant="contained" sx={{ textTransform: 'capitalize' }} type="submit">Register</Button></Box>

                      <Box>  <Link to='/Login' style={{ textDecoration: "none", color: "#000" }}> <Typography ml={5} pt={1}>Already have an account?</Typography></Link> </Box>
                    </Box>
                  </form>
                </Box>
              </Grid>
              <Grid lg={6} sx={{ display: { xs: "none", lg: "block" } }}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CardMedia
                    component="img"
                    image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Auth/signup.webp"
                    alt="Goole"
                    sx={{ width: "auto", }}

                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}

      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
    </Wrapper>
  );
};
export default Signup;
