import React, { useState } from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

import "aos/dist/aos.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";
import axios from "axios";
import { useLocation, useSearchParams } from "react-router-dom";
import vertical from "../../Assets/constants/programs.json";
import { groupProgramsByCategory } from "../../helpers/common.helpers";

const currencies = [
  {
    value: "Data Science",
    label: "Data Science",
  },
  {
    value: "Product Management",
    label: "Product Management",
  },
  {
    value: "General Management",
    label: "General Management",
  },
  // {
  //   value: "HR Analytics",
  //   label: "HR Analytics",
  // },
  // {
  //   value: "Sales and Marketing",
  //   label: "Sales and Marketing",
  // },
  {
    value: "Digital Transformation",
    label: "Digital Transformation",
  },
  {
    value: "Business Management",
    label: "Business Management",
  },
  {
    value: "Project Management",
    label: "Project Management",
  },
  {
    value: "strategic management",
    label: "strategic management",
  },
  // {
  //   value: "Operations & Supply Chain Management",
  //   label: "Operations & Supply Chain Management",
  // },
  {
    value: "Senior Management",
    label: "Senior Management",
  },
  {
    value: "Finance",
    label: "Finance",
  },
];

const RcbDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "733px",
  },
}));

const streams = Object.keys(groupProgramsByCategory(vertical));

function RcbDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

RcbDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Rcta(props) {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [program, setProgram] = useState("");

  const handleChange = (event) => {
    setProgram(event.target.value);
  };

  const [loader, setLoader] = useState(false);

  const LoaderClose = () => {
    setLoader(false);
  };
  const LoaderOpen = () => {
    setLoader(true);
  };

  const [rcb, setRcb] = useState({
    mobile: "",
    name: "",
    newemail: "",
    program: "",
    ctaname: "",
    ctapage: "",
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
  });
  const rcbhandleChange = (e) => {
    setRcb({ ...rcb, [e.target.name]: e.target.value });
  };
  const options = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };
  // console.log(props.program,"see name")
  var ctapage = location.pathname.replace("/", "");
  var utm_source =
    searchParams.get("utm_source") == undefined
      ? "null"
      : searchParams.get("utm_source");
  var utm_medium =
    searchParams.get("utm_medium") == undefined
      ? "null"
      : searchParams.get("utm_medium");
  var utm_campaign =
    searchParams.get("utm_campaign") == undefined
      ? "null"
      : searchParams.get("utm_campaign");
  const submitForm = (e) => {
    e.preventDefault();
    LoaderOpen();
    const sendRcbctaData = {
      phone: rcb.mobile,
      name: rcb.name,
      email: rcb.newemail,
      program: props.program ? props.program : program,
      source: "Request Callback Top Bar",
      page: ctapage,
      utm_source: utm_source,
      utm_medium: utm_medium,
      utm_campaign: utm_campaign,
      form_id: "request_callback",
      type: "CTA",
    };

    console.log(sendRcbctaData);
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/common_publish/commonPublish`,
        JSON.stringify(sendRcbctaData),
        options
      )
      .then((result) => {
        // //console.log(result.data.status);
        LoaderClose();
        props.handleClose();

        if (result.data.status == "200") {
          if (window) {
            window.open("https://accredian.com/cta-thank-you", "_blank");
          }
          // Swal.fire({
          //   title: "Success",
          //   text: "Your request submitted successfully. Our expert will call you soon.",
          //   icon: "success",
          //   confirmButtonText: "OK",
          // })
          setRcb("");
        } else {
          Swal.fire({
            title: "Warning",
            text: "Something went wrong. Please try again!",
            icon: "Warning",
            confirmButtonText: "OK",
          });
        }
      });
  };
  return (
    <>
      <RcbDialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box>
          <Grid lg={12} sm={12} md={12} xs={12} container>
            <Grid
              lg={5.5}
              md={5.5}
              sm={5.5}
              sx={{
                display: {
                  xs: "none",
                  lg: "block",
                  sm: "block",
                  md: "block",
                },
                backgroundImage:
                  "linear-gradient(#f4f5f7 0% , rgba(0, 0, 0, 0.7) 0% ),url(https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/rcb_dummy.avif)",
                backgroundSize: "cover",
                width: "100%",
              }}
            >
              <Box sx={{ mx: 3, mt: 3 }}>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    textAlign: "center",
                    mx: 1,
                    mt: props.program ? 6 : 10,
                    marginBottom: "0.8rem !important",
                    color: "#fff",
                  }}
                >
                  Unlocking Potential with Top Tier Educational Institutes
                </Typography>
                {/* <Typography
                  sx={{
                    fontSize: "0.9rem",
                    // border:"1px solid #fff",
                    // borderRadius:"20px",
                    p: 1,
                    //    borderWidth:"70%",
                    // width:"70%",
                    // ml:2,
                    // color: "#0049AE",
                    color: "#fff",
                    textAlign: "center",
                    mx: 1,
                    mt: props.program ? 20 : 21,
                    marginBottom: "0.1rem !important",
                  }}
                >
                  Call our Experts at :
                </Typography>

                <table>
                  <tr>
                    <td style={{ color: "#fff", fontSize: "14px", padding: "3px" }}>Data Science</td>
                    <td style={{ color: "#fff", fontSize: "14px", paddingLeft: "15px" }}>+91 8595709940</td>
                  </tr>
                  <tr>
                    <td style={{ color: "#fff", fontSize: "14px", padding: "3px" }}>Product Management</td>
                    <td style={{ color: "#fff", fontSize: "14px", paddingLeft: "15px" }}>+91 9526468843</td>
                  </tr>
                  <tr>
                    <td style={{ color: "#fff", fontSize: "14px", padding: "3px" }}>General Management</td>
                    <td style={{ color: "#fff", fontSize: "14px", paddingLeft: "15px" }}>+91 8130897175</td>
                  </tr>
                  <tr>
                    <td style={{ color: "#fff", fontSize: "14px", padding: "3px" }}>Senior Management</td>
                    <td style={{ color: "#fff", fontSize: "14px", paddingLeft: "15px" }}>+91 8130897175</td>
                  </tr>
                </table> */}
              </Box>
            </Grid>

            {/* <Divider orientation="vertical" flexItem sx={{ pl: 2 }}>
      <Typography
        sx={{
          display: { xs: "none", lg: "block" },
          marginBottom: "0rem!important",
        }}
      >
        OR
      </Typography>
    </Divider> */}

            <Grid lg={6} xs={12} sm={6} md={6}>
              <Box sx={{ mt: 1.5, ml: { lg: 2, xs: 0 } }}>
                <RcbDialogTitle
                  id="customized-dialog-title"
                  onClose={props.handleClose}
                ></RcbDialogTitle>
                {/* <Box sx={{display:"flex",justifyContent:"flex-end",alignItems:"flex-end",width:{lg:"100%",xs:"95%",sm:"100%",md:"100%"}}}>
        <CloseIcon sx={{cursor:"pointer"}} onClick={props.handleClose}/>
        </Box> */}

                {/* <Box sx={{display:"flex"}}> */}
                <Typography
                  sx={{
                    fontSize: "1rem",
                    textAlign: "center",
                    mx: 1,
                    marginBottom: "0.7rem !important",
                  }}
                >
                  Schedule a 1-on-1 consultation
                </Typography>

                {/* </Box> */}

                <Typography
                  sx={{
                    fontSize: "1.5rem",
                    textAlign: "center",
                    color: "#0049AE",
                    mx: 1,
                  }}
                >
                  Request a Call Back
                </Typography>
              </Box>
              <Box sx={{ ml: 0 }}>
                <form onSubmit={submitForm}>
                  <TextField
                    id="name"
                    label="Name"
                    variant="standard"
                    textAlign="canter"
                    sx={{
                      pb: 1,
                      mx: 7,
                      width: { lg: "75%", xs: "65%", sm: "65%", md: "65%" },
                    }}
                    onChange={rcbhandleChange}
                    value={rcb.name}
                    type="text"
                    name="name"
                    required={true}
                  />

                  <TextField
                    id="number"
                    label="Mobile"
                    variant="standard"
                    textAlign="canter"
                    sx={{
                      pb: 1,
                      mx: 7,
                      width: { lg: "75%", xs: "65%", sm: "65%", md: "65%" },
                    }}
                    required={true}
                    onChange={rcbhandleChange}
                    value={rcb.mobile}
                    type="number"
                    name="mobile"
                  />

                  <TextField
                    id="newemail"
                    label="Email"
                    variant="standard"
                    textAlign="canter"
                    sx={{
                      pb: 2,
                      mx: 7,
                      width: { lg: "75%", xs: "65%", sm: "65%", md: "65%" },
                    }}
                    type="email"
                    name="newemail"
                    onChange={rcbhandleChange}
                    value={rcb.newemail}
                    required={true}
                  />
                  {props.program ? (
                    <Box sx={{ pb: 2 }}></Box>
                  ) : (
                    <TextField
                      id="program"
                      select
                      value={program}
                      onChange={handleChange}
                      variant="standard"
                      sx={{
                        pb: 3,
                        mx: 7,
                        width: { lg: "75%", xs: "65%", sm: "65%", md: "65%" },
                      }}
                      name="program"
                      label={program === "" ? "Select Category" : ""}
                      // label="Select Program"
                      required={true}
                      InputLabelProps={{ shrink: false }}
                    >
                      <MenuItem value="">
                        <em>---- Select Category ----</em>
                      </MenuItem>
                      {streams.map((stream) => (
                        <MenuItem
                          key={stream.toUpperCase()}
                          value={stream}
                          name="program"
                          sx={{
                            textTransform:
                              stream?.toLowerCase() === "cxo"
                                ? "uppercase"
                                : "capitalize",
                          }}
                        >
                          {stream.toLowerCase() === "data science & ai"
                            ? "Data Science & AI"
                            : stream}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}

                  <center>
                    {" "}
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        mb: 2,
                        backgroundColor: "#ff8c1a",
                        textTransform: "none",
                        borderRadius: "20px",
                        p: 1,
                        ml: { lg: 3, xs: 0 },
                        width: { lg: "75%", sm: "65%", xs: "65%", md: "75%" },
                        "&:hover": {
                          mb: 2,
                          backgroundColor: "#ff8c1a",
                          textTransform: "none",
                          p: 1,
                          ml: { lg: 3, xs: 0 },
                          width: { lg: "75%", sm: "65%", xs: "65%", md: "75%" },
                          borderRadius: "20px",
                        },
                      }}
                    >
                      Call Me Back
                    </Button>
                  </center>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </RcbDialog>
    </>
  );
}
