export const Broucher_data = [

    {
        
       pcode:"EPPM",
       link:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/Executive-program-in-PM-_5-months-XLRI-November.pdf"

    },
    {
        pcode:"EPGM",
        link:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/Executive-program-in-General-Management-November-2023.pdf"
    },
    {
        pcode:"EPDT",
        link:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/XLRI_DT_Brochure_2023_v3.pdf"
    },
    // {
    //     pcode:"EPSL",
    //     link:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/XLRI-Strategy-&-Leadership.pdf"
    // },
    {
        pcode:"EPCS",
        link:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/cyber-security-brochure.pdf"
    },
    {
        pcode:"EPOSCM",
        link:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/OSCM-brochure.pdf"
    },
    {
        pcode:"EPDSAIA",
        link:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IIT-Executive-Program-in-DS-AI-12-months.pdf"
    },
    {
        pcode:"ACDSML",
        link:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IIT-Advanced-Certification-in-DS-ML-6-months.pdf"
    },
    {
        pcode:"EPCDA",
        link:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/Certificate-in-Data-Analytics-3-months.pdf"
    },
    {
        pcode:"EPPMDS",
        link:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IIT-Executive-Program-PM-DS-11-months.pdf"
    },
    {
        pcode:"EPDDPM",
        link:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IIT-Executive-Program-in-DDPM-11-months.pdf"
    },
    {
        pcode:"EPPM",
        link:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IIT-Executive-Program-in-PM-8-months.pdf"
    },
    {
        pcode:"ACPM",
        link:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IIT-Advanced-Certification-in-PM-5-months.pdf"
    },
    {
        pcode:"EPDSBA",
        link:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IIT-Executive-Program-in-DS-BA-11-months.pdf"
    },
    {
        pcode:"EPMLAI",
        link:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IIT-Executive-in-ML-AI.pdf"
    },
    {
        pcode:"ACAI",
        link:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IIT-Adv-Cert-in-AI-1.pdf"
    },
    {
        pcode:"EPDSPM",
        link:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IIT-Executive-Program-DS-PM-11-months.pdf"
    },
    {
        pcode:"EPPMBA",
        link:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IIT-Executive-Program-in-PM-BA-11-months.pdf"
    },
    {
        pcode:"EPPMDT",
        link:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IIT-Executive-Program-in-PM-DT-10-months.pdf"
    },
    {
        pcode:"EPDSDT",
        link:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IIT-Executive-Program-in-DS-DT-11-months.pdf"
    },
    // {
    //     pcode:"ACDT",
    //     link:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IITG-Advanced-Certification-in-Digital-Transformation.pdf"
    // },
    {
        pcode:"EPPMPMI",
        link:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IIT-Executive-Program-PM-PI-11-months.pdf"
    },
    {
        pcode:"EPDSPMI",
        link:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IIT-Executive-Program-DS-%26-PI-11-months.pdf"
    },
    {
        pcode:"PGPDSAI",
        link:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/PGP-DS_AI_brochure_2023.pdf"
    },
    {
        pcode:"PGPDSBA",
        link:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/PGP-in-DSBA-Harvard-3.pdf"
    },
    {
        pcode:"GCDAI",
        link:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/Global-Certificate-in-Data-Science-AI.pdf"
    },
    {
        pcode:"PGPMLAI",
        link:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/PGP-in-ML-and-AI-brochure.pdf"
    },
    {
        pcode:"GCD",
        link:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/Global-Certificate-in-Data-Science.pdf"
    },
    {
        pcode:"CDF",
        link:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/Certificate-in-Data-Science-Foundation.pdf"
    },
    {
        pcode:"PGPAI",
        link:" https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/PGP-in-ai-brochure.pdf"
    },
   

   





];