import { Box, Modal } from "@mui/material";
import { IoIosClose } from "react-icons/io";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import { IoWarning } from "react-icons/io5";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { decodeToken, isExpired } from "react-jwt";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { userActions } from "../../store/reducers/user";
import { createUser } from "../../services/auth.service";
import { createApplicationStepTracker } from "../../services/program.service";
import { encodeBase64 } from "../../helpers/encodeTrackerId";
// import image7 from "./assets/image7.png";
// import logo from "./assets/logo.png";

function SignUpModal({ open, email, handleClose }) {
  const [isOpen, setIsopen] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [tokenExp, setTokenexp] = useState("");
  const dispatch = useDispatch();
  const { authType, redirectUrl, category } = useSelector(
    (state) => state.user
  );
  const options = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: { firstname: "", lastname: "", contact: "" },
    mode: "onChange",
  });

  const closeSignUpModal = () => {
    reset();
    handleClose();
  };
  function setCookie(name, value, hours, domain) {
    let expires = "";
    if (hours) {
      const date = new Date();
      date.setTime(date.getTime() + hours * 60 * 60 * 1000);
      expires = `; expires=${date.toUTCString()}`;
    }
    const cookieDomain = domain ? `; domain=${domain}` : "";
    document.cookie = `${name}=${value || ""}${expires}${cookieDomain}; path=/`;
  }

    // Function for redirecting the user to the specific portal
  function openUniversityApp(university, encodedTracker) {
    // Check the current environment
    const isDevelopment = process.env.NODE_ENV === "development";

    // Construct the URL based on the environment
    const url = isDevelopment
      ? `${process.env.REACT_APP_PAYMENT_PORTAL_BASE_URL}/?app_id=${encodedTracker}` // Localhost URL for development
      : `https://${university}.accredian.com/?app_id=${encodedTracker}`; // Production URL

    // Open the URL in a new browser tab
    window.open(url, "_blank");
  }

      // Function checking user otp and if coming from program page then creating his steptracker 
  const onSubmit = async (data) => {
    console.log(data)
    setLoading(true);
    try {
      const first_name = data.firstname;
      const mobile = data.contact;
      const last_name = data.lastname;
      const payload = {
        email,
        first_name,
        last_name,
        mobile,
        auth_type: "signup",
      };
      const response = await createUser(payload);
      if (response) {
        const auth_type = response.auth_type;
        setCookie("token", response.token, 12, "accredian.com");
        const myDecodedToken = decodeToken(response.token);
        const isMyTokenExpired = isExpired(response.token);
        setTokenexp(isMyTokenExpired);
        localStorage.setItem("name", myDecodedToken.data.firstname);
        localStorage.setItem("token", response.token);
        toast.success("Account Created Successfully", {
          position: "top-right",
        });
        const userId = myDecodedToken.data.user_id;
        dispatch(userActions.setAuthenticatedState(true));

        if (category) {
          const universityName = localStorage.getItem("university_shortName");
          const stepTrackerPayload = {
            id: userId,
            category: category,
            universityName: universityName,
          };
          createApplicationStepTracker(stepTrackerPayload)
            .then((response) => {
              // Handle the successful response

              const encodedTracker = encodeBase64(response.data);

              setTimeout(() => {
                openUniversityApp(universityName, encodedTracker);
                // window.open(
                //   `https://${universityName}.accredian.com/?app_id=${encodedTracker}`,
                //   "_blank"
                // );
                // handleOTPVerification(auth_type);
                window.location.reload();
                handleClose();
              }, 1000);
            })
            .catch((error) => {
              console.log(error);
              setTimeout(() => {
                window.location.href = `${process.env.REACT_APP_BASE_URL}/`;
                // window.open(redirectUrl, "_blank");
                handleClose();
              }, 1000);
            });
        } else {
          setTimeout(() => {
            window.location.href = `${process.env.REACT_APP_BASE_URL}/`;

            handleClose();
          }, 1000);
        }
      }
    } catch (error) {
      console.log("Error occurred:", error);
      if (error.response) {
        const { status, data } = error.response;
        if (status === 409) {
          toast.error(
            "The provided email is already linked to an existing account.",
            { position: "top-right" }
          );
          // setLoading(false);
          return;
        } else {
          // toast.error(data.message);
          console.error(`Error ${status}: ${data.message}`);
        }
      } else if (error.request) {
        console.error("No response received from the server.");
      } else {
        console.log("Error message:", error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {open && (
        <Modal
          open={true}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          onClose={loading ? null : closeSignUpModal}
        >
          <div class="font-popins font-normal max-w-[90%] w-[840px] relative flex flex-row justify-center items-center outline-none border-none h-full mx-auto">
            <div className=" relative  bg-white flex justify-center items-center  rounded-2xl w-full outline-none border-none">
              <div className=" absolute py-2 w-full text-center  flex items-center justify-end top-0 right-2 ">
                <button
                  className=" w-8 h-8 rounded-lg bg-white flex items-center justify-center cursor-pointer"
                  onClick={closeSignUpModal}
                  disabled={loading}
                >
                  <IoIosClose className="w-12 h-12 text-gray-600" />
                </button>
              </div>

              {/* Left Side: Image */}
              <Box
                sx={{
                  width: "50%",
                  display: {
                    xs: "none", // Show on extra-small screens
                    sm: "none", // Show on small screens
                    md: "block", // Hide on medium screens and up
                  },
                  padding: 4,
                }}
              >
                <img
                  src="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/signUpModalImage.svg"
                  alt="Description"
                  style={{ width: "100%", height: "auto", borderRadius: "8px" }}
                />
              </Box>

              {/* Right Side: Form */}
              <Box
                sx={{
                  width: "50%",
                  width: {
                    xs: "100%", // 100% width on extra-small screens
                    md: "50%", // 50% width on medium screens and up
                  },
                  height: "100%",
                  padding: 4,
                  // paddingLeft: 2,
                  // paddingRight: {
                  //   xs: 2,
                  //   md: 4,
                  // },
                }}
              >
                {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <img
                  src={logo}
                  alt="Company Logo"
                  style={{ width: "150px", height: "auto" }}
                />
              </Box> */}

                <div className="mb-4 text-2xl font-semibold text-paleBlack pb-3 pt-3">
                  <h2>Welcome! Sign up or Login</h2>
                </div>
                {/* <div id="modal-description" className="mb-4 text-paleBlack ">
                    <p>
                   
                    </p>
                  </div> */}
                <form onSubmit={handleSubmit(onSubmit)}>
                  {/* div 1 */}
                  <div className="mb-4">
                    <input
                      className={`w-full px-4 py-3 font-normal text-sm bg-white border-b-2 outline-none transition disabled:opacity-70 disabled:cursor-not-allowed pl-2 mt-2
   ${errors["firstname"] ? "border-rose-500" : "border-neutral-300"} 
   ${errors["firstname"] ? "focus:border-rose-500" : "focus:border-blue-500"}`}
                      {...register("firstname", {
                        required: {
                          value: true,
                          message: "Firstname is required",
                        },
                      })}
                      id="firstname"
                      disabled={loading}
                      type="text"
                      placeholder="Firstname (Required)"
                    />
                    {errors.firstname?.message && (
                      <div className="text-red-500 text-xs w-full font-medium flex items-center gap-1 mt-1 pl-2">
                        <span className="mt-[-1px]">
                          <IoWarning />
                        </span>
                        <p>{errors.firstname?.message}</p>
                      </div>
                    )}

                    {/* div 2 */}

                    <input
                      className={`w-full px-4 py-3 font-normal text-sm bg-white border-b-2 outline-none transition disabled:opacity-70 disabled:cursor-not-allowed pl-2 mt-2
   ${errors["lastname"] ? "border-rose-500" : "border-neutral-300"} 
   ${errors["lastname"] ? "focus:border-rose-500" : "focus:border-blue-500"}`}
                      {...register("lastname", {
                        required: {
                          value: true,
                          message: "Lastname is required",
                        },
                      })}
                      id="lastname"
                      type="text"
                      disabled={loading}
                      placeholder="Lastname (Required)"
                    />
                    {errors.lastname?.message && (
                      <div className="text-red-500 text-xs w-full font-medium flex items-center gap-1 mt-1 pl-2">
                        <span className="mt-[-1px]">
                          <IoWarning />
                        </span>
                        <p>{errors.lastname?.message}</p>
                      </div>
                    )}
                  </div>
                  <div
                    className={`relative ${
                      errors?.contact?.message ? "mt-2" : "mt-4"
                    }`}
                  >
                    <Controller
                      name="contact"
                      control={control}
                      rules={{
                        required: "Contact number is required",
                        minLength: {
                          value: 8,
                          message: "Must provide contact number",
                        },
                      }}
                      render={({ field }) => (
                        <PhoneInput
                          inputStyle={{
                            width: "100%",
                            borderTop: "none",
                            borderLeft: "none",
                            borderRight: "none",
                            borderBottom: errors.contact
                              ? "2px solid red"
                              : "2px solid rgb(212 212 212)",
                            borderRadius: "0px",
                            padding: "10px",
                            paddingLeft: "55px",
                            outline: "none",
                            fontSize: "0.875rem",
                            lineHeight: "1.25rem",
                          }}
                          specialLabel=""
                          enableSearch={true}
                          disabled={loading}
                          country={"in"}
                          placeholder="Contact (Required)"
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                    {errors.contact?.message && (
                      <div className="text-red-500 text-xs w-full font-medium flex items-center gap-1 mt-1 pl-2">
                        <span className="mt-[-1px]">
                          <IoWarning />
                        </span>
                        <p>{errors.contact?.message}</p>
                      </div>
                    )}
                  </div>

                  <div className="w-full text-[12px] font-base p-2 text-center">
                    <h3 className="">
                      By submitting the form, you agree to our{" "}
                      <Link to="/terms" className="text-universal">
                        Terms and Conditions
                      </Link>{" "}
                      and our{" "}
                      <Link to="/terms/privacy" className="text-universal">
                        Privacy Policy
                      </Link>
                      .
                    </h3>
                  </div>
                  <button
                    className={`hover:bg-darkBlue text-base relative mt-6 px-4 py-2 bg-universal font-medium text-white w-full flex justify-center rounded-lg drop-shadow-md`}
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress size={24} sx={{ color: "white" }} />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </form>
              </Box>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default SignUpModal;
