export const Webinardata = [

    {
       id:"ds",
        programname:"Data Science",
        programdicription:"Join our Exclusive Masterclass & start your Data Science Journey",
        cardhadding1:"What exactly is Data Science?",
        carddicription1:"Data Science is the study of cleaning, visualizing, and analyzing data to make informed business decisions.",
        cardhadding2:"Top 3 skills to build now to become a Data Scientist",
        carddicription2:"The top 3 in-demand Data Science skills include Python, Data Visualization, and Machine Learning.",
        cardhadding3:"How to transition NO MATTER your background?",
        carddicription3:"Irrespective of your background you can learn everything from Data analysis to Machine Learning.",
        cardhadding4:"Data Science, Machine Learning & AI",
        carddicription4:"The ideal learning path is data analysis, & data visualization followed by Machine Learning to an advanced level.",
        cardimage1:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/5.webp",
        cardimage2:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/6.webp",
        cardimage3:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/7.webp",
        masterclasshadding1:"Graduates with 1-3 Years of Experience",
        masterclassdicription1:"If you have 1-3 years of experience & want to get into Data Science roles, this masterclass will give you an idea how to take 1st step.",
        masterclassimage1:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/8.webp",
        masterclasshadding2:"Working Professionals",
        masterclassdicription2:"Since you already have 3+ of experience your goal should be targeting lead roles, this masterclass will help you.",
        masterclassimage2:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/9.webp",
        masterclasshadding3:"People on Career Break",
        masterclassdicription3:"If you were on career break & now want to restart your career, this masterclass will guide you step-by-by on transition.",
        masterclassimage3:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/10.webp",
              

       



    },
    {
        id:"pm",
         programname:"Product Management",
         programdicription:"Join our Exclusive Masterclass & start your Product Management Journey",
         cardhadding1:"What exactly is Product Management?",
         carddicription1:"Product Management is the study of cleaning, visualizing, and analyzing data to make informed business decisions.",
         cardhadding2:"Top 3 skills to build now to become a Data Scientist",
         carddicription2:"The top 3 in-demand Product Management skills include Python, Data Visualization, and Machine Learning.",
         cardhadding3:"How to transition NO MATTER your background?",
         carddicription3:"Irrespective of your background you can learn everything from Data analysis to Machine Learning.",
         cardhadding4:"Product Management, Machine Learning & AI",
         carddicription4:"The ideal learning path is data analysis, & data visualization followed by Machine Learning to an advanced level.",
         cardimage1:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/5.webp",
         cardimage2:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/6.webp",
         cardimage3:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/7.webp",
         masterclasshadding1:"Graduates with 1-3 Years of Experience",
         masterclassdicription1:"If you have 1-3 years of experience & want to get into Product Management roles, this masterclass will give you an idea how to take 1st step.",
         masterclassimage1:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/8.webp",
         masterclasshadding2:"Working Professionals",
         masterclassdicription2:"Since you already have 3+ of experience your goal should be targeting lead roles, this masterclass will help you.",
         masterclassimage2:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/9.webp",
         masterclasshadding3:"People on Career Break",
         masterclassdicription3:"If you were on career break & now want to restart your career, this masterclass will guide you step-by-by on transition.",
         masterclassimage3:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/10.webp",
               

 
 
     },
];