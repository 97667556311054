import Modal from "@mui/material/Modal";

import { Link } from "react-router-dom";
import { Box, CardMedia, Typography } from "@mui/material";
import { ThumbUp } from "@mui/icons-material";
import { useEffect, useState } from "react";

const Thankyou = () => {
    const [pagename,setPagename]=useState("")
    useEffect(() => {
        setPagename(localStorage.getItem("pagename"))
        localStorage.removeItem("pagename");
      }, []);
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage:
          "url(https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/thank-you-background.webp)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
      }}
    >
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "900px",
              textAlign: "center",
              color: "white",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              px: 4,
            }}
          >
            <CardMedia
              component="img"
              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accredainnew.webp"
              alt="Goole"
              sx={{
                maxWidth: "250px",
                width: "100%",
                mt:1,
                objectFit: "unset",
              }}
            />
            <Box
              sx={{
                width: "100%",
                backgroundColor: "white",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                color: "black",
                px: 2,
                py: 4,
                borderRadius: "8px",
                mt: 6,
                boxShadow: 1,
              }}
            >
              <ThumbUp sx={{ fontSize: "3rem" }} />
              <Typography variant="h3" sx={{ mt: 5, fontWeight: "bold" }}>
              Thank You!
              </Typography>
              <Typography  sx={{ mt: 5,width:"60%",textAlign:"center"}}>
              You have successfully registered for an exclusive {pagename} Masterclass with Accredian. You'll get the masterclass link & other details soon over your registered email id.
              </Typography>
              <Typography  sx={{width:"60%",textAlign:"center"}}>
              If you have any questions please feel free to reach out to us on <a style={{textDecoration:"none"}} href="mailto:admissions@accredian.com">admissions@accredian.com</a>
              </Typography>
             
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: 3,
                alignItems: "center",
                justifyContent: "center",
                mt: 6,
              }}
            >
              <Typography>©2024 Accredian</Typography>
              <Typography>|</Typography>
              <Link style={{textDecoration:"none",color:"white",marginTop:"-30px"}} href="/">accredian.com</Link>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
export default Thankyou;