import React, { useRef, useEffect, useState } from "react";
import { useParams, useSearchParams, useLocation, useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  Box,
  Container,
  CardMedia,
  FormGroup,
  TextField,
  Backdrop,
} from "@mui/material";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";
import CropFreeIcon from "@mui/icons-material/CropFree";
import { Iitprogram } from "../../data/Iitprogram";
import "../../Assets/css/ProgramPage/style.css";
import HelpIcon from "@mui/icons-material/Help";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import DownloadIcon from "@mui/icons-material/Download";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { withStyles } from "@mui/styles";
import useStyles from "../../Assets/js/ProgramPage/style";
import AOS from "aos";
import "aos/dist/aos.css";
import Swal from "sweetalert2";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Footer from "../../Components/Footer/Footer";
import Programfee from "../programfee/programfee";
import Rcta from "../../Components/cta/request_a_call_back";
import ApplyNow from "../../Components/Signup/ApplyNow";
export default function ProgramSectionFive() {
  const { id } = useParams();
  const [data, setData] = useState();
  const [rcbprogram, setProgramrcb] = useState("");
  const [loader, setLoader] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate()
  const location = useLocation();
  var ctapage = location.pathname.replace("/", "");
  const [adapplynow, setAdapplynow] = useState(false);
  const handleAadpplyOpen = () => {
    setAdapplynow(true);
  };
  const handleAadpplyclose = () => {
    setAdapplynow(false);
  };
  useEffect(() => {
    let ans = Iitprogram.filter((obj) => {
      return obj.id == id;
    });
    //console.log(ans);
    setData(ans);
    AOS.init({ duration: 2000 });
  }, [id]);
  const classes = useStyles();
  const desksliderRef = useRef();
  const deskgotoNext = () => {
    desksliderRef.current.slickNext();
  };
  const deskgotoPrev = () => {
    desksliderRef.current.slickPrev();
  };
  const progamhandleChange = (event) => {
    setProgramrcb(event.target.value);
  };
  const LoaderClose = () => {
    setLoader(false);
  };
  const LoaderOpen = () => {
    setLoader(true);
  };
  const [applynow, setApplynow] = useState(false);
  const handleApplyOpen = () => {
    setApplynow(true);
  };
  const handleApplyclose = () => {
    setApplynow(false);
  };
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,

    autoplay: true,
    autoplaySpeed: 6000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
      {
        breakpoint: 900,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
    ],
  };
  const options = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };
  const [sc_mobile, setscMobile] = useState("");
  const handleChangescmob = (e) => {
    // //console.log(e.target.value)
    setscMobile(e.target.value);
  };

  const handleScheduleCall = () => {
    if (
      sc_mobile.toString().length >= 10 &&
      sc_mobile.toString().length <= 12
    ) {
      LoaderOpen();
      const sendRcbctaData = {
        phone: sc_mobile,
        page: ctapage,
      };
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/schedule/schedulecall`,
          JSON.stringify(sendRcbctaData),
          options
        )
        .then((result) => {
          // //console.log(result.data.status);
          LoaderClose();

          if (result.data.status == "200") {
            navigate('/cta-thank-you')
            setscMobile("");
            // Swal.fire({
            //   title: "Success",
            //   text: "Your request submitted successfully. Our expert will call you soon.",
            //   icon: "success",
            //   confirmButtonText: "OK",
            // });
          } else {
            setscMobile("");
            Swal.fire({
              title: "Warning",
              text: "Something went wrong. Please try again!",
              icon: "Warning",
              confirmButtonText: "OK",
            });
          }
        });
    } else {
      Swal.fire({
        title: "Warning",
        text: "Please enter your valid mobile number",
        icon: "Warning",
        confirmButtonText: "OK",
      });
    }
  };

  const StyledTextField = withStyles({
    root: {
      "& fieldset": {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        height: "63px!important",
        width: "325px!important",
        border: "1px solid #007bff",
        "@media (max-width: 700px)": {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          height: "63px!important",
          width: "275px!important",
          border: "1px solid #007bff",
        },
      },
    },
  })(TextField);

  const StyledButton = withStyles({
    root: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      background:
        "linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",
      height: "56px!important",
      width: "250px!important",
      padding: "13px!important",
      fontSize: "24px!important",
      "@media (max-width: 700px)": {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        background:
          "linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",
        height: "56px!important",
        width: "295px!important",
        padding: "13px!important",
        fontSize: "24px!important",
      },
    },
  })(Button);
  const [currency, setCurrency] = useState("---- Select Program ----");

  const handleChange = (event) => {
    setCurrency(event.target.value);
  };

  const [expanded, setExpanded] = useState(false);

  const BehandleChange = (pdid) => (event, newExpanded) => {
    setExpanded(newExpanded ? pdid : false);
  };
  const FaqhandleChange = (faq) => (event, newExpanded) => {
    setExpanded(newExpanded ? faq : false);
  };
  const [newexpanded, setExpandednew] = useState(false);
  const FaqsubhandleChange = (childfaqid) => (event, newExpanded) => {
    setExpandednew(newExpanded ? childfaqid : false);
  };

  return (
    <>
      {data &&
        data.map((Iitprogram) => (
          <>
            <Box sx={{}}>
              <Container fixed>
                <Box sx={{ mb: 3 }} >
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{
                      fontSize: {
                        lg: "38px",
                        sm: "38px",
                        md: "38px",
                        xs: "35px",
                      },
                      fontWeight: "bold",
                      pb: 6,
                      textAlign: {
                        lg: "left",
                        md: "center",
                        sm: "center",
                        xs: "center",
                      },
                    }}
                  >
                    Beyond The Program Advantage
                  </Typography>
                  <Box
                    sx={{ mr: { lg: 5, xs: 0, sm: 0, md: 0 }, mb: 6 }}
                  >
                    {Iitprogram.programadvantage &&
                      Iitprogram.programadvantage.map((pd) => (
                        <Accordion
                          sx={{ mb: 2 }}
                          expanded={expanded === pd.pdid}
                          onChange={BehandleChange(pd.pdid)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id={pd.pdid}
                            sx={{
                              height: "86px",
                              border: "solid 0.5px #007bff",
                              borderRadius: "9px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                fontSize: "18px",
                                display: "flex",
                                mt: 5,
                                mb: "40px !important",
                              }}
                            >
                              {" "}
                              <CheckIcon
                                sx={{
                                  color: "#00AF0B",
                                  fontSize: "1.2rem",
                                  mx: 1,
                                  mt: 0.8,
                                }}
                              />
                              {pd.hadding}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              backgroundColor: "#f8f8f8",
                              borderRadius: "9px",
                              border: "solid 0.5px #007bff",
                              p: 0,
                            }}
                          >
                            <Box>
                              <Grid lg={12} xs={12} sm={12} md={12} container>
                                <Grid lg={3} xs={12} sm={6} md={4}>
                                  <CardMedia
                                    component="img"
                                    image={pd.cardimges}
                                    alt="us"
                                    sx={{ py: 3, px: 2 }}
                                    className={pd.classname}
                                  />
                                </Grid>
                                <Grid lg={9} xs={12} sm={6} md={8}>
                                  {pd.details &&
                                    pd.details.map((tr) => (
                                      <>
                                        <Typography
                                          sx={{
                                            px: 3,
                                            mb: "0.9rem!important",
                                            fontWeight: "bold",
                                          }}
                                          className={tr.hdesc}
                                        >
                                          {tr.haddingtext}
                                        </Typography>
                                        <Typography
                                          sx={{ px: 3, mb: "0.9rem!important" }}
                                          className={tr.desc}
                                        >
                                          {tr.texthdding}
                                        </Typography>
                                      </>
                                    ))}
                                </Grid>
                              </Grid>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                  </Box>
                </Box>

                <Box >
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{
                      fontSize: {
                        lg: "38px",
                        sm: "38px",
                        md: "38px",
                        xs: "35px",
                      },
                      fontWeight: "bold",
                      pb: 6,
                      textAlign: {
                        lg: "left",
                        md: "center",
                        sm: "center",
                        xs: "center",
                      },
                    }}
                  >
                    Admission Process
                  </Typography>
                  <Grid
                    lg={12}
                    xs={12}
                    sm={12}
                    md={12}
                    container
                    sx={{ mr: { xs: 0, lg: 3 } }}
                  >
                    <Grid lg={4} sm={12} xs={12} md={6}>
                      <Typography
                        variant="h4"
                        component="div"
                        sx={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#007bff",
                          pb: 2,
                        }}
                      >
                        STEP 1
                      </Typography>
                      <Typography
                        sx={{
                          width: 0,
                          height: 0,
                          borderBottom: "20px solid #0091ff",
                          borderRight: "20px solid transparent",
                          marginBottom: "0px !important",
                          pb: 0,
                        }}
                      ></Typography>
                      <Typography
                        sx={{
                          borderBottom: "1px solid #0091ff",
                          width: "350px",
                        }}
                      ></Typography>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#000",
                          pb: 2,
                          marginBottom: "0px !important",
                        }}
                      >
                        {Iitprogram.steponehadding}
                      </Typography>
                      <Typography
                        sx={{ fontSize: "16px", color: "#000", pb: 2 }}
                      >
                        {Iitprogram.steponedes}
                      </Typography>
                    </Grid>
                    <Grid lg={4} sm={12} xs={12} md={6}>
                      <Typography
                        variant="h4"
                        component="div"
                        sx={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#007bff",
                          pb: 2,
                        }}
                      >
                        STEP 2
                      </Typography>
                      <Typography
                        sx={{
                          width: 0,
                          height: 0,
                          borderBottom: "20px solid #0091ff",
                          borderRight: "20px solid transparent",
                          marginBottom: "0px !important",
                          pb: 0,
                        }}
                      ></Typography>
                      <Typography
                        sx={{
                          borderBottom: "1px solid #0091ff",
                          width: "350px",
                        }}
                      ></Typography>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#000",
                          pb: 2,
                          marginBottom: "0px !important",
                        }}
                      >
                        {Iitprogram.steptwohadding}
                      </Typography>
                      <Typography
                        sx={{ fontSize: "16px", color: "#000", pb: 2 }}
                      >
                        {Iitprogram.steptwodes}
                      </Typography>
                    </Grid>
                    <Grid lg={4} sm={12} xs={12} md={6}>
                      <Typography
                        variant="h4"
                        component="div"
                        sx={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#007bff",
                          pb: 2,
                        }}
                      >
                        STEP 3
                      </Typography>
                      <Typography
                        sx={{
                          width: 0,
                          height: 0,
                          borderBottom: "20px solid #0091ff",
                          borderRight: "20px solid transparent",
                          marginBottom: "0px !important",
                          pb: 0,
                        }}
                      ></Typography>
                      <Typography
                        sx={{
                          borderBottom: "1px solid #0091ff",
                          width: "350px",
                        }}
                      ></Typography>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#000",
                          pb: 2,
                          marginBottom: "0px !important",
                        }}
                      >
                        {Iitprogram.steptreehadding}
                      </Typography>
                      <Typography
                        sx={{ fontSize: "16px", color: "#000", pb: 2 }}
                      >
                        {Iitprogram.steptreedes}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <center>
                  <Button
                    variant="contained"
                    sx={{ mb: 5, mt: 4 }}
                    className={classes.DownloadBrochureone}
                    onClick={handleAadpplyOpen}
                  >
                    Apply Now{" "}
                  </Button>
                </center>
              </Container>
              <Programfee
                programfee={Iitprogram.programtotalfee}
                month12={Iitprogram.mothemi12}
                month18={Iitprogram.mothemi18}
                month24={Iitprogram.mothemi24}
                category={Iitprogram.category}
                university={Iitprogram.university}
                course={Iitprogram.title}
                vertical={Iitprogram.vertical}
              />
              <Container fixed>
                <Box sx={{ pb: 6, mt: 5 }} >
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{ fontSize: "38px", fontWeight: "bold", pb: 6, ml: 1 }}
                  >
                    Frequently Asked Questions
                  </Typography>
                  <Box sx={{ mr: 5 }}>
                    {Iitprogram.faq &&
                      Iitprogram.faq.map((faq) => (
                        <Accordion
                          sx={{
                            boxShadow: "none",
                            "&&:before": {
                              borderBottom: "none",
                              backgroundColor: "#fff",
                            },
                          }}
                          expanded={expanded === faq.faqid}
                          onChange={FaqhandleChange(faq.faqid)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id={faq.faqid}
                            sx={{
                              height: "86px",
                              mb: 2,
                            }}
                          >
                            <Typography
                              sx={{
                                marginBottom: "0px !important",
                                fontSize: "22px",
                                fontWeight: "bold",
                              }}
                            >
                              {faq.manhadding}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {faq.faqtwo &&
                              faq.faqtwo.map((faqtwo) => (
                                <Accordion
                                  sx={{
                                    backgroundColor: "rgb(250, 251, 252)",
                                    boxShadow: "none",
                                    "&&:before": {
                                      borderBottom: "none",
                                      backgroundColor: "#fff",
                                    },
                                  }}
                                  expanded={newexpanded === faqtwo.childfaqid}
                                  onChange={FaqsubhandleChange(
                                    faqtwo.childfaqid
                                  )}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id={faqtwo.childfaqid}
                                  >
                                    <Typography
                                      sx={{
                                        marginBottom: "0px !important",
                                        fontSize: "22px",
                                      }}
                                    >
                                      {faqtwo.secondhadding}
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    {faqtwo.faqdetails &&
                                      faqtwo.faqdetails.map((faqdetails) => (
                                        <Typography
                                          sx={{
                                            fontWeight: "100 !important",
                                            marginBottom: "0.5rem!important",
                                          }}
                                        >
                                          {faqdetails}
                                        </Typography>
                                      ))}
                                  </AccordionDetails>
                                </Accordion>
                              ))}
                          </AccordionDetails>
                        </Accordion>
                      ))}
                  </Box>
                </Box>
                <Box
                  sx={{ mr: { lg: 6, sm: 0, md: 0, xs: 0 }, pb: 6 }}

                >
                  <Grid
                    lg={12}
                    xs={12}
                    sm={12}
                    md={12}
                    container
                    sx={{ boxShadow: "0 0 15px 0 rgb(0 0 0 / 10%)" }}
                  >
                    <Grid lg={12} xs={12} sm={12} md={12}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "34px",
                          marginBottom: "0px !important",
                          mx: 3,
                          pt: 4,
                        }}
                      >
                        Have more queries
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "34px",
                          display: { lg: "flex", sm: "flex", md: "flex" },
                          mx: 3,
                          marginBottom: "0px !important",
                          pb: 4,
                        }}
                      >
                        related to{" "}
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "34px",
                            color: "#002aff",
                            ml: { lg: 1, sm: 1, md: 1, xs: 0 },
                            display: "flex",
                            marginBottom: "0px !important",
                          }}
                        >
                          career
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "34px",
                            ml: { lg: 1, sm: 1, md: 1, xs: 0 },
                            display: "flex",
                            marginBottom: "0px !important",
                          }}
                        >
                          or
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "34px",
                              color: "#002aff",
                              ml: { lg: 1, sm: 1, md: 1, xs: 1 },
                              marginBottom: "0px !important",
                            }}
                          >
                            program?
                          </Typography>
                        </Typography>
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: {
                            lg: "34px",
                            sm: "34px",
                            md: "34px",
                            xs: "25px",
                          },
                          marginBottom: "0px !important",
                          display: { lg: "flex", md: "flex", sm: "flex" },
                          mx: { lg: 3, sm: 2, md: 2, xs: 2 },
                        }}
                      >
                        Call us +91 {Iitprogram.callnumber}{" "}
                        <Typography
                          sx={{
                            fontSize: "34px",
                            marginBottom: "0px !important",
                            ml: 1,
                          }}
                        >
                          (or)
                        </Typography>
                      </Typography>
                      <Grid
                        lg={12}
                        xs={12}
                        sm={12}
                        md={12}
                        container
                        sx={{ mx: 3, pb: 3, pt: 4 }}
                      >
                        <Grid lg={6} xs={12} sm={12} md={12}>
                          <Typography
                            sx={{
                              fontSize: {
                                lg: "25px",
                                md: "25px",
                                sm: "25px",
                                xs: "22px",
                              },

                              ml: { lg: 1, sm: 0, md: 0, xs: 0 },
                              mb: { lg: 0, sm: 3, md: 3, xs: 2 },
                            }}
                          >
                            Schedule chat with our experts
                          </Typography>
                        </Grid>
                        <Grid
                          lg={6}
                          xs={12}
                          sm={12}
                          md={12}
                          sx={{ ml: { lg: -5, xs: 0, sm: 0, md: 0 } }}
                        >
                          <FormGroup row>
                            <TextField
                              variant="outlined"
                              type="number"
                              // height={60}
                              onChange={handleChangescmob}
                              value={sc_mobile}
                              placeholder="+91  enter your number"
                              sx={{
                                mb: { xs: 2 },
                                border: "1px solid #007bff",
                                borderRadius: 2,
                                width: "250px",
                                // height:"60px"
                                // borderTopRightRadius: 0,
                                // borderBottomRightRadius: 0,
                              }}
                            />
                            <StyledButton
                              variant="contained"
                              disableElevation
                              onClick={handleScheduleCall}
                            // sx={{ ml:{lg:5,sm:5,md:5,xs:0} }}
                            >
                              SCHEDULE CALL
                            </StyledButton>
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Container>
            </Box>
            {/*  Mobile version For last section */}

            {/* End Mobile version For last  section */}

            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loader}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Rcta open={applynow} handleClose={handleApplyclose} />
            <ApplyNow
              open={adapplynow}
              handleClose={handleAadpplyclose}
              category={Iitprogram.category}
              university={Iitprogram.university}
              course={Iitprogram.title}
            />
          </>
        ))}
      <Footer />
    </>
  );
}
