import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  ListItemIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Backdrop,
  CardMedia,
  Grid,
  Container,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import Swal from "sweetalert2";
const Datasciencebook = () => {
  const [loopen, setLoOpen] = useState(false);
  const [friendname, setfriendname] = useState("");
  const [friendemail, setfriendemail] = useState("");
  const [friendnumber, setfriendnumber] = useState("");
  const [programname, setProgramname] = useState("");
  const [company, setCompany] = useState("");
  const [job, setJob] = useState("");
  const [open, setOpen] = useState(false);
  const handleChangeprogram = (e) => {
    setProgramname(e.target.value);
  };
  const handleChangename = (e) => {
    setfriendname(e.target.value);
  };
  const handleChangeemail = (e) => {
    setfriendemail(e.target.value);
  };
  const handleChangenumber = (e) => {
    setfriendnumber(e.target.value);
  };
  const handleChangejob = (e) => {
    setJob(e.target.value);
  };
  const handleChangecompany = (e) => {
    setCompany(e.target.value);
  };
  const LoaderOpen = () => {
    setLoOpen(true);
  };
  const LoaderClose = () => {
    setLoOpen(false);
  };
  const handleCloseref = () => {
    setOpen(false);
  };
  const handleClickOpenref = async () => {
    setOpen(true);
  };
  const handleSubmitRef= async(e)=>{
    e.preventDefault();
    LoaderOpen();
    await axios({
      method: "post",
   
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/ebook/ds-ebook`,
      data: {
        phone:friendnumber,
        name: friendname,
        email: friendemail,
        looking_for: programname,
        job_title:job,
        company_name:company,

      },
    }).then((res) => {
      if(res.data.status==200){
        handleCloseref();
        LoaderClose();
        Swal.fire({
          title: "Success",
          text: "Thank you for downloading the Data Science E-book, We hope you like the same!",
          icon: "success",
          confirmButtonText: "OK",
    
   
        }).then((conf) => {
          if (conf.isConfirmed) {
           window.open("https://accredian.com/wp-content/uploads/2023/10/E-book-Data-Science.pdf", "_blank");
          }
        });
     
      }

      //  setfile(filesize(res.data[0].file_size, {base: 2, standard: "jedec"}));
    });
  }
  return (
    <>
      <Box
        sx={{
          backgroundImage: `linear-gradient(rgba(219, 32, 44, 0.8), rgba(219, 32, 44, 0.8)), url(https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/ebook/ebookbg.webp)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center bottom",
        }}
      >
        <center>
          <Box sx={{ pt:8,mb:3 }}>
            <CardMedia
              component="img"
              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accredainnew.webp"
              alt="green iguana"
              sx={{
                width: 270,
                py: 1,
                ml: 2.5,
              }}
            />
          </Box>
          <Box>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "1.3rem",
                textAlign: "center",
                marginBottom: "0rem!important",
              }}
            >
              Thank you for showing interest.
            </Typography>
          </Box>
          <Box sx={{ pb:8 }}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize:{lg: "3.5rem",md: "3.5rem",sm: "3.5rem",xs: "2.5rem"},
                color: "#fff",
                textAlign: "center",
                marginBottom: "0rem!important",
               
              }}
            >
              Download E-book below
            </Typography>
          </Box>
        </center>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            width: 0,
            height: 0,
            borderLeft: "37px solid transparent",
            borderRight: "38px solid transparent",
            borderTop: "20px solid #DE2F2C",
          }}
        ></Box>
      </Box>
      <Box sx={{ backgroundColor: "#F5B525", mt:{lg:-2.5,sm:-2.5,md:-2.5,xs:-2.5}  }}>
        <Box sx={{pt:{lg:12,sm:12,md:12,xs:12}, pb: 10, px:{lg:5,xl:10,xs:0} }}>
          <Container>
        <Grid container sx={{ px:{lg:5,sm:1,md:1,xs:0} }} >
          <Grid item lg={6} sm={6} md={6} xs={12}>
            <Box sx={{ }}>
              <Typography
              variant="h1"
                sx={{
                  fontWeight: 'bold',
                  fontSize:{lg: "4rem",sm: "4rem",md: "4rem",xs: "2rem"},
                  textAlign: "center",
                  lineHeight: 1,
                  color:"rgba(0,0,0,1)",
                  fontFamily:"Ubuntu,sans-serif",
                  fontStyle:"normal"
                }}
              >
                <strong>Data Science </strong>
              </Typography>
              <Typography
              variant="h1"
                sx={{
                  fontWeight: 'bold',
                       fontSize:{lg: "4rem",sm: "4rem",md: "4rem",xs: "2rem"},
                  textAlign: "center",
                  lineHeight: 1,
                  color:"rgba(0,0,0,1)",
                  fontFamily:"Ubuntu,sans-serif",
                  fontStyle:"normal"
                }}
              >
             <strong>Roles & salaries</strong> 
              </Typography>
              <Typography
              variant="h1"
                sx={{
                  fontWeight: 'bold',
                       fontSize:{lg: "4rem",sm: "4rem",md: "4rem",xs: "2rem"},
                  textAlign: "center",
                  lineHeight: 1,
                  color:"rgba(0,0,0,1)",
                  fontFamily:"Ubuntu,sans-serif",
                  fontStyle:"normal"
                }}
              >
              <strong>revealed</strong>
              </Typography>
            </Box>
            <Box sx={{ px:{lg:7,sm:3,md:3,xs:3}, pt: 5 }}>
              <Typography
                sx={{
                  fontSize: "1rem",
                  textAlign: "center",
                  lineHeight: 1.75,
                  fontFamily:"Ubuntu,sans-serif",
                  fontStyle:"normal",
                  marginBottom: "0rem!important",
                }}
              >
                This data science handbook will provide you with all 
              </Typography>
              <Typography
                sx={{
                  fontSize: "1rem",
                  textAlign: "center",
                  lineHeight: 1.75,
                  fontFamily:"Ubuntu,sans-serif",
                  fontStyle:"normal",
                  marginBottom: "0rem!important",
                }}
              >
                 necessary
                information relevant to data science Job
              </Typography>
              <Typography
                sx={{
                  fontSize: "1rem",
                  textAlign: "center",
                  lineHeight: 1.75,
                  fontFamily:"Ubuntu,sans-serif",
                  fontStyle:"normal",
                  marginBottom: "0rem!important",
                }}
              >
               functions & salaries
              </Typography>
            </Box>
            <Box sx={{ mt: 4 }}>
              <center>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "rgb(219,32,44)",
                    textTransform: "none",
                    borderRadius: "12px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    padding:"14px 24px",
                    border:"1px solid rgb(219,32,44)",
                    boxShadow:"none",
                    "&:hover": {
                      backgroundColor: "rgb(219,32,44)",
                      textTransform: "none",
                      borderRadius: "8px",
                      fontSize: "18px",
                      fontWeight: "bold",
                      padding:"14px 24px",
                      border:"1px solid rgb(219,32,44)",
                      boxShadow:"none",
                    },
                  }}
                  onClick={handleClickOpenref}
                >
                  Download E-book Now
                </Button>
              </center>
            </Box>
          </Grid>
          <Grid item lg={6} sm={6} md={6} xs={12} sx={{mt:{lg:0,sm:0,md:0,xs:4}}}>
            <CardMedia
              component="img"
              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/ebook/sectionone.webp"
              alt="green iguana"
              sx={{ maxWidth:{lg:"482px",sm:"482px",md:"482px",xs:"500px"}, ml:{lg:5,md:2,sm:2} }}
            />
          </Grid>
        </Grid>
        </Container>
        </Box>
        
      </Box>
      <Box sx={{ mb: 5 }}>
        <Box sx={{ mt:{lg:5,md:5,sm:5,xs:7}, mb: 5 }}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize:{lg:"2.5rem",sm:"2.5rem",md:"2.5rem",xs:"2rem"}, 
              color: "#DB204E",
              textAlign: "center",
              fontFamily:"Ubuntu,sans-serif",
              fontStyle:"normal"
            }}
          >
            What Data Science Tools you can master
          </Typography>
        </Box>
        <Box sx={{ml:{lg:20,sm:10,md:13,xs:7}}}>
          <Container>
          <Grid container sx={{ mb:{lg:6,sm:6,md:6,xs:0} }}>
          

            <Grid item lg={2} sm={2} md={2} xs={12}>
              <CardMedia
                component="img"
                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/5.webp"
                alt="green iguana"
                  sx={{ width:{lg:"170px",sm:"110px",md:"140px",xs:"250px"},mb:{xs:5,md:0,sm:0,lg:0}  }}
              />
            </Grid>
            <Grid item lg={2} sm={2} md={2} xs={12}>
              <CardMedia
                component="img"
                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/1.webp"
                alt="green iguana"
                  sx={{ width:{lg:"170px",sm:"110px",md:"140px",xs:"250px"},ml:{sm:2,md:2,xs:0,lg:0},mb:{xs:5,md:0,sm:0,lg:0}    }}
              />
            </Grid>
            <Grid item lg={2} sm={2} md={2} xs={12}>
              <CardMedia
                component="img"
                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/2.webp"
                alt="green iguana"
                  sx={{ width:{lg:"170px",sm:"110px",md:"140px",xs:"250px"},ml:{sm:4,md:4,xs:0,lg:0},mb:{xs:5,md:0,sm:0,lg:0}    }}
              />
            </Grid>
            <Grid item lg={2} sm={2} md={2} xs={12}>
              <CardMedia
                component="img"
                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/3.webp"
                alt="green iguana"
                  sx={{ width:{lg:"170px",sm:"110px",md:"140px",xs:"250px"},ml:{sm:6,md:6,xs:0,lg:0},mb:{xs:5,md:0,sm:0,lg:0}    }}
              />
            </Grid>
            <Grid item lg={2} sm={2} md={2} xs={12}>
              <CardMedia
                component="img"
                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/6.webp"
                alt="green iguana"
                  sx={{ width:{lg:"170px",sm:"110px",md:"140px",xs:"250px"},ml:{sm:8,md:8,xs:0,lg:0},mb:{xs:5,md:0,sm:0,lg:0}    }}
              />
            </Grid>
         
          </Grid>
          <Grid container sx={{ pb: 3 }}>
        

            <Grid item lg={2} sm={2} md={2} xs={12}>
              <CardMedia
                component="img"
                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/17.webp"
                alt="green iguana"
                  sx={{ width:{lg:"170px",sm:"110px",md:"140px",xs:"250px"},mb:{xs:5,md:0,sm:0,lg:0}   }}
              />
            </Grid>
            <Grid item lg={2} sm={2} md={2} xs={12}>
              <CardMedia
                component="img"
                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/gcddsai/Keras.webp"
                alt="green iguana"
                sx={{ width:{lg:"170px",sm:"110px",md:"140px",xs:"250px"},ml:{sm:2,md:2,xs:0,lg:0},mb:{xs:5,md:0,sm:0,lg:0}   }}
              />
            </Grid>
            <Grid item lg={2} sm={2} md={2} xs={12}>
              <CardMedia
                component="img"
                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/\tools-images/gcddsai/Tensorflow.webp"
                alt="green iguana"
                  sx={{ width:{lg:"170px",sm:"110px",md:"140px",xs:"250px"},ml:{sm:4,md:4,xs:0,lg:0},mb:{xs:5,md:0,sm:0,lg:0}    }}
              />
            </Grid>
            <Grid item lg={2} sm={2} md={2} xs={12}>
              <CardMedia
                component="img"
                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/4.webp"
                alt="green iguana"
                  sx={{ width:{lg:"170px",sm:"110px",md:"140px",xs:"250px"},ml:{sm:6,md:6,xs:0,lg:0},mb:{xs:5,md:0,sm:0,lg:0}    }}
              />
            </Grid>
            <Grid item lg={2} sm={2} md={2} xs={12}>
              <CardMedia
                component="img"
                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/18.webp"
                alt="green iguana"
                  sx={{ width:{lg:"170px",sm:"110px",md:"140px",xs:"250px"},ml:{sm:8,md:8,xs:0,lg:0},mb:{xs:5,md:0,sm:0,lg:0}    }}
              />
            </Grid>
           
          </Grid>
       </Container>
        </Box>
      </Box>
      <Box sx={{ backgroundColor: "#F5B525" }}>
      <Box sx={{pt:{lg:12,sm:12,md:12,xs:2}, pb: 10, px:{lg:5,xl:10} }}>
          <Container>
        <Grid container sx={{ px:{lg:5,xl:10} }} >
          <Grid item lg={6} xs={12} sm={6} md={6}>
            <Box sx={{  }}>
            <Typography
            variant="h1"
                sx={{
                  fontWeight: 'bold',
                       fontSize:{lg: "4rem",sm: "4rem",md: "4rem",xs: "2rem"},
                  textAlign: "center",
                  lineHeight: 1.2,
                  color:"rgba(0,0,0,1)",
                  fontFamily:"Ubuntu,sans-serif",
                  fontStyle:"normal"
                }}
              >
              <strong>MUST-HAVE</strong>
              </Typography>
              <Typography
              variant="h1"
                sx={{
                  fontWeight: 'bold',
                       fontSize:{lg: "4rem",sm: "4rem",md: "4rem",xs: "2rem"},
                  textAlign: "center",
                  lineHeight: 1.2,
                  color:"rgba(0,0,0,1)",
                  fontFamily:"Ubuntu,sans-serif",
                  fontStyle:"normal"
                }}
              >
              <strong>DATA</strong>
              </Typography>
              <Typography
              variant="h1"
                sx={{
                  fontWeight: 'bold',
                       fontSize:{lg: "4rem",sm: "4rem",md: "4rem",xs: "2rem"},
                  textAlign: "center",
                  lineHeight: 1.2,
                  color:"rgba(0,0,0,1)",
                  fontFamily:"Ubuntu,sans-serif",
                  fontStyle:"normal"
                }}
              >
              <strong>SCIENCE</strong>
              </Typography>
              <Typography
              variant="h1"
                sx={{
                  fontWeight: 'bold',
                       fontSize:{lg: "4rem",sm: "4rem",md: "4rem",xs: "2rem"},
                  textAlign: "center",
                  lineHeight: 1.2,
                  color:"rgba(0,0,0,1)",
                  fontFamily:"Ubuntu,sans-serif",
                  fontStyle:"normal"
                }}
              >
              <strong>SKILLS</strong>
              </Typography>
              <Typography
              variant="h1"
                sx={{
                  fontWeight: 'bold',
                       fontSize:{lg: "4rem",sm: "4rem",md: "4rem",xs: "2rem"},
                  textAlign: "center",
                  lineHeight: 1.2,
                  color:"rgba(0,0,0,1)",
                  fontFamily:"Ubuntu,sans-serif",
                  fontStyle:"normal"
                }}
              >
              <strong>TO GET HIRED</strong>
              </Typography>
           
            </Box>
            <Box sx={{ px: 7, pt: 3 }}>
              <Typography
                sx={{
                  fontSize: "1.2rem",
                  textAlign: "center",
                  lineHeight: 1.75,
                  fontFamily:"Ubuntu,sans-serif",
                  fontStyle:"normal"
                }}
              >
                Get to know what data science skills are required to get hired
                in MAANG companies.
              </Typography>
            </Box>
            <Box sx={{ mt: 4 }}>
              <center>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "rgb(219,32,44)",
                    textTransform: "none",
                    borderRadius: "12px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    padding:"14px 24px",
                    border:"1px solid rgb(219,32,44)",
                    boxShadow:"none",
                    "&:hover": {
                      backgroundColor: "rgb(219,32,44)",
                      textTransform: "none",
                      borderRadius: "8px",
                      fontSize: "18px",
                      fontWeight: "bold",
                      padding:"14px 24px",
                      border:"1px solid rgb(219,32,44)",
                      boxShadow:"none",
                    },
                  }}
                  onClick={handleClickOpenref}
                >
                  Download E-book Now
                </Button>
              </center>
            </Box>
          </Grid>
          <Grid item lg={6} xs={12} sm={6} md={6} sx={{mt:{lg:0,sm:0,md:0,xs:4}}}>
            <CardMedia
              component="img"
              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/ebook/sectiontwo.png"
              alt="green iguana"
              sx={{maxWidth:{lg:"482px",sm:"482px",md:"482px"}, ml:{lg:5,md:2,sm:2}  }}
            />
          </Grid>
        </Grid>
        </Container>
        </Box>
      </Box>
      <Box>
      <Box sx={{pt:{lg:12,sm:12,md:12,xs:2}, pb: 10, px:{lg:5,xl:10} }}>
          <Container>
        <Grid container sx={{ px:{lg:5,xl:10} }} >
          <Grid item lg={6} xs={12} sm={6} md={6}>
            <CardMedia
              component="img"
              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/ebook/sectionthree.png"
              alt="green iguana"
              sx={{ maxWidth: "482px",}}
            />
          </Grid>
          <Grid item lg={6} xs={12} sm={6} md={6} sx={{mt:{lg:0,sm:0,md:0,xs:4}}}>
            <Box sx={{}}>
            <Typography
            variant="h1"
                sx={{
                  fontWeight: 'bold',
                       fontSize:{lg: "4rem",sm: "4rem",md: "4rem",xs: "2rem"},
                  textAlign: "center",
                  lineHeight: 1.1,
                  color:"rgba(0,0,0,1)",
                  fontFamily:"Ubuntu,sans-serif",
                  fontStyle:"normal"
                }}
              >
              <strong> While</strong>
              </Typography>
              <Typography
            variant="h1"
                sx={{
                  fontWeight: 'bold',
                       fontSize:{lg: "4rem",sm: "4rem",md: "4rem",xs: "2rem"},
                  textAlign: "center",
                  lineHeight: 1.1,
                  color:"rgba(0,0,0,1)",
                  fontFamily:"Ubuntu,sans-serif",
                  fontStyle:"normal"
                }}
              >
              <strong> Transition into</strong>
              </Typography>
              <Typography
            variant="h1"
                sx={{
                  fontWeight: 'bold',
                       fontSize:{lg: "4rem",sm: "4rem",md: "4rem",xs: "2rem"},
                  textAlign: "center",
                  lineHeight: 1.1,
                  color:"rgba(0,0,0,1)",
                  fontFamily:"Ubuntu,sans-serif",
                  fontStyle:"normal"
                }}
              >
              <strong> DATA SCIENCE</strong>
              </Typography>
              <Typography
            variant="h1"
                sx={{
                  fontWeight: 'bold',
                       fontSize:{lg: "4rem",sm: "4rem",md: "4rem",xs: "2rem"},
                  textAlign: "center",
                  lineHeight: 1.1,
                  color:"rgba(0,0,0,1)",
                  fontFamily:"Ubuntu,sans-serif",
                  fontStyle:"normal"
                }}
              >
              <strong>you must have</strong>
              </Typography>
              <Typography
            variant="h1"
                sx={{
                  fontWeight: 'bold',
                       fontSize:{lg: "4rem",sm: "4rem",md: "4rem",xs: "2rem"},
                  textAlign: "center",
                  lineHeight: 1.1,
                  color:"rgba(0,0,0,1)",
                  fontFamily:"Ubuntu,sans-serif",
                  fontStyle:"normal"
                }}
              >
              <strong>
                  lot of questions</strong>
              </Typography>
        
            </Box>
            <Box sx={{ px: 7, pt: 3 }}>
              <Typography
                sx={{
                  fontSize: "1.2rem",
                  textAlign: "center",
                  lineHeight: 1.75,
                  fontFamily:"Ubuntu,sans-serif",
                  fontStyle:"normal"
                }}
              >
                Get clarity where to get started!
              </Typography>
            </Box>
            <Box sx={{ mt: 4 }}>
              <center>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "rgb(219,32,44)",
                    textTransform: "none",
                    borderRadius: "12px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    padding:"14px 24px",
                    border:"1px solid rgb(219,32,44)",
                    boxShadow:"none",
                    "&:hover": {
                      backgroundColor: "rgb(219,32,44)",
                      textTransform: "none",
                      borderRadius: "8px",
                      fontSize: "18px",
                      fontWeight: "bold",
                      padding:"14px 24px",
                      border:"1px solid rgb(219,32,44)",
                      boxShadow:"none",
                    },
                  }}
                  onClick={handleClickOpenref}
                >
                  Download E-book below
                </Button>
              </center>
            </Box>
          </Grid>
        </Grid>
        </Container>
        </Box>
      </Box>
      <Box sx={{ backgroundColor: "#F5B525", mb: 5 }}>
        <Box sx={{ pt:{lg:9,md:9,sm:9,xs:0}, display: "flex", justifyContent: "center" }}>
          <Box sx={{ borderBottom: "1px solid #000",width:"1085px",display:{lg:"block",md:"block",sm:"block",xs:"none"} }}></Box>
        </Box>
        <Box sx={{ pt:5,pb:3 }}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "2.25rem",
              textAlign: "center",
              fontFamily:"Ubuntu,sans-serif",
              fontStyle:"normal"
            }}
          >
            What other readers have to say
          </Typography>
        </Box>
        <Grid container sx={{ px:{lg:15,sm:15,md:15,xs:3}, pb: 5 }}>
          <Grid item lg={6} xs={12} sm={6} md={6} sx={{mb:{xs:5,sm:5,md:5,lg:5} }}>
            <CardMedia
              component="img"
              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/ebook/t1.png"
              alt="green iguana"
            
            />
          </Grid>
          <Grid item lg={6} xs={12} sm={6} md={6} sx={{ mb:{xs:5,sm:5,md:5,lg:5}, pl:{lg:3,sm:3,md:3,xs:0}}}>
            <CardMedia
              component="img"
              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/ebook/t2.png"
              alt="green iguana"
             
            />
          </Grid>
          <Grid item lg={6} xs={12} sm={6} md={6} sx={{ mb:{xs:5,sm:5,md:5,lg:5} }}>
            <CardMedia
              component="img"
              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/ebook/t3.png"
              alt="green iguana"
           
            />
          </Grid>
          <Grid item lg={6} xs={12} sm={6} md={6} sx={{mb:{xs:5,sm:5,md:5,lg:5}, pl:{lg:3,sm:3,md:3,xs:0} }}>
            <CardMedia
              component="img"
              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/ebook/t4.png"
              alt="green iguana"
           
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ pt:{lg:12,sm:12,md:12,xs:2}, pb: 10, px:{lg:5,xl:10} }}>
          <Container>
        <Grid container sx={{ px:{lg:5,xl:10} }} >
          <Grid item lg={6} xs={12} sm={6} md={6}>
            <Box sx={{ }}>
            <Typography
            variant="h1"
                sx={{
                  fontWeight: 'bold',
                       fontSize:{lg: "4rem",sm: "4rem",md: "4rem",xs: "2rem"},
                  textAlign: "center",
                  lineHeight: 1.1,
                  color:"rgba(0,0,0,1)",
                  fontFamily:"Ubuntu,sans-serif",
                  fontStyle:"normal"
                }}
              >
              <strong>
              HOW TO </strong>
              </Typography>

              <Typography
            variant="h1"
                sx={{
                  fontWeight: 'bold',
                       fontSize:{lg: "4rem",sm: "4rem",md: "4rem",xs: "2rem"},
                  textAlign: "center",
                  lineHeight: 1.1,
                  color:"rgba(0,0,0,1)",
                  fontFamily:"Ubuntu,sans-serif",
                  fontStyle:"normal"
                }}
              >
              <strong>
             KICKSTART</strong>
             </Typography>
             <Typography
            variant="h1"
                sx={{
                  fontWeight: 'bold',
                       fontSize:{lg: "4rem",sm: "4rem",md: "4rem",xs: "2rem"},
                  textAlign: "center",
                  lineHeight: 1.1,
                  color:"rgba(0,0,0,1)",
                  fontFamily:"Ubuntu,sans-serif",
                  fontStyle:"normal"
                }}
              >
              <strong>
              YOUR CAREER </strong>
              </Typography>
              <Typography
            variant="h1"
                sx={{
                  fontWeight: 'bold',
                       fontSize:{lg: "4rem",sm: "4rem",md: "4rem",xs: "2rem"},
                  textAlign: "center",
                  lineHeight: 1.1,
                  color:"rgba(0,0,0,1)",
                  fontFamily:"Ubuntu,sans-serif",
                  fontStyle:"normal"
                }}
              >
              <strong>
             IN</strong>
              </Typography>
              <Typography
            variant="h1"
                sx={{
                  fontWeight: 'bold',
                       fontSize:{lg: "4rem",sm: "4rem",md: "4rem",xs: "2rem"},
                  textAlign: "center",
                  lineHeight: 1.1,
                  color:"rgba(0,0,0,1)",
                  fontFamily:"Ubuntu,sans-serif",
                  fontStyle:"normal"
                }}
              >
              <strong>
              DATA SCIENCE</strong>
              </Typography>
        
            </Box>
            <Box sx={{ px: 7, pt: 3 }}>
              <Typography
                sx={{
                  fontSize: "1.2rem",
                  textAlign: "center",
                  lineHeight: 1.75,
                  fontFamily:"Ubuntu,sans-serif",
                  fontStyle:"normal"
                }}
              >
                Get step by step learning path to get started in Data Science.
              </Typography>
            </Box>
            <Box sx={{ mt: 4 }}>
              <center>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "rgb(219,32,44)",
                    textTransform: "none",
                    borderRadius: "12px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    padding:"14px 24px",
                    border:"1px solid rgb(219,32,44)",
                    boxShadow:"none",
                    "&:hover": {
                      backgroundColor: "rgb(219,32,44)",
                      textTransform: "none",
                      borderRadius: "8px",
                      fontSize: "18px",
                      fontWeight: "bold",
                      padding:"14px 24px",
                      border:"1px solid rgb(219,32,44)",
                      boxShadow:"none",
                    },
                  }}
                  onClick={handleClickOpenref}
                >
                  Download E-book Now
                </Button>
              </center>
            </Box>
          </Grid>
          <Grid item lg={6} xs={12} sm={6} md={6} sx={{mt:{lg:0,sm:0,md:0,xs:4}}}>
            <CardMedia
              component="img"
              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/ebook/sectionfour.png"
              alt="green iguana"
              sx={{ maxWidth:{lg:"482px",sm:"482px",md:"482px"}, ml:{lg:5,md:2,sm:2}  }}
            />
          </Grid>
        </Grid>
        </Container>
        </Box>
   
      <Box sx={{ backgroundColor: "rgba(219,32,44,1)" }}>
        <Box sx={{ pt:10,pb:5 }}>
          <Typography
            sx={{ color: "#fff", textAlign: "center", fontSize:{lg:"1.22rem",sm: "1.22rem",md: "1.22rem",xs: "1rem"},pb:1,   fontFamily:"Ubuntu,sans-serif",
            fontStyle:"normal" }}
          >
            For queries related to Data Science
          </Typography>
          <Typography
            sx={{ color: "#fff", textAlign: "center", fontSize:{lg:"1.22rem",sm: "1.22rem",md: "1.22rem",xs: "1rem"},pb:1,   fontFamily:"Ubuntu,sans-serif",
            fontStyle:"normal" }}
          >
            Reach us directly at +91 7063119228
          </Typography>
          <Typography
            sx={{ color: "#fff", textAlign: "center", fontSize:{lg:"1.22rem",sm: "1.22rem",md: "1.22rem",xs: "1rem"},pb:1,   fontFamily:"Ubuntu,sans-serif",
            fontStyle:"normal" }}
          >
            or write to us at admissions@accredian.com
          </Typography>
          <Typography
            sx={{
              color: "#fff",
              textAlign: "center",
              fontSize: "0.9rem",
              marginBottom: "0rem!important",
              fontFamily:"Ubuntu,sans-serif",
              fontStyle:"normal"
            }}
          >
            © 2023 Accredian, All Rights Reserved.
          </Typography>
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={handleCloseref}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ p:{lg:3,md:3,sm:3,xs:0},overflow:'hidden' }}
      >
        <DialogTitle
          sx={{
            width: { lg: "430px", sm: "430px", md: "430px" },
            fontSize:{lg:25,md:25,sm:25,xs:17},
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Get your FREE E-Book Now
          <ClearIcon
            onClick={handleCloseref}
            sx={{ float: "right", mt: 0.5, ml: 2, cursor: "pointer" }}
          />
        </DialogTitle>
        <DialogContent sx={{}}>
          <form onSubmit={handleSubmitRef}>
            <Box sx={{ mb: 2, mt: 2 }}>
              <TextField
                onChange={handleChangename}
                fullWidth
                label="Name"
                required={true}
                name="name"
                id="name"
                type="text"
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                onChange={handleChangeemail}
                fullWidth
                label="Email"
                required={true}
                name="email"
                id="email"
                type="email"
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                onChange={handleChangenumber}
                fullWidth
                label="Mobile"
                required={true}
                name="number"
                id="number"
                type="number"
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                onChange={handleChangejob}
                fullWidth
                label="Job title"
                required={true}
                name="job"
                id="job"
                type="text"
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                onChange={handleChangecompany}
                fullWidth
                label="Company name"
                required={true}
                name="company"
                id="job"
                type="text"
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                id="program"
                select
                name="program"
                onChange={handleChangeprogram}
                fullWidth
                label={
                  programname === ""
                    ? "Looking for DS Career Transition? (Yes/No)"
                    : ""
                }
                // label="Select Program"
                required={true}
                InputLabelProps={{ shrink: false }}
                // defaultValue="Select a program"
              >
                {/* <MenuItem value="Select a program">
                <em>--- Select a program ---</em>
              </MenuItem> */}
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </TextField>
            </Box>

            <Box>
              <Button
                variant="contained"
                type="submit"
                fullWidth
                sx={{
                  backgroundColor: "rgb(219,32,44)",
                  textTransform: "none",
                  borderRadius: "12px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  padding:"5px 5px",
                  border:"1px solid rgb(219,32,44)",
                  boxShadow:"none",
                  "&:hover": {
                    backgroundColor: "rgb(219,32,44)",
                    textTransform: "none",
                    borderRadius: "8px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    padding:"5px 5px",
                    border:"1px solid rgb(219,32,44)",
                    boxShadow:"none",
                  },
                }}
              >
                Download Now
              </Button>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ fontSize: "10px", textAlign: "center" }}>
                Privacy Policy: We hate spam and promise to keep your email
                address safe.
              </Typography>
            </Box>
          </form>
        </DialogContent>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loopen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    </>
  );
};
export default Datasciencebook;
