import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Container,
  Grid,
  CardMedia,
  Badge,
  Popover,
  TextField,
  Backdrop,
} from "@mui/material";
import { Link, useNavigate,useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Footer from "../Footer/Footer";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { decode as atob, encode as btoa } from "base-64";
import Testingnavbar from "../Navbar/testinnav";
import Swal from 'sweetalert2';
const Resetpassword = () => {
  var CryptoJS = require("crypto-js");
  let navigate = useNavigate();
  const { id } = useParams();
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [message, setMessage] = useState();
  const [messagecolor, setMessagecolor] = useState();
  const [user, setUser] = useState();
  const [npassword, setPassword] = useState();
  const [open, setOpen] = useState(false);
  const LoaderClose = () => {
    setOpen(false);
  };
  const LoaderOpen = () => {
    setOpen(true);
  };
  var pass, conPass;

  const fetchVal = (e) => {
    if (e.target.id == "password") {
      pass = e.target.value;
      comparePassword(pass, conPass);
    }
    if (e.target.id == "confirmPassword") {
      conPass = e.target.value;
      comparePassword(pass, conPass);
    }
  };
  const comparePassword = (pass, conPass) => {
    if (pass == "" && conPass == "") {
      setBtnDisabled(true);
      setMessage("");
    } else if (pass == conPass) {
      setBtnDisabled(false);
      setMessage("New Password and Confirm Password are matched");
      setMessagecolor("#248f24");
      setPassword(pass);
    } else if (pass != conPass) {
      setBtnDisabled(true);
      setMessage("New Password and Confirm Password are not matched");
      setMessagecolor("#ff6666");
    }
  };


  const submitForm = async (e) => {
    e.preventDefault();
    LoaderOpen();
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    const sandData = {
    user_id:atob(id),
    password: CryptoJS.AES.encrypt(
        npassword,
        "ACCREDIAN@login$2022@$newweb$@"
      ).toString(),
    };
    await axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/change-password`,
        JSON.stringify(sandData),
        options
      )
      .then((result) => {
        if (result.data.status == "200") {
            Swal.fire({
                title: "Success",
                text: "Your password has been changed successfully.",
                icon: "success",
                confirmButtonText: "OK",
          
         
              }).then((conf) => {
                if (conf.isConfirmed) {
                    localStorage.clear();
                    navigate(`/Login`);
                }
              });
      
         
        }
        LoaderClose();
      });
  };

  return (
    <>
      <Testingnavbar />
      <Box sx={{ pt: 15, pb: 5 }}>
      <Container>
          <Grid container spacing={4} justifyContent="center">
            <Grid item lg={4} sx={{ pb: 4 }}>
              <Box
                sx={{
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  p: 3,
                }}
              >
    
    
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1rem",
                        marginBottom: "1rem!important",
                        px: 3,
                      }}
                    >
                      Reset Password
                    </Typography>
                

                  <Box sx={{ px: 2 }}>
                    <form onSubmit={submitForm}>
                      <TextField
                        id="password"
                        label="New Password"
                        fullWidth
                        sx={{ pr: 3, mb: 3 }}
                        name="password"
                        type="password"
                        requireds
                        InputLabelProps={{ shrink: true }}
                        onChange={fetchVal}
                      />

                      <TextField
                        id="confirmPassword"
                        label="Confirm New Password"
                        fullWidth
                        sx={{ pr: 3, mb: 3 }}
                        name="qualification"
                        type="password"
                        required
                        InputLabelProps={{ shrink: true }}
                        onChange={fetchVal}
                      />

                      <Typography
                        color={messagecolor}
                        sx={{ fontSize: "0.8rem", fontWeight: "bold" }}
                      >
                        {message}
                      </Typography>

                      <Button
                        variant="contained"
                        type="submit"
                        sx={{
                          mb: 0.3,

                          mr: 3,
                          background: "#009ef7",

                          borderRadius: "8px",
                          textTransform: "none",

                          "&:hover": {
                            background: "#009ef7",

                            borderRadius: "8px",
                            textTransform: "none",
                          },
                        }}
                        id="submit-button"
                        disabled={btnDisabled}
                      >
                        Save changes
                      </Button>
                    </form>
                    <Box sx={{ my: 1 }}>
                      <Typography
                        sx={{
                          fontSize: "0.8rem",
                          
                          fontWeight: "bold",
                        }}
                      >
                      Note:- *New Password And Confirm Password should be the same{" "}
                      </Typography>
                    </Box>
                  </Box>
                  </Box>
          
            </Grid>
          </Grid>
          </Container>
          </Box>
          <Footer />
          <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
export default Resetpassword;
