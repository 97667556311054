import React, { useRef, useEffect, useState } from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import ApplyNow from "../../Components/Signup/ApplyNow";
import Rcta from "../../Components/cta/request_a_call_back";
export default function Programfee(props) {
  // console.log(props,"kkk")
  const [applynow, setApplynow] = useState(false);
  const [cta, setCta] = useState(false);
  const handleApplyOpen = () => {
    setApplynow(true);
  };
  const handleApplyclose = () => {
    setApplynow(false);
  };

  const handleClickOpenRcb = () => {
    setCta(true);
  };
  const RcbhandleClose = () => {
    setCta(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: {
            lg: "linear-gradient( -56deg, #f4f5f7 0%, #f4f5f7 55%, #013A6B 40%, #013A6B 40%)",
            xs: "linear-gradient( 0deg, #f4f5f7 0%, #f4f5f7 55%, #013A6B 40%, #013A6B 40%)",
            md: "linear-gradient( -56deg, #f4f5f7 0%, #f4f5f7 55%, #013A6B 40%, #013A6B 40%)",
            sm: "linear-gradient( -56deg, #f4f5f7 0%, #f4f5f7 55%, #013A6B 40%, #013A6B 40%)",
          },
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        }}
      // linear-gradient(90deg, rgba(6, 23, 255, 1) 0%, rgba(6, 23, 255, 1) 35%, rgba(115, 76, 255, 1) 86%)
      >
        <Box>
          <Grid lg={12} xs={12} sm={12} md={12} container>
            <Grid lg={6} xs={12} sm={6} md={6}>
              <Box>
                <Box
                  sx={{
                    padding: {
                      lg: "73px 89px !important",
                      xs: "66px 20px !important",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      ml: { lg: 0, sm: 0, md: 0, xs: 0 },
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        textAlign: "left",
                        fontSize: {
                          lg: "34px",
                          xs: "32px",
                          sm: "34px",
                          md: "34px",
                        },
                      }}
                    >
                      Program Fee
                    </Typography>
                    <Typography
                      sx={{
                        color: "#fff",
                        textAlign: "left",
                        fontSize: "15px",
                        pt: 2.7,
                        ml: 1,
                      }}
                    >
                      (Pre Scholarship)
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      color: "#fff",
                      textAlign: "left",
                      fontSize: "58px",
                      fontWeight: "bold",
                    }}
                  >
                    ₹ {props.programfee}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff",
                      textAlign: "left",
                      fontSize: "24px",
                    }}
                  >
                    (plus applicable GST)
                  </Typography>
                  <Box sx={{ pt: 5 }}>
                    <Button
                      variant="contained"
                      sx={{
                        color: "#000 !important",
                        backgroundColor: "#fff !important",
                        borderColor: "#fff !important",
                        borderRadius: "5px !important",
                        width: "140px",
                        textTransform: "none !important",
                        fontSize: "18px",
                        p: 1,
                      }}
                      onClick={handleClickOpenRcb}
                    >
                      Enquire Now{" "}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid lg={6} xs={12} sm={6} md={6}>
              <Box
                sx={{
                  padding: {
                    lg: "73px 44px!important",
                    xs: "80px 20px !important",
                  },
                  ml: { lg: 2, xs: 0, md: 2 },
                }}
              >
                <Box sx={{ ml: { lg: 0, xs: 0, md: 5, sm: 10 }, mt: { lg: 0, xs: 0, md: 12, sm: 10 }, }}>
                  <Typography sx={{ fontSize: "28px", fontWeight: 700 }}>
                    EMI Options
                  </Typography>
                </Box>
                <Box sx={{ pt: 1.5 }}>
                  <Box sx={{
                    display: "flex",
                    border: "1px solid #000",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                  }}
                  >
                    <Box
                      sx={{
                        width: {
                          lg: "385px",
                          xs: "148px",
                          sm: "358px",
                          md: "420px",
                        },
                        borderRight: "1px solid #000",
                        p: 2,
                        // height: { lg: "21px", xs: "40px",md:"50px"} ,
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#3c4852",
                          fontSize: {
                            lg: "16px",
                            xs: "18px",
                            sm: "20px",
                            md: "23px",
                          },
                          fontWeight: 600,
                          marginBottom: "0rem !important",
                        }}
                      >
                        12 month EMI (No cost EMI)
                      </Typography>
                    </Box>
                    <Box sx={{ p: 2, width: "40%", height: "25px" }}>
                      <Typography
                        sx={{
                          color: "#3c4852",
                          fontSize: {
                            lg: "16px",
                            xs: "18px",
                            sm: "20px",
                            md: "23px",
                          },
                          fontWeight: 700,
                          marginBottom: "0rem !important",
                        }}
                      >
                        Rs.{props.month12}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", border: "1px solid #000" }}>
                    <Box
                      sx={{
                        width: {
                          lg: "385px",
                          xs: "148px",
                          sm: "358px",
                          md: "420px",
                        },
                        borderRight: "1px solid #000",
                        p: 2,
                        // height: { lg: "21px", xs: "40px",md:"50px"} ,
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#3c4852",
                          fontSize: {
                            lg: "16px",
                            xs: "18px",
                            sm: "20px",
                            md: "23px",
                          },
                          fontWeight: 600,
                          marginBottom: "0rem !important",
                        }}
                      >
                        18 month EMI (Standard EMI)
                      </Typography>
                    </Box>
                    <Box sx={{ p: 2, width: "40%", height: "25px" }}>
                      <Typography
                        sx={{
                          color: "#3c4852",
                          fontSize: {
                            lg: "16px",
                            xs: "18px",
                            sm: "20px",
                            md: "23px",
                          },
                          fontWeight: 700,
                          marginBottom: "0rem !important",
                        }}
                      >
                        Rs.{props.month18}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", border: "1px solid #000" }}>
                    <Box
                      sx={{
                        width: {
                          lg: "385px",
                          xs: "148px",
                          sm: "358px",
                          md: "420px",
                        },
                        borderRight: "1px solid #000",
                        p: 2,
                        // height: { lg: "21px", xs: "40px",md:"50px"} ,
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#3c4852",
                          fontSize: {
                            lg: "16px",
                            xs: "18px",
                            sm: "20px",
                            md: "23px",
                          },
                          fontWeight: 700,
                          marginBottom: "0rem !important",
                        }}
                      >
                        24 month EMI (Standard EMI)
                      </Typography>
                    </Box>
                    <Box sx={{ p: 2, width: "40%", height: "25px" }}>
                      <Typography
                        sx={{
                          color: "#3c4852",
                          fontSize: {
                            lg: "16px",
                            xs: "18px",
                            sm: "20px",
                            md: "23px",
                          },
                          fontWeight: 700,
                          marginBottom: "0rem !important",
                        }}
                      >
                        Rs.{props.month24}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ pt: 2, display: { lg: "flex", md: "flex", xs: "block" }, justifyContent: { xs: "center" }, alignItems: { xs: "center" } }}>
                  <Box >

                    <Button
                      variant="contained"
                      sx={{
                        color: "#fff !important",
                        backgroundColor: "#fff !important",
                        background:
                          "linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",
                        borderRadius: "5px !important",
                        width: { lg: "190px", xs: "340px", sm: "190px", md: "190px" },
                        textTransform: "none !important",
                        fontSize: "18px",
                        isplay: { lg: "block", md: "flex", xs: "block" }, justifyContent: { xs: "center" }, alignItems: { xs: "center" },
                        p: 1,
                      }}
                      onClick={handleClickOpenRcb}
                    // onClick={handleApplyOpen}
                    >
                      Avail EMI Options{" "}
                    </Button>

                  </Box>
                  <Box sx={{ ml: { lg: 2, xs: 0.5, md: 1 } }}>
                    <Typography sx={{ fontSize: ".75rem", pt: { lg: 2, md: 1, sm: 1, xs: 2 }, color: "#666666" }}><b style={{ color: "#000" }}>Disclaimer:</b> Rate of interest can vary subject to credit profile. Loan approval is at the sole discretion of the finance partner</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <ApplyNow
        open={applynow}
        handleClose={handleApplyclose}
        category={props.category}
        university={props.university}
        course={props.title}
      />
      <Rcta open={cta} handleClose={RcbhandleClose} program={props.vertical} />
    </>
  );
}
