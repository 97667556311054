import React, { useState } from 'react'
import Rcta from '../../cta/request_a_call_back'

const Hero = () => {

  const [cta, setCta] = useState(false);
  const handleClickOpenRcb = () => {
    setCta(true);
  };
  const RcbhandleClose = () => {
    setCta(false);
  };

  return (
    <div className="main-container-4 xl:px-12 px-0">
      {/*<div className="home-gradient-right" /> */}
      <div className="home-4-container w-full max-w-[85rem] flex justify-center relative md:flex-row flex-col xl:px-0 px-4 py-10">
        <div className="home-4-container-left flex-1 flex flex-col gap-3 md:items-start items-center">
          <div className="w-full flex justify-center md:justify-start">
            {/* <h1 className="text-center cursor-context-menu md:text-start text-4xl 2xl:text-[5vw] 2xl:leading-[5.5vw] lg:text-5xl xl:text-7xl font-bold max-w-lg xl:max-w-[700px]">Unlock your Dream Career with </h1> */}
            <h1 className="text-center cursor-context-menu md:text-start text-4xl lg:text-5xl xl:text-7xl 2xl:text-[5.2vw] 3xl:text-[5.5rem] font-bold max-w-lg xl:max-w-[700px]">Unlock Dream Career <br className='lg:hidden block' /> with Top </h1>
          </div>
          {/* <div className="w-full max-w-[300px] lg:max-w-[380px] xl:max-w-[500px] 2xl:max-w-[590px] md:mt-0 -mt-3">
            <h1 className='text-4xl md:text-start text-center 2xl:text-[5vw] lg:text-5xl xl:text-7xl font-bold cursor-context-menu'>Top Universities</h1>
            <img className='w-full mt-2' src="/Rcimages/line.png" alt="line" />
          </div> */}
          <div className="w-full max-w-[200px] lg:max-w-[280px] xl:max-w-[400px] 2xl:max-w-[490px] md:mt-0 -mt-3">
            <h1 className='text-4xl 2xl:text-[5.2vw] lg:text-5xl xl:text-7xl 3xl:text-[5.5rem] font-bold cursor-context-menu'>Universities</h1>
            <img className='w-full mt-2' src="/Rcimages/line.png" alt="line" />
          </div>
          <div className="w-full flex justify-center md:justify-start">
            {/* <p className="max-w-sm lg:max-w-[630px] font-semibold text-sm md:text-start text-center md:text-base lg:text-[20px] text-paleBlack">Advance your career with India's premier online school. Experience top university programs for effective learning and success.</p> */}
            <p className="max-w-sm lg:max-w-md xl:max-w-[630px] font-semibold text-sm md:text-start text-center md:text-base xl:text-[20px] text-paleBlack">Welcome to Accredian, your gateway to premier online programs from India's top universities, tailored for career success.</p>
          </div>
          <div className="flex items-center gap-4 md:justify-start justify-center">
            <button className="text-nowrap text-sm xl:text-base drop-shadow-xl py-2 xl:py-4 px-4 xl:px-7 bg-white rounded-[4px] text-black font-semibold xl:font-bold transition-all duration-100 hover:bg-universal border-universal hover:text-white border" onClick={handleClickOpenRcb} title='Talk to Expert'>
              Talk to Expert
            </button>
            <a href="#courses" className="text-sm drop-shadow-xl xl:text-base xl:font-bold font-semibold xl:px-6 xl:py-4 px-4 py-2 bg-universal text-white hover:bg-darkBlue border border-universal rounded-[4px]">
              Explore Courses
            </a>
          </div>
        </div>
        <div className="home-4-container-right md:py-0 py-16 sm:flex hidden justify-center ">
          <div className='relative w-full sm:w-[70%] md:w-[40vw] lg:w-[45vw] 3xl:w-[42rem]'>
            {/* <img src="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/scholar.svg" alt="home" className=' w-full' /> */}
            <img src="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/scholar.svg" alt="home" className=' w-full' />
          </div>
        </div>
      </div>
      <Rcta open={cta} handleClose={RcbhandleClose} />
    </div>
  )
}

export default Hero