import React, { useEffect, useState, useRef } from "react";
import {
  CardMedia,
  Typography,
  Grid,
  Button,
  Box,
  Container,
  TextField,
  InputLabel,
  Card,
  CardContent,
  Checkbox,
  FormGroup,
  FormControlLabel,
  CardActions,
  Backdrop,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(0),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1A2027",
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 16,
    width: "372px",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
// export default function Payment() {
//   const [price, setPrice] = useState(199);
//   const [gst, setGst] = useState(36);
//   const [total, setTotal] = useState(235);
//   const [newprice, setNewprice] = useState(199);
//   const [newgst, setNewgst] = useState(36);
//   const handleApplyOpen = () => {
//     const totalprice = price + newprice;
//     const totalgst = gst + newgst;
//     setPrice(totalprice);
//     setGst(totalgst);
//     // setTotal(price+gst);
//     const sum = totalprice + totalgst;
//     setTotal(sum);
//     console.log(sum);

//   };
export default function Payment() {
  let navigate = useNavigate();
  // Initial state values
  const initialPrice = 199;
  const initialGst = 36;
  const additionalPrice = 499; // New price
  const additionalGst = 90; // New GST
  const logo = "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accredian.webp"
  // State hooks
  const [isRecordingNeeded, setIsRecordingNeeded] = useState(false);
  const [price, setPrice] = useState(initialPrice);
  const [gst, setGst] = useState(initialGst);
  const [total, setTotal] = useState(initialPrice + initialGst);
  const [name, setName] = useState();
  const [mobile, setMobile] = useState();
  const [email, seEmail] = useState();
  const [loopen, setLoOpen] = useState(false);
  const LoaderOpen = () => {
    setLoOpen(true);
  };
  const LoaderClose = () => {
    setLoOpen(false);
  };
  const handleName = (e) => {
    setName(e.target.value)
  }
  const handleMobile = (e) => {
    setMobile(e.target.value)
  }
  const handleEmail = (e) => {
    seEmail(e.target.value)
  }
  const handleCheckboxChange = () => {
    setIsRecordingNeeded(!isRecordingNeeded);

    if (!isRecordingNeeded) {
      // If recording is needed, add the additional cost
      setPrice(initialPrice + additionalPrice);
      setGst(initialGst + additionalGst);
      setTotal(initialPrice + initialGst + additionalPrice + additionalGst);
    } else {
      // If not needed, revert to initial cost
      setPrice(initialPrice);
      setGst(initialGst);
      setTotal(initialPrice + initialGst);
    }
  };
  const InsertintoSheet = (name, email, phone, total, pagename) => {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();


    const simpleDateString = `${day}/${month}/${year}`;
    const formDatab = new FormData();
    // formDatab.append("Sno",sn);
    formDatab.append("Date", simpleDateString);
    formDatab.append("Name", name);
    formDatab.append("Email", email);
    // formDatab.append("Gender",data.gender);
    // formDatab.append("Dob",data.dob);
    formDatab.append("Phone", phone);
    formDatab.append("Amount", total);
    // formDatab.append("payment_id",data.experience);
    formDatab.append("Category", pagename);


    fetch(
      "https://script.google.com/macros/s/AKfycbwre-Wod9SxhkvLbCug_cu4tOWOJPIpzrTA2Lwyu_hg-OQunypsxQfzSij19LupytXEGA/exec",
      {
        method: "POST",
        body: formDatab
      }
    )
      .then((res) => {
        res.json()
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });



  }
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  const checkoutHandler = async (total) => {

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: total * 100,
      currency: "INR",
      name: "Accredian",
      description: "registration fees for Webinar",
      image:
        { logo },
      // order_id: order.data.response.id,
      handler: function (response) {
        LoaderOpen();
        if (response) {
          InsertintoSheet(name, email, mobile, total, localStorage.getItem("pagename"))
          axios({
            method: "post",
            url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/common_publish/commonPublish`,
            data: {
              name: name,
              email: email,
              mobile: mobile,
              amount: total * 100,
              payment_id: response.razorpay_payment_id,
              pagename: localStorage.getItem("pagename"),
              type: "webinarPayment"

            },
          }).then((res) => {
            // //console.log(res,"razorpay")
            if (res.status == 200) {
              navigate(`/Thankyou`);


            }
            else {
              Swal.fire({
                title: "Warning",
                text: "We are processing your request are team will contact you soon",
                icon: "warning",

                confirmButtonText: "OK",
              });
            }
            LoaderClose();
          })
        }
        else {
          InsertintoSheet(name, email, mobile, total, localStorage.getItem("pagename"))
          setTimeout(() => {
            LoaderClose()
            Swal.fire({
              title: "Request in Process",
              text: "We are processing your request are team will contact you soon",
              icon: "success",
              confirmButtonText: "OK",
            });
          }, 60000)
        }



      },
      prefill: {
        name: name,
        email: email,
        mobile: mobile,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#121212",
      },
    };
    const razor = new window.Razorpay(options);
    razor.open();

    // //console.log("chala kya");
  };
  return (
    <>
      <Box sx={{ background: "#F5F5F5" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: { lg: "center", xs: "left" },
            alignItems: { lg: "center", xs: "left" },
            py: 2,
          }}
        >
          <Box
            sx={{
              background: "#fff",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              width: { lg: "859px", xs: "650px" },
              p: { lg: 3, xs: 1 },
              borderRadius: "8px",
            }}
          >
            <Box>
              <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                Payment Details
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box sx={{}}>
                <Box sx={{ p: 1 }}>
                  <form>
                    <Box sx={{ mb: 3 }}>
                      <InputLabel shrink htmlFor="name">
                        Name
                      </InputLabel>
                      <BootstrapInput
                        id="name"
                        name="name"
                        type="text"
                        required
                        value={name}
                        placeholder="Enter Name"
                        onChange={handleName}
                      />
                    </Box>

                    <Box sx={{ mb: 3 }}>
                      <InputLabel shrink htmlFor="email">
                        Email
                      </InputLabel>
                      <BootstrapInput
                        id="email"
                        name="email"
                        type="email"
                        value={email}
                        required
                        placeholder="Enter Email"
                        onChange={handleEmail}
                      />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                      <InputLabel shrink htmlFor="phone">
                        Phone
                      </InputLabel>
                      <BootstrapInput
                        id="phone"
                        name="phone"
                        type="number"
                        value={mobile}
                        min="10"
                        max="12"
                        required
                        placeholder="Enter Phone"
                        onChange={handleMobile}
                      />
                    </Box>
                    <Box sx={{ mt: 5, }}>
                      <Card
                        sx={{
                          border: "2px dashed grey",
                          width: { lg: "395px", xs: "350px" },
                          mb: 4,

                        }}
                      >
                        <CardContent sx={{ paddingBottom: "8px !important" }}>
                          <Box sx={{ display: "flex" }}>
                            <Box>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          // Access the icon component
                                          fontSize: 20,
                                        }, // Set the font size of the icon, thereby changing the checkbox size
                                      }}
                                      checked={isRecordingNeeded}
                                      onChange={handleCheckboxChange}


                                    />
                                  }
                                  label={
                                    <Typography
                                      gutterBottom
                                      variant="p"
                                      component="div"
                                      color="textSecondary"
                                      sx={{ fontSize: ".875rem" }}
                                    >
                                      Yes, I need the recording
                                    </Typography>
                                  }
                                />
                              </FormGroup>
                            </Box>
                            <Box sx={{ ml: 10, mb: 1 }}>
                              <Typography
                                component="div"
                                color="textSecondary"
                                sx={{
                                  textDecoration: "line-through",
                                  fontSize: ".875rem",
                                  marginBottom: "0.1rem!important",
                                }}
                              >
                                ₹1000.00
                              </Typography>
                              <Typography
                                component="div"
                                sx={{ fontSize: ".875rem", fontWeight: "bold" }}
                              >
                                ₹499.00
                              </Typography>
                            </Box>
                          </Box>
                          <Box sx={{ display: "flex" }}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <CardMedia
                                component="img"
                                src="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/Masterclass.webp"
                                sx={{ width: "100px", height: "100px" }}
                              ></CardMedia>
                            </Box>
                            <Box sx={{ width: "350px", ml: 3 }}>
                              <Box>
                                <Typography
                                  variant="p"
                                  sx={{
                                    fontSize: ".875rem",
                                    marginBottom: "0.2rem!important",
                                  }}
                                >
                                  1. Connecting The Dots with Recording: get exclusive video on Data Science career transition.
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  variant="p"
                                  sx={{
                                    fontSize: ".875rem",
                                    marginBottom: "0.2rem!important",
                                  }}
                                >
                                  2. How to Break into Data Science Roles in 2024
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  variant="p"
                                  sx={{
                                    fontSize: ".875rem",
                                    marginBottom: "0.2rem!important",
                                  }}
                                >
                                  3. What Background is required in Data science roles?
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box
                            bgcolor="#F5F5F5"
                            sx={{ px: 1, py: 0.5, borderRadius: "5px", mt: 2 }}
                          >
                            <Typography
                              color="textSecondary"
                              sx={{
                                fontSize: ".695rem",
                                fontWeight: 600,
                                marginBottom: "0.2rem!important",
                              }}
                            >
                              P.S. You will not get this offer once you leave
                              this page.
                            </Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    </Box>
                    <Box sx={{ borderBottom: "1px solid #000" }}>
                      <Box sx={{ display: "flex" }}>
                        <Box sx={{ width: { lg: "345px", xs: "280px" } }}>
                          <Typography
                            sx={{
                              marginBottom: "0.3rem!important",
                              color: "#4d4d4d",
                            }}
                          >
                            Original Price
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              marginBottom: "0.3rem!important",
                              color: "#4d4d4d",
                            }}
                          >
                            ₹{price}.00
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex" }}>
                        <Box sx={{ width: { lg: "348px", xs: "283px" } }}>
                          <Typography
                            sx={{
                              marginBottom: "0.5rem!important",
                              color: "#4d4d4d",
                            }}
                          >
                            GST
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              marginBottom: "0.5rem!important",
                              color: "#4d4d4d",
                            }}
                          >
                            ₹{gst}.00
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", mt: 1 }}>
                      <Box sx={{ width: { lg: "348px", xs: "283px" } }}>
                        <Typography
                          sx={{
                            marginBottom: "0.5rem!important",
                            fontWeight: "bold",
                            color: "#4d4d4d",
                          }}
                        >
                          Total
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            marginBottom: "0.5rem!important",
                            fontWeight: "bold",
                            color: "#4d4d4d",
                          }}
                        >
                          ₹{total}.00
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Button
                        variant="contained"
                        // fullWidth
                        disabled={!(name && email && mobile)}
                        sx={{
                          color: "#fff",
                          backgroundColor: "#1A1326",
                          "&:hover": {
                            color: "#fff",
                            backgroundColor: "#1A1326",
                          },
                          borderRadius: "5px !important",
                          textTransform: "none !important",
                          fontSize: "16px",
                          p: 1,
                          width: { lg: "400px", xs: "350px" }
                        }}
                        onClick={() => {
                          checkoutHandler(total);
                        }}
                      >
                        Pay ₹{total}
                      </Button>
                    </Box>
                  </form>
                </Box>
              </Box>
              <Box sx={{ width: "457px", display: { lg: "block", xs: "none", md: "block", sm: "none" }, p: 1, ml: 4 }}>
                <Box>
                  <Box sx={{ mt: -7.5 }}>
                    <Typography
                      sx={{
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        color: "#4d4d4d",
                      }}
                    >
                      Data Science Masterclass by Nikhil Bhogaraju | Sunday 11
                      AM
                    </Typography>
                  </Box>
                  <Box>
                    <CardMedia
                      component="img"
                      src="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/Masterclass.webp"
                    ></CardMedia>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography
                      sx={{
                        marginBottom: "0.5rem!important",
                        color: "#4d4d4d",
                      }}
                    >
                      Learn with me about Data Science, 5-step-by-step learning
                      path, Why Data Science? How to transition into Data
                      Science, and so much more.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        marginBottom: "0.2rem!important",
                        fontWeight: 600,
                        fontSize: ".875rem",
                        color: "#4d4d4d",
                      }}
                    >
                      Date of Masterclass: 21st January, 2024
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        marginBottom: "0.5rem!important",
                        fontWeight: 600,
                        fontSize: ".875rem",
                        color: "#4d4d4d",
                      }}
                    >
                      Time of Masterclass: 11:00 AM
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
