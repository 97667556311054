import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  Button,
  Box,
  Container,
  CardMedia,
  Backdrop,
} from "@mui/material";
import { useParams, useSearchParams, useLocation } from "react-router-dom";
import { Iitprogram } from "../../data/Iitprogram";
import DownloadIcon from "@mui/icons-material/Download";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useStyles from "../../Assets/js/ProgramPage/style";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AOS from "aos";
import "aos/dist/aos.css";
import Moment from "react-moment";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "733px",
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#ff9800" : "#fff176",
  },
}));
export default function ProgramSectionOne() {
  const date = moment().day("Sunday").add(7, "days");
  const app = localStorage.getItem("user_id");
  const [isFetching, setIsFetching] = useState(true);
  const { id } = useParams();
  const [rcbprogram, setProgramrcb] = useState("");
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [program, setProgram] = useState("");
  const [source, setSource] = useState("");
  const [pdf, setPdf] = useState("");
  const [expanded, setExpanded] = useState("trem1");
  const [open, setOpen] = useState(false);
  const [Rcbopen, RcbsetOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState();
  const progamhandleChange = (event) => {
    setProgramrcb(event.target.value);
  };

  useEffect(() => {
    let ans = Iitprogram.filter((obj) => {
      return obj.id == id;
    });
    //console.log(ans);
    setData(ans);
    setProgram(ans[0].programname);
    setSource(ans[0].ctasource);
    setPdf(ans[0].ctapdf);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    AOS.init({ duration: 2000 });
  }, [id]);

  const classes = useStyles();
  const sliderRef = useRef();
  const ptsliderRef = useRef();
  const ptgotoNext = () => {
    ptsliderRef.current.slickNext();
  };
  const ptgotoPrev = () => {
    ptsliderRef.current.slickPrev();
  };
  const deskgotoNext = () => {
    sliderRef.current.slickNext();
  };
  const deskgotoPrev = () => {
    sliderRef.current.slickPrev();
  };
  const settings = {
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,

    autoplay: true,
    autoplaySpeed: 6000,

    responsive: [
      {
        breakpoint: 1124,
        settings: {
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
      {
        breakpoint: 900,
        settings: {
          infinite: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
    ],
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpenRcb = () => {
    RcbsetOpen(true);
  };
  const RcbhandleClose = () => {
    RcbsetOpen(false);
  };
  const LoaderClose = () => {
    setLoader(false);
  };
  const LoaderOpen = () => {
    setLoader(true);
  };
  const [cta, setCta] = useState({
    mobile: "",
    name: "",
    newemail: "",

    ctapage: "",

    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
  });

  const ctahandleChange = (e) => {
    setCta({ ...cta, [e.target.name]: e.target.value });
  };
  const options = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };

  var ctapage = location.pathname.replace("/", "");
  var utm_source =
    searchParams.get("utm_source") == undefined
      ? "null"
      : searchParams.get("utm_source");
  var utm_medium =
    searchParams.get("utm_medium") == undefined
      ? "null"
      : searchParams.get("utm_medium");
  var utm_campaign =
    searchParams.get("utm_campaign") == undefined
      ? "null"
      : searchParams.get("utm_campaign");
  const submitForm = (e) => {
    e.preventDefault();
    LoaderOpen();
    const sendRcbctaData = {
      phone: cta.mobile,
      name: cta.name,
      email: cta.newemail,
      program: program,
      source: source,
      page: ctapage,
      utm_source: utm_source,
      utm_medium: utm_medium,
      utm_campaign: utm_campaign,
      form_id: "brochure",
      type: "CTA"
    };
    //console.log(sendRcbctaData);
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/common_publish/commonPublish`,
        JSON.stringify(sendRcbctaData),
        options
      )
      .then((result) => {
        // //console.log(result.data.status);
        LoaderClose();
        handleClose();

        if (result.data.status == "200") {
          Swal.fire({
            title: "Success",
            text: "Your request submitted successfully. Our expert will call you soon.",
            icon: "success",
            confirmButtonText: "OK",
          }).then((conf) => {
            if (conf.isConfirmed) {
              window.open(pdf, "_blank");
            }
          });

          setCta("");
        } else {
          Swal.fire({
            title: "Warning",
            text: "Something went wrong. Please try again!",
            icon: "Warning",
            confirmButtonText: "OK",
          });
        }
      });
  };
  const handleChange = (trem) => (event, newExpanded) => {
    setExpanded(newExpanded ? trem : false);
  };
  return (
    <>
      {data &&
        data.map((dataone) => (
          <Box sx={{ mt: { lg: 5 } }}>
            <Container fixed>
              <Box>
                <Typography
                  variant="h4"
                  component="div"
                  sx={{
                    fontSize: "38px",
                    fontWeight: "bold",
                    pb: 6,
                    textAlign: {
                      lg: "left",
                      sm: "center",
                      md: "center",
                      xs: "center",
                    },
                  }}
                >
                  Program Syllabus
                </Typography>

                <Grid lg={12} xs={12} sm={12} md={12} container sx={{ pb: 6 }}>
                  <Grid
                    lg={3}
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{
                      boxShadow: "0 0 15px 0 rgb(0 0 0 / 10%)",
                      borderRadius: "9px",
                      p: 4,
                      mb: 2,
                      maxWidth: {
                        lg: "22% !important",
                        xs: "100% !important",
                        sm: "47% !important",
                        md: "40% !important",
                      },
                      ml: { lg: 0, sm: 2, xs: 0, md: 7 },
                    }}

                  >
                    <Typography
                      align="center"
                      variant="h4"
                      component="div"
                      color="#002bff"
                      sx={{ fontSize: "22px", fontWeight: "bold" }}
                    >
                      {dataone.Programsyllabustrmtext}
                    </Typography>
                    <Typography
                      align="center"
                      variant="h4"
                      component="div"
                      color="#002bff"
                      sx={{ fontSize: "18px" }}
                    >
                      {dataone.Programsyllabustremds}
                    </Typography>
                  </Grid>
                  <Grid
                    lg={3}
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{
                      boxShadow: "0 0 15px 0 rgb(0 0 0 / 10%)",
                      borderRadius: "9px",
                      p: 4,
                      mb: 2,
                      maxWidth: {
                        lg: "22% !important",
                        xs: "100% !important",
                        sm: "47% !important",
                        md: "40% !important",
                      },
                      ml: { lg: 4, sm: 2, xs: 0, md: 4 },
                    }}

                  >
                    <Typography
                      align="center"
                      variant="h4"
                      component="div"
                      color="#002bff"
                      sx={{ fontSize: "22px", fontWeight: "bold" }}
                    >
                      {dataone.Programsyllabusclasstext}
                    </Typography>
                    <Typography
                      align="center"
                      variant="h4"
                      component="div"
                      color="#002bff"
                      sx={{ fontSize: "22px", fontWeight: "bold" }}
                    >
                      live classes
                    </Typography>
                  </Grid>
                  <Grid
                    lg={3}
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{
                      boxShadow: "0 0 15px 0 rgb(0 0 0 / 10%)",
                      borderRadius: "9px",
                      p: 4,
                      mb: 2,
                      ml: { lg: 4, sm: 2, xs: 0, md: 7 },
                      maxWidth: {
                        lg: "22% !important",
                        xs: "100% !important",
                        sm: "47% !important",
                        md: "40% !important",
                      },
                    }}

                  >
                    <Typography
                      align="center"
                      variant="h4"
                      component="div"
                      color="#002bff"
                      sx={{ fontSize: "22px", fontWeight: "bold" }}
                    >
                      {dataone.Programsyllabuscareetext}
                    </Typography>
                    <Typography
                      align="center"
                      variant="h4"
                      component="div"
                      color="#002bff"
                      sx={{ fontSize: "22px", fontWeight: "bold" }}
                    >
                      sessions
                    </Typography>
                  </Grid>
                  <Grid
                    lg={3}
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{
                      background:
                        "linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",
                      borderRadius: "9px",
                      p: 4,
                      mb: 2,
                      maxWidth: {
                        lg: "22% !important",
                        xs: "100% !important",
                        sm: "47% !important",
                        md: "40% !important",
                      },
                      display: {
                        lg: "block",
                        xs: "none",
                        sm: "block",
                        md: "block",
                      },
                      ml: { lg: 4, sm: 2, xs: 0, md: 4 },
                    }}

                  >
                    <Typography
                      variant="h4"
                      component="div"
                      color="#fff"
                      sx={{
                        fontSize: "22px",
                        cursor: "pointer",
                        display: "flex",
                        mt: { lg: 0, xs: 0, sm: 0, md: 1 },
                      }}
                      onClick={handleClickOpen}
                    >
                      Download Curriculum
                      <div className="text-3xl">
                        <DownloadIcon
                          fontSize="large"
                          sx={{
                            color: "#fff",
                            // py: { lg: 1, sm: 0, md: 0, xs: 0 },
                          }}
                        />
                      </div>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              {/*   Mobile version For four section */}

              {/*  End Mobile version For four section */}
              <Box
                sx={{ mr: 5, display: { xs: "none", lg: "block" } }}

              >
                {dataone.accd &&
                  dataone.accd.map((ds) => (
                    <Accordion
                      sx={{ mb: 2 }}
                      expanded={expanded === ds.accodingid}
                      onChange={handleChange(ds.accodingid)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={ds.accodingid}
                        sx={{
                          height: "86px",
                          border: "solid 0.5px #0091ff",
                          borderRadius: "9px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "22px",
                            display: "flex",
                            mt: 5,
                            mb: "40px !important",
                          }}
                        >
                          <MoreVertIcon sx={{ fontSize: "1.9rem" }} />{" "}
                          {ds.hadding}{" "}
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "#6c757d",
                              mt: 1,
                              mb: "0px !important",
                              pl: 4,
                            }}
                          >
                            {" "}
                            {ds.week}
                          </Typography>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          backgroundColor: "#f8f8f8",
                          borderRadius: "9px",
                          border: "solid 0.5px #0091ff",
                          p: 0,
                        }}
                      >
                        {ds.tremone &&
                          ds.tremone.map((tr) => (
                            <Typography
                              sx={{
                                borderTop: "0.5px solid  #0091ff",
                                pt: 2,
                                px: 3,
                                pb: 0,
                                mb: "0.9rem!important",
                              }}
                            >
                              {tr}
                            </Typography>
                          ))}
                      </AccordionDetails>
                    </Accordion>
                  ))}
              </Box>
              {/* Mobile version For five section */}
              <Box
                sx={{ display: { xs: "block", lg: "none" } }}

              >
                {dataone.accd &&
                  dataone.accd.map((ds) => (
                    <Accordion
                      sx={{ mb: 2 }}
                      expanded={expanded === ds.accodingid}
                      onChange={handleChange(ds.accodingid)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={ds.accodingid}
                        sx={{
                          height: "86px",
                          border: "solid 0.5px #0091ff",
                          borderRadius: "9px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            display: "flex",
                            mt: 5,
                            mb: "40px !important",
                          }}
                        >
                          <MoreVertIcon
                            sx={{ fontSize: "1.9rem", my: "auto" }}
                          />{" "}
                          {ds.hadding} ({ds.week})
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          backgroundColor: "#f8f8f8",
                          borderRadius: "9px",
                          border: "solid 0.5px #0091ff",
                          p: 0,
                        }}
                      >
                        {ds.tremone &&
                          ds.tremone.map((tr) => (
                            <Typography
                              sx={{
                                borderTop: "0.5px solid  #0091ff",
                                pt: 2,
                                px: 3,
                                pb: 0,
                                mb: "0.9rem!important",
                              }}
                            >
                              {tr}
                            </Typography>
                          ))}
                      </AccordionDetails>
                    </Accordion>
                  ))}
              </Box>
              {/* End Mobile version For five section */}
            </Container>
            <center>
              <Button
                variant="contained"
                sx={{ mb: 5, mt: 4 }}
                className={classes.DownloadBrochureone}
                onClick={handleClickOpen}
              >
                Download Curriculum{" "}
                <DownloadIcon fontSize="large" sx={{ pl: 1 }} />
              </Button>
            </center>
            <BootstrapDialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
                sx={{
                  backgroundColor: "#f8f9fa",
                  color: "#6c757d",
                  textAlign: "center",
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                }}
              >
                50% Complete
                <BorderLinearProgress
                  variant="determinate"
                  value={50}
                  sx={{ my: 2 }}
                />
              </BootstrapDialogTitle>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loader}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <Box>
                <Grid lg={12} sm={12} md={12} xs={12} container>
                  <Grid lg={4} sm={4} md={4}>
                    <CardMedia
                      component="img"
                      image={dataone.brochurectaimg}
                      alt="Goole"
                      sx={{
                        width: "208px",
                        mx: 3,
                        py: 3,
                        display: { xs: "none", lg: "block", sm: "block", md: "block" },
                      }}
                    />
                  </Grid>
                  <Grid lg={8} sm={8} md={8}>
                    <Box sx={{ mx: 3, py: 3 }}>
                      <Typography
                        variant="h4"
                        component="div"
                        sx={{ fontSize: "25px" }}
                      >
                        {dataone.brochurectahadding}
                      </Typography>
                    </Box>
                    <Box sx={{ mx: 3 }}>
                      <form onSubmit={submitForm}>
                        <Box sx={{ pb: 2 }}>
                          <TextField
                            id="standard-basic"
                            label="First Name"
                            variant="standard"
                            name="name"
                            onChange={ctahandleChange}
                            value={cta.name}
                            type="text"
                            fullWidth
                            required
                            sx={{ pb: 1 }}
                          />
                          <TextField
                            id="standard-basic"
                            label="Email"
                            variant="standard"
                            type="email"
                            name="newemail"
                            onChange={ctahandleChange}
                            value={cta.email}
                            fullWidth
                            required
                            sx={{ pb: 1 }}
                          />
                          <TextField
                            label="Phone Number"
                            variant="standard"
                            onChange={ctahandleChange}
                            value={cta.mobile}
                            type="number"
                            name="mobile"
                            fullWidth
                            required
                            sx={{ pb: 1 }}
                          />
                        </Box>
                        <Box sx={{ pb: 2 }}>
                          <Button
                            variant="contained"
                            sx={{
                              mb: 2,
                              backgroundColor: "#0039e6",
                              textTransform: "none",
                              p: 1,
                              "&:hover": {
                                mb: 2,
                                backgroundColor: "#0039e6",
                                textTransform: "none",
                                p: 1,
                              },
                            }}
                            fullWidth
                            type="submit"
                          >
                            Download Brochure here!
                          </Button>
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              color: "rgb(119, 119, 119)",
                              mx: 1,
                            }}
                          >
                            Privacy Policy: We hate spam and promise to keep
                            your email address safe
                          </Typography>
                        </Box>
                      </form>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </BootstrapDialog>
            {/* <BootstrapDialog
              onClose={DchandleClose}
              aria-labelledby="customized-dialog-title"
              open={Dcopen}
            >
              <DcDialogTitle
                id="customized-dialog-title"
                onClose={DchandleClose}
              >
                <CardMedia
                  component="img"
                  image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logos/accredian.webp"
                  alt="Goole"
                  sx={{ width: "125px", float: "right",}}
                />
              </DcDialogTitle>
              <Box>
                <Typography
                  sx={{
                    fontSize: "31px",
                    color: "#F06516EB",
                    fontFamily: "Mulish;",
                    marginBottom: "0.5rem !important",
                    textAlign:"center",
                    fontWeight:"bold",
                  }}
                >
                  {dataone.curriculumctahadding}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1.3rem",
                 
                    fontFamily: "Mulish;",
                    marginBottom: "0.7rem !important",
                    textAlign:"center",
                    fontWeight:"bold",
                  }}
                >
                  Boost Your {dataone.curriculumctatext} Career
                </Typography>
              </Box>

              <Box>
                <Grid item lg={12} container>
                  <Grid item lg={6} >
                  <CardMedia
                  component="img"
                  image={dataone.curriculumctaimg}
                  alt="Goole"
                  sx={{ width:"258px",mx:5,display: { xs: "none", lg: "block" } }}
                />
                  </Grid>

                  <Grid item lg={6} xs={12}>
    
                    <Box  className={classes.curriculumcta}>
                    <TextField
                        id="standard-basic"
                        label=" Full Name"
                        variant="standard"
                        textAlign="canter"
                        sx={{ pb: 1, mx: 7, width: "65%" }}
                      />
                 
                   
                      <TextField
                        id="standard-basic"
                        label="Email"
                        variant="standard"
                        textAlign="canter"
                        sx={{ pb: 2, mx: 7, width: "65%" }}
                      />
                           <TextField
                        id="standard-basic"
                        label="Phone Number"
                        variant="standard"
                        textAlign="canter"
                        sx={{ pb: 3, mx: 7, width: "65%" }}
                      />

                      <center>
                        {" "}
                        <Button
                          variant="contained"
                          sx={{
                            mb: 2,
                            background: "rgba(240, 101, 22, 0.92) !important",
                            textTransform: "none",
                            p: 1,
                            width: "50%",
                          }}
                        >
                          Download Now
                        </Button>
                      </center>
                      <Typography
                  sx={{
                    fontSize: "0.6rem",
                 color:"#0000005C",
                    fontFamily: "Mulish;",
                    marginBottom: "0.7rem !important",
                    textAlign:"center",
                   
                  }}
                >
                 Privacy Policy: We hate spam and
promise to keep your email address safe
                </Typography>
                    </Box>
              
                  </Grid>
                </Grid>
              </Box>
            </BootstrapDialog> */}
          </Box>
        ))}
    </>
  );
}
