import React, { useEffect, useState } from "react";
import Navbar3 from "./Main/MainNavbar";

function Testingnavbar(props) {

  const cookie = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, '$1');
  useEffect(() => {
    // //console.log(auth)
    var user_id = localStorage.getItem("user_id");

    var token = localStorage.getItem("token");
    setUser(user_id);
  }, []);
  const [user, setUser] = useState(null);

  return (
    <Navbar3 userInfo={cookie} setUserInfo={setUser} tokenExp={props.tokenExp} />
  );
}
export default Testingnavbar;