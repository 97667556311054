import React, { useEffect, useState } from "react";
import DownloadIcon from '@mui/icons-material/Download';
import { useParams, useSearchParams, useLocation } from "react-router-dom";
import {
    Typography,
    Button,
    Box,
    Paper,
    Grid,
    CardMedia
  } from "@mui/material";
  import { Broucher_data } from "../../data/broucher";

  const Broucher=()=>{
    const { id } = useParams();
    const[show,setshow]=useState(false)
    const [url,seturl]=useState("")
    useEffect(()=>{
      const data = Broucher_data.filter((val)=>{
            return val.pcode==id
        })
        if(data.length==0 || id==null){
            setshow(true)
        }
        else{
            seturl(data[0].link)
            const link = document.createElement('a');
            link.href = data[0].link;
          
            document.body.appendChild(link);
            link.target="_blank"
            link.click();
            
            // window.open(link.href, '_blank');
        }
       
    },[])
    return !show?(
        <>
        <Box sx={{display:"flex",alignItems:"center",justifyContent:"center",background:"#f3f6f9",width:"100%",height:"100vh"}}>
        <Paper sx={{p:6,borderRadius:"5px",display:"flex",alignItems:"center",justifyContent:"center",width:{lg:"auto",md:"auto",sm:"auto",xs:"70%"}}} elevation={3}>
            <Box>
                <Box sx={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <CardMedia
                  component="img"
                  image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/Growth%20curve-amico%20(1).png"
                  alt="Goole"
                  sx={{
                   width:"250px",
                   height:"200px",
                   mb:2,
                    // height:"100%",382px
                    display: { xs: "block", lg: "block",sm:"block",md:"block" },
                  }}
                />
                </Box>
           
            <Typography sx={{fontWeight:"500",fontSize:"22px",textAlign:"center"}}>
            Please click below button to download brochure
        </Typography>
        <Box sx={{display:"flex",alignItems:"center",justifyContent:"center"}}>
        <Button href={url} target="_blank" sx={{textTransform:"none"}} variant="contained">Download Brochure <DownloadIcon sx={{fontSize:"20px",ml:0.5}} /></Button>
        </Box>
       
            </Box>
             
            
       
        </Paper>
        </Box>
        </>
    ):(
        <Box sx={{display:"flex",alignItems:"center",justifyContent:"center",width:"100%",height:"100vh"}}>
           <div>
      <Typography variant="h1">
        404
      </Typography>
      <Typography variant="h4" gutterBottom>
        Oops! Page not found.
      </Typography>
      <Typography variant="body1" gutterBottom>
        The page you're looking for might have been removed or doesn't exist.
      </Typography>
      {/* <Button variant="contained" color="primary" href="/">
        Go back to home
      </Button> */}
    </div>
        </Box>
    )
  }
  export default Broucher