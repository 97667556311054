import React from "react";
import Login from "../Components/Login/Login";
import Home from "../Components/Home/Home";
const AuthLogin = () => {

  // <div>
  //   {window.location.href = "https://dashboard.accredian.com/"}
  // </div> 

  const useAuth = () => {

    const cookie = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, '$1');
    if (cookie) {
      return true
    } else {
      return false
    }
  }
  const auth = useAuth();
  return auth ? <Home /> : <Login />;
}
export default AuthLogin;
