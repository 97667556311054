
import React from "react";
import Signup from "../Components/Signup/Signup";
import Home from "../Components/Home/Home";
import {
  Navigate,
} from "react-router-dom";
// import { GiToken } from "react-icons/gi";
const AuthSignup = () => {
  const useAuth=()=>{
    const token=localStorage.getItem('token')
    const cookie = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, '$1');
    if(cookie){
      return true
    } else {
      return false
    }
  }
  const auth = useAuth();
  return auth ? <Home /> : <Signup/>;
}
export default AuthSignup;
