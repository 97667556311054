import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const Iitprogram = [
  {
    /* Start gcds*/
  },

  {
    id: "executive-program-in-ds-and-ai-advance",
    category: "2",
    university: "iitg",
    vertical: "Data Science",
    brochureHeader:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/iitg.png",
    backgroundimage:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/program-page-banner-image/iita.webp",
    mobbackground:
      "linear-gradient(0deg, rgba(25,122,237,1) 0%, rgba(34,39,125,1) 0%, rgba(31,33,64,1) 99%)",
    hadding:
      "Executive Program in Data Science & Artificial Intelligence with E&ICT Academy, IIT Guwahati",
    title: "IITG - Executive Program in DS & AI 12M",
    programduration: "12 Months | Live Instructor led | Weekend Online",
    programdescription:
      "Embark on a 12-term learning journey in Data Science, Machine Learning, & End-to-End AI Product Development.",
    callnumber: "90796-53292",
    stacksone: "E&ICT Academy",
    stacksonedescription: "IIT Guwahati",
    stackstwo: "Live Classes",
    stackstwodescription: "12 Months",
    stacksthree: "Next Cohort",
    stacksthreedescription: "August 2024",
    stackslast: "Career Services ",
    stackslastdescription: "by Accredian",
    progarminclud1: "12 Terms - Machine Learning, AI & End to End AI Product",
    progarminclud2: "Duration: 12 Months | Weekend Online",
    progarminclud3: "Live Instructor led classes",
    progarminclud4: "Daily doubt clearing",
    progarminclud5: "Term projects & Capstone projects",
    progarminclud6: "No Cost EMI upto 12 months",
    progarminclud7: "Placement Support & Assistance",
    progarminclud8: "2-day career immersion at IIT Guwahati",
    progarminclud9: "1:1 career mentorship sessions",
    progarminclud10:
      "Learn from the top industry professionals and expert sessions by IIT Guwahati Faculty",
    progarminclud11: "Certification from E&ICT, IIT Guwahati",
    whoshouldjoin:
      "This program is suitable for individuals interested in establishing a foundation in Data Science and developing AI solutions for real-world applications.",
    Jobopportunities:
      "Prepare for interviews in roles such as Data Analyst, Business Analyst, ML Engineer, Data scientist, AI Engineer, AI Specialist, AI Leader, AI Product Manager and more.",
    suchitquotes:
      "Every individual from various backgrounds must learn Analytics to outshine in the competitive world today!",
    suchitinfo: "-Suchit Majumdar, Chief Data Science Mentor, Accredian.",
    Programsyllabustrmtext: "12 Terms",
    Programsyllabustremds: "(12 months)",
    Programsyllabusclasstext: "190+ hours of",
    Programsyllabuscareetext: "8 career",
    growt: "150%",
    scholarshipseats: "2",
    nonscholarshipseats: "4",
    setsction: "block",
    accd: [
      {
        hadding: "Term 1 : Data Analysis with Python",
        week: "4 weeks",
        accodingid: "trem1",
        tremone: [
          "Data Science Fundamentals   ",
          " Data Operation with Numpy ",
          "Data Manipulation with Pandas ",
        ],
      },
      {
        hadding: "Term 2 : Data Visualization Techniques",
        week: "4 weeks",
        accodingid: "trem2",
        tremone: [
          "Introduction to Data Visualization",
          "Data Visualization using Matplotlib",
          "Hands-on Pandas for Rapid Visualization ",
          "Seaborn for Data Visualization",
        ],
      },
      {
        hadding: "Term 3 : EDA & Data Storytelling",
        week: "4 weeks",
        accodingid: "trem3",
        tremone: [
          "Introduction to Exploratory Data Analysis",
          "EDA Framework Deep Dive",
          "Scientific Exploration of Industry Data (Case Study) ",
          "Student Presentations & Insight Delivery ",
        ],
      },
      {
        hadding: "Minor Capstone Project",
        week: "",
        accodingid: "trem4",
        tremone: [
          "In a simulated environment, you get to work with a major Telecom company that seeks help from your team of Data Scientists to resolve a major marketing hurdle. Get a near real world exposure of working on industry problems within data science teams",
        ],
      },
      {
        hadding: "Term 4 : Machine Learning Foundation",
        week: "4 weeks",
        accodingid: "trem5",
        tremone: [
          "Introduction to Machine Learning",
          "Linear Regression",
          "Logistic Regression",
          "Model Evaluation Techniques",
        ],
      },
      {
        hadding: "Term 5 : Machine Learning Intermediate",
        week: "4 weeks",
        accodingid: "trem6",
        tremone: [
          "Decision Trees",
          "Random Forests ",
          "Dimensionality Reduction using Principal Component Analysis (PCA) ",
          "Naive Bayes Classifier ",
        ],
      },
      {
        hadding: "Term 6: Machine Learning Advanced",
        week: "4 weeks",
        accodingid: "trem7",
        tremone: [
          "KNN (K- Nearest neighbors)",
          "K-means Clustering",
          "Ensemble Learning ",
          "Optimization ",
        ],
      },
      {
        hadding: "Major Capstone Project",
        week: "",
        accodingid: "trem8",
        tremone: [
          "Experience a real world simulation of a company Employee Retention Program that needs to be solved using Data Science and Machine Learning.Work with a team of Data Scientists and experience real-world problem solving through Data Science",
        ],
      },
      {
        hadding: "Term 7 : Basics of AI, TensorFlow, and Keras",
        week: "4 weeks",
        accodingid: "trem9",
        tremone: [
          "Introduction to Artificial Intelligence",
          "Introduction to Deep Learning Module",
          "Deep Learning Model Practical with Tensorflow and Keras",
        ],
      },
      {
        hadding: "Term 8 : Computer Vision",
        week: "4 weeks",
        accodingid: "trem10",
        tremone: [
          "Introduction to Convolutional Neural Networks",
          "Decoding Image Components",
          "Identifying MNIST using CNN",
          "Preprocessing Image Data to apply CNN",
        ],
      },
      {
        hadding: "Term 9: Natural Language Processing",
        week: "4 weeks",
        accodingid: "trem11",
        tremone: [
          "Introduction to NLP & Word Vectors",
          "Decoding a Textual Data",
          "NLP using Recurrent Neural Networks (RNN)",
          "NLP using Memory Alterations",
        ],
      },
      {
        hadding: "AI Capstone Project",
        week: "",
        accodingid: "trem12",
        tremone: [
          "In this ever evolving world, solving complex problems get easier using AI.In this capstone project you will be an AI expert who is helping a hypothetical company resolve some major challenges it faces by using AI. Get a chance to work with a team of AI specialists and a simulated environment to help you relate to real world challenges companies face regularly.",
        ],
      },
      {
        hadding: "Term 10 : Specialization in CV",
        week: "4 weeks",
        accodingid: "trem13",
        tremone: [
          "Transfer Learning",
          "Object Detection using CNN based Algorithms - I",
          "Object Detection using CNN based Algorithms - II",
          "Instance Segmentation in Images",
        ],
      },
      {
        hadding: "Term 11 : Specialization in NLP",
        week: "4 weeks",
        accodingid: "trem14",
        tremone: [
          "Teach Machines to Generate New Textual Data (Language Modeling)",
          "Language Translation - English to Spanish using Seq2Seq Models",
          "Techniques to Enhance Seq2Seq Models (Attention Mechanism)",
          "Advanced NLP using BERT",
        ],
      },
      {
        hadding: "Term 12 : Building AI Solution",
        week: "4 weeks",
        accodingid: "trem15",
        tremone: [
          "Intoduction to Cloud Computing",
          "Language Translation - English to Spanish using Seq2Seq Models",
          "Techniques to Enhance Seq2Seq Models (Attention Mechanism)",
          "Advanced NLP using BERT",
        ],
      },
    ],
    programtoolhadding: "Programming Languages & Tools",
    progaming: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/3.webp",
      // "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/6.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/7.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/8.webp",
    ],
    toolandassinment: "75+ Topic wise Assignments",
    toolandpackge: "30+ Tools and Packages",
    faculty: [
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Deepesh.webp",
        name: "Deepesh",
        college: "IIM-Calcutta",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Nishkam.webp",
        name: "Nishkam",
        college: "Paypal",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/amit-goyal.webp",
        name: "Amit",
        college: "Paytm",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Gaurav-Trivedi-1.webp",
        name: "Gaurav",
        college: "IIT Guwahati",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Lavi.webp",
        name: "Lavi",
        college: "Google",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/subhodeep.webp",
        name: "Subhodeep",
        college: "Jio",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Neelmani.webp",
        name: "Neelmani",
        college: "Gojek",
      },
    ],
    projectsectionhadding: "Work on world class Projects",
    project: [
      {
        projectimg:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/pgpdsf/p1.webp",
        projecthadding: "Product Classification based on Review",
        sortdescription: "Predict the label of the product based on a review.",
        longdescription:
          "The dataset contains a collection of reviews of the 6 distinct products. The train set contains 40000 rows and 4 columns while the test set contains 10000 rows and 3 columns.",
        id: "1",
      },
      {
        projectimg:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/pgpdsf/p2.webp",
        projecthadding: "Classify whether Real or Fake News",
        sortdescription: "Determine if an article is fake news or not?.",
        longdescription:
          "The dataset contains text data of different articles. The train set contains 35918 rows and 6 columns while the test set contains 8980 rows and 5 columns.",
        id: "2",
      },
      {
        projectimg:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/pgpdsf/p3.webp",
        projecthadding: "Subject Text Classification",
        sortdescription: "Predict the topic of the text passed by the user.",
        longdescription:
          "The dataset consists of different satellite images. The train set contains 3700 images while the test set contains 300 images.",
        id: "3",
      },
      {
        projectimg:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/pgpdsf/p4.webp",
        projecthadding: "Predicting the Topic of the News",
        sortdescription: "Classify a news article into a broader topic.",
        longdescription:
          "The dataset contains a collection of news documents. The train set contains 1780 rows and 4 columns while the test set contains 445 rows and 3 columns.",
        id: "4",
      },
      {
        projectimg:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/pgpdsf/p5.webp",
        projecthadding: "Nietzsche Texts Generator",
        sortdescription:
          "Generate texts out a set of characters or words of Nietzsche texts using text generation model.",
        longdescription: "The dataset consists of text written by Nietzsche.",
        id: "5",
      },
      {
        projectimg:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/pgpdsf/p6.webp",
        projecthadding: "Abalone Age Prediction",
        sortdescription:
          "Predict the age of abalones based on physical measurements.",
        longdescription:
          "The dataset contains all the necessary information about the abalones like their sex, length, diameter, height, weight, etc. The train set contains 3341 rows and 10 columns while the test set contains 836 rows and 9 columns.",
        id: "6",
      },
      {
        projectimg:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/pgpdsf/p7.webp",
        projecthadding: "Drug Prediction",
        sortdescription:
          "Automatic drug prescription based on the health report of the person.",
        longdescription:
          "The dataset contains all the necessary information about the person’s health like their sex, BP, Age, Cholesterol etc. The train set contains 160 rows and 7 columns while the test set contains 40 rows and 6 columns.",
        id: "7",
      },
      {
        projectimg:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcd/p8.webp",
        projecthadding: "Avocado Price Prediction",
        sortdescription:
          "Predicting the future price of avocadoes based on historical data.",
        longdescription:
          "The dataset contains weekly retail scan data for National Retail Volume (units) and price of avocados. The train set contains 14599 rows and 14 columns while the test set contains 3650 rows and 13 columns.",
        id: "8",
      },
    ],
    cc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/IIT-certificate/iit12.webp",
    // mc: "https://accredian.com/wp-content/uploads/2022/08/gcdm.webp",
    certifictetstate: "Professionals upskilled in Data Science & AI skillset",

    compnyimg: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw6.webp",
    ],
    programadvantage: [
      {
        pdid: "btpa1",
        hadding: " Learning Resources & Technical Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/1.webp",
        details: [
          {
            texthdding:
              "Dedicated resource center with 1000+ resources to ease your learning journey. Daily technical support to resolve your queries.",
            desc: "desc",
          },
        ],
        classname: "btpaimageone",
      },
      {
        pdid: "btpa2",
        hadding: " Industry Immersion with Top Data Science Leaders",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/2.webp",
        details: [
          {
            haddingtext: "Monthly Speaker Connect",
            texthdding:
              "At Accredian, we bring in leading Data Science professionals from around the globe for monthly lectures. This provides you with practical insights and prepares you for intense competition in the field of Data Science and AI.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
          {
            haddingtext: "International Summits",
            texthdding:
              "We regularly arrange Data Science Summits every six months, where we invite leaders from across the world. The latest summit was the Southeast Asia Conference, focusing on the SEA region.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa3",
        hadding: "Placement Assistance",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/3.webp",
        details: [
          {
            texthdding:
              "1.Carefully crafted program, such as the ‘Career Launchpad,’ is tailored to assist candidates in refining their resumes & profiles.",
            desc: "descthre",
          },
          {
            texthdding: "2.Participate in Mock interviews and become job ready",
            desc: "desctwo",
          },
          {
            texthdding:
              "3.Utilise a dedicated job opening portal to find your perfect role.",
            desc: "desctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa4",
        hadding: "Lifelong Community Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/4.webp",
        details: [
          {
            texthdding:
              "Lifetime access to the resources, content and community support to clear your doubts.",
            desc: "lastdesc",
          },
        ],
        classname: "btpaimageone",
      },
    ],
    steponehadding: "Fill the application form",
    steponedes: "Apply by filling a simple online application form.",
    steptwohadding: "Eligibility Test",
    steptwodes:
      "Attempt the Eligibility Test and get shortlisted for the admission procedure.",
    steptreehadding: "Start Learning",
    steptreedes:
      "Get access to your dashboard and begin your journey with starter kits.",
    programtotalfee: "2,50,000",
    mothemi12: "14,250",
    mothemi18: "9,880",
    mothemi24: "7,678",

    emi: "EMIs Starting 5k/month",
    studentreviews: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/6.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/7.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/8.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/9.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/10.webp",
    ],
    faq: [
      {
        manhadding: "Program Pre-Requisites",
        faqid: "faq1",
        faqtwo: [
          {
            childfaqid: "cfaq1",
            secondhadding:
              "1.Do I need to know Programming & Math before enrolling to a program?",
            faqdetails: [
              "Background in Programming & Maths is helpful but not mandatory.",
            ],
          },
          {
            childfaqid: "cfaq2",
            secondhadding:
              "2.What is the minimum system configuration required?",
            faqdetails: [
              "Any latest processor (i3/i5/i7) with 2.0GHz clock speed and RAM 4GB (required), 8GB(recommended).",
            ],
          },
        ],
      },
      {
        manhadding: "Syllabus & Projects Covered",
        faqid: "faq2",
        faqtwo: [
          {
            childfaqid: "cfaq3",
            secondhadding: "1.How many projects can I anticipate working on?",
            faqdetails: [
              "In the Executive Program for Data Science & AI, you will undertake 12 Term Projects, 1 Mini Capstone Project, and 1 mandatory Capstone Project, as well as 1 AI Capstone Project. In addition to these, we provide a repository of over 100 projects spanning various domains for you to choose from.",
            ],
          },
          {
            childfaqid: "cfaq4",
            secondhadding:
              "2.What's the difference between Term Project & Capstone Project?",
            faqdetails: [
              "Term Projects are individual assignments specific to each term and will be shared upon the completion of every term. Capstone Projects closely resemble real-world Data Science projects, requiring the application of various concepts learned throughout the program.",
            ],
          },
          {
            childfaqid: "cfaq5",
            secondhadding:
              "3.What all topics are covered in the Executive Program in Data Science & AI",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Download our latest program{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  brochure
                </Typography>{" "}
                &{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  curriculum.
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
      {
        manhadding: "Time Commitment",
        faqid: "faq3",
        faqtwo: [
          {
            childfaqid: "cfaq6",
            secondhadding:
              "1.How much time does one need to spend on a regular basis?",
            faqdetails: [
              "Daily 30 mins of prep time is required. You can choose to practice, read or watch videos. Additionally, on weekends one must attend 2 hours of live classes on both Saturday & Sunday.",
            ],
          },
          {
            childfaqid: "cfaq7",
            secondhadding: "2.What is the duration of the program?",
            faqdetails: [
              "Every term is for 4-5 weeks approximately. And the overall Executive Program in Data Science & AI duration is 12 months.",
            ],
          },
        ],
      },

      {
        manhadding: "Why IIT Guwahati ?",
        faqid: "faq5",
        faqtwo: [
          {
            childfaqid: "cfaq10",
            secondhadding:
              "1.How is the Executive Program in Data Science & AI program different?",
            faqdetails: [
              <>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  This program is distinguished by three key elements:
                </Typography>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  1. <b>Iterative Mastery Approach:</b> IITG's exclusive
                  Iterative Mastery Approach ensures development through a
                  step-by-step methodology, specifically designed to welcome
                  individuals from diverse backgrounds. Whether you're seeking a
                  lateral move in the industry or looking to broaden your
                  skills, our program is tailored to empower you on your unique
                  learning journey.
                </Typography>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  <Typography sx={{ marginBottom: "0.5rem !important" }}>
                    2. <b>Versatile Skill Development Model:</b> IIT G's unique
                    framework ensures that every Data Science and AI concept is
                    taught in the context of real-world industry problems. Our
                    program goes beyond theory, emphasizing practical case
                    studies to prepare students for the challenges they'll face
                    in the professional arena.
                  </Typography>
                </Typography>
                <Typography sx={{ marginBottom: "4px !important" }}>
                  3. <b>Accredian Career Center:</b> Aligned with our mission to
                  cultivate the Data leaders of tomorrow, the Accredian Career
                  Center empowers you to confidently launch your Data Science
                  career.
                </Typography>
              </>,
            ],
          },
          {
            childfaqid: "cfaq11",
            secondhadding: "2.Tell me more about Career Assistance?",
            faqdetails: [
              "Accredian provides career assistance through the Career Launchpad program. Students gain hands-on experience as leaders in their chosen field with rigorous resume and portfolio building sessions. The program also covers creating a strong online presence and developing top-notch interview skills.",
            ],
          },
          {
            childfaqid: "cfaq12",
            secondhadding: "3. What's the refund policy?",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Go through our Student Policy{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  <Link
                    to="/policies/iitg-policy"
                    style={{ textDecoration: "none" }}
                  >
                    link
                  </Link>
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
    ],

    brochurectaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/iitdsaia.webp",
    brochurectahadding: "Download EPDSAIA®️ Program Brochure!",
    curriculumctahadding: "Download EPDSAI®️ Program Curriculum !",
    curriculumctaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/Executive-Program-in-DS-AI-a.webp",
    curriculumctatext: "Data Science",
    displayone: "block",
    displaytwo: "none",
    freemodulesdesplay: "block",
    jobandaddon: "none",
    careerassistance: "block",
    addmodul: "block",
    addmodultwo: "block",
    ml: "-53px",
    ctasource: "Data Science Brochure",
    programname: "Executive Program in Data Science & Artificial Intelligence",
    ctapdf:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IIT-Executive-Program-in-DS-AI-12-months.pdf",
  },
  {
    /* Emd gdcs*/
  },
  // {
  //   id: "executive-program-in-ds-and-ai",
  //   category:"2",
  // vertical:"Data Science",
  //   university:"iitg",
  //   backgroundimage:
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/program-page-banner-image/iita.webp",
  //   mobbackground:
  //     "linear-gradient(0deg, rgba(25,122,237,1) 0%, rgba(34,39,125,1) 0%, rgba(31,33,64,1) 99%)",

  //   hadding: "Executive Program in Data Science & Artificial Intelligence with E&ICT Academy, IIT Guwahati",
  //   title:"IITG - Executive Program in DS & AI 10M",
  //   programduration: "10 Months | Live Instructor led | Weekend Online",
  //   programdescription:"Master Data Analytics, Machine Learning & AI in 10 Months with India's #1 Data Science+AI program",
  //   callnumber: "70421-48123",
  //   stacksone: "E&ICT Academy",
  //   stacksonedescription: "IIT Guwahati",
  //   stackstwo: "Live Classes",
  //   stackstwodescription: "10 Months",
  //   stacksthree: "Next Cohort",
  //   stacksthreedescription: "28th January 2024",
  //   stackslast: "Career Services ",
  //   stackslastdescription: "by Accredian",
  //   progarminclud1: "9 Terms - Data Analytics, Machine Learning, & AI",
  //   progarminclud2: "Duration: 10 Months | Weekend Online",
  //   progarminclud3: "Live Instructor led classes",
  //   progarminclud4: "Daily doubt clearing",
  //   progarminclud5: "Term projects & Capstone projects",
  //   progarminclud6: "No Cost EMI upto 12 months",
  //   progarminclud7: "Placement Support & Assistance",
  //   progarminclud8: "2-day career immersion at IIT Guwahati",
  //   progarminclud9: "1:1 career mentorship sessions",
  //   progarminclud10: "Learn from the top industry professionals and expert sessions by IIT Guwahati Faculty",
  //   progarminclud11: "Certification from E&ICT, IIT Guwahati",
  //   whoshouldjoin:
  //     "People who are more interested in building a solid data science foundation and building ML/AI solutions in the real world.",
  //   Jobopportunities:
  //     "Get ready to crack interviews for roles like Data Analyst, Business Analyst, ML Engineer, Data scientist, Artificial Intelligence (AI) Engineer, AI Specialist, AI Leader, AI Product Manager etc.",
  //   suchitquotes:
  //     "Every individual from various backgrounds must learn Analytics to outshine in the competitive world today!",
  //   suchitinfo: "-Suchit Majumdar, Chief Data Science Mentor, Accredian.",
  //   Programsyllabustrmtext: "9 Terms",
  //   Programsyllabustremds: "(10 months)",
  //   Programsyllabusclasstext: "36+ hours of",
  //   Programsyllabuscareetext: "8+ career",
  //   growt:"150%",
  //   scholarshipseats:"3",
  //   nonscholarshipseats:"9",
  //   setsction:"block",
  //   accd: [
  //     {
  //       hadding: "Term 1 : Data Analysis with Python",
  //       week: "4 weeks",
  //       accodingid: "trem1",
  //       tremone: [
  //         "Data Science Fundamentals   ",
  //         " Data Operation with Numpy ",
  //         "Data Manipulation with Pandas ",
  //       ],
  //     },
  //     {
  //       hadding: "Term 2 : Data Visualization Techniques",
  //       week: "4 weeks",
  //       accodingid: "trem2",
  //       tremone: [
  //         "Introduction to Data Visualization",
  //         "Data Visualization using Matplotlib",
  //         "Hands-on Pandas for Rapid Visualization ",
  //         "Seaborn for Data Visualization",
  //       ],
  //     },
  //     {
  //       hadding: "Term 3 : EDA & Data Storytelling",
  //       week: "4 weeks",
  //       accodingid: "trem3",
  //       tremone: [
  //         "Introduction to Exploratory Data Analysis",
  //         "EDA Framework Deep Dive",
  //         "Scientific Exploration of Industry Data (Case Study) ",
  //         "Student Presentations & Insight Delivery ",
  //       ],
  //     },
  //     {
  //       hadding: "Minor Capstone Project",
  //       week: "",
  //       accodingid: "trem4",
  //       tremone: [
  //         "In a simulated environment get to work with a major Telecom company that seeks help from your team of Data Scientists to resolve a major marketing hurdle in front of them. Get a near real world exposure of working on industry problems within data science teams to reach a common end goal of helping customers win",
  //       ],
  //     },
  //     {
  //       hadding: "Term 4 : Machine Learning Foundation",
  //       week: "4 weeks",
  //       accodingid: "trem5",
  //       tremone: [
  //         "Introduction to Machine Learning",
  //         "Linear Regression",
  //         "Logistic Regression",
  //         "Model Evaluation Techniques",
  //       ],
  //     },
  //     {
  //       hadding: "Term 5 : Machine Learning Intermediate",
  //       week: "4 weeks",
  //       accodingid: "trem6",
  //       tremone: [
  //         "Decision Trees",
  //         "Random Forests ",
  //         "Dimensionality Reduction using Principal Component Analysis (PCA) ",
  //         "Naive Bayes Classifier ",
  //       ],
  //     },
  //     {
  //       hadding: "Term 6: Machine Learning Advanced",
  //       week: "4 weeks",
  //       accodingid: "trem7",
  //       tremone: [
  //         "KNN (K- Nearestneighbors)",
  //         "K-means Clustering",
  //         "Ensemble Learning ",
  //         "Optimization ",

  //       ],
  //     },
  //     {
  //       hadding: "Major Capstone Project",
  //       week: "",
  //       accodingid: "trem8",
  //       tremone: [
  //         "Experience a real world simulation of a company Employee Retention Program that needs to be solved using Data Science and Machine Learning.Work with a team of Data Scientists and experience real-world problem solving through Data Science",

  //       ],
  //     },
  //     {
  //       hadding: "Term 7 : Basics of AI, TensorFlow, and Keras",
  //       week: "4 weeks",
  //       accodingid: "trem9",
  //       tremone: [
  //         "Introduction to Artificial Intelligence",
  //         "Introduction to Deep Learning Module",
  //         "Deep Learning Model Practical with Tensorflow and Keras",

  //       ],
  //     },
  //     {
  //       hadding: "Term 8 : Computer Vision - I",
  //       week: "4 weeks",
  //       accodingid: "trem10",
  //       tremone: [
  //         "Intro to Convolutional Neural Networks",
  //         "Decoding Image Components",
  //         "Identifying MNIST using CNN",
  //         "Preprocessing Image Data to apply CNN",

  //       ],
  //     },
  //     {
  //       hadding: "Term 8: Natural Language Processing - I",
  //       week: "4 weeks",
  //       accodingid: "trem11",
  //       tremone: [
  //         "Introduction to NLP & Word Vectors",
  //         "Decoding a Textual Data",
  //         "NLP using Recurrent Neural Networks (RNN)",
  //         "NLP using Memory Alterations",

  //       ],
  //     },
  //     {
  //       hadding: "AI Capstone Project",
  //       week: "",
  //       accodingid: "trem12",
  //       tremone: [
  //         "In this ever evolving world, solving complex problems get easier using AI.In this capstone project you will be an AI expert who is helping a hypothetical company resolve some major challenges it faces by using AI. Get a chance to work with a team of AI specialists and a simulated environment to help you relate to real world challenges companies face regularly.",

  //       ],
  //     },
  //     {
  //       hadding: "Term 9 : (Specialization) Computer Vision - II",
  //       week: "4 weeks",
  //       accodingid: "trem13",
  //       tremone: [
  //         "Transfer Learning",
  //         "Object Detection using CNN based Algorithms - I",
  //         "Object Detection using CNN based Algorithms - II",
  //         "Instance Segmentation in Images",

  //       ],
  //     },
  //     {
  //       hadding: "Term 9 :(Specialization) Natural Language Processing - II",
  //       week: "4 weeks",
  //       accodingid: "trem14",
  //       tremone: [
  //         "Teach Machines to Generate New Textual Data (Language Modeling)",
  //         "Language Translation - English to Spanish using Seq2Seq Models",
  //         "Techniques to Enhance Seq2Seq Models (Attention Mechanism)",
  //         "Advanced NLP using BERT",

  //       ],
  //     },

  //   ],
  //   programtoolhadding: "Programming Languages & Tools",
  //   progaming: [
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/1.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/2.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/3.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/4.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/5.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/6.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/7.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/8.webp",
  //   ],
  //   toolandassinment: "50+ Topic wise Assignments",
  //   toolandpackge: "25+ Tools and Packages",
  //   faculty: [
  //     {
  //       facultyimag:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Deepesh.webp",
  //       name: "Deepesh",
  //       college: "IIM-Calcutta",
  //     },
  //     {
  //       facultyimag:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Nishkam.webp",
  //       name: "Nishkam",
  //       college: "Paypal",
  //     },
  //     {
  //       facultyimag:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/amit-goyal.webp",
  //       name: "Amit",
  //       college: "Paytm",
  //     },
  //     {
  //       facultyimag: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Gaurav-Trivedi-1.webp",
  //       name: "Gaurav",
  //       college: "IIT Guwahati",
  //     },
  //     {
  //       facultyimag: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Lavi.webp",
  //       name: "Lavi",
  //       college: "Google",
  //     },
  //     {
  //       facultyimag:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/subhodeep.webp",
  //       name: "Subhodeep",
  //       college: "Jio",
  //     },
  //     {
  //       facultyimag: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Neelmani.webp",
  //       name: "Neelmani",
  //       college: "Gojek",
  //     },
  //   ],
  //   projectsectionhadding: "Work on world class Projects",
  //   project: [
  //     {
  //       projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p1.webp",
  //       projecthadding: "Concrete Compressive Strength Prediction",
  //       sortdescription: "Predicting the compressive strength of the concrete, based on the materials used.",
  //       longdescription:
  //         "The dataset contains materials used in making the concrete. The train set contains 824 rows and 10 columns while the test set contains 206 rows and 9 columns.",
  //       id: "1",
  //     },
  //     {
  //       projectimg:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p2.webp",
  //       projecthadding: "Solar Radiation Prediction",
  //       sortdescription:
  //         "Predicting the future solar radiations, based on the historical meteorological data.",
  //       longdescription:
  //         "The dataset contains 4 Months of the measurements. The data comes directly from NASA’s weather research lab based on actual reading taken by their sensors situated at different locations. The train set contains 26148 rows and 12 columns while the test set contains 6538 rows and 11 columns.",
  //       id: "2",
  //     },
  //     {
  //       projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p3.webp",
  //       projecthadding: "Legendary Pokemon Prediction",
  //       sortdescription: "Predicting the legendary pokemons and also identifying what makes a pokemon legendary.",
  //       longdescription:
  //         "The dataset consists of information of different pokemons and their abilities. The train set contains 640 rows and 41 columns while the test set contains 161 rows and 40 columns.",
  //       id: "3",
  //     },
  //     {
  //       projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p4.webp",
  //       projecthadding: "Human Activity Prediction",
  //       sortdescription:
  //         "Predicting the human activity and draw other insights by analyzing the smartphone sensor data.",
  //       longdescription:
  //         "The dataset contains a collection of news documents. The train set contains 1780 rows and 4 columns while the test set contains 445 rows and 3 columns.",
  //       id: "4",
  //     },
  //     {
  //       projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p5.webp",
  //       projecthadding: "Seattle Weather Prediction",
  //       sortdescription:
  //         "Predict whether it will rain on a specific day in Seattle or not.",
  //       longdescription:
  //         "The dataset contains a collection of news documents. The train set contains 1780 rows and 4 columns while the test set contains 445 rows and 3 columns.",
  //       id: "5",
  //     },
  //     {
  //       projectimg:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p6.webp",
  //       projecthadding: "Hubway data visualization challenge",
  //       sortdescription:
  //         "Produce visualizations that reveal interesting user patterns about how people in Boston gets around on Hubway.",
  //       longdescription:
  //         "The dataset contains 1 million observations on bike usage by residents of Boston.",
  //       id: "6",
  //     },
  //     {
  //       projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p7.webp",
  //       projecthadding: "Kickstarter funding patterns",
  //       sortdescription: "Derive insights on successful and failed projects on Kickstarter platform.",
  //       longdescription:
  //         "The dataset contains 15 variables and around 400,000 observations.",
  //       id: "7",
  //     },
  //     {
  //       projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p4.webp",
  //       projecthadding: "How bad is the Air Quality in metropolitans?",
  //       sortdescription: "Analyse worsening airquality in metropolitan cities.",
  //       longdescription:
  //         "The dataset is small and contains 6 variables and over 100 observations.",
  //       id: "8",
  //     },
  //   ],
  //   cc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/IIT-certificate/iit12.webp",
  //   // mc: "https://accredian.com/wp-content/uploads/2022/08/gcdm.webp",
  //   certifictetstate: "Data Science & AI ",

  //   compnyimg: [
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw1.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw2.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw3.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw4.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw5.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw6.webp",
  //   ],
  //   programadvantage: [
  //     {
  //       pdid: "btpa1",
  //       hadding: " Learning Resources & Technical Support",
  //       cardimges:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/1.webp",
  //       details: [
  //         {
  //           texthdding:
  //             "Dedicated resource center with 1000+ resources to ease your learning journey. Daily technical support to resolve your queries.",
  //           desc: "desc",
  //         },
  //       ],
  //       classname: "btpaimageone",
  //     },
  //     {
  //       pdid: "btpa2",
  //       hadding: " Industry Immersion with Top Data Science Leaders",
  //       cardimges:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/2.webp",
  //       details: [
  //         {
  //           haddingtext: "Monthly Speaker Connect",
  //           texthdding:
  //             "At Accredian, we feature monthly lectures led by leading professionals worldwide. This offers you practical insights and readies you for the intense competition in the field.",
  //           desc: "desctwo",
  //           hdesc: "hdesctwo",
  //         },
  //         {
  //           haddingtext: "International Summits",
  //           texthdding:
  //             "Every six months, we usually organize Data Science Summits inviting leaders from one entire geography. Most recent one being SEA - South East Asia Conference.",
  //           desc: "desctwo",
  //           hdesc: "hdesctwo",
  //         },
  //       ],
  //       classname: "btpaimagetwo",
  //     },
  //     {
  //       pdid: "btpa3",
  //       hadding: "Placement Assistance",
  //       cardimges:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/3.webp",
  //       details: [
  //         {
  //           texthdding:
  //             "1.Carefully crafted program, such as the ‘Career Launchpad,’ is tailored to assist candidates in refining their resumes & profiles.",
  //           desc: "descthre",
  //         },
  //         {
  //           texthdding:
  //             "2.Participate in Mock interviews and become job ready",
  //           desc: "desctwo",
  //         },
  //         {
  //           texthdding:
  //             "3.Utilise a dedicated job opening portal to find your perfect role.",
  //           desc: "desctwo",
  //         },
  //       ],
  //       classname: "btpaimagetwo",
  //     },
  //     {
  //       pdid: "btpa4",
  //       hadding: "Lifelong Community Support",
  //       cardimges:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/4.webp",
  //       details: [
  //         {
  //           texthdding:
  //             "Lifetime access to the resources, content and community support to clear your doubts.",
  //           desc: "lastdesc",
  //         },
  //       ],
  //       classname: "btpaimageone",
  //     },
  //   ],
  //   steponehadding: "Fill the application form",
  //   steponedes: "Apply by filling a simple online application form.",
  //   steptwohadding: "Eligibility Test",
  //   steptwodes: "Attempt the Eligibility Test and get shortlisted for the admission procedure.",
  //   steptreehadding: "Start Learning",
  //   steptreedes:
  //     "Get access to your dashboard and begin your journey with starter kits.",
  //   programtotalfee: "INR 2,00,000+GST",
  //   programscholarshipfee: "INR 11,800",
  //   emi: "EMIs Starting 5k/month",
  //   studentreviews: [
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/1.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/2.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/3.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/4.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/5.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/6.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/7.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/8.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/9.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/10.webp",
  //   ],
  //   faq: [
  //     {
  //       manhadding: "Program Pre-Requisites",
  //       faqid: "faq1",
  //       faqtwo: [
  //         {
  //           childfaqid: "cfaq1",
  //           secondhadding:
  //             "1.Do I need to know Programming & Math before enrolling to a program?",
  //           faqdetails: [
  //             "Background in Programming & Maths is helpful but not mandatory.",
  //           ],
  //         },
  //         {
  //           childfaqid: "cfaq2",
  //           secondhadding:
  //             "2.What is the minimum system configuration required?",
  //           faqdetails: [
  //             "Any latest processor (i3/i5/i7) with 2.0GHz clock speed and RAM 4GB (required), 8GB(recommended).",
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       manhadding: "Syllabus & Projects Covered",
  //       faqid: "faq2",
  //       faqtwo: [
  //         {
  //           childfaqid: "cfaq3",
  //           secondhadding: "1.How many projects can I anticipate working on?",
  //           faqdetails: [
  //             "In the Executive Program in Data Science & AI, you're expected to work on 12 Term Projects, 1 Mini Capstone Project & 1 Capstone Project & 1 AI Capstone Project mandatorily. Beyond these, we have a repository of 100+ projects from various domains on which you can work.",
  //           ],
  //         },
  //         {
  //           childfaqid: "cfaq4",
  //           secondhadding:
  //             "2.What's the difference between Term Project & Capstone Project?",
  //           faqdetails: [
  //             "Term Projects are individual assignments specific to each term and will be shared upon the completion of every term. Capstone Projects closely resemble real-world projects, requiring the application of various concepts learned throughout the program.",
  //           ],
  //         },
  //         {
  //           childfaqid: "cfaq5",
  //           secondhadding:
  //             "3.What all topics are covered in the Executive Program in Data Science & AI",
  //           faqdetails: [
  //             <Typography
  //               sx={{ display: "flex", marginBottom: "0px !important" }}
  //             >
  //               Download our latest program{" "}
  //               <Typography
  //                 sx={{
  //                   color: "#eb8c28",
  //                   ml: 1,
  //                   mr: 1,
  //                   marginBottom: "0px !important",
  //                 }}
  //               >
  //                 brochure
  //               </Typography>{" "}
  //               &{" "}
  //               <Typography
  //                 sx={{
  //                   color: "#eb8c28",
  //                   ml: 1,
  //                   marginBottom: "0px !important",
  //                 }}
  //               >
  //                 curriculum.
  //               </Typography>
  //             </Typography>,
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       manhadding: "Time Commitment",
  //       faqid: "faq3",
  //       faqtwo: [
  //         {
  //           childfaqid: "cfaq6",
  //           secondhadding: "1.How much time does one need to spend on a regular basis?",
  //           faqdetails: [
  //             "Daily 30 mins of prep time is required. You can choose to practice, read or watch videos. Additionally, on weekends one must attend 2 hours of live classes on both Saturday & Sunday.",
  //           ],
  //         },
  //         {
  //           childfaqid: "cfaq7",
  //           secondhadding: "2.What is the duration of the program?",
  //           faqdetails: [
  //             "Every term is for 4-5 weeks approximately. And the overall Executive Program in Data Science & AI duration is 12 months.",
  //           ],
  //         },
  //       ],
  //     },

  //     {
  //       manhadding: "Why IIT Guwahati ?",
  //       faqid: "faq5",
  //       faqtwo: [
  //         {
  //           childfaqid: "cfaq10",
  //           secondhadding: "1.How's the Executive Program in Data Science & AI program different?",
  //           faqdetails: [
  //             "Three things make this program world class 1. Progressive Learning Architecture: Accredian's proprietary Progressive Learning Architecture ensures that irrespective of the background you are from, you will attain mastery in a step-by-step way 2. Concept-to-Context®️ Learning Framework: Accredian's Concept-to-Context®️ framework ensures that you are learning every Data Science concept in context of real world industry problems 3. Accredian Career Center: Accredian's mission is to groom Data leaders of tomorrow. Accredian Career Center helps you launch a Data Science career with 100% confidence.",
  //           ],
  //         },
  //         {
  //           childfaqid: "cfaq11",
  //           secondhadding: "2.Tell me more about Career Assistance?",
  //           faqdetails: [
  //             "Accredian provides career assistance through the Career Launchpad program. Students gain hands-on experience as leaders in their chosen field with rigorous resume and portfolio building sessions. The program also covers creating a strong online presence and developing top-notch interview skills.",
  //           ],
  //         },
  //         {
  //           childfaqid: "cfaq12",
  //           secondhadding: "3. What's the refund policy?",
  //           faqdetails: [
  //             <Typography
  //               sx={{ display: "flex", marginBottom: "0px !important" }}
  //             >
  //               Go through our Student Policy{" "}
  //               <Typography
  //                 sx={{
  //                   color: "#eb8c28",
  //                   ml: 1,
  //                   mr: 1,
  //                   marginBottom: "0px !important",
  //                 }}
  //               >
  //                  <Link to="/policies/iitg-policy" style={{ textDecoration: "none" }}>link</Link>
  //               </Typography>
  //             </Typography>,
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  //   // otherprogram: [
  //   //   {
  //   //     otherprogramimg:
  //   //       "https://accredian.com/wp-content/uploads/2022/08/gcdp.webp",
  //   //     programname: "Global Certificate in Data Science & AI",
  //   //     month: "10 months",
  //   //     pagelink: "global-certificate-in-data-science-and-ai/",
  //   //   },
  //   //   {
  //   //     otherprogramimg:
  //   //       "https://accredian.com/wp-content/uploads/2022/08/pgpdsaifp.webp",
  //   //     programname: "PGP in Data Science",
  //   //     month: "12 months",
  //   //     pagelink: "pgp-in-data-science/",
  //   //   },
  //   //   {
  //   //     otherprogramimg:
  //   //       "https://accredian.com/wp-content/uploads/2022/08/gcdaip.webp",
  //   //     programname: "PGP in Data Science & AI",
  //   //     month: "12 months",
  //   //     pagelink:
  //   //       "pgp-in-data-science-and-ai/",
  //   //   },
  //   // ],

  //   brochurectaimg:
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/iitdsai.webp",
  //   brochurectahadding: "Download EPDSAI®️ Program Brochure!",
  //   curriculumctahadding: "Download EPDSAI®️ Program Curriculum !",
  //   curriculumctaimg:
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/Executive-Program-in-DS-ai-10.webp",
  //   curriculumctatext: "Data Science",
  //   displayone: "block",
  //   displaytwo: "none",
  //   freemodulesdesplay:"block",
  //   jobandaddon:"none",
  //   careerassistance:"block",
  //   addmodul:"none",
  //   addmodultwo:"block",
  //   ml: "-53px",
  //   ctasource:"Data Science Brochure",
  //   programname:"Executive Program in Data Science & Artificial Intelligence",
  //   ctapdf:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IIT-Executive-Program-in-DS-AI-10-months.pdf",
  // },
  {
    /* Start gcdsai*/
  },
  //  {
  //   id: "advanced-certification-in-ds-and-ml",
  //   category:"2",
  //   university:"iitg",
  //   backgroundimage:
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/program-page-banner-image/iit2.webp",
  //   mobbackground:
  //     "linear-gradient(0deg, rgba(25,122,237,1) 0%, rgba(34,39,125,1) 0%, rgba(31,33,64,1) 99%)",

  //   hadding: "Advanced Certification in Data Science and Machine Learning with E&ICT Academy, IIT Guwahati",
  //   title:"IITG - Advanced Certification in DS & ML",
  //   programduration: "6 Months | Live Instructor led | Weekend Online",
  //   programdescription:"Master Data Analytics, Machine Learning & Visualization with India's #1 Data Science program.",
  //   callnumber: "70421-48123",
  //   stacksone: "E&ICT Academy",
  //   stacksonedescription: "IIT Guwahati",
  //   stackstwo: "Live Classes",
  //   stackstwodescription: "6 Months",
  //   stacksthree: "Next Cohort",
  //   stacksthreedescription: "28th January 2024",
  //   stackslast: "Career Services ",
  //   stackslastdescription: "by Accredian",
  //   progarminclud1: "6 Terms - Python, Data Analytics & Machine Learning",
  //   progarminclud2: "Duration: 6 Months | Weekend Online",
  //   progarminclud3: "Live Instructor led classes",
  //   progarminclud4: "Daily doubt clearing",
  //   progarminclud5: "Term projects & Capstone projects",
  //   progarminclud6: "No Cost EMI upto 12 months",
  //   progarminclud7: "Placement Support & Assistance",
  //   progarminclud8: "2-day career immersion at IIT Guwahati",
  //   progarminclud9: "1:1 career mentorship sessions",
  //   progarminclud10: "Learn from the top industry professionals and expert sessions by IIT Guwahati Faculty",
  //   progarminclud11: "Certification from E&ICT, IIT Guwahati",
  //   whoshouldjoin:
  //     "People who are more interested in building a solid data science foundation and building ML solutions in the real world.",
  //   Jobopportunities:
  //     "Get ready to crack interviews for roles like Data Analyst, Business Analyst, ML Engineer, Data scientist, etc.",
  //   suchitquotes:
  //     "Every individual from various backgrounds must learn Analytics to outshine in the competitive world today!",
  //   suchitinfo: "-Suchit Majumdar, Chief Data Science Mentor, Accredian.",
  //   Programsyllabustrmtext: "6 Terms",
  //   Programsyllabustremds: "(6 months)",
  //   Programsyllabusclasstext: "150+ hours of",
  //   Programsyllabuscareetext: "75+ career",
  //   growt:"80%",
  //   scholarshipseats:"2",
  //   nonscholarshipseats:"5",
  //   setsction:"block",
  //   accd: [
  //     {
  //       hadding: "Term 1 : Data Analysis with Python",
  //       week: "4 weeks",
  //       accodingid: "trem1",
  //       tremone: [
  //         "Data Science Fundamentals   ",
  //         " Data Operation with Numpy ",
  //         "Data Manipulation with Pandas ",
  //       ],
  //     },
  //     {
  //       hadding: "Term 2 : Data Visualization Techniques",
  //       week: "4 weeks",
  //       accodingid: "trem2",
  //       tremone: [
  //         "Introduction to Data Visualization",
  //         "Data Visualization using Matplotlib",
  //         "Hands-on Pandas for Rapid Visualization ",
  //         "Seaborn for Data Visualization",
  //       ],
  //     },
  //     {
  //       hadding: "Term 3 : EDA & Data Storytelling",
  //       week: "4 weeks",
  //       accodingid: "trem3",
  //       tremone: [
  //         "Introduction to Exploratory Data Analysis",
  //         "EDA Framework Deep Dive",
  //         "Scientific Exploration of Industry Data (Case Study) ",
  //         "Student Presentations & Insight Delivery ",
  //       ],
  //     },
  //     {
  //       hadding: "Minor Capstone Project",
  //       week: "",
  //       accodingid: "trem4",
  //       tremone: [
  //         "In a simulated environment get to work with a major Telecom company that seeks help from your team of Data Scientists to resolve a major marketing hurdle in front of them. Get a near real world exposure of working on industry problems within data science teams to reach a common end goal of helping customers win",
  //       ],
  //     },
  //     {
  //       hadding: "Term 4 : Machine Learning Foundation",
  //       week: "4 weeks",
  //       accodingid: "trem5",
  //       tremone: [
  //         "Introduction to Machine Learning",
  //         "Linear Regression",
  //         "Logistic Regression",
  //         "Model Evaluation Techniques",
  //       ],
  //     },
  //     {
  //       hadding: "Term 5 : Machine Learning Intermediate",
  //       week: "4 weeks",
  //       accodingid: "trem6",
  //       tremone: [
  //         "Decision Trees",
  //         "Random Forests ",
  //         "Dimensionality Reduction using Principal Component Analysis (PCA) ",
  //         "Naive Bayes Classifier ",
  //       ],
  //     },
  //     {
  //       hadding: "Term 6: Machine Learning Advanced",
  //       week: "4 weeks",
  //       accodingid: "trem7",
  //       tremone: [
  //         "KNN (K- Nearest neighbors)",
  //         "K-means Clustering",
  //         "Ensemble Learning ",
  //         "Optimization ",

  //       ],
  //     },
  //     {
  //       hadding: "Major Capstone Project",
  //       week: "",
  //       accodingid: "trem8",
  //       tremone: [
  //         "Experience a real world simulation of a company Employee Retention Program that needs to be solved using Data Science and Machine Learning.Work with a team of Data Scientists and experience real-world problem solving through Data Science",

  //       ],
  //     },

  //   ],
  //   programtoolhadding: "Programming Languages & Tools",
  //   progaming: [
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/1.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/2.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/3.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/4.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/5.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/6.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/7.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/8.webp",
  //   ],
  //   toolandassinment: "30+ Topic wise Assignments",
  //   toolandpackge: "10+ Tools and Packages",
  //   faculty: [
  //     {
  //       facultyimag:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Deepesh.webp",
  //       name: "Deepesh",
  //       college: "IIM-Calcutta",
  //     },
  //     {
  //       facultyimag:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Nishkam.webp",
  //       name: "Nishkam",
  //       college: "Paypal",
  //     },
  //     {
  //       facultyimag:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/amit-goyal.webp",
  //       name: "Amit",
  //       college: "Paytm",
  //     },
  //     {
  //       facultyimag: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Gaurav-Trivedi-1.webp",
  //       name: "Gaurav",
  //       college: "IIT Guwahati",
  //     },
  //     {
  //       facultyimag: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Lavi.webp",
  //       name: "Lavi",
  //       college: "Google",
  //     },
  //     {
  //       facultyimag:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/subhodeep.webp",
  //       name: "Subhodeep",
  //       college: "Jio",
  //     },
  //     {
  //       facultyimag: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Neelmani.webp",
  //       name: "Neelmani",
  //       college: "Gojek",
  //     },
  //   ],
  //   projectsectionhadding: "Work on world class Projects",
  //   project: [
  //     {
  //       projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcd/p1.webp",
  //       projecthadding: "Telecom Churn Prediction",
  //       sortdescription: "Classify whether a customer will churn or not.",
  //       longdescription:
  //         "The dataset consist of all the necessary information about customer's behaviour. The train set contains 5634 rows and 21 columns while the test set contains 1409 rows and 20 columns.",
  //       id: "1",
  //     },
  //     {
  //       projectimg:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcd/p2.webp",
  //       projecthadding: "Price Prediction of Used Cars",
  //       sortdescription:
  //         "Predicting the price of used cars.",
  //       longdescription:
  //         "The dataset consist of the information about the prices of used cars based on its features. The train set contains 181 rows and 27 columns while the test set contains 20 rows and 26 columns.",
  //       id: "2",
  //     },
  //     {
  //       projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcd/p3.webp",
  //       projecthadding: "Olympic 1896-2014",
  //       sortdescription: "Analysis which country have won the most medals at Olympic games.",
  //       longdescription:
  //         "The dataset contains 9 variables and 31.2k observations of the summer olympic games(1896-2014).",
  //       id: "3",
  //     },
  //     {
  //       projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcd/p4.webp",
  //       projecthadding: "Abalone Age Prediction",
  //       sortdescription:
  //         "Predict the age of abalones based on physical measurements.",
  //       longdescription:
  //         "The dataset contains all the necessary information about the abalones like their sex, length, diameter, height, weight, etc. The train set contains 3341 rows and 10 columns while the test set contains 836 rows and 9 columns..",
  //       id: "4",
  //     },
  //     {
  //       projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcd/p5.webp",
  //       projecthadding: "Restaurant Across America Data",
  //       sortdescription:
  //         "Analyze the availability and accessibility of food across America.",
  //       longdescription:
  //         "The dataset contains 10 variables and 10k observations of the fast food restaurants in America.",
  //       id: "5",
  //     },
  //     {
  //       projectimg:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcd/p6.webp",
  //       projecthadding: "Halloween Candy Winpercent Prediction",
  //       sortdescription:
  //         "To predict the winpercent of halloween candies..",
  //       longdescription:
  //         "The dataset contains all the necessary information about the different candies present in a competition like sugarpercent, pricepercent. The train set contains 68 rows and 13 columns while the test set contains 17 rows and 12 columns..",
  //       id: "6",
  //     },
  //     {
  //       projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcd/p7.webp",
  //       projecthadding: "Flight Passengers Satisfaction Prediction",
  //       sortdescription: "Predicting the satisfaction level of flight passengers.",
  //       longdescription:
  //         "The dataset consists of information of passenger boarding and deboarding information and the services provided during the travel in flight. The train set contains 83123 rows and 24 columns while the test set contains 20781 rows and 23 columns.",
  //       id: "7",
  //     },
  //     {
  //       projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcd/p8.webp",
  //       projecthadding: "Avocado Price Prediction",
  //       sortdescription: "Predicting the future price of avocadoes based on historical data.",
  //       longdescription:
  //         "The dataset contains weekly retail scan data for National Retail Volume (units) and price of avocados. The train set contains 14599 rows and 14 columns while the test set contains 3650 rows and 13 columns.",
  //       id: "8",
  //     },
  //   ],
  //   cc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/IIT-certificate/IITGdsml.webp",
  //   // mc: "https://accredian.com/wp-content/uploads/2022/08/gcdm.webp",
  //   certifictetstate: "Data Science & AI ",

  //   compnyimg: [
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/company-images/1.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/company-images/2.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/company-images/3.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/company-images/4.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/company-images/5.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/company-images/6.webp",
  //   ],
  //   programadvantage: [
  //     {
  //       pdid: "btpa1",
  //       hadding: " Learning Resources & Technical Support",
  //       cardimges:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/1.webp",
  //       details: [
  //         {
  //           texthdding:
  //             "Dedicated resource center with 1000+ resources to ease your learning journey. Daily technical support to resolve your queries.",
  //           desc: "desc",
  //         },
  //       ],
  //       classname: "btpaimageone",
  //     },
  //     {
  //       pdid: "btpa2",
  //       hadding: " Industry Immersion with Top Data Science Leaders",
  //       cardimges:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/2.webp",
  //       details: [
  //         {
  //           haddingtext: "Monthly Speaker Connect",
  //           texthdding:
  //             "At Accredian, we feature monthly lectures led by leading professionals worldwide. This offers you practical insights and readies you for the intense competition in the field.",
  //           desc: "desctwo",
  //           hdesc: "hdesctwo",
  //         },
  //         {
  //           haddingtext: "International Summits",
  //           texthdding:
  //             "Every six months, we usually organize Data Science Summits inviting leaders from one entire geography. Most recent one being SEA - South East Asia Conference.",
  //           desc: "desctwo",
  //           hdesc: "hdesctwo",
  //         },
  //       ],
  //       classname: "btpaimagetwo",
  //     },
  //     {
  //       pdid: "btpa3",
  //       hadding: "Placement Assistance",
  //       cardimges:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/3.webp",
  //       details: [
  //         {
  //           texthdding:
  //             "1.Carefully crafted program, such as the ‘Career Launchpad,’ is tailored to assist candidates in refining their resumes & profiles.",
  //           desc: "descthre",
  //         },
  //         {
  //           texthdding:
  //             "2.Participate in Mock interviews and become job ready",
  //           desc: "desctwo",
  //         },
  //         {
  //           texthdding:
  //             "3.Utilise a dedicated job opening portal to find your perfect role.",
  //           desc: "desctwo",
  //         },
  //       ],
  //       classname: "btpaimagetwo",
  //     },
  //     {
  //       pdid: "btpa4",
  //       hadding: "Lifelong Community Support",
  //       cardimges:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/4.webp",
  //       details: [
  //         {
  //           texthdding:
  //             "Lifetime access to the resources, content and community support to clear your doubts.",
  //           desc: "lastdesc",
  //         },
  //       ],
  //       classname: "btpaimageone",
  //     },
  //   ],
  //   steponehadding: "Fill the application form",
  //   steponedes: "Apply by filling a simple online application form.",
  //   steptwohadding: "Eligibility Test",
  //   steptwodes: "Attempt the Eligibility Test and get shortlisted for the admission procedure.",
  //   steptreehadding: "Start Learning",
  //   steptreedes:
  //     "Get access to your dashboard and begin your journey with starter kits.",
  //   programtotalfee: "INR 95,000+GST",
  //   programscholarshipfee: "INR 8,358",
  //   emi: "EMIs Starting 5k/month",
  //   studentreviews: [
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/1.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/2.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/3.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/4.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/5.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/6.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/7.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/8.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/9.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/10.webp",
  //   ],
  //   faq: [
  //     {
  //       manhadding: "Program Pre-Requisites",
  //       faqid: "faq1",
  //       faqtwo: [
  //         {
  //           childfaqid: "cfaq1",
  //           secondhadding:
  //             "1.Do I need to know Programming & Math before enrolling to a program?",
  //           faqdetails: [
  //             "Background in Programming & Maths is helpful but not mandatory.",
  //           ],
  //         },
  //         {
  //           childfaqid: "cfaq2",
  //           secondhadding:
  //             "2.What is the minimum system configuration required?",
  //           faqdetails: [
  //             "Any latest processor (i3/i5/i7) with 2.0GHz clock speed and RAM 4GB (required), 8GB(recommended).",
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       manhadding: "Syllabus & Projects Covered",
  //       faqid: "faq2",
  //       faqtwo: [
  //         {
  //           childfaqid: "cfaq3",
  //           secondhadding: "1.How many projects can I anticipate working on?",
  //           faqdetails: [
  //             "In the Executive Program in Data Science & AI, you're expected to work on 12 Term Projects, 1 Mini Capstone Project & 1 Capstone Project & 1 AI Capstone Project mandatorily. Beyond these, we have a repository of 100+ projects from various domains on which you can work.",
  //           ],
  //         },
  //         {
  //           childfaqid: "cfaq4",
  //           secondhadding:
  //             "2.What's the difference between Term Project & Capstone Project?",
  //           faqdetails: [
  //             "Term Projects are individual assignments specific to each term and will be shared upon the completion of every term. Capstone Projects closely resemble real-world projects, requiring the application of various concepts learned throughout the program.",
  //           ],
  //         },
  //         {
  //           childfaqid: "cfaq5",
  //           secondhadding:
  //             "3.What all topics are covered in the Advanced Certificate in Data Science & ML",
  //           faqdetails: [
  //             <Typography
  //               sx={{ display: "flex", marginBottom: "0px !important" }}
  //             >
  //               Download our latest program{" "}
  //               <Typography
  //                 sx={{
  //                   color: "#eb8c28",
  //                   ml: 1,
  //                   mr: 1,
  //                   marginBottom: "0px !important",
  //                 }}
  //               >
  //                 brochure
  //               </Typography>{" "}
  //               &{" "}
  //               <Typography
  //                 sx={{
  //                   color: "#eb8c28",
  //                   ml: 1,
  //                   marginBottom: "0px !important",
  //                 }}
  //               >
  //                 curriculum.
  //               </Typography>
  //             </Typography>,
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       manhadding: "Time Commitment",
  //       faqid: "faq3",
  //       faqtwo: [
  //         {
  //           childfaqid: "cfaq6",
  //           secondhadding: "1.How much time does one need to spend on a regular basis?",
  //           faqdetails: [
  //             "Daily 30 mins of prep time is required. You can choose to practice, read or watch videos. Additionally, on weekends one must attend 2 hours of live classes on both Saturday & Sunday.",
  //           ],
  //         },
  //         {
  //           childfaqid: "cfaq7",
  //           secondhadding: "2.What is the duration of the program?",
  //           faqdetails: [
  //             "Every term is for 4-5 weeks approximately. And the overall Executive Program in Data Science & AI duration is 12 months.",
  //           ],
  //         },
  //       ],
  //     },

  //     {
  //       manhadding: "Why IIT Guwahati ?",
  //       faqid: "faq5",
  //       faqtwo: [
  //         {
  //           childfaqid: "cfaq10",
  //           secondhadding: "1.How's the Advanced Certificate in Data Science & ML different?",
  //           faqdetails: [
  //             "Three things make this program world class 1. Progressive Learning Architecture: Accredian's proprietary Progressive Learning Architecture ensures that irrespective of the background you are from, you will attain mastery in a step-by-step way 2. Concept-to-Context®️ Learning Framework: Accredian's Concept-to-Context®️ framework ensures that you are learning every Data Science concept in context of real world industry problems 3. Accredian Career Center: Accredian's mission is to groom Data leaders of tomorrow. Accredian Career Center helps you launch a Data Science career with 100% confidence.",
  //           ],
  //         },
  //         {
  //           childfaqid: "cfaq11",
  //           secondhadding: "2.Tell me more about Career Assistance?",
  //           faqdetails: [
  //             "Accredian provides career assistance through the Career Launchpad program. Students gain hands-on experience as leaders in their chosen field with rigorous resume and portfolio building sessions. The program also covers creating a strong online presence and developing top-notch interview skills.",
  //           ],
  //         },
  //         {
  //           childfaqid: "cfaq12",
  //           secondhadding: "3. What's the refund policy?",
  //           faqdetails: [
  //             <Typography
  //               sx={{ display: "flex", marginBottom: "0px !important" }}
  //             >
  //               Go through our Student Policy{" "}
  //               <Typography
  //                 sx={{
  //                   color: "#eb8c28",
  //                   ml: 1,
  //                   mr: 1,
  //                   marginBottom: "0px !important",
  //                 }}
  //               >
  //                 <Link to="/policies/iitg-policy" style={{ textDecoration: "none" }}>link</Link>
  //               </Typography>
  //             </Typography>,
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  //   // otherprogram: [
  //   //   {
  //   //     otherprogramimg:
  //   //       "https://accredian.com/wp-content/uploads/2022/08/gcdp.webp",
  //   //     programname: "Global Certificate in Data Science & AI",
  //   //     month: "10 months",
  //   //     pagelink: "global-certificate-in-data-science-and-ai/",
  //   //   },
  //   //   {
  //   //     otherprogramimg:
  //   //       "https://accredian.com/wp-content/uploads/2022/08/pgpdsaifp.webp",
  //   //     programname: "PGP in Data Science",
  //   //     month: "12 months",
  //   //     pagelink: "pgp-in-data-science/",
  //   //   },
  //   //   {
  //   //     otherprogramimg:
  //   //       "https://accredian.com/wp-content/uploads/2022/08/gcdaip.webp",
  //   //     programname: "PGP in Data Science & AI",
  //   //     month: "12 months",
  //   //     pagelink:
  //   //       "pgp-in-data-science-and-ai/",
  //   //   },
  //   // ],

  //   brochurectaimg:
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/iita.webp",
  //   brochurectahadding: "Download ACDSML®️ Program Brochure!",
  //   curriculumctahadding: "Download ACDSML®️ Program Curriculum !",
  //   curriculumctaimg:
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/iita.webp",
  //   curriculumctatext: "Data Science",
  //   displayone: "block",
  //   displaytwo: "none",
  //   freemodulesdesplay:"block",
  //   jobandaddon:"none",
  //   careerassistance:"block",
  //   addmodul:"block",
  //   addmodultwo:"block",
  //   ml: "-53px",
  //   ctasource:"Data Science Brochure",
  //   programname:"Advanced Certification in Data Science and Machine Learning",
  //   ctapdf:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IIT-Advanced-Certification-in-DS-ML-6-months.pdf",
  // },

  // {
  //   id: "certificate-in-data-analytics",
  //   category:"2",
  //   university:"iitg",
  //   backgroundimage:
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/program-page-banner-image/iit2.webp",
  //   mobbackground:
  //     "linear-gradient(0deg, rgba(25,122,237,1) 0%, rgba(34,39,125,1) 0%, rgba(31,33,64,1) 99%)",

  //   hadding: "Certificate in Data Analytics with E&ICT Academy, IIT Guwahati",
  //   title:"IITG - Certificate in Data Analytics",
  //   programduration: "3 Months | Live Instructor led | Weekend Online",
  //   programdescription:"Master Data Analytics, Machine Learning & Visualization with India's #1 Data Science program.",
  //   callnumber: "70421-48123",
  //   stacksone: "E&ICT Academy",
  //   stacksonedescription: "IIT Guwahati",
  //   stackstwo: "Live Classes",
  //   stackstwodescription: "3 Months",
  //   stacksthree: "Next Cohort",
  //   stacksthreedescription: "28th January 2024",
  //   stackslast: "Career Services ",
  //   stackslastdescription: "by Accredian",
  //   progarminclud1: "13 Module - Python, Data Analytics & Machine Learning",
  //   progarminclud2: "Duration: 3 Months | Weekend Online",
  //   progarminclud3: "Live Instructor led classes",
  //   progarminclud4: "Daily doubt clearing",
  //   progarminclud5: "Term projects & Capstone projects",
  //   progarminclud6: "No Cost EMI upto 12 months",
  //   progarminclud7: "Placement Support & Assistance",
  //   progarminclud8: "2-day career immersion at IIT Guwahati",
  //   progarminclud9: "1:1 career mentorship sessions",
  //   progarminclud10: "Learn from the top industry professionals and expert sessions by IIT Guwahati Faculty",
  //   progarminclud11: "Certification from E&ICT, IIT Guwahati",
  //   whoshouldjoin:
  //     "Engineers, Project Managers, Consultants & Non-technical backgrounds (Teachers, Sales & Marketing) & anyone interested in upskilling in Data Science.",
  //   Jobopportunities:
  //     "Analyst, Data Analyst, Business Analyst, Product Analyst, Associate Data Scientist, Decision Scientist.",
  //   suchitquotes:
  //     "Every individual from various backgrounds must learn Analytics to outshine in the competitive world today!",
  //   suchitinfo: "-Suchit Majumdar, Chief Data Science Mentor, Accredian.",
  //   Programsyllabustrmtext: "13 Module",
  //   Programsyllabustremds: "(3 months)",
  //   Programsyllabusclasstext: "25+ hours of",
  //   Programsyllabuscareetext: "10+ career",
  //   growt:"45%",
  //   scholarshipseats:"2",
  //   nonscholarshipseats:"3",
  //   setsction:"block",
  //   accd: [
  //     {
  //       hadding: "Module 1 : Data Science Fundamentals  ",
  //       week: "",
  //       accodingid: "trem1",
  //       tremone: [
  //         "Thought Experiment: Data Science from a layman’s perspective ",
  //         "Brief intro to Data Science",
  //         "How companies use Data Science",
  //         "Overview of Data Science project lifecycle",
  //         "Walkthrough of data types and data challenges",
  //       ],
  //     },
  //     {
  //       hadding: "Module 2 : Recap: Python for Data Science",
  //       week: "",
  //       accodingid: "trem2",
  //       tremone: [
  //         "In-class quiz for Python Basics",
  //         "Common Python concepts and sample questions",
  //         "Variable, Inbuilt datatypes, functions, modules and Packages",
  //         "File operations and error handling",
  //       ],
  //     },
  //     {
  //       hadding: "Module 3 : Recap: Statistics for Data Science",
  //       week: "",
  //       accodingid: "trem3",
  //       tremone: [
  //         "In-class quiz for Descriptive Statistics",
  //         "Common charts used",
  //         "In-class quiz for Inferential Statistics",
  //         "Probability, Central Limit Theorem,Normal Distribution & Hypothesis testing ",
  //       ],
  //     },
  //     {
  //       hadding: "Module 4 : Data Operations with Numpy",
  //       week: "",
  //       accodingid: "trem4",
  //       tremone: [
  //         "Introduction to Numpy Arrays",
  //         "How to apply mathematical operations in Numpy",
  //         "Array manipulation using Numpy",
  //         "Broadcast values across Arrays using Numpy",
  //       ],
  //     },
  //     {
  //       hadding: "Module 5 : Data Manipulation with Pandas",
  //       week: "",
  //       accodingid: "trem5",
  //       tremone: [
  //         "Types of Data Structures in Pandas",
  //         "Clean data using Pandas",
  //         "Manipulating data in Pandas",
  //         "How to deal with missing values",
  //         "Hands-on: Implement Numpy arrays and Pandas Dataframes",
  //       ],
  //     },
  //     {
  //       hadding: "Module 6 : Introduction to Data Visualization",
  //       week: "",
  //       accodingid: "trem6",
  //       tremone: [
  //         "Brief introduction to Data Visualization",
  //         "Advantages and Applications of Data Visualization",
  //         "Univariate statistical charts",
  //         "Bivariate statistical charts",
  //         "Multivariate statistical charts",
  //       ],
  //     },
  //     {
  //       hadding: "Module 7 : Data Visualization using Matplotlib",
  //       week: "",
  //       accodingid: "trem7",
  //       tremone: [
  //         "Introduction to Python’s Data Visualization library - Matplotlib",
  //         "Basic usage of Matplotlib",
  //         "Using matplotlib to plot statistical charts",
  //         "Labelling the plots using matplotlib",

  //       ],
  //     },
  //     {
  //       hadding: "Module 8 : Hands-on Pandas for Rapid Visualization",
  //       week: "",
  //       accodingid: "trem8",
  //       tremone: [
  //         "Understanding role of product management.",
  //         "Defining product vision & strategy",
  //         "Identifying key stakeholders & managing expectations",

  //       ],
  //     },
  //     {
  //       hadding: "Module 9 : Seaborn for Data Visualization",
  //       week: "",
  //       accodingid: "trem9",
  //       tremone: [
  //         "Seaborn Data Visualization library-Introduction",
  //         "Importing and setting up seaborn",
  //         "Using seaborn to plot different statistical charts",
  //         "Adding details to seaborn charts using matplotlib",

  //       ],
  //     },
  //     {
  //       hadding: "Module 10 : LIntroduction to Exploratory Data Analysis",
  //       week: "",
  //       accodingid: "trem10",
  //       tremone: [
  //         "Introduction to Exploratory Data Analysis (EDA) steps",
  //         "Purpose of EDA",
  //         "Advantages of EDA",
  //         "Applications of EDA",

  //       ],
  //     },
  //     {
  //       hadding: "Module 11 : EDA Framework Deep Dive",
  //       week: "",
  //       accodingid: "trem11",
  //       tremone: [
  //         "Framework for Scientific Exploration",
  //         "Case study: Perform EDA to explore survival using the Titanic dataset",
  //         "Apply the EDA framework on a real-world dataset",
  //         "Generate insights and create a story around them",

  //       ],
  //     },
  //     {
  //       hadding: "Module 12 : Scientific Exploration of Industry Data - I & II",
  //       week: "",
  //       accodingid: "trem12",
  //       tremone: [
  //         "Case study: Perform EDA to explore Online Retail dataset",
  //         "Implement the EDA steps and framework in the retail domain.",
  //         "Case study: Analyze mental health of IT professionals",
  //         "Implement the EDA steps and framework on healthcare in industries.",

  //       ],
  //     },
  //     {
  //       hadding: "Module 13 : Student Presentations & Insight Delivery",
  //       week: "",
  //       accodingid: "trem13",
  //       tremone: [
  //         "Student hosted project delivery sessions.",
  //         "Sessions coordinated by the instructor",
  //         "Storytelling using generated insights.",
  //         "Best-practices for Data Visualization and Insight Delivery.",

  //       ],
  //     },

  //   ],
  //   programtoolhadding: "Programming Languages & Tools",
  //   progaming: [
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/1.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/2.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/3.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/4.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/5.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/6.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/7.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/8.webp",
  //   ],
  //   toolandassinment: "10+ Topic wise Assignments",
  //   toolandpackge: "7+ Tools and Packages",
  //   faculty: [
  //     {
  //       facultyimag:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Deepesh.webp",
  //       name: "Deepesh",
  //       college: "IIM-Calcutta",
  //     },
  //     {
  //       facultyimag:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Nishkam.webp",
  //       name: "Nishkam",
  //       college: "Paypal",
  //     },
  //     {
  //       facultyimag:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/amit-goyal.webp",
  //       name: "Amit",
  //       college: "Paytm",
  //     },
  //     {
  //       facultyimag: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Gaurav-Trivedi-1.webp",
  //       name: "Gaurav",
  //       college: "IIT Guwahati",
  //     },
  //     {
  //       facultyimag: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Lavi.webp",
  //       name: "Lavi",
  //       college: "Google",
  //     },
  //     {
  //       facultyimag:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/subhodeep.webp",
  //       name: "Subhodeep",
  //       college: "Jio",
  //     },
  //     {
  //       facultyimag: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Neelmani.webp",
  //       name: "Neelmani",
  //       college: "Gojek",
  //     },
  //   ],
  //   projectsectionhadding: "Work on world class Projects",
  //   project: [
  //     {
  //       projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p1.webp",
  //       projecthadding: "Concrete Compressive Strength Prediction",
  //       sortdescription: "Predicting the compressive strength of the concrete, based on the materials used.",
  //       longdescription:
  //         "The dataset contains materials used in making the concrete. The train set contains 824 rows and 10 columns while the test set contains 206 rows and 9 columns.",
  //       id: "1",
  //     },
  //     {
  //       projectimg:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p2.webp",
  //       projecthadding: "Solar Radiation Prediction",
  //       sortdescription:
  //         "Predicting the future solar radiations, based on the historical meteorological data.",
  //       longdescription:
  //         "The dataset contains 4 Months of the measurements. The data comes directly from NASA’s weather research lab based on actual reading taken by their sensors situated at different locations. The train set contains 26148 rows and 12 columns while the test set contains 6538 rows and 11 columns.",
  //       id: "2",
  //     },
  //     {
  //       projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p3.webp",
  //       projecthadding: "Legendary Pokemon Prediction",
  //       sortdescription: "Predicting the legendary pokemons and also identifying what makes a pokemon legendary.",
  //       longdescription:
  //         "The dataset consists of information of different pokemons and their abilities. The train set contains 640 rows and 41 columns while the test set contains 161 rows and 40 columns.",
  //       id: "3",
  //     },
  //     {
  //       projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p4.webp",
  //       projecthadding: "Human Activity Prediction",
  //       sortdescription:
  //         "Predicting the human activity and draw other insights by analyzing the smartphone sensor data.",
  //       longdescription:
  //         "The dataset contains a collection of news documents. The train set contains 1780 rows and 4 columns while the test set contains 445 rows and 3 columns.",
  //       id: "4",
  //     },
  //     {
  //       projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p5.webp",
  //       projecthadding: "Seattle Weather Prediction",
  //       sortdescription:
  //         "Predict whether it will rain on a specific day in Seattle or not.",
  //       longdescription:
  //         "The dataset contains a collection of news documents. The train set contains 1780 rows and 4 columns while the test set contains 445 rows and 3 columns.",
  //       id: "5",
  //     },
  //     {
  //       projectimg:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p6.webp",
  //       projecthadding: "Hubway data visualization challenge",
  //       sortdescription:
  //         "Produce visualizations that reveal interesting user patterns about how people in Boston gets around on Hubway.",
  //       longdescription:
  //         "The dataset contains 1 million observations on bike usage by residents of Boston.",
  //       id: "6",
  //     },
  //     {
  //       projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p7.webp",
  //       projecthadding: "Kickstarter funding patterns",
  //       sortdescription: "Derive insights on successful and failed projects on Kickstarter platform.",
  //       longdescription:
  //         "The dataset contains 15 variables and around 400,000 observations.",
  //       id: "7",
  //     },
  //     {
  //       projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p4.webp",
  //       projecthadding: "How bad is the Air Quality in metropolitans?",
  //       sortdescription: "Analyse worsening airquality in metropolitan cities.",
  //       longdescription:
  //         "The dataset is small and contains 6 variables and over 100 observations.",
  //       id: "8",
  //     },
  //   ],
  //   cc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/IIT-certificate/cda.webp",
  //   mc: "https://accredian.com/wp-content/uploads/2022/08/gcdm.webp",
  //   certifictetstate: "Data Science & AI ",

  //   compnyimg: [
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw1.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw2.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw3.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw4.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw5.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw6.webp",
  //   ],
  //   programadvantage: [
  //     {
  //       pdid: "btpa1",
  //       hadding: " Learning Resources & Technical Support",
  //       cardimges:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/1.webp",
  //       details: [
  //         {
  //           texthdding:
  //             "Dedicated resource center with 1000+ resources to ease your learning journey. Daily technical support to resolve your queries.",
  //           desc: "desc",
  //         },
  //       ],
  //       classname: "btpaimageone",
  //     },
  //     {
  //       pdid: "btpa2",
  //       hadding: " Industry Immersion with Top Data Science Leaders",
  //       cardimges:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/2.webp",
  //       details: [
  //         {
  //           haddingtext: "Monthly Speaker Connect",
  //           texthdding:
  //             "At Accredian, we feature monthly lectures led by leading professionals worldwide. This offers you practical insights and readies you for the intense competition in the field.",
  //           desc: "desctwo",
  //           hdesc: "hdesctwo",
  //         },
  //         {
  //           haddingtext: "International Summits",
  //           texthdding:
  //             "Every six months, we usually organize Data Science Summits inviting leaders from one entire geography. Most recent one being SEA - South East Asia Conference.",
  //           desc: "desctwo",
  //           hdesc: "hdesctwo",
  //         },
  //       ],
  //       classname: "btpaimagetwo",
  //     },
  //     {
  //       pdid: "btpa3",
  //       hadding: "Placement Assistance",
  //       cardimges:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/3.webp",
  //       details: [
  //         {
  //           texthdding:
  //             "1.Carefully crafted program, such as the ‘Career Launchpad,’ is tailored to assist candidates in refining their resumes & profiles.",
  //           desc: "descthre",
  //         },
  //         {
  //           texthdding:
  //             "2.Participate in Mock interviews and become job ready",
  //           desc: "desctwo",
  //         },
  //         {
  //           texthdding:
  //             "3.Utilise a dedicated job opening portal to find your perfect role.",
  //           desc: "desctwo",
  //         },
  //       ],
  //       classname: "btpaimagetwo",
  //     },
  //     {
  //       pdid: "btpa4",
  //       hadding: "Lifelong Community Support",
  //       cardimges:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/4.webp",
  //       details: [
  //         {
  //           texthdding:
  //             "Lifetime access to the resources, content and community support to clear your doubts.",
  //           desc: "lastdesc",
  //         },
  //       ],
  //       classname: "btpaimageone",
  //     },
  //   ],
  //   steponehadding: "Fill the application form",
  //   steponedes: "Apply by filling a simple online application form.",
  //   steptwohadding: "Eligibility Test",
  //   steptwodes: "Attempt the Eligibility Test and get shortlisted for the admission procedure.",
  //   steptreehadding: "Start Learning",
  //   steptreedes:
  //     "Get access to your dashboard and begin your journey with starter kits.",
  //   programtotalfee: "INR 60,000+GST",
  //   programscholarshipfee: "INR 4,425",
  //   emi: "EMIs Starting 5k/month",
  //   studentreviews: [
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/1.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/2.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/3.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/4.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/5.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/6.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/7.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/8.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/9.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/10.webp",
  //   ],
  //   faq: [
  //     {
  //       manhadding: "Program Pre-Requisites",
  //       faqid: "faq1",
  //       faqtwo: [
  //         {
  //           childfaqid: "cfaq1",
  //           secondhadding:
  //             "1.Do I need to know Programming & Math before enrolling to a program?",
  //           faqdetails: [
  //             "Background in Programming & Maths is helpful but not mandatory.",
  //           ],
  //         },
  //         {
  //           childfaqid: "cfaq2",
  //           secondhadding:
  //             "2.What is the minimum system configuration required?",
  //           faqdetails: [
  //             "Any latest processor (i3/i5/i7) with 2.0GHz clock speed and RAM 4GB (required), 8GB(recommended).",
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       manhadding: "Syllabus & Projects Covered",
  //       faqid: "faq2",
  //       faqtwo: [
  //         {
  //           childfaqid: "cfaq3",
  //           secondhadding: "1.How many projects can I anticipate working on?",
  //           faqdetails: [
  //             "In the Executive Program in Data Science & AI, you're expected to work on 12 Term Projects, 1 Mini Capstone Project & 1 Capstone Project & 1 AI Capstone Project mandatorily. Beyond these, we have a repository of 100+ projects from various domains on which you can work.",
  //           ],
  //         },
  //         {
  //           childfaqid: "cfaq4",
  //           secondhadding:
  //             "2.What's the difference between Term Project & Capstone Project?",
  //           faqdetails: [
  //             "Term Projects are individual assignments specific to each term and will be shared upon the completion of every term. Capstone Projects closely resemble real-world projects, requiring the application of various concepts learned throughout the program.",
  //           ],
  //         },
  //         {
  //           childfaqid: "cfaq5",
  //           secondhadding:
  //             "3.What all topics are covered in the Executive Program in Data Science & AI",
  //           faqdetails: [
  //             <Typography
  //               sx={{ display: "flex", marginBottom: "0px !important" }}
  //             >
  //               Download our latest program{" "}
  //               <Typography
  //                 sx={{
  //                   color: "#eb8c28",
  //                   ml: 1,
  //                   mr: 1,
  //                   marginBottom: "0px !important",
  //                 }}
  //               >
  //                 brochure
  //               </Typography>{" "}
  //               &{" "}
  //               <Typography
  //                 sx={{
  //                   color: "#eb8c28",
  //                   ml: 1,
  //                   marginBottom: "0px !important",
  //                 }}
  //               >
  //                 curriculum.
  //               </Typography>
  //             </Typography>,
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       manhadding: "Time Commitment",
  //       faqid: "faq3",
  //       faqtwo: [
  //         {
  //           childfaqid: "cfaq6",
  //           secondhadding: "1.How much time does one need to spend on a regular basis?",
  //           faqdetails: [
  //             "Daily 30 mins of prep time is required. You can choose to practice, read or watch videos. Additionally, on weekends one must attend 2 hours of live classes on both Saturday & Sunday.",
  //           ],
  //         },
  //         {
  //           childfaqid: "cfaq7",
  //           secondhadding: "2.What is the duration of the program?",
  //           faqdetails: [
  //             "Every term is for 4-5 weeks approximately. And the overall Executive Program in Data Science & AI duration is 12 months.",
  //           ],
  //         },
  //       ],
  //     },

  //     {
  //       manhadding: "Why IIT Guwahati ?",
  //       faqid: "faq5",
  //       faqtwo: [
  //         {
  //           childfaqid: "cfaq10",
  //           secondhadding: "1.How's the Executive Program in Data Science & AI program different?",
  //           faqdetails: [
  //             "Three things make this program world class 1. Progressive Learning Architecture: Accredian's proprietary Progressive Learning Architecture ensures that irrespective of the background you are from, you will attain mastery in a step-by-step way 2. Concept-to-Context®️ Learning Framework: Accredian's Concept-to-Context®️ framework ensures that you are learning every Data Science concept in context of real world industry problems 3. Accredian Career Center: Accredian's mission is to groom Data leaders of tomorrow. Accredian Career Center helps you launch a Data Science career with 100% confidence.",
  //           ],
  //         },
  //         {
  //           childfaqid: "cfaq11",
  //           secondhadding: "2.Tell me more about Career Assistance?",
  //           faqdetails: [
  //             "Accredian provides career assistance through the Career Launchpad program. Students gain hands-on experience as leaders in their chosen field with rigorous resume and portfolio building sessions. The program also covers creating a strong online presence and developing top-notch interview skills.",
  //           ],
  //         },
  //         {
  //           childfaqid: "cfaq12",
  //           secondhadding: "3. What's the refund policy?",
  //           faqdetails: [
  //             <Typography
  //               sx={{ display: "flex", marginBottom: "0px !important" }}
  //             >
  //               Go through our Student Policy{" "}
  //               <Typography
  //                 sx={{
  //                   color: "#eb8c28",
  //                   ml: 1,
  //                   mr: 1,
  //                   marginBottom: "0px !important",
  //                 }}
  //               >
  //                <Link to="/policies/iitg-policy" style={{ textDecoration: "none" }}>link</Link>
  //               </Typography>
  //             </Typography>,
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  //   // otherprogram: [
  //   //   {
  //   //     otherprogramimg:
  //   //       "https://accredian.com/wp-content/uploads/2022/08/gcdp.webp",
  //   //     programname: "Global Certificate in Data Science & AI",
  //   //     month: "10 months",
  //   //     pagelink: "global-certificate-in-data-science-and-ai/",
  //   //   },
  //   //   {
  //   //     otherprogramimg:
  //   //       "https://accredian.com/wp-content/uploads/2022/08/pgpdsaifp.webp",
  //   //     programname: "PGP in Data Science",
  //   //     month: "12 months",
  //   //     pagelink: "pgp-in-data-science/",
  //   //   },
  //   //   {
  //   //     otherprogramimg:
  //   //       "https://accredian.com/wp-content/uploads/2022/08/gcdaip.webp",
  //   //     programname: "PGP in Data Science & AI",
  //   //     month: "12 months",
  //   //     pagelink:
  //   //       "pgp-in-data-science-and-ai/",
  //   //   },
  //   // ],

  //   brochurectaimg:
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/cda.webp",
  //   brochurectahadding: "Download EPCDA®️ Program Brochure!",
  //   curriculumctahadding: "Download EPCDA®️ Program Curriculum !",
  //   curriculumctaimg:
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/cda.webp",
  //   curriculumctatext: "Data Science",
  //   displayone: "block",
  //   displaytwo: "none",
  //   freemodulesdesplay:"block",
  //   jobandaddon:"none",
  //   careerassistance:"block",
  //   addmodul:"none",
  //   addmodultwo:"block",
  //   ml: "-53px",
  //   ctasource:"Data Science Brochure",
  //   programname:"Certificate in Data Analytics",
  //   ctapdf:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/Certificate-in-Data-Analytics-3-months.pdf",
  // },
  {
    /* Start gcdsai*/
  },
  {
    id: "executive-program-in-pmds",
    category: "4",
    university: "iitg",
    vertical: "Product Management",
    brochureHeader:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/iitg.png",
    backgroundimage:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/program-page-banner-image/iita.webp",
    mobbackground:
      "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 99%)",
    programlevel: "Beginner",
    hadding:
      "Executive Program in Product Management and Data Science with E&ICT Academy, IIT Guwahati ",
    title: "IITG - Executive Program in PM & DS",
    programduration: "11 Months | Live Instructor led | Weekend Online",
    programdescription:
      "Embark on an 11-term learning journey in Product Management, Data Analytics, and Machine Learning.",
    callnumber: "70129-57331",
    stacksone: "E&ICT Academy",
    stacksonedescription: "IIT Guwahati",
    stackstwo: "Live Classes",
    stackstwodescription: "11 Months",
    stacksthree: "Next Cohort",
    stacksthreedescription: "August 2024",
    stackslast: "Career Services ",
    stackslastdescription: "by Accredian",
    progarminclud1: "11 Terms - Product Management, Analytics, ML",
    progarminclud2: "Duration: 11 Months | Weekend Online",
    progarminclud3: "Live Instructor led classes",
    progarminclud4: "2-day Campus Immersion at IIT Guwahati *",
    progarminclud5: "Capstone projects",
    progarminclud6: "No Cost EMI upto 12 months",
    progarminclud7: "Placement Support & Assistance",
    progarminclud8: "Product Demo Day",
    progarminclud9: "1:1 career mentorship sessions",
    progarminclud10: "Learn from the top industry professionals",
    progarminclud11: "Certification from E&ICT, IIT Guwahati",
    whoshouldjoin:
      "Engineers, MBAs, Freshers, Analysts, Leads, Program Managers, Product/Project Managers, or anyone from Non-Tech (Sales, Marketing, Teaching) backgrounds who is interested to learn the building blocks of Product Management.",
    Jobopportunities:
      "Data Analyst, Data Product Manager, Data Science Product Manager, Data Scientist, Product Manager, Senior Product Manager.",
    suchitquotes:
      "The world needs product managers who understand needs,people, and outcomes to build great products.",
    suchitinfo: "-Suchit Majumdar, Chief Product Officer, Accredian",
    Programsyllabustrmtext: "11 Terms",
    Programsyllabustremds: "(11  Months)",
    Programsyllabusclasstext: "170+ hours of",
    Programsyllabuscareetext: "8 career",
    growt: "55%",
    scholarshipseats: "3",
    nonscholarshipseats: "7",
    setsction: "block",
    accd: [
      {
        hadding: "Term 1 : Introduction to Product Management",
        week: "4 weeks",
        accodingid: "trem1",
        tremone: [
          "Roles & Responsibilities of a PM",
          "User Research for PMs",
          "Market Research for PMs",
          "Competitive Analysis for PMs",
        ],
      },
      {
        hadding: "Term 2 : Product Strategy & Roadmap",
        week: "4 weeks",
        accodingid: "trem2",
        tremone: [
          "What is Product Planning Cycle?",
          "Establishing Product Vision & Mission",
          "Formulating Product Strategy & Product Goals",
          "What is Product Backlog & Roadmap",
        ],
      },
      {
        hadding: "Term 3 : Design Thinking & UI/UX",
        week: "4 weeks",
        accodingid: "trem3",
        tremone: [
          "Design Thinking for PMs",
          "Building MVP - Artefacts, Persona Mapping & PRDs",
          "UI/UX for PMs",
          "Designing Mockups, Wireframes, & Prototypes",
        ],
      },
      {
        hadding: "Term 4 : Agile Product Engineering",
        week: "4 weeks",
        accodingid: "trem4",
        tremone: [
          "Introduction to Engineering for Product Managers",
          "Understanding Microservices & Cloud Services",
          "Waterfall & Agile Methodology",
          "Understanding Scrum & Kanban",
        ],
      },
      {
        hadding: "Term 5 : Product Marketing & Launch",
        week: "4 weeks",
        accodingid: "trem5",
        tremone: [
          // "Product Development & Planning User Tests",
          "Product Launch & A/B Testing",
          "Data Driven Product Marketing & Consumer Behaviour",
          "Measuring Brand & Impact of Product Marketing",
          "Marketing Ninja Challenge",
        ],
      },
      {
        hadding: "Project: Design Discovery",
        week: "",
        accodingid: "trem6",
        tremone: [
          "As a PM practitioner, you are required to apply design thinking concepts to create human-centered and action-oriented solutions in Design Discovery. You will be competing with the best to solve a complex challenge.",
        ],
      },
      {
        hadding: "Term 6 : Data Analysis with Python",
        week: "4 weeks",
        accodingid: "trem7",
        tremone: [
          "Data Science Fundamentals  ",
          "Data Operation with Numpy",
          "Data Manipulation with Pandas",
        ],
      },
      {
        hadding: "Term 7 : Data Visualization Techniques",
        week: "4 weeks",
        accodingid: "trem8",
        tremone: [
          "Introduction to Data Visualization",
          "Data Visualization using Matplotlib",
          "Hands-on Pandas for Rapid Visualization",
          "Seaborn for Data Visualization",
        ],
      },
      {
        hadding: "Term 8 :  EDA & Data Storytelling",
        week: "4 weeks",
        accodingid: "trem9",
        tremone: [
          "Introduction to Exploratory Data Analysis",
          "EDA Framework Deep Dive",
          "Scientific Exploration of Industry Data (Case Study)",
          "Student Presentations & Insight Delivery",
        ],
      },
      {
        hadding: "Minor Capstone Project",
        week: "",
        accodingid: "trem10",
        tremone: [
          "In a simulated environment, you get to work with a major Telecom company that seeks help from your team of Data Scientists to resolve a major marketing hurdle. Get a near real world exposure of working on industry problems within data science teams",
        ],
      },
      {
        hadding: "Term 9 : Machine Learning Foundation",
        week: "4 weeks",
        accodingid: "trem11",
        tremone: [
          "Introduction to Machine Learning (ML)",
          "Linear Regression",
          "Logistic Regression",
          "Model Evaluation Techniques",
        ],
      },
      {
        hadding: "Term 10 : Machine Learning Intermediate",
        week: "4 weeks",
        accodingid: "trem12",
        tremone: [
          "Decision Trees",
          "Random Forests",
          "Dimensionality Reduction using PCA",
          "Naïve Bayes Classifier",
        ],
      },
      {
        hadding: "Term 11 : Machine Learning Advanced",
        week: "4 weeks",
        accodingid: "trem13",
        tremone: [
          "KNN (K- Nearest neighbors)",
          "K-means Clustering",
          "Ensemble Learning",
          "Optimization",
        ],
      },
      {
        hadding: "Major Capstone Project",
        week: "",
        accodingid: "trem14",
        tremone: [
          "Experience a real world simulation of a company Employee Retention Program that needs to be solved using Data Science and Machine Learning.Work with a team of Data Scientists and experience real-world problem solving through Data Science",
        ],
      },
    ],
    programtoolhadding: "Tools you will learn",
    progaming: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t6.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t7.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t8.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t9.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t10.webp",
    ],
    toolandassinment: "Topic wise Assignments",
    toolandpackge: "Product Demo Day",
    faculty: [
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/prahlad.webp",
        name: "Prahlad",
        college: "Paypal",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/sai.webp",
        name: "Sai",
        college: "Google",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/Harihara.webp",
        name: "Harihara",
        college: "Amazon",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/shailendra.webp",
        name: "Shailendra",
        college: "Spinny",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/rajib.webp",
        name: "Rajib",
        college: "Walmart",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/Ankur.webp",
        name: "Ankur",
        college: "Archer",
      },
    ],
    cc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/IIT-certificate/eppmds.webp",
    mc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/PM-certificate/merit/cpm.webp",
    certifictetstate: (
      <Typography sx={{ fontSize: "13.5px", textAlign: "center" }}>
        Professionals upskilled in Product Management & Data Science skillset
      </Typography>
    ),
    compnyimg: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw6.webp",
    ],
    programadvantage: [
      {
        pdid: "btpa1",
        hadding: " Learning Resources & Technical Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/1.webp",
        details: [
          {
            texthdding:
              "Dedicated resource center with 1000+ resources to ease your learning journey. Daily technical support to resolve your queries.",
            desc: "desc",
          },
        ],
        classname: "btpaimageone",
      },
      {
        pdid: "btpa2",
        hadding: "Industry Immersion with Top Product Leaders",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/2.webp",
        details: [
          {
            haddingtext: "Monthly Speaker Connect",
            texthdding:
              "At Accredian, we bring in leading Product Management professionals from around the globe for monthly lectures. This provides you with practical insights and prepares you for intense competition in the field of Product Management.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
          {
            haddingtext: "International Summits",
            texthdding:
              "Every six months, we organize Product Week Summits inviting leaders from one diverse geography. Most recent one being India Product Week.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa3",
        hadding: "Placement Assistance",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/3.webp",
        details: [
          {
            texthdding:
              "1.Carefully crafted program such as career launchpad which will help the candidates to polish their resume & LinkedIn profiles.",
            desc: "descthre",
          },
          {
            texthdding: "2.Participate in Mock interviews and become job ready",
            desc: "desctwo",
          },
          {
            texthdding:
              "3.Utilise a dedicated job opening portal to find your perfect role.",
            desc: "desctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa4",
        hadding: "Lifelong Community Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/4.webp",
        details: [
          {
            texthdding:
              "Lifetime access to the resources, content and community support to clear your doubts.",
            desc: "lastdesc",
          },
        ],
        classname: "btpaimageone",
      },
    ],
    steponehadding: "Fill the application form",
    steponedes: "Apply by filling a simple online application form",
    steptwohadding: "Eligibility Test",
    steptwodes:
      "Attempt the Eligibility Test and get shortlisted for the admission procedure.",
    steptreehadding: "Start Learning",
    steptreedes:
      "Get your offer letter and access to your dashboard and begin your journey with starter kits.",
    programtotalfee: "2,30,000",
    mothemi12: "16,867",
    mothemi18: "11,695",
    mothemi24: "9,087",
    emi: "EMIs Starting 5k/month",
    studentreviews: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/6.webp",
    ],
    faq: [
      {
        manhadding: "Program Pre-Requisites",
        faqid: "faq1",
        faqtwo: [
          {
            childfaqid: "cfaq1",
            secondhadding:
              "1.Do I need to have prior product management & Data Science experience to enroll in the program?",
            faqdetails: [
              "No, the program is designed to be inclusive of all levels of experience. All topics will be covered from the basics, making it suitable for individuals from any field of work.",
            ],
          },
          {
            childfaqid: "cfaq2",
            secondhadding:
              "2.What is the minimum system configuration required?",
            faqdetails: [
              "Any latest processor (i3/i5/i7) with 2.0GHz clock speed and RAM 4GB (required), 8GB(recommended).",
            ],
          },
        ],
      },
      {
        manhadding: "Syllabus & Projects Covered",
        faqid: "faq2",
        faqtwo: [
          {
            childfaqid: "cfaq3",
            secondhadding: "1.How many projects can I anticipate working on?",
            faqdetails: [
              "In Executive Program in Product Management and Data Science, you will have access to more than 35 case studies, Mission: Design Discovery, Major Project, and Capstone Project to provide a better understanding of the real-world applications of product management concepts.",
            ],
          },
          {
            childfaqid: "cfaq4",
            secondhadding:
              "2.What's the difference between case studies & projects?",
            faqdetails: [
              "Case studies are targeted towards concept validation and limited to only a single concept. However, projects are targeted to overall learning and are more of a combination of multiple topics.",
            ],
          },
          {
            childfaqid: "cfaq5",
            secondhadding:
              "3.What all topics are covered in the Executive Program Product Management and Data Science Program",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Download our latest program{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  brochure
                </Typography>{" "}
                &{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  curriculum.
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
      {
        manhadding: "Time Commitment",
        faqid: "faq3",
        faqtwo: [
          {
            childfaqid: "cfaq6",
            secondhadding:
              "1.How much time does one need to spend on a regular basis?",
            faqdetails: [
              "Daily 30 mins of prep time is required. You can choose to practice, read or watch videos. Additionally, on weekends one must attend 2 hours of live classes on both Saturday & Sunday.",
            ],
          },
          {
            childfaqid: "cfaq7",
            secondhadding: "2.What is the duration of the program?",
            faqdetails: [
              "The duration of the program is 11 months, every term is approximately 4-5 weeks long.",
            ],
          },
        ],
      },

      {
        manhadding: "Why IIT Guwahati ?",
        faqid: "faq5",
        faqtwo: [
          {
            childfaqid: "cfaq10",
            secondhadding:
              "1.How's the Executive Program in Product Management and Data Science program different?",
            faqdetails: [
              <>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  This program is distinguished by three key elements:
                </Typography>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  1. <b>Iterative Mastery Approach:</b> IITG's exclusive
                  Iterative Mastery Approach ensures development through a
                  step-by-step methodology, specifically designed to welcome
                  individuals from diverse backgrounds. Whether you're seeking a
                  lateral move in the industry or looking to broaden your
                  skills, our program is tailored to empower you on your unique
                  learning journey.
                </Typography>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  <Typography sx={{ marginBottom: "0.5rem !important" }}>
                    2. <b>Versatile Skill Development Model:</b> IIT G's unique
                    framework ensures that every Product Management and Data
                    Science concept is taught in the context of real-world
                    industry problems. Our program goes beyond theory,
                    emphasizing practical case studies to prepare students for
                    the challenges they'll face in the professional arena.
                  </Typography>
                </Typography>
                <Typography sx={{ marginBottom: "4px !important" }}>
                  3. <b>Accredian Career Center:</b> Aligned with our mission to
                  cultivate the Data leaders of tomorrow, the Accredian Career
                  Center empowers you to confidently launch your Data Science
                  career.
                </Typography>
              </>,
            ],
          },
          {
            childfaqid: "cfaq11",
            secondhadding: "2.Tell me more about Career Assistance?",
            faqdetails: [
              "Accredian provides career assistance through the Career Launchpad program. Students gain hands-on experience as leaders in their chosen field with rigorous resume and portfolio building sessions. The program also covers creating a strong online presence and developing top-notch interview skills.",
            ],
          },
          {
            childfaqid: "cfaq12",
            secondhadding: "3. What's the refund policy?",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Go through our Student Policy{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  <Link
                    to="/policies/iitg-policy"
                    style={{ textDecoration: "none" }}
                  >
                    link
                  </Link>
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
    ],

    brochurectaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/epmds.webp",
    brochurectahadding: "Download EPPMDS Program Brochure!",
    curriculumctahadding: "Download EPPMDS Program Curriculum !",
    curriculumctaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/epmds.webp",
    curriculumctatext: "Product Management",
    displayone: "none",
    displaytwo: "block",
    freemodulesdesplay: "none",
    jobandaddon: "none",
    careerassistance: "none",
    ml: "-53px",
    ctasource: "Product Management Brochure",
    programname: "Executive Program in Product Management",
    ctapdf:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IITG-PMDS-Brochure.pdf",
  },
  {
    id: "executive-program-in-data-driven-product-management",
    category: "4",
    university: "iitg",
    vertical: "Product Management",
    brochureHeader:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/iitg.png",
    backgroundimage:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/program-page-banner-image/iita.webp",
    mobbackground:
      "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 99%)",
    programlevel: "Beginner",
    hadding:
      "Executive Program in Data Driven Product Management with E&ICT Academy, IIT Guwahati ",
    title: "IITG - Executive Program in Data Driven Product Management",
    programduration: "11 Months | Live Instructor led | Weekend Online",
    programdescription:
      "Embark on an 11-term learning journey in Product Strategy, Growth, and Modern Product Management.",
    callnumber: "70129-57331",
    stacksone: "E&ICT Academy",
    stacksonedescription: "IIT Guwahati",
    stackstwo: "Live Classes",
    stackstwodescription: "11 Months",
    stacksthree: "Next Cohort",
    stacksthreedescription: "August 2024",
    stackslast: "Career Services ",
    stackslastdescription: "by Accredian",
    progarminclud1:
      "11 Terms - Product Strategy,Growth & Modern Product Management",
    progarminclud2: "Duration: 11 Months | Weekend Online",
    progarminclud3: "Live Instructor led classes",
    progarminclud4: "2-day Campus Immersion at IIT Guwahati *",
    progarminclud5: "Capstone projects",
    progarminclud6: "No Cost EMI upto 12 months",
    progarminclud7: "Placement Support & Assistance",
    progarminclud8: "Product Demo Day",
    progarminclud9: "1:1 career mentorship sessions",
    progarminclud10: "Learn from the top industry professionals",
    progarminclud11: "Certification from E&ICT, IIT Guwahati",
    whoshouldjoin:
      "Our program is tailored for professionals seeking to enhance their skills in data-driven product management. Whether you are a Product Manager, Business Analyst, Data Scientist, Entrepreneur, or professional in related fields, this program equips you with the necessary skills to excel in the evolving landscape of product development.",
    Jobopportunities:
      "Our program prepares individuals for roles such as Project Manager, Market Research Analyst, Entrepreneur, and Consultant, making them valuable contributors in the ever-evolving realm of data-driven product management.",
    suchitquotes:
      "The world needs product managers who understand needs,people, and outcomes to build great products.",
    suchitinfo: "-Suchit Majumdar, Chief Product Officer, Accredian",

    Programsyllabustrmtext: "11 Terms",
    Programsyllabustremds: "(11 Months)",
    Programsyllabusclasstext: "170+ hours of",
    Programsyllabuscareetext: "8 career",
    growt: "55%",
    scholarshipseats: "3",
    nonscholarshipseats: "5",
    setsction: "block",
    accd: [
      {
        hadding: "Term 1 : Introduction to Product Management",
        week: "4 weeks",
        accodingid: "trem1",
        tremone: [
          "Roles & Responsibilities of a PM",
          "User Research for PMs",
          "Market Research for PMs",
          "Competitive Analysis for PMs",
        ],
      },
      {
        hadding: "Term 2 : Product Strategy & Roadmap",
        week: "4 weeks",
        accodingid: "trem2",
        tremone: [
          "What is Product Planning Cycle?",
          "Establishing Product Vision & Mission",
          "Formulating Product Strategy & Product Goals",
          "What is Product Backlog & Roadmap",
        ],
      },
      {
        hadding: "Term 3 : Design Thinking & UI/UX",
        week: "4 weeks",
        accodingid: "trem3",
        tremone: [
          "Design Thinking for PMs",
          "Building MVP - Artefacts, Persona Mapping & PRDs",
          "UI/UX for PMs",
          "Designing Mockups, Wireframes, & Prototypes",
        ],
      },
      {
        hadding: "Term 4 : Agile Product Engineering",
        week: "4 weeks",
        accodingid: "trem4",
        tremone: [
          "Introduction to Engineering for Product Managers",
          "Understanding Microservices & Cloud Services",
          "Waterfall & Agile Methodology",
          "Understanding Scrum & Kanban",
        ],
      },
      {
        hadding: "Term 5 : Product Marketing & Launch",
        week: "4 weeks",
        accodingid: "trem5",
        tremone: [
          // "Product Development & Planning User Tests",
          "Product Launch & A/B Testing",
          "Data Driven Product Marketing & Consumer Behaviour",
          "Measuring Brand & Impact of Product Marketing",
          "Marketing Ninja Challenge",
        ],
      },
      // {
      //   hadding: "Project: Design Discovery",
      //   week: "",
      //   accodingid: "trem6",
      //   tremone: [
      //     "As a PM practitioner, you are required to apply design thinking concepts to create human-centered and action-oriented solutions in Design Discovery. You will be competing with the best to solve a complex challenge.",

      //   ],
      // },
      {
        hadding: "Project: Design Discovery",
        week: "",
        accodingid: "trem7",
        tremone: [
          // "Data Science Fundamentals  ",
          // "Data Operation with Numpy",
          // "Data Manipulation with Pandas",
          "As a PM practitioner, you are required to apply design thinking concepts to create human-centered and action-oriented solutions in Design Discovery. You will be competing with the best to solve a complex challenge.",
        ],
      },
      {
        hadding: "Term 6 : Growth & Scaling",
        week: "4 weeks",
        accodingid: "trem8",
        tremone: [
          "Driving Product Growth",
          "Who is a Product Growth Manager?",
          "Product Growth Strategies",
          "Audience & Channel for Growth",
        ],
      },
      {
        hadding: "Term 7 :  Product Monetization",
        week: "4 weeks",
        accodingid: "trem9",
        tremone: [
          "Understanding Product Monetization",
          "Decoding Buyer Psychology",
          "Product Pricing Models & Strategies",
          "Measuring Product Monetization Success",
        ],
      },
      {
        hadding: " Term 8 : Product Leadership",
        week: "",
        accodingid: "trem10",
        tremone: [
          "Business Strategy",
          "Digital Marketing Strategies for Product Leaders",
          "Building Product Culture - Team, Stakeholders, Skills",
          "Storytelling for PMs",
        ],
      },
      {
        hadding: "Term 9 : Analytics for Product Management",
        week: "4 weeks",
        accodingid: "trem11",
        tremone: [
          "Product Metrics",
          "How to Measure Product Success",
          "Analytical tools for PMs",
          "Basics of SQL for Product Managers",
        ],
      },
      {
        hadding: "Term 10 : Building AI/ML Products",
        week: "4 weeks",
        accodingid: "trem12",
        tremone: [
          "Introduction to AI and ML",
          "Identifying AI/ML Opportunities",
          "Ethical Considerations and Bias in AI/ML",
          "Robozard - Building an AI/ML Product",
        ],
      },
      {
        hadding: "Term 11 : Modern Product Management",
        week: "4 weeks",
        accodingid: "trem13",
        tremone: [
          "No Code for Product Managers",
          "Building a No Code Product",
          "Introduction to Blockchain for PMs",
          "Introduction to ChatGPT for PMs",
        ],
      },
      {
        hadding: "Capstone Project",
        week: "",
        accodingid: "trem14",
        tremone: [
          "Wittr is a major micro blogging app popular across the globe. The management team has reached out to Accredian PM consultants to help them over come a major hurdle related to declining app usage by its customers.",
          "The company has collected Data and figured that customers have been moving onto a major competitor app because it provides them a micro vlog feature. The company need help from you to strategize, plan and design the new feature in their app as well.",
          // "Go ahead and help the company succeed using the new feature"
        ],
      },
    ],
    programtoolhadding: "Tools you will learn",
    progaming: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t6.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t7.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t8.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t9.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t10.webp",
    ],
    toolandassinment: "Topic wise Assignments",
    toolandpackge: "Product Demo Day",
    faculty: [
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/prahlad.webp",
        name: "Prahlad",
        college: "Paypal",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/sai.webp",
        name: "Sai",
        college: "Google",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/Harihara.webp",
        name: "Harihara",
        college: "Amazon",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/shailendra.webp",
        name: "Shailendra",
        college: "Spinny",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/rajib.webp",
        name: "Rajib",
        college: "Walmart",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/Ankur.webp",
        name: "Ankur",
        college: "Archer",
      },
    ],
    cc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/IIT-certificate/EPDDPM.webp",
    mc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/PM-certificate/merit/cpm.webp",
    certifictetstate: (
      <Typography sx={{ fontSize: "13.5px", textAlign: "center" }}>
        Professionals upskilled in Data Driven product skills.
      </Typography>
    ),
    compnyimg: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw6.webp",
    ],
    programadvantage: [
      {
        pdid: "btpa1",
        hadding: " Learning Resources & Technical Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/1.webp",
        details: [
          {
            texthdding:
              "Dedicated resource center with 1000+ resources to ease your learning journey. Daily technical support to resolve your queries.",
            desc: "desc",
          },
        ],
        classname: "btpaimageone",
      },
      {
        pdid: "btpa2",
        hadding: "Industry Immersion with Top Product Leaders",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/2.webp",
        details: [
          {
            haddingtext: "Monthly Speaker Connect",
            texthdding:
              "At Accredian, we bring in leading Product Management professionals from around the globe for monthly lectures. This provides you with practical insights and prepares you for intense competition in the field of Product Management.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
          {
            haddingtext: "International Summits",
            texthdding:
              "Every six months, we organize Product Week Summits inviting leaders from one diverse geography. Most recent one being India Product Week.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa3",
        hadding: "Placement Assistance",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/3.webp",
        details: [
          {
            texthdding:
              "1.Carefully crafted program such as career launchpad which will help the candidates to polish their resume & LinkedIn profiles.",
            desc: "descthre",
          },
          {
            texthdding: "2.Participate in Mock interviews and become job ready",
            desc: "desctwo",
          },
          {
            texthdding:
              "3.Utilise a dedicated job opening portal to find your perfect role.",
            desc: "desctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa4",
        hadding: "Lifelong Community Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/4.webp",
        details: [
          {
            texthdding:
              "Lifetime access to the resources, content and community support to clear your doubts.",
            desc: "lastdesc",
          },
        ],
        classname: "btpaimageone",
      },
    ],
    steponehadding: "Fill the application form",
    steponedes: "Apply by filling a simple online application form",
    steptwohadding: "Eligibility Test",
    steptwodes:
      "Attempt the Eligibility Test and get shortlisted for the admission procedure.",
    steptreehadding: "Start Learning",
    steptreedes:
      "Get your offer letter and access to your dashboard and begin your journey with starter kits.",
    programtotalfee: "2,30,000",
    mothemi12: "16,867",
    mothemi18: "11,695",
    mothemi24: "9,087",
    emi: "EMIs Starting 5k/month",
    studentreviews: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/6.webp",
    ],
    faq: [
      {
        manhadding: "Program Pre-Requisites",
        faqid: "faq1",
        faqtwo: [
          {
            childfaqid: "cfaq1",
            secondhadding:
              "1.Do I need to have prior product management experience to enroll in the program?",
            faqdetails: [
              "No, the program is designed to be inclusive of all levels of experience. All topics will be covered from the basics, making it suitable for individuals from any field of work.",
            ],
          },
          {
            childfaqid: "cfaq2",
            secondhadding:
              "2.What is the minimum system configuration required?",
            faqdetails: [
              "Any latest processor (i3/i5/i7) with 2.0GHz clock speed and RAM 4GB (required), 8GB(recommended).",
            ],
          },
        ],
      },
      {
        manhadding: "Syllabus & Projects Covered",
        faqid: "faq2",
        faqtwo: [
          {
            childfaqid: "cfaq3",
            secondhadding: "1.How many projects can I anticipate working on?",
            faqdetails: [
              "In Executive Program in Data-Driven Product Management, you will have access to more than 35 case studies, Mission: Design Discovery, Major Project, and Capstone Project to provide a better understanding of the real-world applications of product management concepts.",
            ],
          },
          {
            childfaqid: "cfaq4",
            secondhadding:
              "2.What's the difference between case studies & projects?",
            faqdetails: [
              "Case studies are targeted towards concept validation and limited to only a single concept. However, projects are targeted to overall learning and are more of a combination of multiple topics.",
            ],
          },
          {
            childfaqid: "cfaq5",
            secondhadding:
              "3.What all topics are covered in the Executive Program in Data-Driven Product Management Program",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Download our latest program{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  brochure
                </Typography>{" "}
                &{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  curriculum.
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
      {
        manhadding: "Time Commitment",
        faqid: "faq3",
        faqtwo: [
          {
            childfaqid: "cfaq6",
            secondhadding:
              "1.How much time does one need to spend on a regular basis?",
            faqdetails: [
              "Daily 30 mins of prep time is required. You can choose to practice, read or watch videos. Additionally, on weekends one must attend 2 hours of live classes on both Saturday & Sunday.",
            ],
          },
          {
            childfaqid: "cfaq7",
            secondhadding: "2.What is the duration of the program?",
            faqdetails: [
              "The duration of the program is 11 months, every term is approximately 4-5 weeks long.",
            ],
          },
        ],
      },

      {
        manhadding: "Why IIT Guwahati ?",
        faqid: "faq5",
        faqtwo: [
          {
            childfaqid: "cfaq10",
            secondhadding:
              "1.How's the Executive Program in Data-Driven Product Management program different?",
            faqdetails: [
              <>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  This program is distinguished by three key elements:
                </Typography>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  1. <b>Iterative Mastery Approach:</b> IITG's exclusive
                  Iterative Mastery Approach ensures development through a
                  step-by-step methodology, specifically designed to welcome
                  individuals from diverse backgrounds. Whether you're seeking a
                  lateral move in the industry or looking to broaden your
                  skills, our program is tailored to empower you on your unique
                  learning journey.
                </Typography>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  <Typography sx={{ marginBottom: "0.5rem !important" }}>
                    2. <b>Versatile Skill Development Model:</b> IIT G's unique
                    framework ensures that every Data Driven Product Management
                    concept is taught in the context of real-world industry
                    problems. Our program goes beyond theory, emphasizing
                    practical case studies to prepare students for the
                    challenges they'll face in the professional arena.
                  </Typography>
                </Typography>
                <Typography sx={{ marginBottom: "4px !important" }}>
                  3. <b>Accredian Career Center:</b> Aligned with our mission to
                  cultivate the Data leaders of tomorrow, the Accredian Career
                  Center empowers you to confidently launch your Product
                  Management career.
                </Typography>
              </>,
            ],
          },
          {
            childfaqid: "cfaq11",
            secondhadding: "2.Tell me more about Career Assistance?",
            faqdetails: [
              "Accredian provides career assistance through the Career Launchpad program. Students gain hands-on experience as leaders in their chosen field with rigorous resume and portfolio building sessions. The program also covers creating a strong online presence and developing top-notch interview skills.",
            ],
          },
          {
            childfaqid: "cfaq12",
            secondhadding: "3. What's the refund policy?",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Go through our Student Policy{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  <Link
                    to="/policies/iitg-policy"
                    style={{ textDecoration: "none" }}
                  >
                    link
                  </Link>
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
    ],

    brochurectaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/epddpm.webp",
    brochurectahadding: "Download EPDDPM Program Brochure!",
    curriculumctahadding: "Download EPDDPM Program Curriculum !",
    curriculumctaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/epddpm.webp",
    curriculumctatext: "Product Management",
    displayone: "none",
    displaytwo: "block",
    freemodulesdesplay: "none",
    jobandaddon: "none",
    careerassistance: "none",
    ml: "-53px",
    ctasource: "Product Management Brochure",
    programname: "Executive Program in Product Management",
    ctapdf:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IITG-EPDDPM-Brochure_v1.pdf",
  },
  {
    id: "executive-program-in-pm",
    category: "4",
    university: "iitg",
    vertical: "Product Management",
    brochureHeader:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/iitg.png",
    backgroundimage:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/program-page-banner-image/iita.webp",
    mobbackground:
      "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 99%)",
    programlevel: "Beginner",
    hadding:
      "Executive Program in Product Management with E&ICT Academy, IIT Guwahati ",
    title: "IITG - Executive Program in Product Management ",
    programduration: "8 Months | Live Instructor led | Weekend Online",
    programdescription:
      "Embark on an 8-term learning journey in Product Strategy, Growth, and Monetization",
    callnumber: "70129-57331",
    stacksone: "E&ICT Academy",
    stacksonedescription: "IIT Guwahati",
    stackstwo: "Live Classes",
    stackstwodescription: "8 Months",
    stacksthree: "Next Cohort",
    stacksthreedescription: "August 2024",
    stackslast: "Career Services ",
    stackslastdescription: "by Accredian",
    progarminclud1: "8 Terms - Product Strategy,Growth & Monetization",
    progarminclud2: "Duration: 8 Months | Weekend Online",
    progarminclud3: "Live Instructor led classes",
    progarminclud4: "Daily doubt clearing",
    progarminclud5: "Capstone projects",
    progarminclud6: "No Cost EMI upto 12 months",
    progarminclud7: "Placement Support & Assistance",
    progarminclud8: "Product Demo Day",
    progarminclud9: "1:1 career mentorship sessions",
    progarminclud10: "Learn from the top industry professionals",
    progarminclud11: "Certification from E&ICT, IIT Guwahati",
    whoshouldjoin:
      "Individuals involved in product development, project management, or aspiring to enter these fields are encouraged to join this Executive Program for valuable skill enhancement and career advancement.",
    Jobopportunities:
      "After completing this program, participants gain access to diverse job opportunities in technology, business, and project management. This program equips individuals with essential skills for roles in product development and strategic leadership.",
    suchitquotes:
      "The world needs product managers who understand needs,people, and outcomes to build great products.",
    suchitinfo: "-Suchit Majumdar, Chief Product Officer, Accredian",
    Programsyllabustrmtext: "8 Terms",
    Programsyllabustremds: "(8 Months)",
    Programsyllabusclasstext: "100+ hours of",
    Programsyllabuscareetext: "8 career",
    growt: "55%",
    scholarshipseats: "2",
    nonscholarshipseats: "6",
    setsction: "block",
    accd: [
      {
        hadding: "Term 1 : Introduction to Product Management",
        week: "4 weeks",
        accodingid: "trem1",
        tremone: [
          "Roles & Responsibilities of a PM",
          "User Research for PMs",
          "Market Research for PMs",
          "Competitive Analysis for PMs",
        ],
      },
      {
        hadding: "Term 2 : Product Strategy & Roadmap",
        week: "4 weeks",
        accodingid: "trem2",
        tremone: [
          "What is Product Planning Cycle?",
          "Establishing Product Vision & Mission",
          "Formulating Product Strategy & Product Goals",
          "What is Product Backlog & Roadmap",
        ],
      },
      {
        hadding: "Term 3 : Design Thinking & UI/UX",
        week: "4 weeks",
        accodingid: "trem3",
        tremone: [
          "Design Thinking for PMs",
          "Building MVP - Artefacts, Persona Mapping & PRDs",
          "UI/UX for PMs",
          "Designing Mockups, Wireframes, & Prototypes",
        ],
      },
      {
        hadding: "Term 4 :Agile Product Engineering",
        week: "4 weeks",
        accodingid: "trem4",
        tremone: [
          "Introduction to Engineering for Product Managers",
          "Understanding Microservices & Cloud Services",
          "Waterfall & Agile Methodology",
          "Understanding Scrum & Kanban",
        ],
      },
      {
        hadding: "Term 5 : Product Marketing & Launch",
        week: "4 weeks",
        accodingid: "trem5",
        tremone: [
          // "Product Development & Planning User Tests",
          "Product Launch & A/B Testing",
          "Data Driven Product Marketing & Consumer Behaviour",
          "Measuring Brand & Impact of Product Marketing",
          "Marketing Ninja Challenge",
        ],
      },
      {
        hadding: "Project: Design Discovery",
        week: "",
        accodingid: "trem6",
        tremone: [
          "As a PM practitioner, you are required to apply design thinking concepts to create human-centered and action-oriented solutions in Design Discovery. You will be competing with the best to solve a complex challenge.",
        ],
      },
      {
        hadding: "Term 6 : Growth & Scaling",
        week: "4 weeks",
        accodingid: "trem7",
        tremone: [
          "Driving Product Growth",
          "Who is a Product Growth Manager?",
          "Product Growth Strategies",
          "Audience & Channel for Growth",
        ],
      },
      {
        hadding: "Term 7 : Product Monetization",
        week: "4 weeks",
        accodingid: "trem8",
        tremone: [
          "Understanding Product Monetization",
          "Decoding Buyer Psychology",
          "Product Growth Strategies",
          "Product Pricing Models & Strategies",
          "Measuring Product Monetization Success",
        ],
      },
      {
        hadding: "Term 8 : Product Leadership",
        week: "4 weeks",
        accodingid: "trem9",
        tremone: [
          "Business Strategy",
          "Digital Marketing Strategies for Product Leaders",
          "Building Product Culture - Team, Stakeholders, Skills",
          "Storytelling for PMs",
        ],
      },
      {
        hadding: "Capstone Product ",
        week: "",
        accodingid: "trem10",
        tremone: [
          "Wittr is a major micro blogging app popular across the globe. The management team has reached out to Accredian PM consultants to help them over come a major hurdle related to declining app usage by its customers.",
          "The company has collected Data and figured that customers have been moving onto a major competitor app because it provides them a micro vlog feature. The company need help from you to strategize, plan and design the new feature in their app as well.",
        ],
      },
    ],
    programtoolhadding: "Tools you will learn",
    progaming: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t6.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t7.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t8.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t9.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t10.webp",
    ],
    toolandassinment: "Topic wise Assignments",
    toolandpackge: "Product Demo Day",
    faculty: [
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/prahlad.webp",
        name: "Prahlad",
        college: "Paypal",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/sai.webp",
        name: "Sai",
        college: "Google",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/Harihara.webp",
        name: "Harihara",
        college: "Amazon",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/shailendra.webp",
        name: "Shailendra",
        college: "Spinny",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/rajib.webp",
        name: "Rajib",
        college: "Walmart",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/Ankur.webp",
        name: "Ankur",
        college: "Archer",
      },
    ],
    cc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/IIT-certificate/ecpm.webp",
    mc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/PM-certificate/merit/cpm.webp",
    certifictetstate: (
      <Typography sx={{ fontSize: "13.5px", textAlign: "center" }}>
        Professionals upskilled in product skills.
      </Typography>
    ),
    compnyimg: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw6.webp",
    ],
    programadvantage: [
      {
        pdid: "btpa1",
        hadding: " Learning Resources & Technical Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/1.webp",
        details: [
          {
            texthdding:
              "Dedicated resource center with 1000+ resources to ease your learning journey. Daily technical support to resolve your queries.",
            desc: "desc",
          },
        ],
        classname: "btpaimageone",
      },
      {
        pdid: "btpa2",
        hadding: " Industry Immersion with Top Product Leaders",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/2.webp",
        details: [
          {
            haddingtext: "Monthly Speaker Connect",
            texthdding:
              "At Accredian, we bring in leading Product Management professionals from around the globe for monthly lectures. This provides you with practical insights and prepares you for intense competition in the field of Product Management.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
          {
            haddingtext: "International Summits",
            texthdding:
              "Every six months, we organize Product Week Summits inviting leaders from one diverse geography. Most recent one being India Product Week.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa3",
        hadding: "Placement Assistance",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/3.webp",
        details: [
          {
            texthdding:
              "1.Carefully crafted program such as career launchpad which will help the candidates to polish their resume & LinkedIn profiles.",
            desc: "descthre",
          },
          {
            texthdding: "2.Participate in Mock interviews and become job ready",
            desc: "desctwo",
          },
          {
            texthdding:
              "3.Utilise a dedicated job opening portal to find your perfect role.",
            desc: "desctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa4",
        hadding: "Lifelong Community Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/4.webp",
        details: [
          {
            texthdding:
              "Lifetime access to the resources, content and community support to clear your doubts.",
            desc: "lastdesc",
          },
        ],
        classname: "btpaimageone",
      },
    ],
    steponehadding: "Fill the application form",
    steponedes: "Apply by filling a simple online application form",
    steptwohadding: "Eligibility Test",
    steptwodes:
      "Attempt the Eligibility Test and get shortlisted for the admission procedure.",
    steptreehadding: "Start Learning",
    steptreedes:
      "Get your offer letter and access to your dashboard and begin your journey with starter kits.",
    programtotalfee: "1,80,000",
    mothemi12: "13,917",
    mothemi18: "9,649",
    mothemi24: "7,498",
    emi: "EMIs Starting 5k/month",
    studentreviews: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/6.webp",
    ],
    faq: [
      {
        manhadding: "Program Pre-Requisites",
        faqid: "faq1",
        faqtwo: [
          {
            childfaqid: "cfaq1",
            secondhadding:
              "1.Do I need to have prior product management experience to enroll in the program?",
            faqdetails: [
              "No, the program is designed to be inclusive of all levels of experience. All topics will be covered from the basics, making it suitable for individuals from any field of work.",
            ],
          },
          {
            childfaqid: "cfaq2",
            secondhadding:
              "2.What is the minimum system configuration required?",
            faqdetails: [
              "Any latest processor (i3/i5/i7) with 2.0GHz clock speed and RAM 4GB (required), 8GB(recommended).",
            ],
          },
        ],
      },
      {
        manhadding: "Syllabus & Projects Covered",
        faqid: "faq2",
        faqtwo: [
          {
            childfaqid: "cfaq3",
            secondhadding: "1.How many projects can I anticipate working on?",
            faqdetails: [
              "In Executive Program in Product Management you will have access to more than 35 case studies, Project: Design Discovery, and Capstone Project to provide a better understanding of the real-world applications of product management concepts.",
            ],
          },
          {
            childfaqid: "cfaq4",
            secondhadding:
              "2.What's the difference between case studies & projects?",
            faqdetails: [
              "Case studies are targeted towards concept validation and limited to only a single concept. However, projects are targeted to overall learning and are more of a combination of multiple topics.",
            ],
          },
          {
            childfaqid: "cfaq5",
            secondhadding:
              "3.What all topics are covered in the Executive Program in Product Management ",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Download our latest program{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  brochure
                </Typography>{" "}
                &{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  curriculum.
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
      {
        manhadding: "Time Commitment",
        faqid: "faq3",
        faqtwo: [
          {
            childfaqid: "cfaq6",
            secondhadding:
              "1.How much time does one need to spend on a regular basis?",
            faqdetails: [
              "Daily 30 mins of prep time is required. You can choose to practice, read or watch videos. Additionally, on weekends one must attend 2 hours of live classes on both Saturday & Sunday.",
            ],
          },
          {
            childfaqid: "cfaq7",
            secondhadding: "2.What is the duration of the program?",
            faqdetails: [
              "Every term is for 4-5 weeks approximately. And the overall Executive Program in Product Management  duration is 8 months.",
            ],
          },
        ],
      },

      {
        manhadding: "Why IIT Guwahati ?",
        faqid: "faq5",
        faqtwo: [
          {
            childfaqid: "cfaq10",
            secondhadding:
              "1.How's the Executive Program in Product Management  program different?",
            faqdetails: [
              <>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  This program is distinguished by three key elements:
                </Typography>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  1. <b>Iterative Mastery Approach:</b> IITG's exclusive
                  Iterative Mastery Approach ensures development through a
                  step-by-step methodology, specifically designed to welcome
                  individuals from diverse backgrounds. Whether you're seeking a
                  lateral move in the industry or looking to broaden your
                  skills, our program is tailored to empower you on your unique
                  learning journey.
                </Typography>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  <Typography sx={{ marginBottom: "0.5rem !important" }}>
                    2. <b>Versatile Skill Development Model:</b> IIT G's unique
                    framework ensures that every Product Management concept is
                    taught in the context of real-world industry problems. Our
                    program goes beyond theory, emphasizing practical case
                    studies to prepare students for the challenges they'll face
                    in the professional arena.
                  </Typography>
                </Typography>
                <Typography sx={{ marginBottom: "4px !important" }}>
                  3. <b>Accredian Career Center:</b> Aligned with our mission to
                  cultivate the Data leaders of tomorrow, the Accredian Career
                  Center empowers you to confidently launch your Data Science
                  career.
                </Typography>
              </>,
            ],
          },
          {
            childfaqid: "cfaq11",
            secondhadding: "2.Tell me more about Career Assistance?",
            faqdetails: [
              "Accredian provides career assistance through the Career Launchpad program. Students gain hands-on experience as leaders in their chosen field with rigorous resume and portfolio building sessions. The program also covers creating a strong online presence and developing top-notch interview skills.",
            ],
          },
          {
            childfaqid: "cfaq12",
            secondhadding: "3. What's the refund policy?",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Go through our Student Policy{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  <Link
                    to="/policies/iitg-policy"
                    style={{ textDecoration: "none" }}
                  >
                    link
                  </Link>
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
    ],

    brochurectaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/epm.webp",
    brochurectahadding: "Download EPPM®️ Program Brochure!",
    curriculumctahadding: "Download EPPM®️ Program Curriculum !",
    curriculumctaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/epm.webp",
    curriculumctatext: "Product Management",
    displayone: "none",
    displaytwo: "block",
    freemodulesdesplay: "none",
    jobandaddon: "none",
    careerassistance: "none",
    ml: "-53px",
    ctasource: "Product Management Brochure",
    programname: "Executive Program in Product Management",
    ctapdf:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IITG-EPPM-8M-Brochure-v1.pdf",
  },
  {
    id: "advanced-certification-in-pm",
    category: "4",
    university: "iitg",
    vertical: "Product Management",
    brochureHeader:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/iitg.png",
    backgroundimage:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/program-page-banner-image/iit2.webp",
    mobbackground:
      "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 99%)",
    programlevel: "Beginner",
    hadding:
      "Advanced Certification in Product Management with E&ICT Academy, IIT Guwahati",
    title: "IITG - Advanced Certification in Product Management ",
    programduration: "5 Months | Live Instructor led | Weekend Online",
    programdescription:
      "Embark on a 5-term learning journey in Product Management, UI/UX, Product Strategy, Product Marketing.",
    callnumber: "70129-57331",
    stacksone: "E&ICT Academy",
    stacksonedescription: "IIT Guwahati",
    stackstwo: "Live Classes",
    stackstwodescription: "5 Months",
    stacksthree: "Next Cohort",
    stacksthreedescription: "August 2024",
    stackslast: "Career Services ",
    stackslastdescription: "by Accredian",
    progarminclud1: "5 Terms - UI/UX, Engineering, Development",
    progarminclud2: "Duration: 5 Months | Weekend Online",
    progarminclud3: "Live Instructor led classes",
    progarminclud4: "Daily doubt clearing",
    progarminclud5: "Capstone projects",
    progarminclud6: "No Cost EMI upto 12 months",
    progarminclud7: "Placement Support & Assistance",
    progarminclud8: "Product Demo Day",
    progarminclud9: "1:1 career mentorship sessions",
    progarminclud10: "Learn from the top industry professionals",
    progarminclud11: "Certification from E&ICT, IIT Guwahati",
    whoshouldjoin:
      "The Advanced Certification in Product Management is designed for professionals seeking to deepen their expertise in product management. This comprehensive program equips participants with advanced skills and knowledge essential for success in the dynamic and competitive field of product management.",
    Jobopportunities:
      "Unlock diverse career paths, including Product Manager, Product Marketing Manager, Business Analyst, Innovation Manager, Project Manager, and Entrepreneurship, with expertise gained in advanced product management training.",
    suchitquotes:
      "The world needs product managers who understand needs,people, and outcomes to build great products.",
    suchitinfo: "-Suchit Majumdar, Chief Product Officer, Accredian",
    Programsyllabustrmtext: "5 Terms",
    Programsyllabustremds: "(5 Terms)",
    Programsyllabusclasstext: "80+ hours of",
    Programsyllabuscareetext: "4 career",
    growt: "50%",
    scholarshipseats: "3",
    nonscholarshipseats: "6",
    setsction: "block",
    accd: [
      {
        hadding: "Term 1 : Introduction to Product Management",
        week: "4 weeks",
        accodingid: "trem1",
        tremone: [
          "Roles & Responsibilities of a PM",
          "User Research for PMs",
          "Market Research for PMs",
          "Competitive Analysis for PMs",
        ],
      },
      {
        hadding: "Term 2 : Product Strategy & Roadmap",
        week: "4 weeks",
        accodingid: "trem2",
        tremone: [
          "What is Product Planning Cycle?",
          "Establishing Product Vision & Mission",
          "Formulating Product Strategy & Product Goals",
          "What is Product Backlog & Roadmap",
        ],
      },
      {
        hadding: "Term 3 : Design Thinking & UI/UX",
        week: "4 weeks",
        accodingid: "trem3",
        tremone: [
          "Design Thinking for PMs",
          "Building MVP - Artefacts, Persona Mapping & PRDs",
          "UI/UX for PMs",
          "Designing Mockups, Wireframes, & Prototypes",
        ],
      },
      {
        hadding: "Term 4 :Agile Product Engineering",
        week: "4 weeks",
        accodingid: "trem4",
        tremone: [
          "Introduction to Engineering for Product Managers",
          "Understanding Microservices & Cloud Services",
          "Waterfall & Agile Methodology",
          "Understanding Scrum & Kanban",
        ],
      },
      {
        hadding: "Term 5 : Product Marketing & Launch",
        week: "4 weeks",
        accodingid: "trem5",
        tremone: [
          // "Product Development & Planning User Tests",
          "Product Launch & A/B Testing",
          "Data Driven Product Marketing & Consumer Behaviour",
          "Measuring Brand & Impact of Product Marketing",
          "Marketing Ninja Challenge",
        ],
      },
      {
        hadding: "Project: Design Discovery",
        week: "",
        accodingid: "trem6",
        tremone: [
          "As a PM practitioner, you are required to apply design thinking concepts to create human-centered and action-oriented solutions in Design Discovery. You will be competing with the best to solve a complex challenge.",
        ],
      },
    ],
    programtoolhadding: "Tools you will learn",
    progaming: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t6.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t7.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t8.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t9.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t10.webp",
    ],
    toolandassinment: "Topic wise Assignments",
    toolandpackge: "Product Demo Day",
    faculty: [
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/prahlad.webp",
        name: "Prahlad",
        college: "Paypal",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/sai.webp",
        name: "Sai",
        college: "Google",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/Harihara.webp",
        name: "Harihara",
        college: "Amazon",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/shailendra.webp",
        name: "Shailendra",
        college: "Spinny",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/rajib.webp",
        name: "Rajib",
        college: "Walmart",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/Ankur.webp",
        name: "Ankur",
        college: "Archer",
      },
    ],
    cc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/IIT-certificate/acpm.webp",
    mc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/PM-certificate/merit/cpm.webp",
    certifictetstate: (
      <Typography sx={{ fontSize: "13.5px", textAlign: "center" }}>
        Professionals <b>upskilled in</b> product skills.
      </Typography>
    ),
    compnyimg: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw6.webp",
    ],
    programadvantage: [
      {
        pdid: "btpa1",
        hadding: " Learning Resources & Technical Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/1.webp",
        details: [
          {
            texthdding:
              "Dedicated resource center with 1000+ resources to ease your learning journey. Daily technical support to resolve your queries.",
            desc: "desc",
          },
        ],
        classname: "btpaimageone",
      },
      {
        pdid: "btpa2",
        hadding: " Industry Immersion with Top Product Leaders",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/2.webp",
        details: [
          {
            haddingtext: "Monthly Speaker Connect",
            texthdding:
              "At Accredian, we bring in leading Product Management professionals from around the globe for monthly lectures. This provides you with practical insights and prepares you for intense competition in the field of Product Management.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
          {
            haddingtext: "International Summits",
            texthdding:
              "Every six months, we organize Product Week Summits inviting leaders from one diverse geography. Most recent one being India Product Week.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa3",
        hadding: "Placement Assistance",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/3.webp",
        details: [
          {
            texthdding:
              "1.Carefully crafted program such as career launchpad which will help the candidates to polish their resume & LinkedIn profiles.",
            desc: "descthre",
          },
          {
            texthdding: "2.Participate in Mock interviews and become job ready",
            desc: "desctwo",
          },
          {
            texthdding:
              "3.Utilise a dedicated job opening portal to find your perfect role.",
            desc: "desctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa4",
        hadding: "Lifelong Community Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/4.webp",
        details: [
          {
            texthdding:
              "Lifetime access to the resources, content and community support to clear your doubts.",
            desc: "lastdesc",
          },
        ],
        classname: "btpaimageone",
      },
    ],
    steponehadding: "Fill the application form",
    steponedes: "Apply by filling a simple online application form",
    steptwohadding: "Eligibility Test",
    steptwodes:
      "Attempt the Eligibility Test and get shortlisted for the admission procedure.",
    steptreehadding: "Start Learning",
    steptreedes:
      "Get your offer letter and access to your dashboard and begin your journey with starter kits.",
    programtotalfee: "1,40,000",
    mothemi12: "10,967",
    mothemi18: "7,604",
    mothemi24: "5,909",
    emi: "EMIs Starting 5k/month",
    studentreviews: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/6.webp",
    ],
    faq: [
      {
        manhadding: "Program Pre-Requisites",
        faqid: "faq1",
        faqtwo: [
          {
            childfaqid: "cfaq1",
            secondhadding:
              "1.Do I need to have prior product management experience to enroll in the program?",
            faqdetails: [
              "No, the program is designed to be inclusive of all levels of experience. All topics will be covered from the basics, making it suitable for individuals from any field of work.",
            ],
          },
          {
            childfaqid: "cfaq2",
            secondhadding:
              "2.What is the minimum system configuration required?",
            faqdetails: [
              "Any latest processor (i3/i5/i7) with 2.0GHz clock speed and RAM 4GB (required), 8GB(recommended).",
            ],
          },
        ],
      },
      {
        manhadding: "Syllabus & Projects Covered",
        faqid: "faq2",
        faqtwo: [
          {
            childfaqid: "cfaq3",
            secondhadding: "1.How many projects can I anticipate working on?",
            faqdetails: [
              "In Advanced Certification in Product Management Program you will have access to more than 35 case studies, Project: Design Discovery to provide a better understanding of the real-world applications of product management concepts.",
            ],
          },
          {
            childfaqid: "cfaq4",
            secondhadding:
              "2.What's the difference between case studies & projects?",
            faqdetails: [
              "Case studies are targeted towards concept validation and limited to only a single concept. However, projects are targeted to overall learning and are more of a combination of multiple topics.",
            ],
          },
          {
            childfaqid: "cfaq5",
            secondhadding:
              "3.What all topics are covered in the Advanced Certification in Product Management Program ",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Download our latest program{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  brochure
                </Typography>{" "}
                &{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  curriculum.
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
      {
        manhadding: "Time Commitment",
        faqid: "faq3",
        faqtwo: [
          {
            childfaqid: "cfaq6",
            secondhadding:
              "1.How much time does one need to spend on a regular basis?",
            faqdetails: [
              "Daily 30 mins of prep time is required. You can choose to practice, read or watch videos. Additionally, on weekends one must attend 2 hours of live classes on both Saturday & Sunday.",
            ],
          },
          {
            childfaqid: "cfaq7",
            secondhadding: "2.What is the duration of the program?",
            faqdetails: [
              "Every term is for 4-5 weeks approximately. And the overall Advanced Certification in Product Management  duration is 5 months.",
            ],
          },
        ],
      },

      {
        manhadding: "Why IIT Guwahati ?",
        faqid: "faq5",
        faqtwo: [
          {
            childfaqid: "cfaq10",
            secondhadding:
              "1.How's the Advanced Certification in Product Management Program different?",
            faqdetails: [
              <>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  This program is distinguished by three key elements:
                </Typography>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  1. <b>Iterative Mastery Approach:</b> IITG's exclusive
                  Iterative Mastery Approach ensures development through a
                  step-by-step methodology, specifically designed to welcome
                  individuals from diverse backgrounds. Whether you're seeking a
                  lateral move in the industry or looking to broaden your
                  skills, our program is tailored to empower you on your unique
                  learning journey.
                </Typography>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  <Typography sx={{ marginBottom: "0.5rem !important" }}>
                    2. <b>Versatile Skill Development Model:</b> IIT G's unique
                    framework ensures that every Advanced Certification in
                    Product Management concept is taught in the context of
                    real-world industry problems. Our program goes beyond
                    theory, emphasizing practical case studies to prepare
                    students for the challenges they'll face in the professional
                    arena.
                  </Typography>
                </Typography>
                <Typography sx={{ marginBottom: "4px !important" }}>
                  3. <b>Accredian Career Center:</b> Aligned with our mission to
                  cultivate the Data leaders of tomorrow, the Accredian Career
                  Center empowers you to confidently launch your Product
                  Management career.
                </Typography>
              </>,
            ],
          },
          {
            childfaqid: "cfaq11",
            secondhadding: "2.Tell me more about Career Assistance?",
            faqdetails: [
              "Accredian provides career assistance through the Career Launchpad program. Students gain hands-on experience as leaders in their chosen field with rigorous resume and portfolio building sessions. The program also covers creating a strong online presence and developing top-notch interview skills.",
            ],
          },
          {
            childfaqid: "cfaq12",
            secondhadding: "3. What's the refund policy?",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Go through our Student Policy{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  <Link
                    to="/policies/iitg-policy"
                    style={{ textDecoration: "none" }}
                  >
                    link
                  </Link>
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
    ],

    brochurectaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/acpm.webp",
    brochurectahadding: "Download ACPM®️ Program Brochure!",
    curriculumctahadding: "Download ACPM®️ Program Curriculum !",
    curriculumctaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/acpm.webp",
    curriculumctatext: "Product Management",
    displayone: "none",
    displaytwo: "block",
    freemodulesdesplay: "none",
    jobandaddon: "none",
    careerassistance: "none",
    ml: "-53px",
    ctasource: "Product Management Brochure",
    programname: "Advanced Certification in Product Management",
    ctapdf:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IITG-ACPM-Brochure-v1.pdf",
  },
  {
    id: "executive-program-in-ds-ba",
    category: "2",
    university: "iitg",
    vertical: "Data Science",
    brochureHeader:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/iitg.png",
    backgroundimage:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/program-page-banner-image/iita.webp",
    mobbackground:
      "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 99%)",
    programlevel: "Beginner",
    hadding:
      "Executive Program in Data Science and Business Analytics with E&ICT Academy, IIT Guwahati",
    title: "IITG - Executive Program in Data Science and Business Analytics",
    programduration: "11 Months | Live Instructor led | Weekend Online",
    programdescription:
      "Embark on an 11-term learning journey in Data Analytics, ML & Business Analytics.",
    callnumber: "90796-53292",
    stacksone: "E&ICT Academy",
    stacksonedescription: "IIT Guwahati",
    stackstwo: "Live Classes",
    stackstwodescription: "11 Months",
    stacksthree: "Next Cohort",
    stacksthreedescription: "August 2024",
    stackslast: "Career Services ",
    stackslastdescription: "by Accredian",
    progarminclud1: "11 Terms - Data Analytics, ML & Business Analytics",
    progarminclud2: "Duration: 11 Months | Weekend Online",
    progarminclud3: "Live Instructor led classes",
    progarminclud4: "Daily doubt clearing",
    progarminclud5: "Capstone projects",
    progarminclud6: "No Cost EMI upto 12 months",
    progarminclud7: "Placement Support & Assistance",
    progarminclud8: "2-day campus immersion at IIT Guwahati ",
    progarminclud9: "1:1 career mentorship sessions",
    progarminclud10: "Learn from the top industry professionals",
    progarminclud11: "Certification from E&ICT, IIT Guwahati",
    whoshouldjoin:
      "Tailored for technology, business, and analytics professionals, as well as those transitioning into data science and business analysis roles, our Executive Program is ideal for skill advancement in these dynamic fields.",
    Jobopportunities:
      "After completing this course, participants can explore lucrative job opportunities in roles such as Data Scientist, Business Manager, Business Analyst, and more, across diverse industries, leveraging their enhanced skills in Data Science and Business Analytics.",
    suchitquotes:
      "Every individual from various backgrounds must learn Analytics to outshine in the competitive world today!",
    suchitinfo: "-Suchit Majumdar, Chief Data Science Mentor, Accredian.    ",
    Programsyllabustrmtext: "11 Terms",
    Programsyllabustremds: "(11 Terms)",
    Programsyllabusclasstext: "160+ hours of",
    Programsyllabuscareetext: "8 career",
    growt: "80%",
    scholarshipseats: "2",
    nonscholarshipseats: "5",
    setsction: "block",
    accd: [
      {
        hadding: "Term 1 : Data Analysis with Python",
        week: "4 weeks",
        accodingid: "trem1",
        tremone: [
          "Data Science Fundamentals   ",
          " Data Operation with Numpy ",
          "Data Manipulation with Pandas ",
        ],
      },
      {
        hadding: "Term 2 : Data Visualization Techniques",
        week: "4 weeks",
        accodingid: "trem2",
        tremone: [
          "Introduction to Data Visualization",
          "Data Visualization using Matplotlib",
          "Hands-on Pandas for Rapid Visualization ",
          "Seaborn for Data Visualization",
        ],
      },
      {
        hadding: "Term 3 : EDA & Data Storytelling",
        week: "4 weeks",
        accodingid: "trem3",
        tremone: [
          "Introduction to Exploratory Data Analysis",
          "EDA Framework Deep Dive",
          "Scientific Exploration of Industry Data (Case Study) ",
          "Student Presentations & Insight Delivery ",
        ],
      },
      {
        hadding: "Minor Capstone Project",
        week: "",
        accodingid: "trem4",
        tremone: [
          "In a simulated environment get to work with a major Telecom company that seeks help from your team of Data Scientists to resolve a major marketing hurdle in front of them. Get a near real world exposure of working on industry problems within data science teams to reach a common end goal of helping customers win",
        ],
      },
      {
        hadding: "Term 4 : Machine Learning Foundation",
        week: "4 weeks",
        accodingid: "trem5",
        tremone: [
          "Introduction to Machine Learning",
          "Linear Regression",
          "Logistic Regression",
          "Model Evaluation Techniques",
        ],
      },
      {
        hadding: "Term 5 : Machine Learning Intermediate",
        week: "4 weeks",
        accodingid: "trem6",
        tremone: [
          "Decision Trees",
          "Random Forests ",
          "Dimensionality Reduction using Principal Component Analysis (PCA) ",
          "Naive Bayes Classifier ",
        ],
      },
      {
        hadding: "Term 6: Machine Learning Advanced",
        week: "4 weeks",
        accodingid: "trem7",
        tremone: [
          "KNN (K- Nearest neighbors)",
          "K-means Clustering",
          "Ensemble Learning ",
          "Optimization ",
        ],
      },
      {
        hadding: "Major Capstone Project",
        week: "",
        accodingid: "trem8",
        tremone: [
          "Experience a real world simulation of a company Employee Retention Program that needs to be solved using Data Science and Machine Learning.Work with a team of Data Scientists and experience real-world problem solving through Data Science",
        ],
      },
      {
        hadding: "Term 7: Foundation of Business Analytics",
        week: "4 weeks",
        accodingid: "trem9",
        tremone: [
          "Introduction to Business Analytics",
          "Data Analytics Fundamentals for Business Analysts",
          "Statistics Basics for Business",
          "Advanced Business Statistics",
        ],
      },
      {
        hadding: "Term 8: Excel Mastery for Business Insights",
        week: "4 weeks",
        accodingid: "trem10",
        tremone: [
          "Excel fundamentals for Business Analysis",
          "Excel Functions & Conditions",
          "Excel Pivot Tables & Reporting",
          "Excel Modelling",
        ],
      },
      {
        hadding: "Term 9: SQL Essentials for Business Intelligence",
        week: "4 weeks",
        accodingid: "trem11",
        tremone: [
          "Database Basics for Analysts",
          "SQL Fundamentals for Business Analysis",
          "SQL Operations",
          "Advanced SQL",
        ],
      },
      {
        hadding: "Term 10: Business Analytics with Power BI",
        week: "4 weeks",
        accodingid: "trem12",
        tremone: [
          "Power BI for Business reporting",
          "Connecting Data Sources",
          "Data Modelling in Power BI",
          "Functions in Power BI",
        ],
      },
      {
        hadding: "Term 11: Applied Industry Analytics: Case Studies",
        week: "4 weeks",
        accodingid: "trem13",
        tremone: [
          "Domain Expertise as a Business Analytics Expert",
          "Marketing and Retail Analytics",
          "Web and Social Media Analytics",
          "Supply Chain and Logistics Analysis",
        ],
      },
    ],
    programtoolhadding: "Tools you will learn",
    progaming: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/3.webp",
      // "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/6.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/7.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/8.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/9.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/10.webp",
    ],
    toolandassinment: "Topic wise Assignments",
    toolandpackge: "30+ Tools and Packages",
    faculty: [
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Deepesh.webp",
        name: "Deepesh",
        college: "IIM-Calcutta",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Nishkam.webp",
        name: "Nishkam",
        college: "Paypal",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/amit-goyal.webp",
        name: "Amit",
        college: "Paytm",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Gaurav-Trivedi-1.webp",
        name: "Gaurav",
        college: "IIT Guwahati",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Lavi.webp",
        name: "Lavi",
        college: "Google",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/subhodeep.webp",
        name: "Subhodeep",
        college: "Jio",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Neelmani.webp",
        name: "Neelmani",
        college: "Gojek",
      },
    ],
    cc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/IIT-certificate/dsba.webp",
    mc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/PM-certificate/merit/cpm.webp",
    certifictetstate: (
      <Typography sx={{ fontSize: "13.5px", textAlign: "center" }}>
        Professionals upskilled in Data Science & Business Analytics skillset
      </Typography>
    ),
    compnyimg: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw6.webp",
    ],
    programadvantage: [
      {
        pdid: "btpa1",
        hadding: " Learning Resources & Technical Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/1.webp",
        details: [
          {
            texthdding:
              "Dedicated resource center with 1000+ resources to ease your learning journey. Daily technical support to resolve your queries.",
            desc: "desc",
          },
        ],
        classname: "btpaimageone",
      },
      {
        pdid: "btpa2",
        hadding: " Industry Immersion with Top Data Science Leaders",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/2.webp",
        details: [
          {
            haddingtext: "Monthly Speaker Connect",
            texthdding:
              "At Accredian, we feature monthly lectures led by leading professionals worldwide. This offers you practical insights and readies you for the intense competition in the field.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
          {
            haddingtext: "International Summits",
            texthdding:
              "Every six months, we usually organize Data Science Summits inviting leaders from one entire geography. Most recent one being SEA - South East Asia Conference.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa3",
        hadding: "Placement Assistance",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/3.webp",
        details: [
          {
            texthdding:
              "1.Carefully crafted program, such as the ‘Career Launchpad,’ is tailored to assist candidates in refining their resumes & profiles.",
            desc: "descthre",
          },
          {
            texthdding: "2.Participate in Mock interviews and become job ready",
            desc: "desctwo",
          },
          {
            texthdding:
              "3.Utilise a dedicated job opening portal to find your perfect role.",
            desc: "desctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa4",
        hadding: "Lifelong Community Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/4.webp",
        details: [
          {
            texthdding:
              "Lifetime access to the resources, content and community support to clear your doubts.",
            desc: "lastdesc",
          },
        ],
        classname: "btpaimageone",
      },
    ],
    steponehadding: "Fill the application form",
    steponedes: "Apply by filling a simple online application form",
    steptwohadding: "Eligibility Test",
    steptwodes:
      "Attempt the Eligibility Test and get shortlisted for the admission procedure.",
    steptreehadding: "Start Learning",
    steptreedes:
      "Get your offer letter and access to your dashboard and begin your journey with starter kits.",
    programtotalfee: "2,00,000",
    mothemi12: "11,300",
    mothemi18: "7,835",
    mothemi24: "6,088",
    emi: "EMIs Starting 5k/month",
    studentreviews: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/6.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/7.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/8.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/9.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/10.webp",
    ],
    faq: [
      {
        manhadding: "Program Pre-Requisites",
        faqid: "faq1",
        faqtwo: [
          {
            childfaqid: "cfaq1",
            secondhadding:
              "1.Do I need to have prior Data Science and Business Analytics experience to enroll in the program?",
            faqdetails: [
              "No, the program is designed to be inclusive of all levels of experience. All topics will be covered from the basics, making it suitable for individuals from any field of work.",
            ],
          },
          {
            childfaqid: "cfaq2",
            secondhadding:
              "2.What is the minimum system configuration required?",
            faqdetails: [
              "Any latest processor (i3/i5/i7) with 2.0GHz clock speed and RAM 4GB (required), 8GB(recommended).",
            ],
          },
        ],
      },
      {
        manhadding: "Syllabus & Projects Covered",
        faqid: "faq2",
        faqtwo: [
          {
            childfaqid: "cfaq3",
            secondhadding: "1.How many projects can I anticipate working on?",
            faqdetails: [
              "In Executive Program in Data Science and Business Analytics you will have access to more than 35 case studies, Capstone Projects to provide a better understanding of the real-world applications of data science and Analytics.",
            ],
          },
          {
            childfaqid: "cfaq4",
            secondhadding:
              "2.What's the difference between case studies & projects?",
            faqdetails: [
              "Case studies are targeted towards concept validation and limited to only a single concept. However, projects are targeted to overall learning and are more of a combination of multiple topics.",
            ],
          },
          {
            childfaqid: "cfaq5",
            secondhadding:
              "3.What all topics are covered in the Executive Program in Data Science and Business Analytics ",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Download our latest program{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  brochure
                </Typography>{" "}
                &{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  curriculum.
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
      {
        manhadding: "Time Commitment",
        faqid: "faq3",
        faqtwo: [
          {
            childfaqid: "cfaq6",
            secondhadding:
              "1.How much time does one need to spend on a regular basis?",
            faqdetails: [
              "Daily 30 mins of prep time is required. You can choose to practice, read or watch videos. Additionally, on weekends one must attend 2 hours of live classes on both Saturday & Sunday.",
            ],
          },
          {
            childfaqid: "cfaq7",
            secondhadding: "2.What is the duration of the program?",
            faqdetails: [
              "Every term is for 4-5 weeks approximately. And the overall Executive Program in Data Science and Business Analytics duration is 11 months.",
            ],
          },
        ],
      },

      {
        manhadding: "Why IIT Guwahati ?",
        faqid: "faq5",
        faqtwo: [
          {
            childfaqid: "cfaq10",
            secondhadding:
              "1.How's the Executive Program in Data Science and Business Analytics program different?",
            faqdetails: [
              <>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  This program is distinguished by three key elements:
                </Typography>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  1. <b>Iterative Mastery Approach:</b> IITG's exclusive
                  Iterative Mastery Approach ensures development through a
                  step-by-step methodology, specifically designed to welcome
                  individuals from diverse backgrounds. Whether you're seeking a
                  lateral move in the industry or looking to broaden your
                  skills, our program is tailored to empower you on your unique
                  learning journey.
                </Typography>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  <Typography sx={{ marginBottom: "0.5rem !important" }}>
                    2. <b>Versatile Skill Development Model:</b> IIT G's unique
                    framework ensures that every Data Science and BA concept is
                    taught in the context of real-world industry problems. Our
                    program goes beyond theory, emphasizing practical case
                    studies to prepare students for the challenges they'll face
                    in the professional arena.
                  </Typography>
                </Typography>
                <Typography sx={{ marginBottom: "4px !important" }}>
                  3. <b>Accredian Career Center:</b> Aligned with our mission to
                  cultivate the Data leaders of tomorrow, the Accredian Career
                  Center empowers you to confidently launch your Data Science
                  career.
                </Typography>
              </>,
            ],
          },
          {
            childfaqid: "cfaq11",
            secondhadding: "2.Tell me more about Career Assistance?",
            faqdetails: [
              "Accredian provides career assistance through the Career Launchpad program. Students gain hands-on experience as leaders in their chosen field with rigorous resume and portfolio building sessions. The program also covers creating a strong online presence and developing top-notch interview skills.",
            ],
          },
          {
            childfaqid: "cfaq12",
            secondhadding: "3. What's the refund policy?",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Go through our Student Policy{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  <Link
                    to="/policies/iitg-policy"
                    style={{ textDecoration: "none" }}
                  >
                    link
                  </Link>
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
    ],

    brochurectaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/epdadb.webp",
    brochurectahadding: "Download EPDSBA®️ Program Brochure!",
    curriculumctahadding: "Download EPDSBA®️ Program Curriculum !",
    curriculumctaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/epdadb.webp",
    curriculumctatext: "Product Management",
    displayone: "block",
    displaytwo: "none",
    freemodulesdesplay: "none",
    jobandaddon: "none",
    careerassistance: "none",
    ml: "-53px",
    ctasource: "Business Analytics Brochure",
    programname: "Executive Program in Data Science and Business Analytics",
    ctapdf:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IIT-Executive-Program-in-DS-BA-11-months.pdf",
  },
  // {
  //   id: "executive-program-in-ml-and-ai",
  //   category:"2",
  //   university:"iitg",
  //   backgroundimage:
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/program-page-banner-image/iit2.webp",
  //   mobbackground:
  //     "linear-gradient(0deg, rgba(25,122,237,1) 0%, rgba(34,39,125,1) 0%, rgba(31,33,64,1) 99%)",

  //   hadding: "Executive Program in Machine Learning & Artificial Intelligence with E&ICT Academy, IIT Guwahati",
  //   title:"IITG - Executive Program in ML & AI",
  //   programduration: "9 Months | Live Instructor led | Weekend Online",
  //   programdescription:"Master Machine Learning & AI in 9 Months with India's #1 Machine Learning+AI",
  //   callnumber: "70421-48123",
  //   stacksone: "E&ICT Academy",
  //   stacksonedescription: "IIT Guwahati",
  //   stackstwo: "Live Classes",
  //   stackstwodescription: "9 Months",
  //   stacksthree: "Next Cohort",
  //   stacksthreedescription: "6th January 2024",
  //   stackslast: "Career Services ",
  //   stackslastdescription: "by Accredian",
  //   progarminclud1: "9 Terms - Machine Learning, Deep Learning, NLP & CV",
  //   progarminclud2: "Duration: 9 Months | Weekend Online",
  //   progarminclud3: "Live Instructor led classes",
  //   progarminclud4: "Daily doubt clearing",
  //   progarminclud5: "Term projects & Capstone projects",
  //   progarminclud6: "No Cost EMI upto 12 months",
  //   progarminclud7: "Placement Support & Assistance",
  //   progarminclud8: "2-day career immersion at IIT Guwahati",
  //   progarminclud9: "1:1 career mentorship sessions",
  //   progarminclud10: "Learn from the top industry professionals and expert sessions by IIT Guwahati Faculty",
  //   progarminclud11: "Certification from E&ICT, IIT Guwahati",
  //   whoshouldjoin:
  //     "Engineers, Project Managers, Consultants & Non-technical backgrounds (Teachers, Sales & Marketing) & anyone interested in upskilling in Machine Learning & AI.",
  //   Jobopportunities:
  //     "Machine Learning Engineer, Junior Data Scientist, Senior Data Scientist, Data Science Manager/Architect, Principal Data Scientist, AI Engineer, NLP & CV Engineer.",
  //   suchitquotes:
  //     "Machine Learning and AI programs are the architects of tomorrow, building a world where machines comprehend, learn, and innovate alongside us.",
  //   suchitinfo: "-Suchit Majumdar, Chief Data Science Mentor, Accredian.",
  //   Programsyllabustrmtext: "9 Terms",
  //   Programsyllabustremds: "(9 months)",
  //   Programsyllabusclasstext: "140+ hours of",
  //   Programsyllabuscareetext: "8+ career",
  //   growt:"80%",
  //   scholarshipseats:"2",
  //   nonscholarshipseats:"5",
  //   setsction:"block",
  //   accd: [
  //     {
  //       hadding: "Term 1 : Machine Learning Foundation",
  //       week: "4 weeks",
  //       accodingid: "trem1",
  //       tremone: [
  //         "Introduction to Machine Learning ",
  //         "Linear Regression ",
  //         "Logistic Regression",
  //         "Model Evaluation Techniques",
  //       ],
  //     },
  //     {
  //       hadding: "Term 2 : Machine Learning Intermediate",
  //       week: "4 weeks",
  //       accodingid: "trem2",
  //       tremone: [
  //         "Decision Trees",
  //         "Random Forests",
  //         "Dimensionality Reduction using Principal Component Analysis (PCA) ",
  //         "Naive Bayes Classifier",

  //       ],
  //     },
  //     {
  //       hadding: "Term 3 : Machine Learning Advanced",
  //       week: "4 weeks",
  //       accodingid: "trem3",
  //       tremone: [
  //         "KNN (K- Nearest neighbors)",
  //         "K-means Clustering",
  //         "Ensemble Learning",
  //         "Optimization",
  //       ],
  //     },
  //     {
  //       hadding: "Major Capstone Project",
  //       week: "",
  //       accodingid: "trem12",
  //       tremone: [
  //         "Experience a real world simulation of a company Employee Retention Program that needs to be solved using Data Science and Machine Learning.Work with a team of Data Scientists and experience real-world problem solving through Data Science",

  //       ],
  //     },
  //     {
  //       hadding: "Term 4 : Basics of AI, Tensorflow & Keras",
  //       week: "4 weeks",
  //       accodingid: "trem5",
  //       tremone: [
  //         "Introduction to Artificial Intelligence",
  //         "Introduction to Deep Learning Module",
  //         "Components Affecting Deep Learning Models Module",
  //         "Deep Learning Model Practical with Tensorflow & Keras",
  //       ],
  //     },

  //     {
  //       hadding: "Term 5 : Computer Vision",
  //       week: "4 weeks",
  //       accodingid: "trem7",
  //       tremone: [
  //         "Intro to Convolution Neural Networks",
  //         "Decoding Image Components",
  //         "Identifying MNIST using CNN",
  //         "Preprocessing Image Data to apply CNN",
  //       ],
  //     },
  //     {
  //       hadding: "Term 6 : Natural Language Processing ",
  //       week: "4 weeks",
  //       accodingid: "trem8",
  //       tremone: [
  //         "Introduction to NLP & Word Vectors",
  //         "Decoding a Textual Data",
  //         "NLP using Recurrent Neutral Networks (RNN)",
  //         "NLP using Memory Alterations",
  //       ],
  //     },
  //     {
  //       hadding: "AI Capstone Project",
  //       week: "",
  //       accodingid: "trem9",
  //       tremone: [
  //         "In this ever evolving world, solving complex problems get easier using AI.In this capstone project you will be an AI expert who is helping a hypothetical company resolve some major challenges it faces by using AI. Get a chance to work with a team of AI specialists and a simulated environment to help you relate to real world challenges companies face regularly.",

  //       ],
  //     },
  //     {
  //       hadding: "Term 7 : Specialization Computer Vision",
  //       week: "4 weeks",
  //       accodingid: "trem10",
  //       tremone: [
  //         "Understanding Transfer Learning",
  //         "Object Detection using CNN Based Algorithms ",
  //         "Instance Segmentation in Images",
  //         "Introduction to Generative AI",
  //       ],
  //     },
  //     {
  //       hadding: "Term 8 : Specialization Natural Language Processing",
  //       week: "4 weeks",
  //       accodingid: "trem11",
  //       tremone: [
  //         "Teach Machines to Generate New Textual Data (language Modeling)",
  //         "Language Translation - English to Spanish Using Seq2seq Models",
  //         "Techniques to Enhance Seq2seq Models (attention Mechanism) ",
  //         "Advanced NLP Using Bert",
  //         "Understanding LLMs & their Usecases",
  //       ],
  //     },
  //     {
  //       hadding: "Term 9 : Building AI Solution",
  //       week: "4 weeks",
  //       accodingid: "trem6",
  //       tremone: [
  //         "Into to Cloud Computing",
  //         "AI on Google AI Platform",
  //         "Building AI Pipeline",
  //         "Building AI Solution",
  //       ],
  //     },

  //   ],
  //   programtoolhadding: "Programming Languages & Tools",
  //   progaming: [
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/13.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/14.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/5.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/12.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/8.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/15.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/16.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/gcddsai/Keras.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/gcddsai/Tensorflow.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/gcddsai/OpenCV.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/gcddsai/Gensim.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/gcddsai/learn.webp",
  //   ],
  //   toolandassinment: "45+ Topic wise Assignments",
  //   toolandpackge: "15+ Tools and Packages",
  //   faculty: [
  //     {
  //       facultyimag:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Deepesh.webp",
  //       name: "Deepesh",
  //       college: "IIM-Calcutta",
  //     },
  //     {
  //       facultyimag:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Nishkam.webp",
  //       name: "Nishkam",
  //       college: "Paypal",
  //     },
  //     {
  //       facultyimag:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/amit-goyal.webp",
  //       name: "Amit",
  //       college: "Paytm",
  //     },
  //     {
  //       facultyimag: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Gaurav-Trivedi-1.webp",
  //       name: "Gaurav",
  //       college: "IIT Guwahati",
  //     },
  //     {
  //       facultyimag: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Lavi.webp",
  //       name: "Lavi",
  //       college: "Google",
  //     },
  //     {
  //       facultyimag:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/subhodeep.webp",
  //       name: "Subhodeep",
  //       college: "Jio",
  //     },
  //     {
  //       facultyimag: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Neelmani.webp",
  //       name: "Neelmani",
  //       college: "Gojek",
  //     },
  //   ],
  //   projectsectionhadding: "Work on world class Projects",
  //   project: [
  //     {
  //       projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p1.webp",
  //       projecthadding: "Concrete Compressive Strength Prediction",
  //       sortdescription: "Predicting the compressive strength of the concrete, based on the materials used.",
  //       longdescription:
  //         "The dataset contains materials used in making the concrete. The train set contains 824 rows and 10 columns while the test set contains 206 rows and 9 columns.",
  //       id: "1",
  //     },
  //     {
  //       projectimg:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p2.webp",
  //       projecthadding: "Solar Radiation Prediction",
  //       sortdescription:
  //         "Predicting the future solar radiations, based on the historical meteorological data.",
  //       longdescription:
  //         "The dataset contains 4 Months of the measurements. The data comes directly from NASA’s weather research lab based on actual reading taken by their sensors situated at different locations. The train set contains 26148 rows and 12 columns while the test set contains 6538 rows and 11 columns.",
  //       id: "2",
  //     },
  //     {
  //       projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p3.webp",
  //       projecthadding: "Legendary Pokemon Prediction",
  //       sortdescription: "Predicting the legendary pokemons and also identifying what makes a pokemon legendary.",
  //       longdescription:
  //         "The dataset consists of information of different pokemons and their abilities. The train set contains 640 rows and 41 columns while the test set contains 161 rows and 40 columns.",
  //       id: "3",
  //     },
  //     {
  //       projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p4.webp",
  //       projecthadding: "Human Activity Prediction",
  //       sortdescription:
  //         "Predicting the human activity and draw other insights by analyzing the smartphone sensor data.",
  //       longdescription:
  //         "The dataset contains a collection of news documents. The train set contains 1780 rows and 4 columns while the test set contains 445 rows and 3 columns.",
  //       id: "4",
  //     },
  //     {
  //       projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p5.webp",
  //       projecthadding: "Seattle Weather Prediction",
  //       sortdescription:
  //         "Predict whether it will rain on a specific day in Seattle or not.",
  //       longdescription:
  //         "The dataset contains a collection of news documents. The train set contains 1780 rows and 4 columns while the test set contains 445 rows and 3 columns.",
  //       id: "5",
  //     },
  //     {
  //       projectimg:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p6.webp",
  //       projecthadding: "Hubway data visualization challenge",
  //       sortdescription:
  //         "Produce visualizations that reveal interesting user patterns about how people in Boston gets around on Hubway.",
  //       longdescription:
  //         "The dataset contains 1 million observations on bike usage by residents of Boston.",
  //       id: "6",
  //     },
  //     {
  //       projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p7.webp",
  //       projecthadding: "Kickstarter funding patterns",
  //       sortdescription: "Derive insights on successful and faiLed projects on Kickstarter platform.",
  //       longdescription:
  //         "The dataset contains 15 variables and around 400,000 observations.",
  //       id: "7",
  //     },
  //     {
  //       projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p4.webp",
  //       projecthadding: "How bad is the Air Quality in metropolitans?",
  //       sortdescription: "Analyse worsening airquality in metropolitan cities.",
  //       longdescription:
  //         "The dataset is small and contains 6 variables and over 100 observations.",
  //       id: "8",
  //     },
  //   ],
  //   cc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/IIT-certificate/epaiml.webp",
  //   // mc: "https://accredian.com/wp-content/uploads/2022/08/gcdm.webp",
  //   certifictetstate: "Machine Learning & AI ",

  //   compnyimg: [
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/company-images/1.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/company-images/2.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/company-images/3.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/company-images/4.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/company-images/5.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/company-images/6.webp",
  //   ],
  //   programadvantage: [
  //     {
  //       pdid: "btpa1",
  //       hadding: " Learning Resources & Technical Support",
  //       cardimges:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/1.webp",
  //       details: [
  //         {
  //           texthdding:
  //             "Dedicated resource center with 1000+ resources to ease your learning journey. Daily technical support to resolve your queries.",
  //           desc: "desc",
  //         },
  //       ],
  //       classname: "btpaimageone",
  //     },
  //     {
  //       pdid: "btpa2",
  //       hadding: " Industry Immersion with Top Data Science Leaders",
  //       cardimges:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/2.webp",
  //       details: [
  //         {
  //           haddingtext: "Monthly Speaker Connect",
  //           texthdding:
  //             "At Accredian, we feature monthly lectures led by leading professionals worldwide. This offers you practical insights and readies you for the intense competition in the field.",
  //           desc: "desctwo",
  //           hdesc: "hdesctwo",
  //         },
  //         {
  //           haddingtext: "International Summits",
  //           texthdding:
  //             "Every six months, we usually organize Data Science Summits inviting leaders from one entire geography. Most recent one being SEA - South East Asia Conference.",
  //           desc: "desctwo",
  //           hdesc: "hdesctwo",
  //         },
  //       ],
  //       classname: "btpaimagetwo",
  //     },
  //     {
  //       pdid: "btpa3",
  //       hadding: "Placement Assistance",
  //       cardimges:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/3.webp",
  //       details: [
  //         {
  //           texthdding:
  //             "1.Carefully crafted program, such as the ‘Career Launchpad,’ is tailored to assist candidates in refining their resumes & profiles.",
  //           desc: "descthre",
  //         },
  //         {
  //           texthdding:
  //             "2.Participate in Mock interviews and become job ready",
  //           desc: "desctwo",
  //         },
  //         {
  //           texthdding:
  //             "3.Utilise a dedicated job opening portal to find your perfect role.",
  //           desc: "desctwo",
  //         },
  //       ],
  //       classname: "btpaimagetwo",
  //     },
  //     {
  //       pdid: "btpa4",
  //       hadding: "Lifelong Community Support",
  //       cardimges:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/4.webp",
  //       details: [
  //         {
  //           texthdding:
  //             "Lifetime access to the resources, content and community support to clear your doubts.",
  //           desc: "lastdesc",
  //         },
  //       ],
  //       classname: "btpaimageone",
  //     },
  //   ],
  //   steponehadding: "Fill the application form",
  //   steponedes: "Apply by filling a simple online application form.",
  //   steptwohadding: "Eligibility Test",
  //   steptwodes: "Attempt the Eligibility Test and get shortlisted for the admission procedure.",
  //   steptreehadding: "Start Learning",
  //   steptreedes:
  //     "Get access to your dashboard and begin your journey with starter kits.",
  //   programtotalfee: "INR 2,00,000+GST",
  //   programscholarshipfee: "INR 11,800",
  //   emi: "EMIs Starting 5k/month",
  //   studentreviews: [
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/1.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/2.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/3.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/4.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/5.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/6.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/7.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/8.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/9.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/10.webp",
  //   ],
  //   faq: [
  //     {
  //       manhadding: "Program Pre-Requisites",
  //       faqid: "faq1",
  //       faqtwo: [
  //         {
  //           childfaqid: "cfaq1",
  //           secondhadding:
  //             "1.Do I need to know Programming & Math before enrolling to a program?",
  //           faqdetails: [
  //             "Background in Programming & Maths is helpful but not mandatory.",
  //           ],
  //         },
  //         {
  //           childfaqid: "cfaq2",
  //           secondhadding:
  //             "2.What is the minimum system configuration required?",
  //           faqdetails: [
  //             "Any latest processor (i3/i5/i7) with 2.0GHz clock speed and RAM 4GB (required), 8GB(recommended).",
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       manhadding: "Syllabus & Projects Covered",
  //       faqid: "faq2",
  //       faqtwo: [
  //         {
  //           childfaqid: "cfaq3",
  //           secondhadding: "1.How many projects can I anticipate working on?",
  //           faqdetails: [
  //             "In theExecutive Program in Machine Learning & Artificial Intelligence, you're expected to work on 9 Term Projects, 1 Capstone Project & 1 AI Capstone Project mandatorily. Beyond these, we have a repository of 100+ projects from various domains on which you can work.",
  //           ],
  //         },
  //         {
  //           childfaqid: "cfaq4",
  //           secondhadding:
  //             "2.What's the difference between Term Project & Capstone Project?",
  //           faqdetails: [
  //             "Term Projects are individual assignments specific to each term and will be shared upon the completion of every term. Capstone Projects closely resemble real-world projects, requiring the application of various concepts learned throughout the program.",
  //           ],
  //         },
  //         {
  //           childfaqid: "cfaq5",
  //           secondhadding:
  //             "3.What all topics are covered in the Executive Program in Machine Learning & Artificial Intelligence",
  //           faqdetails: [
  //             <Typography
  //               sx={{ display: "flex", marginBottom: "0px !important" }}
  //             >
  //               Download our latest program{" "}
  //               <Typography
  //                 sx={{
  //                   color: "#eb8c28",
  //                   ml: 1,
  //                   mr: 1,
  //                   marginBottom: "0px !important",
  //                 }}
  //               >
  //                 brochure
  //               </Typography>{" "}
  //               &{" "}
  //               <Typography
  //                 sx={{
  //                   color: "#eb8c28",
  //                   ml: 1,
  //                   marginBottom: "0px !important",
  //                 }}
  //               >
  //                 curriculum.
  //               </Typography>
  //             </Typography>,
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       manhadding: "Time Commitment",
  //       faqid: "faq3",
  //       faqtwo: [
  //         {
  //           childfaqid: "cfaq6",
  //           secondhadding: "1.How much time does one need to spend on a regular basis?",
  //           faqdetails: [
  //             "Daily 30 mins of prep time is required. You can choose to practice, read or watch videos. Additionally, on weekends one must attend 2 hours of live classes on both Saturday & Sunday.",
  //           ],
  //         },
  //         {
  //           childfaqid: "cfaq7",
  //           secondhadding: "2.What is the duration of the program?",
  //           faqdetails: [
  //             "Every term is for 4-5 weeks approximately. And the overall Executive Program in Machine Learning & Artificial Intelligence duration is 9 months.",
  //           ],
  //         },
  //       ],
  //     },

  //     {
  //       manhadding: "Why IIT Guwahati ?",
  //       faqid: "faq5",
  //       faqtwo: [
  //         {
  //           childfaqid: "cfaq10",
  //           secondhadding: "1.How's the Executive Program in Machine Learning & Artificial Intelligence different?",
  //           faqdetails: [
  //             "Three things make this program world class 1. Progressive Learning Architecture: Accredian's proprietary Progressive Learning Architecture ensures that irrespective of the background you are from, you will attain mastery in a step-by-step way 2. Concept-to-Context®️ Learning Framework: Accredian's Concept-to-Context®️ framework ensures that you are learning every Data Science concept in context of real world industry problems 3. Accredian Career Center: Accredian's mission is to groom Data leaders of tomorrow. Accredian Career Center helps you launch a Data Science career with 100% confidence.",
  //           ],
  //         },
  //         {
  //           childfaqid: "cfaq11",
  //           secondhadding: "2.Tell me more about Career Assistance?",
  //           faqdetails: [
  //             "Accredian provides career assistance through the Career Launchpad program. Students gain hands-on experience as leaders in their chosen field with rigorous resume and portfolio building sessions. The program also covers creating a strong online presence and developing top-notch interview skills.",
  //           ],
  //         },
  //         {
  //           childfaqid: "cfaq12",
  //           secondhadding: "3. What's the refund policy?",
  //           faqdetails: [
  //             <Typography
  //               sx={{ display: "flex", marginBottom: "0px !important" }}
  //             >
  //               Go through our Student Policy{" "}
  //               <Typography
  //                 sx={{
  //                   color: "#eb8c28",
  //                   ml: 1,
  //                   mr: 1,
  //                   marginBottom: "0px !important",
  //                 }}
  //               >
  //                 <Link to="/policies/iitg-policy" style={{ textDecoration: "none" }}>link</Link>
  //               </Typography>
  //             </Typography>,
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  //   // otherprogram: [
  //   //   {
  //   //     otherprogramimg:
  //   //       "https://accredian.com/wp-content/uploads/2022/08/gcdp.webp",
  //   //     programname: "Global Certificate in Data Science & AI",
  //   //     month: "10 months",
  //   //     pagelink: "global-certificate-in-data-science-and-ai/",
  //   //   },
  //   //   {
  //   //     otherprogramimg:
  //   //       "https://accredian.com/wp-content/uploads/2022/08/pgpdsaifp.webp",
  //   //     programname: "PGP in Data Science",
  //   //     month: "12 months",
  //   //     pagelink: "pgp-in-data-science/",
  //   //   },
  //   //   {
  //   //     otherprogramimg:
  //   //       "https://accredian.com/wp-content/uploads/2022/08/gcdaip.webp",
  //   //     programname: "PGP in Data Science & AI",
  //   //     month: "12 months",
  //   //     pagelink:
  //   //       "pgp-in-data-science-and-ai/",
  //   //   },
  //   // ],

  //   brochurectaimg:
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/epmlai.webp",
  //   brochurectahadding: "Download EPMLAI®️ Program Brochure!",
  //   curriculumctahadding: "Download EPMLAI®️ Program Curriculum !",
  //   curriculumctaimg:
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/epmlai.webp",
  //   curriculumctatext: "Data Science",
  //   displayone: "block",
  //   displaytwo: "none",
  //   freemodulesdesplay:"block",
  //   jobandaddon:"none",
  //   careerassistance:"block",
  //   addmodul:"block",
  //   addmodultwo:"block",
  //   ml: "-53px",
  //   ctasource:"Data Science Brochure",
  //   programname:"Executive Program in Machine Learning & Artificial Intelligence",
  //   ctapdf:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IIT-Executive-in-ML-AI.pdf",
  // },
  // {
  //   id: "advanced-program-in-ai",
  //   category:"2",
  //   university:"iitg",
  //   backgroundimage:
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/program-page-banner-image/iit2.webp",
  //   mobbackground:
  //     "linear-gradient(0deg, rgba(25,122,237,1) 0%, rgba(34,39,125,1) 0%, rgba(31,33,64,1) 99%)",

  //   hadding: "Advanced Certification In Artificial intelligence with E&ICT Academy, IIT Guwahati",
  //   title:"IITG - Advanced Certification in AI",
  //   programduration: "6 Months | Live Instructor led | Weekend Online",
  //   programdescription:"Master Artificial Intelligence in 6 Months with India's #1 AI program",
  //   callnumber: "70421-48123",
  //   stacksone: "E&ICT Academy",
  //   stacksonedescription: "IIT Guwahati",
  //   stackstwo: "Live Classes",
  //   stackstwodescription: "6 Months",
  //   stacksthree: "Next Cohort",
  //   stacksthreedescription: "3 December 2023",
  //   stackslast: "Career Services ",
  //   stackslastdescription: "by Accredian",
  //   progarminclud1: "6 Terms - Deep Learning, NLP & CV",
  //   progarminclud2: "Duration: 6 Months | Weekend Online",
  //   progarminclud3: "Live Instructor led classes",
  //   progarminclud4: "Daily doubt clearing",
  //   progarminclud5: "Term projects & Capstone projects",
  //   progarminclud6: "No Cost EMI upto 12 months",
  //   progarminclud7: "Placement Support & Assistance",
  //   progarminclud8: "2-day career immersion at IIT Guwahati",
  //   progarminclud9: "1:1 career mentorship sessions",
  //   progarminclud10: "Learn from the top industry professionals and expert sessions by IIT Guwahati Faculty",
  //   progarminclud11: "Certification from E&ICT, IIT Guwahati",
  //   whoshouldjoin:
  //     "Engineers, Project Managers, Consultants & Non-technical backgrounds (Teachers, Sales & Marketing) & anyone interested in upskilling in Machine Learning & AI.",
  //   Jobopportunities:
  //     "Get ready to crack interviews for roles like ML Engineer, Data scientist, Artificial Intelligence (AI) Engineer, AI Specialist, Computer Vision Engineer, NLP Engineer, AI Leader, AI Product Manager etc.",
  //   suchitquotes:
  //     "AI is the silent revolution of our time, transforming the unknown challenges of tomorrow into the solvable puzzles of today",
  //   suchitinfo: "-Suchit Majumdar, Chief Data Science Mentor, Accredian.",
  //   Programsyllabustrmtext: "6 Terms",
  //   Programsyllabustremds: "(6 months)",
  //   Programsyllabusclasstext: "90+ hours of",
  //   Programsyllabuscareetext: "8+ career",
  //   growt:"80%",
  //   scholarshipseats:"2",
  //   nonscholarshipseats:"5",
  //   setsction:"block",
  //   accd: [
  //     {
  //       hadding: "Term 1 : Basics of AI, Tensorflow & Keras",
  //       week: "4 weeks",
  //       accodingid: "trem5",
  //       tremone: [
  //         "Introduction to Artificial Intelligence",
  //         "Introduction to Deep Learning Module",
  //         "Components Affecting Deep Learning Models Module",
  //         "Deep Learning Model Practical with Tensorflow & Keras",
  //       ],
  //     },

  //     {
  //       hadding: "Term 2 : Computer Vision",
  //       week: "4 weeks",
  //       accodingid: "trem7",
  //       tremone: [
  //         "Intro to Convolution Neural Networks",
  //         "Decoding Image Components",
  //         "Identifying MNIST using CNN",
  //         "Preprocessing Image Data to apply CNN",
  //       ],
  //     },
  //     {
  //       hadding: "Term 3 : Natural Language Processing ",
  //       week: "4 weeks",
  //       accodingid: "trem8",
  //       tremone: [
  //         "Introduction to NLP & Word Vectors",
  //         "Decoding a Textual Data",
  //         "NLP using Recurrent Neutral Networks (RNN)",
  //         "NLP using Memory Alterations",
  //       ],
  //     },
  //     {
  //       hadding: "AI Capstone Project",
  //       week: "",
  //       accodingid: "trem9",
  //       tremone: [
  //         "In this ever evolving world, solving complex problems get easier using AI.In this capstone project you will be an AI expert who is helping a hypothetical company resolve some major challenges it faces by using AI. Get a chance to work with a team of AI specialists and a simulated environment to help you relate to real world challenges companies face regularly.",

  //       ],
  //     },
  //     {
  //       hadding: "Term 4 : Specialization Computer Vision",
  //       week: "4 weeks",
  //       accodingid: "trem10",
  //       tremone: [
  //         "Understanding Transfer Learning",
  //         "Object Detection using CNN Based Algorithms ",
  //         "Instance Segmentation in Images",
  //         "Introduction to Generative AI",
  //       ],
  //     },
  //     {
  //       hadding: "Term 5 : Specialization Natural Language Processing",
  //       week: "4 weeks",
  //       accodingid: "trem11",
  //       tremone: [
  //         "Teach Machines to Generate New Textual Data (language Modeling)",
  //         "Language Translation - English to Spanish Using Seq2seq Models",
  //         "Techniques to Enhance Seq2seq Models (attention Mechanism) ",
  //         "Advanced NLP Using Bert",
  //         "Understanding LLMs & their Usecases",
  //       ],
  //     },
  //     {
  //       hadding: "Term 6 : Building AI Solution",
  //       week: "4 weeks",
  //       accodingid: "trem6",
  //       tremone: [
  //         "Into to Cloud Computing",
  //         "AI on Google AI Platform",
  //         "Building AI Pipeline",
  //         "Building AI Solution",
  //       ],
  //     },

  //   ],
  //   programtoolhadding: "Programming Languages & Tools",
  //   progaming: [
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/13.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/14.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/5.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/12.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/8.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/15.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/16.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/gcddsai/Keras.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/gcddsai/Tensorflow.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/gcddsai/OpenCV.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/gcddsai/Gensim.webp",
  //   ],
  //   toolandassinment: "30+ Topic wise Assignments",
  //   toolandpackge: "10+ Tools and Packages",
  //   faculty: [
  //     {
  //       facultyimag:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Deepesh.webp",
  //       name: "Deepesh",
  //       college: "IIM-Calcutta",
  //     },
  //     {
  //       facultyimag:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Nishkam.webp",
  //       name: "Nishkam",
  //       college: "Paypal",
  //     },
  //     {
  //       facultyimag:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/amit-goyal.webp",
  //       name: "Amit",
  //       college: "Paytm",
  //     },
  //     {
  //       facultyimag: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Gaurav-Trivedi-1.webp",
  //       name: "Gaurav",
  //       college: "IIT Guwahati",
  //     },
  //     {
  //       facultyimag: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Lavi.webp",
  //       name: "Lavi",
  //       college: "Google",
  //     },
  //     {
  //       facultyimag:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/subhodeep.webp",
  //       name: "Subhodeep",
  //       college: "Jio",
  //     },
  //     {
  //       facultyimag: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Neelmani.webp",
  //       name: "Neelmani",
  //       college: "Gojek",
  //     },
  //   ],
  //   projectsectionhadding: "Work on world class Projects",
  //   project: [
  //     {
  //       projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p1.webp",
  //       projecthadding: "Concrete Compressive Strength Prediction",
  //       sortdescription: "Predicting the compressive strength of the concrete, based on the materials used.",
  //       longdescription:
  //         "The dataset contains materials used in making the concrete. The train set contains 824 rows and 10 columns while the test set contains 206 rows and 9 columns.",
  //       id: "1",
  //     },
  //     {
  //       projectimg:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p2.webp",
  //       projecthadding: "Solar Radiation Prediction",
  //       sortdescription:
  //         "Predicting the future solar radiations, based on the historical meteorological data.",
  //       longdescription:
  //         "The dataset contains 4 Months of the measurements. The data comes directly from NASA’s weather research lab based on actual reading taken by their sensors situated at different locations. The train set contains 26148 rows and 12 columns while the test set contains 6538 rows and 11 columns.",
  //       id: "2",
  //     },
  //     {
  //       projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p3.webp",
  //       projecthadding: "Legendary Pokemon Prediction",
  //       sortdescription: "Predicting the legendary pokemons and also identifying what makes a pokemon legendary.",
  //       longdescription:
  //         "The dataset consists of information of different pokemons and their abilities. The train set contains 640 rows and 41 columns while the test set contains 161 rows and 40 columns.",
  //       id: "3",
  //     },
  //     {
  //       projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p4.webp",
  //       projecthadding: "Human Activity Prediction",
  //       sortdescription:
  //         "Predicting the human activity and draw other insights by analyzing the smartphone sensor data.",
  //       longdescription:
  //         "The dataset contains a collection of news documents. The train set contains 1780 rows and 4 columns while the test set contains 445 rows and 3 columns.",
  //       id: "4",
  //     },
  //     {
  //       projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p5.webp",
  //       projecthadding: "Seattle Weather Prediction",
  //       sortdescription:
  //         "Predict whether it will rain on a specific day in Seattle or not.",
  //       longdescription:
  //         "The dataset contains a collection of news documents. The train set contains 1780 rows and 4 columns while the test set contains 445 rows and 3 columns.",
  //       id: "5",
  //     },
  //     {
  //       projectimg:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p6.webp",
  //       projecthadding: "Hubway data visualization challenge",
  //       sortdescription:
  //         "Produce visualizations that reveal interesting user patterns about how people in Boston gets around on Hubway.",
  //       longdescription:
  //         "The dataset contains 1 million observations on bike usage by residents of Boston.",
  //       id: "6",
  //     },
  //     {
  //       projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p7.webp",
  //       projecthadding: "Kickstarter funding patterns",
  //       sortdescription: "Derive insights on successful and faiLed projects on Kickstarter platform.",
  //       longdescription:
  //         "The dataset contains 15 variables and around 400,000 observations.",
  //       id: "7",
  //     },
  //     {
  //       projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcdsai/p4.webp",
  //       projecthadding: "How bad is the Air Quality in metropolitans?",
  //       sortdescription: "Analyse worsening airquality in metropolitan cities.",
  //       longdescription:
  //         "The dataset is small and contains 6 variables and over 100 observations.",
  //       id: "8",
  //     },
  //   ],
  //   cc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/IIT-certificate/epai.webp",
  //   // mc: "https://accredian.com/wp-content/uploads/2022/08/gcdm.webp",
  //   certifictetstate: "Artificial intelligence",

  //   compnyimg: [
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/company-images/1.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/company-images/2.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/company-images/3.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/company-images/4.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/company-images/5.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/company-images/6.webp",
  //   ],
  //   programadvantage: [
  //     {
  //       pdid: "btpa1",
  //       hadding: " Learning Resources & Technical Support",
  //       cardimges:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/1.webp",
  //       details: [
  //         {
  //           texthdding:
  //             "Dedicated resource center with 1000+ resources to ease your learning journey. Daily technical support to resolve your queries.",
  //           desc: "desc",
  //         },
  //       ],
  //       classname: "btpaimageone",
  //     },
  //     {
  //       pdid: "btpa2",
  //       hadding: " Industry Immersion with Top Data Science Leaders",
  //       cardimges:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/2.webp",
  //       details: [
  //         {
  //           haddingtext: "Monthly Speaker Connect",
  //           texthdding:
  //             "At Accredian, we feature monthly lectures led by leading professionals worldwide. This offers you practical insights and readies you for the intense competition in the field.",
  //           desc: "desctwo",
  //           hdesc: "hdesctwo",
  //         },
  //         {
  //           haddingtext: "International Summits",
  //           texthdding:
  //             "Every six months, we usually organize Data Science Summits inviting leaders from one entire geography. Most recent one being SEA - South East Asia Conference.",
  //           desc: "desctwo",
  //           hdesc: "hdesctwo",
  //         },
  //       ],
  //       classname: "btpaimagetwo",
  //     },
  //     {
  //       pdid: "btpa3",
  //       hadding: "Placement Assistance",
  //       cardimges:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/3.webp",
  //       details: [
  //         {
  //           texthdding:
  //             "1.Carefully crafted program, such as the ‘Career Launchpad,’ is tailored to assist candidates in refining their resumes & profiles.",
  //           desc: "descthre",
  //         },
  //         {
  //           texthdding:
  //             "2.Participate in Mock interviews and become job ready",
  //           desc: "desctwo",
  //         },
  //         {
  //           texthdding:
  //             "3.Utilise a dedicated job opening portal to find your perfect role.",
  //           desc: "desctwo",
  //         },
  //       ],
  //       classname: "btpaimagetwo",
  //     },
  //     {
  //       pdid: "btpa4",
  //       hadding: "Lifelong Community Support",
  //       cardimges:
  //         "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/4.webp",
  //       details: [
  //         {
  //           texthdding:
  //             "Lifetime access to the resources, content and community support to clear your doubts.",
  //           desc: "lastdesc",
  //         },
  //       ],
  //       classname: "btpaimageone",
  //     },
  //   ],
  //   steponehadding: "Fill the application form",
  //   steponedes: "Apply by filling a simple online application form.",
  //   steptwohadding: "Eligibility Test",
  //   steptwodes: "Attempt the Eligibility Test and get shortlisted for the admission procedure.",
  //   steptreehadding: "Start Learning",
  //   steptreedes:
  //     "Get access to your dashboard and begin your journey with starter kits.",
  //   programtotalfee: "INR 95,000+GST",
  //   programscholarshipfee: "INR 8,358",
  //   emi: "EMIs Starting 5k/month",
  //   studentreviews: [
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/1.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/2.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/3.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/4.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/5.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/6.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/7.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/8.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/9.webp",
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/10.webp",
  //   ],
  //   faq: [
  //     {
  //       manhadding: "Program Pre-Requisites",
  //       faqid: "faq1",
  //       faqtwo: [
  //         {
  //           childfaqid: "cfaq1",
  //           secondhadding:
  //             "1.Do I need to know Programming & Math before enrolling to a program?",
  //           faqdetails: [
  //             "Background in Programming & Maths is helpful but not mandatory.",
  //           ],
  //         },
  //         {
  //           childfaqid: "cfaq2",
  //           secondhadding:
  //             "2.What is the minimum system configuration required?",
  //           faqdetails: [
  //             "Any latest processor (i3/i5/i7) with 2.0GHz clock speed and RAM 4GB (required), 8GB(recommended).",
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       manhadding: "Syllabus & Projects Covered",
  //       faqid: "faq2",
  //       faqtwo: [
  //         {
  //           childfaqid: "cfaq3",
  //           secondhadding: "1.How many projects can I anticipate working on?",
  //           faqdetails: [
  //             "In the Advanced Certification In Artificial intelligence , you're expected to work on 6 Term Projects, 1 AI Capstone Project mandatorily. Beyond these, we have a repository of 100+ projects from various domains on which you can work.",
  //           ],
  //         },
  //         {
  //           childfaqid: "cfaq4",
  //           secondhadding:
  //             "2.What's the difference between Term Project & Capstone Project?",
  //           faqdetails: [
  //             "Term Projects are individual assignments specific to each term and will be shared upon the completion of every term. Capstone Projects closely resemble real-world projects, requiring the application of various concepts learned throughout the program.",
  //           ],
  //         },
  //         {
  //           childfaqid: "cfaq5",
  //           secondhadding:
  //             "3.What all topics are covered in the Advanced Certification In Artificial intelligence?",
  //           faqdetails: [
  //             <Typography
  //               sx={{ display: "flex", marginBottom: "0px !important" }}
  //             >
  //               Download our latest program{" "}
  //               <Typography
  //                 sx={{
  //                   color: "#eb8c28",
  //                   ml: 1,
  //                   mr: 1,
  //                   marginBottom: "0px !important",
  //                 }}
  //               >
  //                 brochure
  //               </Typography>{" "}
  //               &{" "}
  //               <Typography
  //                 sx={{
  //                   color: "#eb8c28",
  //                   ml: 1,
  //                   marginBottom: "0px !important",
  //                 }}
  //               >
  //                 curriculum.
  //               </Typography>
  //             </Typography>,
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       manhadding: "Time Commitment",
  //       faqid: "faq3",
  //       faqtwo: [
  //         {
  //           childfaqid: "cfaq6",
  //           secondhadding: "1.How much time does one need to spend on a regular basis?",
  //           faqdetails: [
  //             "Daily 30 mins of prep time is required. You can choose to practice, read or watch videos. Additionally, on weekends one must attend 2 hours of live classes on both Saturday & Sunday.",
  //           ],
  //         },
  //         {
  //           childfaqid: "cfaq7",
  //           secondhadding: "2.What is the duration of the program?",
  //           faqdetails: [
  //             "Every term is for 4-5 weeks approximately. And the overall Advanced Certification In Artificial intelligence  duration is 6 months.",
  //           ],
  //         },
  //       ],
  //     },

  //     {
  //       manhadding: "Why IIT Guwahati ?",
  //       faqid: "faq5",
  //       faqtwo: [
  //         {
  //           childfaqid: "cfaq10",
  //           secondhadding: "1.How's the Advanced Certification In Artificial intelligence  different?",
  //           faqdetails: [
  //             "Three things make this program world class 1. Progressive Learning Architecture: Accredian's proprietary Progressive Learning Architecture ensures that irrespective of the background you are from, you will attain mastery in a step-by-step way 2. Concept-to-Context®️ Learning Framework: Accredian's Concept-to-Context®️ framework ensures that you are learning every Data Science concept in context of real world industry problems 3. Accredian Career Center: Accredian's mission is to groom Data leaders of tomorrow. Accredian Career Center helps you launch a Data Science career with 100% confidence.",
  //           ],
  //         },
  //         {
  //           childfaqid: "cfaq11",
  //           secondhadding: "2.Tell me more about Career Assistance?",
  //           faqdetails: [
  //             "Accredian provides career assistance through the Career Launchpad program. Students gain hands-on experience as leaders in their chosen field with rigorous resume and portfolio building sessions. The program also covers creating a strong online presence and developing top-notch interview skills.",
  //           ],
  //         },
  //         {
  //           childfaqid: "cfaq12",
  //           secondhadding: "3. What's the refund policy?",
  //           faqdetails: [
  //             <Typography
  //               sx={{ display: "flex", marginBottom: "0px !important" }}
  //             >
  //               Go through our Student Policy{" "}
  //               <Typography
  //                 sx={{
  //                   color: "#eb8c28",
  //                   ml: 1,
  //                   mr: 1,
  //                   marginBottom: "0px !important",
  //                 }}
  //               >
  //                 <Link to="/policies/iitg-policy" style={{ textDecoration: "none" }}>link</Link>
  //               </Typography>
  //             </Typography>,
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  //   // otherprogram: [
  //   //   {
  //   //     otherprogramimg:
  //   //       "https://accredian.com/wp-content/uploads/2022/08/gcdp.webp",
  //   //     programname: "Global Certificate in Data Science & AI",
  //   //     month: "10 months",
  //   //     pagelink: "global-certificate-in-data-science-and-ai/",
  //   //   },
  //   //   {
  //   //     otherprogramimg:
  //   //       "https://accredian.com/wp-content/uploads/2022/08/pgpdsaifp.webp",
  //   //     programname: "PGP in Data Science",
  //   //     month: "12 months",
  //   //     pagelink: "pgp-in-data-science/",
  //   //   },
  //   //   {
  //   //     otherprogramimg:
  //   //       "https://accredian.com/wp-content/uploads/2022/08/gcdaip.webp",
  //   //     programname: "PGP in Data Science & AI",
  //   //     month: "12 months",
  //   //     pagelink:
  //   //       "pgp-in-data-science-and-ai/",
  //   //   },
  //   // ],

  //   brochurectaimg:
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/epai.webp",
  //   brochurectahadding: "Download ACAI®️ Program Brochure!",
  //   curriculumctahadding: "Download ACAI®️®️ Program Curriculum !",
  //   curriculumctaimg:
  //     "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/epai.webp",
  //   curriculumctatext: "Data Science",
  //   displayone: "block",
  //   displaytwo: "none",
  //   freemodulesdesplay:"none",
  //   jobandaddon:"none",
  //   careerassistance:"block",
  //   addmodul:"none",
  //   addmodultwo:"none",
  //   ml: "-53px",
  //   ctasource:"Data Science Brochure",
  //   programname:"Advanced Certification In Artificial intelligence",
  //   ctapdf:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IIT-Adv-Cert-in-AI-1.pdf",
  // },
  {
    id: "executive-program-in-dspm",
    category: "2",
    university: "iitg",
    vertical: "Data Science",
    brochureHeader:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/iitg.png",
    backgroundimage:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/program-page-banner-image/iita.webp",
    mobbackground:
      "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 99%)",
    programlevel: "Beginner",
    hadding:
      "Executive Program in  Data Science and Product Management with E&ICT Academy, IIT Guwahati ",
    title: "IITG - Executive Program in DS & PM ",
    programduration: "11 Months | Live Instructor led | Weekend Online",
    programdescription:
      "Embark on an 11- term learning journey in Data Science, Machine Learning, and Product Management.",
    callnumber: "90796-53292",
    stacksone: "E&ICT Academy",
    stacksonedescription: "IIT Guwahati",
    stackstwo: "Live Classes",
    stackstwodescription: "11 Months",
    stacksthree: "Next Cohort",
    stacksthreedescription: "August 2024",
    stackslast: "Career Services ",
    stackslastdescription: "by Accredian",
    progarminclud1: "11 Terms - Analytics, ML, Product Management",
    progarminclud2: "Duration: 11 Months | Weekend Online",
    progarminclud3: "Live Instructor led classes",
    progarminclud4: "2-day Campus Immersion at IIT Guwahati *",
    progarminclud5: "Capstone projects",
    progarminclud6: "No Cost EMI upto 12 months",
    progarminclud7: "Placement Support & Assistance",
    progarminclud8: "Product Demo Day",
    progarminclud9: "1:1 career mentorship sessions",
    progarminclud10: "Learn from the top industry professionals",
    progarminclud11: "Certification from E&ICT, IIT Guwahati",
    whoshouldjoin:
      "Tailored for technology, business, and analytics professionals, as well as those transitioning into data science and product management roles, our Executive Program is ideal for skill advancement in these dynamic fields.",
    Jobopportunities:
      "After completing this course, participants can explore lucrative job opportunities in roles such as Data Scientist, Product Manager, Business Analyst, and more, across diverse industries, leveraging their enhanced skills in Data Science and Product Management.",
    suchitquotes:
      "Data Science and Product Management: the dynamic duo reshaping industries. One deciphers the code of data, the other deciphers the code of user needs",
    suchitinfo: "-Suchit Majumdar, Chief Product Officer, Accredian",
    Programsyllabustrmtext: "11 Terms",
    Programsyllabustremds: "(11  Months)",
    Programsyllabusclasstext: "170+ hours of",
    Programsyllabuscareetext: "8 career",
    growt: "80%",
    scholarshipseats: "3",
    nonscholarshipseats: "7",
    setsction: "block",
    accd: [
      {
        hadding: "Term 1 : Data Analysis with Python",
        week: "4 weeks",
        accodingid: "trem1",
        tremone: [
          "Data Science Fundamentals ",
          "Data Operation with Numpy",
          "Data Manipulation with Pandas",
        ],
      },
      {
        hadding: "Term 2 : Data Visualization Techniques",
        week: "4 weeks",
        accodingid: "trem2",
        tremone: [
          "Introduction to Data Visualization",
          "Data Visualization using Matplotlib",
          "Hands-on Pandas for Rapid Visualization",
          "Seaborn for Data Visualization",
        ],
      },
      {
        hadding: "Term 3 :  EDA & Data Storytelling",
        week: "4 weeks",
        accodingid: "trem3",
        tremone: [
          "Introduction to Exploratory Data Analysis",
          "EDA Framework Deep Dive",
          "Scientific Exploration of Industry Data (Case Study)",
          "Student Presentations & Insight Delivery",
        ],
      },
      {
        hadding: "Minor Capstone Project",
        week: "",
        accodingid: "trem4",
        tremone: [
          "In a simulated environment, you get to work with a major Telecom company that seeks help from your team of Data Scientists to resolve a major marketing hurdle. Get a near real world exposure of working on industry problems within data science teams. ",
        ],
      },
      {
        hadding: "Term 4 : Machine Learning Foundation",
        week: "4 weeks",
        accodingid: "trem5",
        tremone: [
          "Introduction to Machine Learning (ML)",
          "Linear Regression",
          "Logistic Regression",
          "Model Evaluation Techniques",
        ],
      },
      {
        hadding: "Term 5 : Machine Learning Intermediate",
        week: "4 weeks",
        accodingid: "trem6",
        tremone: [
          "Decision Trees",
          "Random Forests",
          "Dimensionality Reduction using Principle Component Analysis",
          "Naïve Bayes Classifier",
        ],
      },
      {
        hadding: "Term 6 : Machine Learning Advanced",
        week: "4 weeks",
        accodingid: "trem7",
        tremone: [
          "KNN (K- Nearest neighbors)",
          "K-means Clustering",
          "Ensemble Learning",
          "Optimization",
        ],
      },
      {
        hadding: "Major Capstone Project",
        week: "",
        accodingid: "trem8",
        tremone: [
          "Experience a real world simulation of a company Employee Retention Program that needs to be solved using Data Science and Machine Learning. Work with a team of Data Scientists and experience real-world problem solving through Data Science",
        ],
      },
      {
        hadding: "Term 7 : Introduction to Product Management",
        week: "4 weeks",
        accodingid: "trem9",
        tremone: [
          "Roles & Responsibilities of a Product Manager (PM)",
          "User Research for PMs",
          "Market Research for PMs",
          "Competitive Analysis for PMs",
        ],
      },
      {
        hadding: "Term 8 : Product Strategy & Roadmap",
        week: "4 weeks",
        accodingid: "trem10",
        tremone: [
          "What is Product Planning Cycle?",
          "Establishing Product Vision & Mission",
          "Formulating Product Strategy & Product Goals",
          "What is Product Backlog & Roadmap",
        ],
      },
      {
        hadding: "Term 9 : Design Thinking & UI/UX",
        week: "4 weeks",
        accodingid: "trem11",
        tremone: [
          "Design Thinking for PMs",
          "Building MVP - Artefacts, Persona Mapping & PRDs",
          "UI/UX for PMs",
          "Designing Mockups, Wireframes, & Prototypes",
        ],
      },
      {
        hadding: "Term 10 : Agile Product Engineering",
        week: "4 weeks",
        accodingid: "trem12",
        tremone: [
          "Introduction to Engineering for Product Managers",
          "Understanding Microservices & Cloud Services",
          "Waterfall & Agile Methodology",
          "Understanding Scrum & Kanban",
        ],
      },
      {
        hadding: "Term 11 : Product Marketing & Launch",
        week: "4 weeks",
        accodingid: "trem13",
        tremone: [
          // "Product Development & Planning User Tests",
          "Product Launch & A/B Testing",
          "Data Driven Product Marketing & Consumer Behaviour",
          "Measuring Brand & Impact of Product Marketing",
          "Marketing Ninja Challenge",
        ],
      },
    ],
    programtoolhadding: "Tools you will learn",
    progaming: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t6.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t7.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t8.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t9.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t10.webp",
    ],
    toolandassinment: "Topic wise Assignments",
    toolandpackge: "Product Demo Day",
    faculty: [
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Deepesh.webp",
        name: "Deepesh",
        college: "IIM-Calcutta",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Nishkam.webp",
        name: "Nishkam",
        college: "Paypal",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Gaurav-Trivedi-1.webp",
        name: "Gaurav",
        college: "IIT Guwahati",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/prahlad.webp",
        name: "Prahlad",
        college: "Paypal",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/sai.webp",
        name: "Sai",
        college: "Google",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/Harihara.webp",
        name: "Harihara",
        college: "Amazon",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/shailendra.webp",
        name: "Shailendra",
        college: "Spinny",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/rajib.webp",
        name: "Rajib",
        college: "Walmart",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/Ankur.webp",
        name: "Ankur",
        college: "Archer",
      },
    ],
    cc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/IIT-certificate/epdspm_new.webp",
    mc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/PM-certificate/merit/cpm.webp",
    certifictetstate: (
      <Typography sx={{ fontSize: "13.5px", textAlign: "center" }}>
        Professionals upskilled in Data Science & Product Management skillset
      </Typography>
    ),
    compnyimg: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw6.webp",
    ],
    programadvantage: [
      {
        pdid: "btpa1",
        hadding: " Learning Resources & Technical Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/1.webp",
        details: [
          {
            texthdding:
              "Dedicated resource center with 1000+ resources to ease your learning journey. Daily technical support to resolve your queries.",
            desc: "desc",
          },
        ],
        classname: "btpaimageone",
      },
      {
        pdid: "btpa2",
        hadding: "Industry Immersion with Top Product Leaders",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/2.webp",
        details: [
          {
            haddingtext: "Monthly Speaker Connect",
            texthdding:
              "At Accredian, we bring in leading Product Management professionals from around the globe for monthly lectures. This provides you with practical insights and prepares you for intense competition in the field of Data Science and Product Management.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
          {
            haddingtext: "International Summits",
            texthdding:
              "We regularly arrange Product Week Summits every six months, where we invite leaders from across the world. The latest summit was the India Product Week.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa3",
        hadding: "Placement Assistance",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/3.webp",
        details: [
          {
            texthdding:
              "1.Carefully crafted program such as career launchpad which will help the candidates to polish their resume & LinkedIn profiles.",
            desc: "descthre",
          },
          {
            texthdding: "2.Participate in Mock interviews and become job ready",
            desc: "desctwo",
          },
          {
            texthdding:
              "3.Utilise a dedicated job opening portal to find your perfect role.",
            desc: "desctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa4",
        hadding: "Lifelong Community Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/4.webp",
        details: [
          {
            texthdding:
              "Lifetime access to the resources, content and community support to clear your doubts.",
            desc: "lastdesc",
          },
        ],
        classname: "btpaimageone",
      },
    ],
    steponehadding: "Fill the application form",
    steponedes: "Apply by filling a simple online application form",
    steptwohadding: "Eligibility Test",
    steptwodes:
      "Attempt the Eligibility Test and get shortlisted for the admission procedure.",
    steptreehadding: "Start Learning",
    steptreedes:
      "Get your offer letter and access to your dashboard and begin your journey with starter kits.",
    programtotalfee: "2,20,000",
    mothemi12: "14,250",
    mothemi18: "9,880",
    mothemi24: "7,678",
    emi: "EMIs Starting 5k/month",
    studentreviews: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/6.webp",
    ],
    faq: [
      {
        manhadding: "Program Pre-Requisites",
        faqid: "faq1",
        faqtwo: [
          {
            childfaqid: "cfaq1",
            secondhadding:
              "1.Is prior experience in Data Science or Product Management required for program enrollment?",
            faqdetails: [
              "No, the program is designed to be inclusive of all levels of experience. All topics will be covered from the basics, making it suitable for individuals from any field of work.",
            ],
          },
          {
            childfaqid: "cfaq2",
            secondhadding:
              "2.What is the minimum system configuration required?",
            faqdetails: [
              "Any latest processor (i3/i5/i7) with 2.0GHz clock speed and RAM 4GB (required), 8GB(recommended).",
            ],
          },
        ],
      },
      {
        manhadding: "Syllabus & Projects Covered",
        faqid: "faq2",
        faqtwo: [
          {
            childfaqid: "cfaq3",
            secondhadding: "1.How many projects can I anticipate working on?",
            faqdetails: [
              "In Executive Program in Data Science and Product Management, you will have access to more than 35 case studies, Mission: Design Discovery, Major Project, and Capstone Project to provide a better understanding of the real-world applications of Data Science and Product Management concepts.",
            ],
          },
          {
            childfaqid: "cfaq4",
            secondhadding:
              "2.What's the difference between case studies & projects?",
            faqdetails: [
              "Case studies are targeted towards concept validation and limited to only a single concept. However, projects are targeted to overall learning and are more of a combination of multiple topics.",
            ],
          },
          {
            childfaqid: "cfaq5",
            secondhadding:
              "3.What all topics are covered in the Executive Program in Data Science and Product Management Program",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Download our latest program{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  brochure
                </Typography>{" "}
                &{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  curriculum.
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
      {
        manhadding: "Time Commitment",
        faqid: "faq3",
        faqtwo: [
          {
            childfaqid: "cfaq6",
            secondhadding:
              "1.How much time does one need to spend on a regular basis?",
            faqdetails: [
              "Daily 30 mins of prep time is required. You can choose to practice, read or watch videos. Additionally, on weekends one must attend 2 hours of live classes on both Saturday & Sunday.",
            ],
          },
          {
            childfaqid: "cfaq7",
            secondhadding: "2.What is the duration of the program?",
            faqdetails: [
              "The duration of the program is 11 months, every term is approximately 4-5 weeks long.",
            ],
          },
        ],
      },

      {
        manhadding: "Why IIT Guwahati ?",
        faqid: "faq5",
        faqtwo: [
          {
            childfaqid: "cfaq10",
            secondhadding:
              "1.How is the Executive Program in Data Science & Product Management program different?",
            faqdetails: [
              <>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  This program is distinguished by three key elements:
                </Typography>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  1. <b>Iterative Mastery Approach:</b> IITG's exclusive
                  Iterative Mastery Approach ensures development through a
                  step-by-step methodology, specifically designed to welcome
                  individuals from diverse backgrounds. Whether you're seeking a
                  lateral move in the industry or looking to broaden your
                  skills, our program is tailored to empower you on your unique
                  learning journey.
                </Typography>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  <Typography sx={{ marginBottom: "0.5rem !important" }}>
                    2. <b>Versatile Skill Development Model:</b> IIT G's unique
                    framework ensures that every Data Science and Product
                    Management concept is taught in the context of real-world
                    industry problems. Our program goes beyond theory,
                    emphasizing practical case studies to prepare students for
                    the challenges they'll face in the professional arena.
                  </Typography>
                </Typography>
                <Typography sx={{ marginBottom: "4px !important" }}>
                  3. <b>Accredian Career Center:</b> Aligned with our mission to
                  cultivate the Data leaders of tomorrow, the Accredian Career
                  Center empowers you to confidently launch your Data Science
                  career.
                </Typography>
              </>,
            ],
          },
          {
            childfaqid: "cfaq11",
            secondhadding: "2.Tell me more about Career Assistance?",
            faqdetails: [
              "Accredian provides career assistance through the Career Launchpad program. Students gain hands-on experience as leaders in their chosen field with rigorous resume and portfolio building sessions. The program also covers creating a strong online presence and developing top-notch interview skills.",
            ],
          },
          {
            childfaqid: "cfaq12",
            secondhadding: "3. What's the refund policy?",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Go through our Student Policy{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  <Link
                    to="/policies/iitg-policy"
                    style={{ textDecoration: "none" }}
                  >
                    link
                  </Link>
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
    ],

    brochurectaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/epdspm.webp",
    brochurectahadding: "Download EPDSPM Program Brochure!",
    curriculumctahadding: "Download EPPMDS Program Curriculum !",
    curriculumctaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/epdspm.webp",
    curriculumctatext: "Product Management",
    displayone: "none",
    displaytwo: "block",
    freemodulesdesplay: "none",
    jobandaddon: "none",
    careerassistance: "none",
    ml: "-53px",
    ctasource: "Product Management Brochure",
    programname: "Executive Program in Data Science and Product Management",
    ctapdf:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IIT-Executive-Program-DS-PM-11-months.pdf",
  },

  {
    id: "executive-program-in-pm-ba",
    category: "4",
    university: "iitg",
    vertical: "Product Management",
    brochureHeader:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/iitg.png",
    backgroundimage:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/program-page-banner-image/iita.webp",
    mobbackground:
      "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 99%)",
    programlevel: "Beginner",
    hadding:
      "Executive Program in Product Management and Business Analytics with E&ICT Academy, IIT Guwahati",
    title:
      "IITG - Executive Program in Product Management and Business Analytics",
    programduration: "10 Months | Live Instructor led | Weekend Online",
    programdescription:
      "Embark on a 10-term learning journey in UI/UX Engineering & Business Analytics.",
    callnumber: "70129-57331",
    stacksone: "E&ICT Academy",
    stacksonedescription: "IIT Guwahati",
    stackstwo: "Live Classes",
    stackstwodescription: "10 Months",
    stacksthree: "Next Cohort",
    stacksthreedescription: "August 2024",
    stackslast: "Career Services ",
    stackslastdescription: "by Accredian",
    progarminclud1: "10 Terms - UI/UX Engineering & Business Analytics",
    progarminclud2: "Duration: 10 Months | Weekend Online",
    progarminclud3: "Live Instructor led classes",
    progarminclud4: "Daily doubt clearing",
    progarminclud5: "Capstone projects",
    progarminclud6: "No Cost EMI upto 12 months",
    progarminclud7: "Placement Support & Assistance",
    progarminclud8: "2-day campus immersion at IIT Guwahati ",
    progarminclud9: "1:1 career mentorship sessions",
    progarminclud10: "Learn from the top industry professionals",
    progarminclud11: "Certification from E&ICT, IIT Guwahati",
    whoshouldjoin:
      "This Executive Program is designed for professionals aiming to excel in Product Management and Business Analytics. Whether you're a product manager, business analyst, or professional from diverse backgrounds, this program hones your skills at the intersection of product management and analytics, preparing you for a successful career.",
    Jobopportunities:
      "Upon completion of this course, individuals can explore diverse job opportunities, including roles such as Product Manager, Business Analyst, Data Analyst, and other positions that demand expertise in both Product Management and Business Analytics. Graduates are well-equipped to thrive in dynamic and evolving professional landscapes.",
    suchitquotes:
      "Every individual from various backgrounds must learn Analytics to outshine in the competitive world today!",
    suchitinfo: "-Suchit Majumdar, Chief Data Science Mentor, Accredian.    ",
    Programsyllabustrmtext: "10 Terms",
    Programsyllabustremds: "(10 Terms)",
    Programsyllabusclasstext: "170+ hours of",
    Programsyllabuscareetext: "8 career",
    growt: "80%",
    scholarshipseats: "2",
    nonscholarshipseats: "5",
    setsction: "block",
    accd: [
      {
        hadding: "Term 1 : Introduction to product management",
        week: "4 weeks",
        accodingid: "trem1",
        tremone: [
          "Roles & Responsibilities of a PM",
          "User Research for PMs",
          "Market Research for PMs",
          "Competitive Analysis for PMs",
        ],
      },
      {
        hadding: "Term 2 : Product Strategy & Roadmap",
        week: "4 weeks",
        accodingid: "trem2",
        tremone: [
          "What is Product Planning Cycle?",
          "Establising Product Vision & Mission",
          "Formulating Product Strategy & Product Goals",
          "What is Product Backlog & Roadmap",
        ],
      },
      {
        hadding: "Term 3 : Design Thinking & UI/UX",
        week: "4 weeks",
        accodingid: "trem3",
        tremone: [
          "Design Thinking & UI/UX",
          "Building MVP - Artefacts, Persona Mapping & PRDs",
          "UI/UX for PMs",
          "Designing Mockups,Wireframes, & Prototypes",
        ],
      },
      // {
      //   hadding: "Minor Capstone Project",
      //   week: "",
      //   accodingid: "trem4",
      //   tremone: [
      //     "In a simulated environment get to work with a major Telecom company that seeks help from your team of Data Scientists to resolve a major marketing hurdle in front of them. Get a near real world exposure of working on industry problems within data science teams to reach a common end goal of helping customers win",
      //   ],
      // },
      {
        hadding: "Term 4 : Product Engineering",
        week: "4 weeks",
        accodingid: "trem5",
        tremone: [
          "Intoduction to Engineering for Product Managers",
          "LUnderstanding Microservices & Cloud Services",
          "Waterfall & Agile Methodology",
          "Understanding Scrum & Kanban",
        ],
      },
      {
        hadding: "Term 5 : Product Marketing & Launch",
        week: "4 weeks",
        accodingid: "trem6",
        tremone: [
          // "Product Development & Planning User Tests",
          "Product Launch & A/B Testing",
          "Data Driven Product Marketing & Consumer Behaviour",
          "Measuring Brand & Impact of Product Marketing",
          "Marketing Ninja Challenge",
        ],
      },
      // {
      //   hadding: "Term 6: Machine Learning Advanced",
      //   week: "4 weeks",
      //   accodingid: "trem7",
      //   tremone: [
      //     "KNN (K- Nearest neighbors)",
      //     "K-means Clustering",
      //     "Ensemble Learning ",
      //     "Optimization ",

      //   ],
      // },
      {
        hadding: "Project: Design Discovery",
        week: "",
        accodingid: "trem8",
        tremone: [
          "Design Discovery requires one to apply design thinking concepts to create human- centred and action- oriented solutions. As a PM practitioner you will be competing with the best to solve a complex challenge.",
        ],
      },
      {
        hadding: "Term 6: Foundation of Business Analytics",
        week: "4 weeks",
        accodingid: "trem9",
        tremone: [
          "Introduction to Business Analytics",
          "Data Analytics Fundamentals for Business Analysts",
          "Statistics Basics for Business",
          "Advanced Business Statistics",
        ],
      },
      {
        hadding: "Term 7: Excel Mastery for Business Insights",
        week: "4 weeks",
        accodingid: "trem10",
        tremone: [
          "Excel fundamentals for Business Analysis",
          "Excel Functions & Conditions",
          "Excel Pivot Tables & Reporting",
          "Excel Modelling",
        ],
      },
      {
        hadding: "Term 8: SQL Essentials for Business Intelligence",
        week: "4 weeks",
        accodingid: "trem11",
        tremone: [
          "Database Basics for Analysts",
          "SQL Fundamentals for Business Analysis",
          "SQL Operations",
          "Advanced SQL",
        ],
      },
      {
        hadding: "Term 9: Business Analytics with Power BI",
        week: "4 weeks",
        accodingid: "trem12",
        tremone: [
          "Power BI for Business reporting",
          "Connecting Data Sources",
          "Data Modelling in Power BI",
          "Functions in Power BI",
        ],
      },
      {
        hadding: "Term 10: Applied Industry Analytics: Case Studies",
        week: "4 weeks",
        accodingid: "trem13",
        tremone: [
          "Business Analytics in BFSI",
          "Marketing & Telecom Analytics",
          "Healthcare & Manufacturing Analytics",
          "Ecommerce & Supply Chain Analytics",
        ],
      },
    ],
    programtoolhadding: "Tools you will learn",
    progaming: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t6.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t7.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t8.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t9.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t10.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/7.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/9.webp",
    ],
    toolandassinment: "Topic wise Assignments",
    toolandpackge: "15+ Tools and Packages",
    faculty: [
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Deepesh.webp",
        name: "Deepesh",
        college: "IIM-Calcutta",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Nishkam.webp",
        name: "Nishkam",
        college: "Paypal",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/amit-goyal.webp",
        name: "Amit",
        college: "Paytm",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Gaurav-Trivedi-1.webp",
        name: "Gaurav",
        college: "IIT Guwahati",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/sai.webp",
        name: "Sai",
        college: "Google",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/Harihara.webp",
        name: "Harihara",
        college: "Amazon",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/shailendra.webp",
        name: "Shailendra",
        college: "Spinny",
      },
    ],
    cc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/IIT-certificate/eppmba.webp",
    mc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/PM-certificate/merit/eppmba.webp",
    certifictetstate: (
      <Typography sx={{ fontSize: "13.5px", textAlign: "center" }}>
        Professionals <b>upskilled in</b> product analytics.
      </Typography>
    ),
    compnyimg: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw6.webp",
    ],
    programadvantage: [
      {
        pdid: "btpa1",
        hadding: " Learning Resources & Technical Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/1.webp",
        details: [
          {
            texthdding:
              "Dedicated resource center with 1000+ resources to ease your learning journey. Daily technical support to resolve your queries.",
            desc: "desc",
          },
        ],
        classname: "btpaimageone",
      },
      {
        pdid: "btpa2",
        hadding: " Industry Immersion with Top Product Leaders",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/2.webp",
        details: [
          {
            haddingtext: "Monthly Speaker Connect",
            texthdding:
              "At Accredian, we bring in leading Product Management professionals from around the globe for monthly lectures. This provides you with practical insights and prepares you for intense competition in the field of Product Management.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
          {
            haddingtext: "International Summits",
            texthdding:
              "Every six months, we organize Product Week Summits inviting leaders from one diverse geography. Most recent one being India Product Week.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa3",
        hadding: "Placement Assistance",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/3.webp",
        details: [
          {
            texthdding:
              "1.Carefully crafted program such as career launchpad which will help the candidates to polish their resume & LinkedIn profiles.",
            desc: "descthre",
          },
          {
            texthdding: "2.Participate in Mock interviews and become job ready",
            desc: "desctwo",
          },
          {
            texthdding:
              "3.Utilise a dedicated job opening portal to find your perfect role.",
            desc: "desctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa4",
        hadding: "Lifelong Community Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/4.webp",
        details: [
          {
            texthdding:
              "Lifetime access to the resources, content and community support to clear your doubts.",
            desc: "lastdesc",
          },
        ],
        classname: "btpaimageone",
      },
    ],
    steponehadding: "Fill the application form",
    steponedes: "Apply by filling a simple online application form",
    steptwohadding: "Eligibility Test",
    steptwodes:
      "Attempt the Eligibility Test and get shortlisted for the admission procedure.",
    steptreehadding: "Start Learning",
    steptreedes:
      "Get your offer letter and access to your dashboard and begin your journey with starter kits.",
    programtotalfee: "2,30,000",
    mothemi12: "16,867",
    mothemi18: "11,695",
    mothemi24: "9,087",
    emi: "EMIs Starting 5k/month",
    studentreviews: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/6.webp",
    ],
    faq: [
      {
        manhadding: "Program Pre-Requisites",
        faqid: "faq1",
        faqtwo: [
          {
            childfaqid: "cfaq1",
            secondhadding:
              "1.Do I need to have prior Product Management and Business Analytics experience to enroll in the program?",
            faqdetails: [
              "No, the program is designed to be inclusive of all levels of experience. All topics will be covered from the basics, making it suitable for individuals from any field of work.",
            ],
          },
          {
            childfaqid: "cfaq2",
            secondhadding:
              "2.What is the minimum system configuration required?",
            faqdetails: [
              "Any latest processor (i3/i5/i7) with 2.0GHz clock speed and RAM 4GB (required), 8GB(recommended).",
            ],
          },
        ],
      },
      {
        manhadding: "Syllabus & Projects Covered",
        faqid: "faq2",
        faqtwo: [
          {
            childfaqid: "cfaq3",
            secondhadding: "1.How many projects can I anticipate working on?",
            faqdetails: [
              "In Executive Program in Product Management and Business Analytics you will have access to more than 35 case studies, Capstone Projects to provide a better understanding of the real-world applications of product management and Analytics.",
            ],
          },
          {
            childfaqid: "cfaq4",
            secondhadding:
              "2.What's the difference between case studies & projects?",
            faqdetails: [
              "Case studies are targeted towards concept validation and limited to only a single concept. However, projects are targeted to overall learning and are more of a combination of multiple topics.",
            ],
          },
          {
            childfaqid: "cfaq5",
            secondhadding:
              "3.What all topics are covered in the Executive Program in Product Management and Business Analytics ",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Download our latest program{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  brochure
                </Typography>{" "}
                &{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  curriculum.
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
      {
        manhadding: "Time Commitment",
        faqid: "faq3",
        faqtwo: [
          {
            childfaqid: "cfaq6",
            secondhadding:
              "1.How much time does one need to spend on a regular basis?",
            faqdetails: [
              "Daily 30 mins of prep time is required. You can choose to practice, read or watch videos. Additionally, on weekends one must attend 2 hours of live classes on both Saturday & Sunday.",
            ],
          },
          {
            childfaqid: "cfaq7",
            secondhadding: "2.What is the duration of the program?",
            faqdetails: [
              "Every term is for 4-5 weeks approximately. And the overall Executive Program in Product Management and Business Analytics duration is 10 months.",
            ],
          },
        ],
      },

      {
        manhadding: "Why IIT Guwahati ?",
        faqid: "faq5",
        faqtwo: [
          {
            childfaqid: "cfaq10",
            secondhadding:
              "1.How's the Executive Program in Product Management and Business Analytics program different?",
            faqdetails: [
              <>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  This program is distinguished by three key elements:
                </Typography>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  1. <b>Iterative Mastery Approach:</b> IITG's exclusive
                  Iterative Mastery Approach ensures development through a
                  step-by-step methodology, specifically designed to welcome
                  individuals from diverse backgrounds. Whether you're seeking a
                  lateral move in the industry or looking to broaden your
                  skills, our program is tailored to empower you on your unique
                  learning journey.
                </Typography>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  <Typography sx={{ marginBottom: "0.5rem !important" }}>
                    2. <b>Versatile Skill Development Model:</b> IIT G's unique
                    framework ensures that every Product Management and Business
                    Analytics concept is taught in the context of real-world
                    industry problems. Our program goes beyond theory,
                    emphasizing practical case studies to prepare students for
                    the challenges they'll face in the professional arena.
                  </Typography>
                </Typography>
                <Typography sx={{ marginBottom: "4px !important" }}>
                  3. <b>Accredian Career Center:</b> Aligned with our mission to
                  cultivate the Data leaders of tomorrow, the Accredian Career
                  Center empowers you to confidently launch your Data Science
                  career.
                </Typography>
              </>,
            ],
          },
          {
            childfaqid: "cfaq11",
            secondhadding: "2.Tell me more about Career Assistance?",
            faqdetails: [
              "Accredian offers career assistance through Product Management Career Launchpad. The student gets hands-on experience as a Product Leader with rigorous resume and GitHub building sessions. You will also learn to create a strong LinkedIn presence and develop top notch interview skills.",
            ],
          },
          {
            childfaqid: "cfaq12",
            secondhadding: "3. What's the refund policy?",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Go through our Student Policy{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  <Link
                    to="/policies/iitg-policy"
                    style={{ textDecoration: "none" }}
                  >
                    link
                  </Link>
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
    ],

    brochurectaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/eppmba.webp",
    brochurectahadding: "Download EPPMBA®️ Program Brochure!",
    curriculumctahadding: "Download EPPMBA®️ Program Curriculum !",
    curriculumctaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/eppmba.webp",
    curriculumctatext: "Product Management",
    displayone: "none",
    displaytwo: "block",
    freemodulesdesplay: "none",
    jobandaddon: "none",
    careerassistance: "none",
    ml: "-53px",
    ctasource: "Business Analytics Brochure",
    programname:
      "Executive Program in Product Management and Business Analytics",
    ctapdf:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IITG-PMBA-Brochure.pdf",
  },
  {
    id: "executive-program-in-pm-and-dt",
    category: "4",
    university: "iitg",
    vertical: "Product Management",
    brochureHeader:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/iitg.png",
    backgroundimage:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/program-page-banner-image/iit2.webp",
    mobbackground:
      "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 99%)",
    programlevel: "Beginner",
    hadding:
      "Executive Program in Product Management and Digital Transformation with E&ICT Academy, IIT Guwahati",
    title:
      "IITG - Executive Program in Product Management and Digital Transformation",
    programduration: "10 Months | Live Instructor led | Weekend Online",
    programdescription:
      "Embark on a 10-term learning journey in UI/UX, Digital Strategy, Design Thinking.",
    callnumber: "70129-57331",
    stacksone: "E&ICT Academy",
    stacksonedescription: "IIT Guwahati",
    stackstwo: "Live Classes",
    stackstwodescription: "10 Months",
    stacksthree: "Next Cohort",
    stacksthreedescription: "August 2024",
    stackslast: "Career Services ",
    stackslastdescription: "by Accredian",
    progarminclud1: "10 Terms - UI/UX, Digital Strategy, Design Thinking",
    progarminclud2: "Duration: 10 Months | Weekend Online",
    progarminclud3: "Live Instructor led classes",
    progarminclud4: "Daily doubt clearing",
    progarminclud5: "Capstone projects",
    progarminclud6: "No Cost EMI upto 12 months",
    progarminclud7: "Placement Support & Assistance",
    progarminclud8: "2-day career immersion at IIT Guwahati",
    progarminclud9: "1:1 career mentorship sessions",
    progarminclud10: "Learn from the top industry professionals",
    progarminclud11: "Certification from E&ICT, IIT Guwahati",
    whoshouldjoin:
      "The program is designed for seasoned professionals aiming to enhance their skills in product management and navigate the complexities of digital transformation. This comprehensive program equips participants with strategic insights, leadership capabilities, and digital proficiency essential for success in contemporary business environments.",
    Jobopportunities:
      "Graduates of this program can explore diverse job opportunities, including roles such as Product Manager, Digital Transformation Strategist, Project Manager, Business Analyst, and other leadership positions in industries embracing digital innovation.",
    suchitquotes:
      "In the age of digital transformation, success is not just about delivering a product; it's about delivering an experience.",
    suchitinfo: "-Suchit Majumdar, Chief Product Officer, Accredian",
    Programsyllabustrmtext: "10 Terms",
    Programsyllabustremds: "(10 Terms)",
    Programsyllabusclasstext: "170+ hours of",
    Programsyllabuscareetext: "8 career",
    growt: "80%",
    scholarshipseats: "3",
    nonscholarshipseats: "6",
    setsction: "block",
    accd: [
      {
        hadding: "Term 1 : Introduction to Product Management",
        week: "4 weeks",
        accodingid: "trem1",
        tremone: [
          "Roles & Responsibilities of a PM",
          "User Research for PMs",
          "Market Research for PMs",
          "Competitive Analysis for PMs",
        ],
      },
      {
        hadding: "Term 2 : Product Strategy & Roadmap",
        week: "4 weeks",
        accodingid: "trem2",
        tremone: [
          "What is Product Planning Cycle?",
          "Establishing Product Vision & Mission",
          "Formulating Product Strategy & Product Goals",
          "What is Product Backlog & Roadmap",
        ],
      },
      {
        hadding: "Term 3 : Design Thinking & UI/UX",
        week: "4 weeks",
        accodingid: "trem3",
        tremone: [
          "Design Thinking for PMs",
          "Building MVP - Artefacts, Persona Mapping & PRDs",
          "UI/UX for PMs",
          "Designing Mockups, Wireframes, & Prototypes",
        ],
      },
      {
        hadding: "Term 4 : Agile Product Engineering",
        week: "4 weeks",
        accodingid: "trem4",
        tremone: [
          "Introduction to Engineering for Product Managers",
          "Understanding Microservices & Cloud Services",
          "Waterfall & Agile Methodology",
          "Understanding Scrum & Kanban",
        ],
      },
      {
        hadding: "Term 5 : Product Marketing & Launch",
        week: "4 weeks",
        accodingid: "trem5",
        tremone: [
          // "Product Development & Planning User Tests",
          "Product Launch & A/B Testing",
          "Data Driven Product Marketing & Consumer Behaviour",
          "Measuring Brand & Impact of Product Marketing",
          "Marketing Ninja Challenge",
        ],
      },
      {
        hadding: "Project: Design Discovery",
        week: "",
        accodingid: "trem6",
        tremone: [
          "As a PM practitioner, you are required to apply design thinking concepts to create human-centered and action-oriented solutions in Design Discovery. You will be competing with the best to solve a complex challenge.",
        ],
      },
      {
        hadding: "Term 6 : Digital Strategy & Planning",
        week: "4 weeks",
        accodingid: "trem7",
        tremone: [
          "Digital Disruption in 21st Century",
          "Strategy Development and Alignment",
          "Building a Customer- Centric Approach",
          "Measuring and Optimizing Digital Strategy",
        ],
      },
      {
        hadding: "Term 7 : Digital Business Models and Value Proposition",
        week: "4 weeks",
        accodingid: "trem8",
        tremone: [
          "Fundamentals of Digital Business Models",
          "Value Proposition Developmentt",
          "Monetizing Digital Business Models",
          "Digital Ecosystems and Partnerships",
        ],
      },
      {
        hadding: "Term 8 : Digital Technology & Tools",
        week: "4 weeks",
        accodingid: "trem9",
        tremone: [
          "Foundations of Digital Technologies",
          "Cybersecurity and Data Privacy",
          "AI/ML & IOT Analytics",
          "Blockchain & Industry 4.0",
        ],
      },
      {
        hadding:
          "Term 9 : Digital Transformation Frameworks and Implementation",
        week: "4 weeks",
        accodingid: "trem10",
        tremone: [
          "Digital Transformation Models",
          "Digital Transformation Roadmaps",
          "Agile Methodologies for Transformation",
          "Digital Transformation Governance",
        ],
      },
      {
        hadding: "Term 10 : Digital Leadership & Change Management",
        week: "4 weeks",
        accodingid: "trem11",
        tremone: [
          "Leadership in Digital Transformation",
          "Change Management Strategies",
          "Building an Innovation First culture",
          "Sustaining Digital Success",
        ],
      },
      {
        hadding: " Capstone Project ",
        week: "",
        accodingid: "trem12",
        tremone: [
          "Lead the development of a Digital Transformation Roadmap for your organization, utilizing a standardized methodology to evaluate digital readiness and formulate the rationale for change, growth strategies, new governance structures, key capabilities, and cultural adaptation.",
        ],
      },
    ],
    programtoolhadding: "Tools you will learn",
    progaming: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t6.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t7.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t8.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t9.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t10.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/7.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/9.webp",
    ],
    toolandassinment: (
      <>
        <Typography
          variant="h4"
          component="div"
          align="center"
          sx={{
            fontSize: "23px",
            fontWeight: "bold",
            marginBottom: "0px!important",
          }}
        >
          Boardroom
        </Typography>
        <Typography
          variant="h4"
          component="div"
          align="center"
          sx={{
            fontSize: "23px",
            fontWeight: "bold",
            marginBottom: "0px!important",
          }}
        >
          Simulation
        </Typography>
      </>
    ),
    toolandpackge: (
      <>
        <Typography
          variant="h4"
          component="div"
          align="center"
          sx={{
            fontSize: "23px",
            fontWeight: "bold",
            marginBottom: "0px!important",
          }}
        >
          Product Demo
        </Typography>
        <Typography
          variant="h4"
          component="div"
          align="center"
          sx={{
            fontSize: "23px",
            fontWeight: "bold",
            marginBottom: "0px!important",
          }}
        >
          Day
        </Typography>
      </>
    ),
    faculty: [
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/prahlad.webp",
        name: "Prahlad",
        college: "Paypal",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/sai.webp",
        name: "Sai",
        college: "Google",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/Harihara.webp",
        name: "Harihara",
        college: "Amazon",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/shailendra.webp",
        name: "Shailendra",
        college: "Spinny",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/rajib.webp",
        name: "Rajib",
        college: "Walmart",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/Ankur.webp",
        name: "Ankur",
        college: "Archer",
      },
    ],
    cc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/IIT-certificate/eppmdt.webp",
    mc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/PM-certificate/merit/cpm.webp",
    certifictetstate: (
      <Typography sx={{ fontSize: "13.5px", textAlign: "center" }}>
        Professionals <b>upskilled in</b> product skills.
      </Typography>
    ),
    compnyimg: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw6.webp",
    ],
    programadvantage: [
      {
        pdid: "btpa1",
        hadding: " Learning Resources & Technical Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/1.webp",
        details: [
          {
            texthdding:
              "Dedicated resource center with 1000+ resources to ease your learning journey. Daily technical support to resolve your queries.",
            desc: "desc",
          },
        ],
        classname: "btpaimageone",
      },
      {
        pdid: "btpa2",
        hadding: " Industry Immersion with Top Product Leaders",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/2.webp",
        details: [
          {
            haddingtext: "Monthly Speaker Connect",
            texthdding:
              "At Accredian, we bring in leading Product Management professionals from around the globe for monthly lectures. This provides you with practical insights and prepares you for intense competition in the field of Product Management.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
          {
            haddingtext: "International Summits",
            texthdding:
              "Every six months, we organize Product Week Summits inviting leaders from one diverse geography. Most recent one being India Product Week.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa3",
        hadding: "Placement Assistance",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/3.webp",
        details: [
          {
            texthdding:
              "1.Carefully crafted program such as career launchpad which will help the candidates to polish their resume & LinkedIn profiles.",
            desc: "descthre",
          },
          {
            texthdding: "2.Participate in Mock interviews and become job ready",
            desc: "desctwo",
          },
          {
            texthdding:
              "3.Utilise a dedicated job opening portal to find your perfect role.",
            desc: "desctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa4",
        hadding: "Lifelong Community Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/4.webp",
        details: [
          {
            texthdding:
              "Lifetime access to the resources, content and community support to clear your doubts.",
            desc: "lastdesc",
          },
        ],
        classname: "btpaimageone",
      },
    ],
    steponehadding: "Fill the application form",
    steponedes: "Apply by filling a simple online application form",
    steptwohadding: "Eligibility Test",
    steptwodes:
      "Attempt the Eligibility Test and get shortlisted for the admission procedure.",
    steptreehadding: "Start Learning",
    steptreedes:
      "Get your offer letter and access to your dashboard and begin your journey with starter kits.",
    programtotalfee: "2,30,000",
    mothemi12: "16,867",
    mothemi18: "11,695",
    mothemi24: "9,087",
    emi: "EMIs Starting 5k/month",
    studentreviews: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/6.webp",
    ],
    faq: [
      {
        manhadding: "Program Pre-Requisites",
        faqid: "faq1",
        faqtwo: [
          {
            childfaqid: "cfaq1",
            secondhadding:
              "1.Do I need to have prior Product Management and Digital Transformation experience to enroll in the program?",
            faqdetails: [
              "No, the program is designed to be inclusive of all levels of experience. All topics will be covered from the basics, making it suitable for individuals from any field of work.",
            ],
          },
          {
            childfaqid: "cfaq2",
            secondhadding:
              "2.What is the minimum system configuration required?",
            faqdetails: [
              "Any latest processor (i3/i5/i7) with 2.0GHz clock speed and RAM 4GB (required), 8GB(recommended).",
            ],
          },
        ],
      },
      {
        manhadding: "Syllabus & Projects Covered",
        faqid: "faq2",
        faqtwo: [
          {
            childfaqid: "cfaq3",
            secondhadding: "1.How many projects can I anticipate working on?",
            faqdetails: [
              "In Executive Program in Product Management and Digital Transformation Program you will have access to more than 35 case studies, Project: Design Discovery, and Capstone Project to provide a better understanding of the real-world applications of product management and Digital Transformation concepts.",
            ],
          },
          {
            childfaqid: "cfaq4",
            secondhadding:
              "2.What's the difference between case studies & projects?",
            faqdetails: [
              "Case studies are targeted towards concept validation and limited to only a single concept. However, projects are targeted to overall learning and are more of a combination of multiple topics.",
            ],
          },
          {
            childfaqid: "cfaq5",
            secondhadding:
              "3.What all topics are covered in the Executive Program in Product Management and Digital Transformation Program ",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Download our latest program{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  brochure
                </Typography>{" "}
                &{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  curriculum.
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
      {
        manhadding: "Time Commitment",
        faqid: "faq3",
        faqtwo: [
          {
            childfaqid: "cfaq6",
            secondhadding:
              "1.How much time does one need to spend on a regular basis?",
            faqdetails: [
              "Daily 30 mins of prep time is required. You can choose to practice, read or watch videos. Additionally, on weekends, participants must attend 2 hours of live classes on both Saturday and Sunday for Product Management, as well as 3-hour live classes on Sunday for Digital Transformation.",
            ],
          },
          {
            childfaqid: "cfaq7",
            secondhadding: "2.What is the duration of the program?",
            faqdetails: [
              "Every term is for 4-5 weeks approximately. And the overall Executive Program in Product Management and Digital Transformation  duration is 10 months.",
            ],
          },
        ],
      },

      {
        manhadding: "Why IIT Guwahati ?",
        faqid: "faq5",
        faqtwo: [
          {
            childfaqid: "cfaq10",
            secondhadding:
              "1.How's the Executive Program in Product Management and Digital Transformation Program different?",
            faqdetails: [
              <>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  This program is distinguished by three key elements:
                </Typography>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  1. <b>Iterative Mastery Approach:</b> IITG's exclusive
                  Iterative Mastery Approach ensures development through a
                  step-by-step methodology, specifically designed to welcome
                  individuals from diverse backgrounds. Whether you're seeking a
                  lateral move in the industry or looking to broaden your
                  skills, our program is tailored to empower you on your unique
                  learning journey.
                </Typography>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  <Typography sx={{ marginBottom: "0.5rem !important" }}>
                    2. <b>Versatile Skill Development Model:</b> IIT G's unique
                    framework ensures that every Product Management and Digital
                    Transformation concept is taught in the context of
                    real-world industry problems. Our program goes beyond
                    theory, emphasizing practical case studies to prepare
                    students for the challenges they'll face in the professional
                    arena.
                  </Typography>
                </Typography>
                <Typography sx={{ marginBottom: "4px !important" }}>
                  3. <b>Accredian Career Center:</b> Aligned with our mission to
                  cultivate the Data leaders of tomorrow, the Accredian Career
                  Center empowers you to confidently launch your Data Science
                  career.
                </Typography>
              </>,
            ],
          },
          {
            childfaqid: "cfaq11",
            secondhadding: "2.Tell me more about Career Assistance?",
            faqdetails: [
              "Accredian offers career assistance through Product Management  Career Launchpad. The student gets hands-on experience as a Product Leader with rigorous resume and GitHub building sessions. You will also learn to create a strong LinkedIn presence and develop top notch interview skills.",
            ],
          },
          {
            childfaqid: "cfaq12",
            secondhadding: "3. What's the refund policy?",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Go through our Student Policy{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  <Link
                    to="/policies/iitg-policy"
                    style={{ textDecoration: "none" }}
                  >
                    link
                  </Link>
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
    ],

    brochurectaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/eppmdt.webp",
    brochurectahadding: "Download EPPMDT®️ Program Brochure!",
    curriculumctahadding: "Download EPPMDT®️ Program Curriculum !",
    curriculumctaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/eppmdt.webp",
    curriculumctatext: "Product Management",
    displayone: "none",
    displaytwo: "block",
    freemodulesdesplay: "none",
    jobandaddon: "none",
    careerassistance: "none",
    ml: "-53px",
    ctasource: "Product Management Brochure",
    programname:
      "Executive Program in Product Management and Digital Transformation",
    ctapdf:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IITG-PMDT-Brochure.pdf",
  },
  {
    id: "executive-program-in-ds-and-dt",
    category: "2",
    university: "iitg",
    vertical: "Data Science",
    brochureHeader:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/iitg.png",
    backgroundimage:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/program-page-banner-image/iit2.webp",
    mobbackground:
      "linear-gradient(0deg, rgba(25,122,237,1) 0%, rgba(34,39,125,1) 0%, rgba(31,33,64,1) 99%)",

    hadding:
      "Executive Program in Data Science and Digital Transformation with E&ICT Academy, IIT Guwahati",
    title: "IITG - Executive Program in DS & DT",
    programduration: "11 Months | Live Instructor led | Weekend Online",
    programdescription:
      "Embark on an 11- term learning journey Data Analytics, Machine Learning, Digital Strategy, and Design Thinking.",
    callnumber: "90796-53292",
    stacksone: "E&ICT Academy",
    stacksonedescription: "IIT Guwahati",
    stackstwo: "Live Classes",
    stackstwodescription: "11 Months",
    stacksthree: "Next Cohort",
    stacksthreedescription: "August 2024",
    stackslast: "Career Services ",
    stackslastdescription: "by Accredian",
    progarminclud1:
      "11 Terms - Data Analytics & Machine Learning,Digital Strategy, Design Thinking",
    progarminclud2: "Duration: 11 Months | Weekend Online",
    progarminclud3: "Live Instructor led classes",
    progarminclud4: "Daily doubt clearing",
    progarminclud5: "Term projects & Capstone projects",
    progarminclud6: "No Cost EMI upto 12 months",
    progarminclud7: "Placement Support & Assistance",
    progarminclud8: "2-day career immersion at IIT Guwahati",
    progarminclud9: "1:1 career mentorship sessions",
    progarminclud10:
      "Learn from the top industry professionals and expert sessions by IIT Guwahati Faculty",
    progarminclud11: "Certification from E&ICT, IIT Guwahati",
    whoshouldjoin:
      "This program is well-suited for individuals seeking to build a strong foundation in Data Science and to create practical digital transformation solutions for real-world applications.",
    Jobopportunities:
      "Upon completing the 11-month program, participants can explore diverse job opportunities in roles such as Data Scientist, Digital Transformation Specialist, Business Analyst, and more, equipped with comprehensive skills for the dynamic industry landscape.",
    suchitquotes:
      "Every individual from various backgrounds must learn Analytics to outshine in the competitive world today!",
    suchitinfo: "-Suchit Majumdar, Chief Data Science Mentor, Accredian.",
    Programsyllabustrmtext: "11 Terms",
    Programsyllabustremds: "(11 months)",
    Programsyllabusclasstext: "170+ hours of",
    Programsyllabuscareetext: "8 career",
    growt: "80%",
    scholarshipseats: "2",
    nonscholarshipseats: "5",
    setsction: "block",
    accd: [
      {
        hadding: "Term 1 : Data Analysis with Python",
        week: "4 weeks",
        accodingid: "trem1",
        tremone: [
          "Data Science Fundamentals   ",
          " Data Operation with Numpy ",
          "Data Manipulation with Pandas ",
        ],
      },
      {
        hadding: "Term 2 : Data Visualization Techniques",
        week: "4 weeks",
        accodingid: "trem2",
        tremone: [
          "Introduction to Data Visualization",
          "Data Visualization using Matplotlib",
          "Hands-on Pandas for Rapid Visualization ",
          "Seaborn for Data Visualization",
        ],
      },
      {
        hadding: "Term 3 : EDA & Data Storytelling",
        week: "4 weeks",
        accodingid: "trem3",
        tremone: [
          "Introduction to Exploratory Data Analysis",
          "EDA Framework Deep Dive",
          "Scientific Exploration of Industry Data (Case Study) ",
          "Student Presentations & Insight Delivery ",
        ],
      },
      {
        hadding: "Minor Capstone Project",
        week: "",
        accodingid: "trem4",
        tremone: [
          "In a simulated environment get to work with a major Telecom company that seeks help from your team of Data Scientists to resolve a major marketing hurdle in front of them. Get a near real world exposure of working on industry problems within data science teams to reach a common end goal of helping customers win",
        ],
      },
      {
        hadding: "Term 4 : Machine Learning Foundation",
        week: "4 weeks",
        accodingid: "trem5",
        tremone: [
          "Introduction to Machine Learning",
          "Linear Regression",
          "Logistic Regression",
          "Model Evaluation Techniques",
        ],
      },
      {
        hadding: "Term 5 : Machine Learning Intermediate",
        week: "4 weeks",
        accodingid: "trem6",
        tremone: [
          "Decision Trees",
          "Random Forests ",
          "Dimensionality Reduction using Principal Component Analysis (PCA) ",
          "Naive Bayes Classifier ",
        ],
      },
      {
        hadding: "Term 6: Machine Learning Advanced",
        week: "4 weeks",
        accodingid: "trem7",
        tremone: [
          "KNN (K- Nearest neighbors)",
          "K-means Clustering",
          "Ensemble Learning ",
          "Optimization ",
        ],
      },
      {
        hadding: "Major Capstone Project",
        week: "",
        accodingid: "trem8",
        tremone: [
          "Experience a real world simulation of a company Employee Retention Program that needs to be solved using Data Science and Machine Learning.Work with a team of Data Scientists and experience real-world problem solving through Data Science",
        ],
      },
      {
        hadding: "Term 7: Digital Strategy & Planning",
        week: "4 weeks",
        accodingid: "trem9",
        tremone: [
          "Digital Disruption in 21st Century",
          "Strategy Development and Alignment",
          "Developing a Customer- Centric Strategy",
          "Measuring and Optimizing Digital Strategy",
        ],
      },
      {
        hadding: "Term 8: Digital Business Models and Value Proposition ",
        week: "4 weeks",
        accodingid: "trem10",
        tremone: [
          "Fundamentals of Digital Business Models",
          "Value Proposition Development",
          "Monetizing Digital Business Models",
          "Digital Ecosystems and Partnership",
        ],
      },
      {
        hadding: "Term 9: Digital Technology & Tools",
        week: "4 weeks",
        accodingid: "trem11",
        tremone: [
          "Foundations of Digital Technologies",
          "Cybersecurity and Data Privacy",
          "AI/ML & IOT Analytics",
          "Blockchain & Industry 4.0",
        ],
      },
      {
        hadding:
          "Term 10: Digital Transformation Frameworks and Implementation",
        week: "4 weeks",
        accodingid: "trem12",
        tremone: [
          "Digital Transformation Models",
          "Digital Transformation Roadmaps",
          "Agile Methodologies for Transformation",
          "Digital Transformation Governance",
        ],
      },
      {
        hadding: "Term 11: Digital Leadership & Change Management",
        week: "4 weeks",
        accodingid: "trem13",
        tremone: [
          "Leadership in Digital Transformation",
          "Change Management Strategies",
          "Building an Innovation First culture",
          "Sustaining Digital Success",
        ],
      },
      {
        hadding: "Capstone Project",
        week: "",
        accodingid: "trem14",
        tremone: [
          "Lead Development of Digital Transformation Roadmap for Your Organization Utilize a Standardized Methodology to Evaluate Digital Readiness and Formulate the Rationale for Change, Growth Strategies, New Governance Structures, Key Capabilities, and Cultural Adaptation.",
        ],
      },
    ],
    programtoolhadding: "Programming Languages & Tools",
    progaming: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/3.webp",
      // "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/6.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/7.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/8.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/9.webp",
    ],
    toolandassinment: "30+ Topic wise Assignments",
    toolandpackge: "10+ Tools and Packages",
    faculty: [
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Deepesh.webp",
        name: "Deepesh",
        college: "IIM-Calcutta",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Nishkam.webp",
        name: "Nishkam",
        college: "Paypal",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/amit-goyal.webp",
        name: "Amit",
        college: "Paytm",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Gaurav-Trivedi-1.webp",
        name: "Gaurav",
        college: "IIT Guwahati",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Lavi.webp",
        name: "Lavi",
        college: "Google",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/subhodeep.webp",
        name: "Subhodeep",
        college: "Jio",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Neelmani.webp",
        name: "Neelmani",
        college: "Gojek",
      },
    ],
    projectsectionhadding: "Work on world class Projects",
    project: [
      {
        projectimg:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcd/p1.webp",
        projecthadding: "Telecom Churn Prediction",
        sortdescription: "Classify whether a customer will churn or not.",
        longdescription:
          "The dataset consist of all the necessary information about customer's behaviour. The train set contains 5634 rows and 21 columns while the test set contains 1409 rows and 20 columns.",
        id: "1",
      },
      {
        projectimg:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcd/p2.webp",
        projecthadding: "Price Prediction of Used Cars",
        sortdescription: "Predicting the price of used cars.",
        longdescription:
          "The dataset consist of the information about the prices of used cars based on its features. The train set contains 181 rows and 27 columns while the test set contains 20 rows and 26 columns.",
        id: "2",
      },
      {
        projectimg:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcd/p3.webp",
        projecthadding: "Olympic 1896-2014",
        sortdescription:
          "Analysis which country have won the most medals at Olympic games.",
        longdescription:
          "The dataset contains 9 variables and 31.2k observations of the summer olympic games(1896-2014).",
        id: "3",
      },
      {
        projectimg:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcd/p4.webp",
        projecthadding: "Abalone Age Prediction",
        sortdescription:
          "Predict the age of abalones based on physical measurements.",
        longdescription:
          "The dataset contains all the necessary information about the abalones like their sex, length, diameter, height, weight, etc. The train set contains 3341 rows and 10 columns while the test set contains 836 rows and 9 columns..",
        id: "4",
      },
      {
        projectimg:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcd/p5.webp",
        projecthadding: "Restaurant Across America Data",
        sortdescription:
          "Analyze the availability and accessibility of food across America.",
        longdescription:
          "The dataset contains 10 variables and 10k observations of the fast food restaurants in America.",
        id: "5",
      },
      {
        projectimg:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcd/p6.webp",
        projecthadding: "Halloween Candy Winpercent Prediction",
        sortdescription: "To predict the winpercent of halloween candies..",
        longdescription:
          "The dataset contains all the necessary information about the different candies present in a competition like sugarpercent, pricepercent. The train set contains 68 rows and 13 columns while the test set contains 17 rows and 12 columns..",
        id: "6",
      },
      {
        projectimg:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcd/p7.webp",
        projecthadding: "Flight Passengers Satisfaction Prediction",
        sortdescription:
          "Predicting the satisfaction level of flight passengers.",
        longdescription:
          "The dataset consists of information of passenger boarding and deboarding information and the services provided during the travel in flight. The train set contains 83123 rows and 24 columns while the test set contains 20781 rows and 23 columns.",
        id: "7",
      },
      {
        projectimg:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcd/p8.webp",
        projecthadding: "Avocado Price Prediction",
        sortdescription:
          "Predicting the future price of avocadoes based on historical data.",
        longdescription:
          "The dataset contains weekly retail scan data for National Retail Volume (units) and price of avocados. The train set contains 14599 rows and 14 columns while the test set contains 3650 rows and 13 columns.",
        id: "8",
      },
    ],
    cc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/IIT-certificate/epdsdt.webp",
    // mc: "https://accredian.com/wp-content/uploads/2022/08/gcdm.webp",
    certifictetstate:
      "Professionals upskilled in Data Science and Digital transformation skillset. ",

    compnyimg: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/company-images/1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/company-images/2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/company-images/3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/company-images/4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/company-images/5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/company-images/6.webp",
    ],
    programadvantage: [
      {
        pdid: "btpa1",
        hadding: " Learning Resources & Technical Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/1.webp",
        details: [
          {
            texthdding:
              "Dedicated resource center with 1000+ resources to ease your learning journey. Daily technical support to resolve your queries.",
            desc: "desc",
          },
        ],
        classname: "btpaimageone",
      },
      {
        pdid: "btpa2",
        hadding: " Industry Immersion with Top Data Science Leaders",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/2.webp",
        details: [
          {
            haddingtext: "Monthly Speaker Connect",
            texthdding:
              "At Accredian, we feature monthly lectures led by leading professionals worldwide. This offers you practical insights and readies you for the intense competition in the field.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
          {
            haddingtext: "International Summits",
            texthdding:
              "We regularly arrange Data Science Summits every six months, where we invite leaders from across the world. The latest summit was the Southeast Asia Conference, focusing on the SEA region.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa3",
        hadding: "Placement Assistance",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/3.webp",
        details: [
          {
            texthdding:
              "1.Carefully crafted program, such as the ‘Career Launchpad,’ is tailored to assist candidates in refining their resumes & profiles.",
            desc: "descthre",
          },
          {
            texthdding: "2.Participate in Mock interviews and become job ready",
            desc: "desctwo",
          },
          {
            texthdding:
              "3.Utilise a dedicated job opening portal to find your perfect role.",
            desc: "desctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa4",
        hadding: "Lifelong Community Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/4.webp",
        details: [
          {
            texthdding:
              "Lifetime access to the resources, content and community support to clear your doubts.",
            desc: "lastdesc",
          },
        ],
        classname: "btpaimageone",
      },
    ],
    steponehadding: "Fill the application form",
    steponedes: "Apply by filling a simple online application form.",
    steptwohadding: "Eligibility Test",
    steptwodes:
      "Attempt the Eligibility Test and get shortlisted for the admission procedure.",
    steptreehadding: "Start Learning",
    steptreedes:
      "Get access to your dashboard and begin your journey with starter kits.",
    programtotalfee: "2,20,000",
    mothemi12: "14,250",
    mothemi18: "9,880",
    mothemi24: "7,678",
    emi: "EMIs Starting 5k/month",
    studentreviews: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/6.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/7.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/8.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/9.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/10.webp",
    ],
    faq: [
      {
        manhadding: "Program Pre-Requisites",
        faqid: "faq1",
        faqtwo: [
          {
            childfaqid: "cfaq1",
            secondhadding:
              "1.Do I need to know Programming & Math before enrolling to a program?",
            faqdetails: [
              "Background in Programming & Maths is helpful but not mandatory.",
            ],
          },
          {
            childfaqid: "cfaq2",
            secondhadding:
              "2.What is the minimum system configuration required?",
            faqdetails: [
              "Any latest processor (i3/i5/i7) with 2.0GHz clock speed and RAM 4GB (required), 8GB(recommended).",
            ],
          },
        ],
      },
      {
        manhadding: "Syllabus & Projects Covered",
        faqid: "faq2",
        faqtwo: [
          {
            childfaqid: "cfaq3",
            secondhadding: "1.How many projects can I anticipate working on?",
            faqdetails: [
              "In the Executive Program in Data Science and Digital Transformation, you will undertake 11 Term Projects, 3 Capstone Projects. In addition to these, we provide a repository of over 100 projects spanning various domains for you to choose from.",
            ],
          },
          {
            childfaqid: "cfaq4",
            secondhadding:
              "2.What's the difference between Term Project & Capstone Project?",
            faqdetails: [
              "Term Projects are individual assignments specific to each term and will be shared upon the completion of every term. Capstone Projects closely resemble real-world projects, requiring the application of various concepts learned throughout the program.",
            ],
          },
          {
            childfaqid: "cfaq5",
            secondhadding:
              "3.What all topics are covered in the Executive Program in Data Science and Digital Transformation",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Download our latest program{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  brochure
                </Typography>{" "}
                &{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  curriculum.
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
      {
        manhadding: "Time Commitment",
        faqid: "faq3",
        faqtwo: [
          {
            childfaqid: "cfaq6",
            secondhadding:
              "1.How much time does one need to spend on a regular basis?",
            faqdetails: [
              "Daily 30 mins of prep time is required. You can choose to practice, read or watch videos. Additionally, on weekends, participants must attend 2 hours of live classes on both Saturday and Sunday for Data Science, as well as 3-hour live classes on Sunday for Digital Transformation.",
            ],
          },
          {
            childfaqid: "cfaq7",
            secondhadding: "2.What is the duration of the program?",
            faqdetails: [
              "Every term is for 4-5 weeks approximately. And the overall Executive Program in Data Science & DT duration is 11 months.",
            ],
          },
        ],
      },

      {
        manhadding: "Why IIT Guwahati ?",
        faqid: "faq5",
        faqtwo: [
          {
            childfaqid: "cfaq10",
            secondhadding:
              "1.How's the Executive Program in Data Science & DT different?",
            faqdetails: [
              <>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  This program is distinguished by three key elements:
                </Typography>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  1. <b>Iterative Mastery Approach:</b> IITG's exclusive
                  Iterative Mastery Approach ensures development through a
                  step-by-step methodology, specifically designed to welcome
                  individuals from diverse backgrounds. Whether you're seeking a
                  lateral move in the industry or looking to broaden your
                  skills, our program is tailored to empower you on your unique
                  learning journey.
                </Typography>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  <Typography sx={{ marginBottom: "0.5rem !important" }}>
                    2. <b>Versatile Skill Development Model:</b> IIT G's unique
                    framework ensures that every Data Science and Digital
                    Transformation concept is taught in the context of
                    real-world industry problems. Our program goes beyond
                    theory, emphasizing practical case studies to prepare
                    students for the challenges they'll face in the professional
                    arena.
                  </Typography>
                </Typography>
                <Typography sx={{ marginBottom: "4px !important" }}>
                  3. <b>Accredian Career Center:</b> Aligned with our mission to
                  cultivate the Data leaders of tomorrow, the Accredian Career
                  Center empowers you to confidently launch your Data Science
                  career.
                </Typography>
              </>,
            ],
          },
          {
            childfaqid: "cfaq11",
            secondhadding: "2.Tell me more about Career Assistance?",
            faqdetails: [
              "Accredian provides career assistance through the Career Launchpad program. Students gain hands-on experience as leaders in their chosen field with rigorous resume and portfolio building sessions. The program also covers creating a strong online presence and developing top-notch interview skills.",
            ],
          },
          {
            childfaqid: "cfaq12",
            secondhadding: "3. What's the refund policy?",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Go through our Student Policy{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  <Link
                    to="/policies/iitg-policy"
                    style={{ textDecoration: "none" }}
                  >
                    link
                  </Link>
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
    ],
    // otherprogram: [
    //   {
    //     otherprogramimg:
    //       "https://accredian.com/wp-content/uploads/2022/08/gcdp.webp",
    //     programname: "Global Certificate in Data Science & AI",
    //     month: "10 months",
    //     pagelink: "global-certificate-in-data-science-and-ai/",
    //   },
    //   {
    //     otherprogramimg:
    //       "https://accredian.com/wp-content/uploads/2022/08/pgpdsaifp.webp",
    //     programname: "PGP in Data Science",
    //     month: "12 months",
    //     pagelink: "pgp-in-data-science/",
    //   },
    //   {
    //     otherprogramimg:
    //       "https://accredian.com/wp-content/uploads/2022/08/gcdaip.webp",
    //     programname: "PGP in Data Science & AI",
    //     month: "12 months",
    //     pagelink:
    //       "pgp-in-data-science-and-ai/",
    //   },
    // ],

    brochurectaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/epdsdt.webp",
    brochurectahadding: "Download EPDSDT®️ Program Brochure!",
    curriculumctahadding: "Download EPDSDT®️ Program Curriculum !",
    curriculumctaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/epdsdt.webp",
    curriculumctatext: "Data Science",
    displayone: "block",
    displaytwo: "none",
    freemodulesdesplay: "block",
    jobandaddon: "none",
    careerassistance: "block",
    addmodul: "block",
    addmodultwo: "block",
    ml: "-53px",
    ctasource: "Data Science Brochure",
    programname: "Executive Program in Data Science and Digital Transformation",
    ctapdf:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IIT-Executive-Program-in-DS-DT-11-months.pdf",
  },
  {
    id: "advanced-certification-in-dt",
    category: "2",
    university: "iitg",
    vertical: "Data Science",
    brochureHeader:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/iitg.png",
    backgroundimage:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/program-page-banner-image/iit2.webp",
    mobbackground:
      "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 99%)",
    programlevel: "Beginner",
    hadding:
      "Advanced Certification in Digital Transformation with E&ICT Academy, IIT Guwahati",
    title: "IITG - Advanced Certificationin Digital Transformation",
    programduration: "5 Months | Live Instructor led | Weekend Online",
    programdescription:
      "Specialize in Digital Transformation Leadership  in 5 Months with India's #1  Digital Transformation Certification",
    callnumber: "70129-57331",
    stacksone: "E&ICT Academy",
    stacksonedescription: "IIT Guwahati",
    stackstwo: "Live Classes",
    stackstwodescription: "5 Months",
    stacksthree: "Next Cohort",
    stacksthreedescription: "August 2024",
    stackslast: "Career Services ",
    stackslastdescription: "by Accredian",
    progarminclud1:
      "5 Terms - Digital Strategy, Technologies, Design Thinking and Frameworks",
    progarminclud2: "Duration: 5 Months | Weekend Online",
    progarminclud3: "Live Instructor led classes",
    progarminclud4: "Daily doubt clearing",
    progarminclud5: "Capstone projects",
    progarminclud6: "No Cost EMI upto 12 months",
    progarminclud7: "Placement Support & Assistance",
    progarminclud8:
      "Industry Immersion with top Digital Transformation Leaders",
    progarminclud9: "1:1 career mentorship sessions",
    progarminclud10: "Learn from the top industry professionals",
    progarminclud11: "Certification from E&ICT, IIT Guwahati",
    whoshouldjoin:
      "Engineers, MBAs, Freshers, Analysts, Leads, Program Managers, Product/Project managers, or anyone from non tech (Sales, Marketing, Teaching) backgrounds who is interested to learn building blocks of Product Management.",
    Jobopportunities:
      "Entreprenuers & Business Owners, VPs & CXOs,Digital Manager,P&L Leader,Project/Product Managers, Tech/Team Leads, Business Manager/Vertical Heads, Strategy & Consulting Professionals",
    suchitquotes:
      "In the age of digital transformation, success is not just about delivering a product; it's about delivering an experience.",
    suchitinfo: "-Suchit Majumdar, Chief Product Officer, Accredian",
    Programsyllabustrmtext: "5 Terms",
    Programsyllabustremds: "(5 Terms)",
    Programsyllabusclasstext: "80+ hours of",
    Programsyllabuscareetext: "4 career",
    growt: "80%",
    scholarshipseats: "3",
    nonscholarshipseats: "6",
    setsction: "block",
    accd: [
      {
        hadding: "Term 1 : Digital Strategy & Planning",
        week: "4 weeks",
        accodingid: "trem1",
        tremone: [
          "Digital Disruption in 21st Century",
          "Strategy Development and Alignment",
          "Data Driven Product Marketing & Consumer Behaviour",
          "Measuring and Optimizing Digital Strategy",
        ],
      },
      {
        hadding: "Term 2 : Digital Business Models and Value Proposition",
        week: "4 weeks",
        accodingid: "trem2",
        tremone: [
          "Fundamentals of Digital Business Models",
          "Value Proposition Developmentt",
          "Monetizing Digital Business Models",
          "Digital Ecosystems and Partnerships",
        ],
      },
      {
        hadding: "Term 3 : Digital Technology & Tools",
        week: "4 weeks",
        accodingid: "trem3",
        tremone: [
          "Foundations of Digital Technologies",
          "Cybersecurity and Data Privacy",
          "AI/ML & IOT Analytics",
          "Blockchain & Industry 4.0",
        ],
      },
      {
        hadding:
          "Term 4 : Digital Transformation Frameworks and Implementation",
        week: "4 weeks",
        accodingid: "trem4",
        tremone: [
          "Digital Transformation Models",
          "Digital Transformation Roadmaps",
          "Agile Methodologies for Transformation",
          "Digital Transformation Governance",
        ],
      },
      {
        hadding: "Term 5 : Digital Leadership & Change Management",
        week: "4 weeks",
        accodingid: "trem5",
        tremone: [
          "Leadership in Digital Transformation",
          "Change Management Strategies",
          "Building an Innovation First culture",
          "Sustaining Digital Success",
        ],
      },
      {
        hadding: " Capstone Project ",
        week: "",
        accodingid: "trem6",
        tremone: [
          "In the project to lead the Development of Digital Transformation Roadmap for an organisation, leverage the teachings from the course. Utilize a standardised methodology to evaluate the digital readiness  and formulate the rationale for change, growth strategies, and new governance structures. This practical application ensures a direct integration of the course insights into real-world scenarios.",
        ],
      },
    ],
    programtoolhadding: "Tools you will learn",
    progaming: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t6.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t7.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t8.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t9.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t10.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/7.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/9.webp",
    ],
    toolandassinment: " Boardroom  Simulation ",
    toolandpackge: " DT Hackathon",
    faculty: [
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/prahlad.webp",
        name: "Prahlad",
        college: "Paypal",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/sai.webp",
        name: "Sai",
        college: "Google",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/Harihara.webp",
        name: "Harihara",
        college: "Amazon",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/shailendra.webp",
        name: "Shailendra",
        college: "Spinny",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/rajib.webp",
        name: "Rajib",
        college: "Walmart",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/Ankur.webp",
        name: "Ankur",
        college: "Archer",
      },
    ],
    cc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/IIT-certificate/acdt.webp",
    mc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/PM-certificate/merit/cpm.webp",
    certifictetstate: (
      <Typography sx={{ fontSize: "13.5px", textAlign: "center" }}>
        Professionals upskilled in Digital Transformation
      </Typography>
    ),
    compnyimg: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw6.webp",
    ],
    programadvantage: [
      {
        pdid: "btpa1",
        hadding: " Learning Resources & Technical Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/1.webp",
        details: [
          {
            texthdding:
              "Dedicated resource center with 1000+ resources to ease your learning journey. Daily technical support to resolve your queries.",
            desc: "desc",
          },
        ],
        classname: "btpaimageone",
      },
      {
        pdid: "btpa2",
        hadding: " Industry Immersion with Top Product Leaders",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/2.webp",
        details: [
          {
            haddingtext: "Monthly Speaker Connect",
            texthdding:
              "At Accredian, we bring in leading Product Management professionals from around the globe for monthly lectures. This provides you with practical insights and prepares you for intense competition in the field of Product Management.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
          {
            haddingtext: "International Summits",
            texthdding:
              "Every six months, we organize Product Week Summits inviting leaders from one diverse geography. Most recent one being India Product Week.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa3",
        hadding: "Placement Assistance",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/3.webp",
        details: [
          {
            texthdding:
              "1.Carefully crafted program such as career launchpad which will help the candidates to polish their resume & LinkedIn profiles.",
            desc: "descthre",
          },
          {
            texthdding: "2.Participate in Mock interviews and become job ready",
            desc: "desctwo",
          },
          {
            texthdding:
              "3.Utilise a dedicated job opening portal to find your perfect role.",
            desc: "desctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa4",
        hadding: "Lifelong Community Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/4.webp",
        details: [
          {
            texthdding:
              "Lifetime access to the resources, content and community support to clear your doubts.",
            desc: "lastdesc",
          },
        ],
        classname: "btpaimageone",
      },
    ],
    steponehadding: "Fill the application form",
    steponedes: "Apply by filling a simple online application form",
    steptwohadding: "Eligibility Test",
    steptwodes:
      "Attempt the Eligibility Test and get shortlisted for the admission procedure.",
    steptreehadding: "Start Learning",
    steptreedes:
      "Get your offer letter and access to your dashboard and begin your journey with starter kits.",
    programtotalfee: "2,40,000",
    mothemi12: "15,884",
    mothemi18: "11,013",
    mothemi24: "8,558",
    emi: "EMIs Starting 5k/month",
    studentreviews: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/6.webp",
    ],
    faq: [
      {
        manhadding: "Program Pre-Requisites",
        faqid: "faq1",
        faqtwo: [
          {
            childfaqid: "cfaq1",
            secondhadding:
              "1.Do I need to have prior Digital Transformation experience to enroll in the program?",
            faqdetails: [
              "No, the program is designed to be inclusive of all levels of experience. All topics will be covered from the basics, making it suitable for individuals from any field of work.",
            ],
          },
          {
            childfaqid: "cfaq2",
            secondhadding:
              "2.What is the minimum system configuration required?",
            faqdetails: [
              "Any latest processor (i3/i5/i7) with 2.0GHz clock speed and RAM 4GB (required), 8GB(recommended).",
            ],
          },
        ],
      },
      {
        manhadding: "Syllabus & Projects Covered",
        faqid: "faq2",
        faqtwo: [
          {
            childfaqid: "cfaq3",
            secondhadding: "1.How many projects can I anticipate working on?",
            faqdetails: [
              "In Advanced Certification in  Digital Transformation Program you will have access to more than 35 case studies, Mission: Design Discovery, Major Product, and Capstone Project to provide a better understanding of the real-world applications of product management and Digital Transformation concepts.",
            ],
          },
          {
            childfaqid: "cfaq4",
            secondhadding:
              "2.What's the difference between case studies & projects?",
            faqdetails: [
              "Case studies are targeted towards concept validation and limited to only a single concept. However, projects are targeted to overall learning and are more of a combination of multiple topics.",
            ],
          },
          {
            childfaqid: "cfaq5",
            secondhadding:
              "3.What all topics are covered in the Advanced Certification in  Digital Transformation Program ",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Download our latest program{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  brochure
                </Typography>{" "}
                &{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  curriculum.
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
      {
        manhadding: "Time Commitment",
        faqid: "faq3",
        faqtwo: [
          {
            childfaqid: "cfaq6",
            secondhadding:
              "1.How much time does one need to spend on a regular basis?",
            faqdetails: [
              "Daily 30 mins of prep time is required. You can choose to practice, read or watch videos. Additionally, on weekends one must attend 2 hours of live classes on both Saturday & Sunday.",
            ],
          },
          {
            childfaqid: "cfaq7",
            secondhadding: "2.What is the duration of the program?",
            faqdetails: [
              "Every term is for 4-5 weeks approximately. And the overall Advanced Certification in  Digital Transformation  duration is 5 months.",
            ],
          },
        ],
      },

      {
        manhadding: "Why IIT Guwahati ?",
        faqid: "faq5",
        faqtwo: [
          {
            childfaqid: "cfaq10",
            secondhadding:
              "1.How's the Advanced Certification in  Digital Transformation Program different?",
            faqdetails: [
              "Three things make this program world class 1. Progressive Learning Architecture: Accredian's proprietary Progressive Learning Architecture ensures that irrespective of the background you are from, you will attain mastery in a step-by-step way 2. Concept-to-Context®️ Learning Framework: Accredian's Concept-to-Context®️ framework ensures that you are learning every Digital Transformation concept in context of real world industry problems 3. Accredian Career Center: Accredian's mission is to groom Product leaders of tomorrow. Accredian Career Center helps you launch a Digital Transformation career with 100% confidence.",
            ],
          },
          {
            childfaqid: "cfaq11",
            secondhadding: "2.Tell me more about Career Assistance?",
            faqdetails: [
              "Accredian offers career assistance through Digital Transformation  Career Launchpad. The student gets hands-on experience as a Product Leader with rigorous resume and GitHub building sessions. You will also learn to create a strong LinkedIn presence and develop top notch interview skills.",
            ],
          },
          {
            childfaqid: "cfaq12",
            secondhadding: "3. What's the refund policy?",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Go through our Student Policy{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  <Link
                    to="/policies/iitg-policy"
                    style={{ textDecoration: "none" }}
                  >
                    link
                  </Link>
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
    ],

    brochurectaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/acdt.webp",
    brochurectahadding: "Download ACDT®️ Program Brochure!",
    curriculumctahadding: "Download ACDT®️ Program Curriculum !",
    curriculumctaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/acdt.webp",
    curriculumctatext: "Product Management",
    displayone: "none",
    displaytwo: "block",
    freemodulesdesplay: "none",
    jobandaddon: "none",
    careerassistance: "none",
    ml: "-53px",
    ctasource: "Digital Transformation Brochure",
    programname: "Advanced Certificationin Digital Transformation",
    ctapdf:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IITG-Advanced-Certification-in-Digital-Transformation.pdf",
  },

  /// new program EPPMPMI
  {
    id: "executive-program-in-pmpmi",
    category: "4",
    university: "iitg",
    vertical: "Project Management",
    brochureHeader:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/iitg.png",
    backgroundimage:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/program-page-banner-image/iit2.webp",
    mobbackground:
      "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 99%)",
    programlevel: "Beginner",
    hadding:
      "Executive Program in Product Management and Project Management with E&ICT Academy, IIT Guwahati",
    title:
      "IITG - Executive Program in Product Management and Project Management",
    programduration: "11 Months | Live Instructor led | Weekend Online",
    programdescription:
      "Embark on an 11-term learning journey in Design Thinking, UI/UX Engineering, & Project Management",
    callnumber: "70129-57331",
    stacksone: "E&ICT Academy",
    stacksonedescription: "IIT Guwahati",
    stackstwo: "Live Classes",
    stackstwodescription: "11 Months",
    stacksthree: "Next Cohort",
    stacksthreedescription: "August 2024",
    stackslast: "Career Services ",
    stackslastdescription: "by Accredian",
    progarminclud1: "11 Terms - UI/UX, Agile, Six Sigma",
    progarminclud2: "Duration: 11 Months | Weekend Online",
    progarminclud3: "Live Instructor led classes",
    progarminclud4: "Daily doubt clearing",
    progarminclud5: "Capstone projects",
    progarminclud6: "No Cost EMI upto 12 months",
    progarminclud7: "Placement Support & Assistance",
    progarminclud8: "2-day career immersion at IIT Guwahati",
    progarminclud9: "1:1 career mentorship sessions",
    progarminclud10: "Learn from the top industry professionals",
    progarminclud11: "Certification from E&ICT, IIT Guwahati",
    whoshouldjoin:
      "The program is open to professionals across diverse backgrounds who aspire to enhance their skills in both Product Management and Project Management, offering a comprehensive learning experience for success in today's competitive business environment.",
    Jobopportunities:
      "Graduates of the program can pursue a range of promising career opportunities. These include roles such as Product Manager, Project Manager, Program Manager, and other leadership positions, providing a versatile skill set to thrive in dynamic and challenging professional environments.",
    suchitquotes:
      "In the realm of projects and products, success is not a destination; it's a journey of well-managed milestones.",
    suchitinfo: "-Suchit Majumdar, Chief Product Officer, Accredian",
    Programsyllabustrmtext: "11 Terms",
    Programsyllabustremds: "(11 Terms)",
    Programsyllabusclasstext: "170+ hours of",
    Programsyllabuscareetext: "6 career",
    growt: "80%",
    scholarshipseats: "3",
    nonscholarshipseats: "6",
    setsction: "block",
    accd: [
      {
        hadding: "Term 1 : Introduction to Product Management",
        week: "4 weeks",
        accodingid: "trem1",
        tremone: [
          "Roles & Responsibilities of a PM",
          "User Research for PMs",
          "Market Research for PMs",
          "Competitive Analysis for PMs",
        ],
      },
      {
        hadding: "Term 2 : Product Strategy & Roadmap",
        week: "4 weeks",
        accodingid: "trem2",
        tremone: [
          "What is Product Planning Cycle?",
          "Establishing Product Vision & Mission",
          "Formulating Product Strategy & Product Goals",
          "What is Product Backlog & Roadmap",
        ],
      },
      {
        hadding: "Term 3 : Design Thinking & UI/UX",
        week: "4 weeks",
        accodingid: "trem3",
        tremone: [
          "Design Thinking for PMs",
          "Building MVP - Artefacts, Persona Mapping & PRDs",
          "UI/UX for PMs",
          "Designing Mockups, Wireframes, & Prototypes",
        ],
      },
      {
        hadding: "Term 4 : Product Engineering",
        week: "4 weeks",
        accodingid: "trem4",
        tremone: [
          "Introduction to Engineering for Product Managers",
          "Understanding Microservices & Cloud Services",
          "Waterfall & Agile Methodology",
          "Understanding Scrum & Kanban",
        ],
      },
      {
        hadding: "Term 5 : Product Marketing & Launch",
        week: "4 weeks",
        accodingid: "trem5",
        tremone: [
          // "Product Development & Planning User Tests",
          "Product Launch & A/B Testing",
          "Data Driven Product Marketing & Consumer Behaviour",
          "Measuring Brand & Impact of Product Marketing",
          "Marketing Ninja Challenge",
        ],
      },
      {
        hadding: "Project: Design Discovery",
        week: "",
        accodingid: "trem6",
        tremone: [
          "Design Discovery requires one to apply design thinking, product engineering and marketing concepts to create human-centred and action-oriented solutions. As a PM practitioner you will be competing with the best to solve a complex challenge.",
        ],
      },
      {
        hadding: "Term 6 : Project Management Fundamentals",
        week: "4 weeks",
        accodingid: "trem7",
        tremone: ["Introduction to Project Management", "Project Initiation"],
      },
      {
        hadding: "Term 7 : Project Planning & Execution",
        week: "4 weeks",
        accodingid: "trem8",
        tremone: ["Project Planning", "Project Execution"],
      },
      {
        hadding: "Term 8 : Risk Management & Agile Project Management",
        week: "4 weeks",
        accodingid: "trem9",
        tremone: ["Project Risk Management", "Agile Project Management"],
      },
      {
        hadding: "Term 9 : Contract, Procurement, Closure",
        week: "4 weeks",
        accodingid: "trem10",
        tremone: [
          "Project Procurement and Contract Management",
          "Project Closure and Evaluation",
        ],
      },
      {
        hadding: "Term 10 :  Project Leadership & Advanced Topics",
        week: "4 weeks",
        accodingid: "trem11",
        tremone: [
          "Leadership and Soft Skills for Project Managers",
          "Advanced Topics in Project Management",
        ],
      },
      {
        hadding: "Term 11: Capstone Project",
        week: "",
        accodingid: "trem12",
        tremone: [
          "Professional Development and Certification",
          "Capstone Project",
        ],
      },
    ],
    programtoolhadding: "Tools you will learn",
    progaming: [
      // "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t2.webp",
      // "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t3.webp",
      // "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t4.webp",
      // "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t6.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t7.webp",
      // "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t8.webp",
      // "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t9.webp",
      // "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t10.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/19.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/20.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/21.webp",
    ],
    toolandassinment: (
      <>
        <Typography
          variant="h4"
          component="div"
          align="center"
          sx={{
            fontSize: "23px",
            fontWeight: "bold",
            marginBottom: "0px!important",
          }}
        >
          Capstone Project
        </Typography>
        <Typography
          variant="h4"
          component="div"
          align="center"
          sx={{
            fontSize: "23px",
            fontWeight: "bold",
            marginBottom: "0px!important",
          }}
        >
          Demo Day
        </Typography>
      </>
    ),
    toolandpackge: (
      <>
        <Typography
          variant="h4"
          component="div"
          align="center"
          sx={{
            fontSize: "23px",
            fontWeight: "bold",
            marginBottom: "0px!important",
          }}
        >
          Product Demo
        </Typography>
        <Typography
          variant="h4"
          component="div"
          align="center"
          sx={{
            fontSize: "23px",
            fontWeight: "bold",
            marginBottom: "0px!important",
          }}
        >
          Day
        </Typography>
      </>
    ),
    faculty: [
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/prahlad.webp",
        name: "Prahlad",
        college: "Paypal",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/sai.webp",
        name: "Sai",
        college: "Google",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/Harihara.webp",
        name: "Harihara",
        college: "Amazon",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/shailendra.webp",
        name: "Shailendra",
        college: "Spinny",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/rajib.webp",
        name: "Rajib",
        college: "Walmart",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/Ankur.webp",
        name: "Ankur",
        college: "Archer",
      },
    ],
    cc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/IIT-certificate/eppmpm.webp",
    mc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/PM-certificate/merit/cpm.webp",
    certifictetstate: (
      <Typography sx={{ fontSize: "13.5px", textAlign: "center" }}>
        Professionals upskilled in Product and Project Management skills.
      </Typography>
    ),
    compnyimg: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw6.webp",
    ],
    programadvantage: [
      {
        pdid: "btpa1",
        hadding: " Learning Resources & Technical Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/1.webp",
        details: [
          {
            texthdding:
              "Dedicated resource center with 1000+ resources to ease your learning journey. Daily technical support to resolve your queries.",
            desc: "desc",
          },
        ],
        classname: "btpaimageone",
      },
      {
        pdid: "btpa2",
        hadding: " Industry Immersion with Top Product Leaders",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/2.webp",
        details: [
          {
            haddingtext: "Monthly Speaker Connect",
            texthdding:
              "At Accredian, we bring in leading Product Management professionals from around the globe for monthly lectures. This provides you with practical insights and prepares you for intense competition in the field of Product Management.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
          {
            haddingtext: "International Summits",
            texthdding:
              "Every six months, we organize Product Week Summits inviting leaders from one diverse geography. Most recent one being India Product Week.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa3",
        hadding: "Placement Assistance",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/3.webp",
        details: [
          {
            texthdding:
              "1.Carefully crafted program such as career launchpad which will help the candidates to polish their resume & LinkedIn profiles.",
            desc: "descthre",
          },
          {
            texthdding: "2.Participate in Mock interviews and become job ready",
            desc: "desctwo",
          },
          {
            texthdding:
              "3.Utilise a dedicated job opening portal to find your perfect role.",
            desc: "desctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa4",
        hadding: "Lifelong Community Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/4.webp",
        details: [
          {
            texthdding:
              "Lifetime access to the resources, content and community support to clear your doubts.",
            desc: "lastdesc",
          },
        ],
        classname: "btpaimageone",
      },
    ],
    steponehadding: "Fill the application form",
    steponedes: "Apply by filling a simple online application form",
    steptwohadding: "Eligibility Test",
    steptwodes:
      "Attempt the Eligibility Test and get shortlisted for the admission procedure.",
    steptreehadding: "Start Learning",
    steptreedes:
      "Get your offer letter and access to your dashboard and begin your journey with starter kits.",
    programtotalfee: "2,30,000",
    mothemi12: "16,867",
    mothemi18: "11,695",
    mothemi24: "9,087",
    emi: "EMIs Starting 5k/month",
    studentreviews: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/6.webp",
    ],
    faq: [
      {
        manhadding: "Program Pre-Requisites",
        faqid: "faq1",
        faqtwo: [
          {
            childfaqid: "cfaq1",
            secondhadding:
              "1.Do I need to have prior Product Management and Project Management experience to enroll in the program?",
            faqdetails: [
              "No, the program is designed to be inclusive of all levels of experience. All topics will be covered from the basics, making it suitable for individuals from any field of work.",
            ],
          },
          {
            childfaqid: "cfaq2",
            secondhadding:
              "2.What is the minimum system configuration required?",
            faqdetails: [
              "Any latest processor (i3/i5/i7) with 2.0GHz clock speed and RAM 4GB (required), 8GB(recommended).",
            ],
          },
        ],
      },
      {
        manhadding: "Syllabus & Projects Covered",
        faqid: "faq2",
        faqtwo: [
          {
            childfaqid: "cfaq3",
            secondhadding: "1.How many projects can I anticipate working on?",
            faqdetails: [
              "In Executive Program in Product Management and Project Management Program you will have access to more than 35 case studies, Project: Design Discovery, and Capstone Project to provide a better understanding of the real-world applications of Product Management and Project Management concepts.",
            ],
          },
          {
            childfaqid: "cfaq4",
            secondhadding:
              "2.What's the difference between case studies & projects?",
            faqdetails: [
              "Case studies are targeted towards concept validation and limited to only a single concept. However, projects are targeted to overall learning and are more of a combination of multiple topics.",
            ],
          },
          {
            childfaqid: "cfaq5",
            secondhadding:
              "3.What all topics are covered in the Executive Program in Product Management and Project Management Program ",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Download our latest program{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  brochure
                </Typography>{" "}
                &{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  curriculum.
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
      {
        manhadding: "Time Commitment",
        faqid: "faq3",
        faqtwo: [
          {
            childfaqid: "cfaq6",
            secondhadding:
              "1.How much time does one need to spend on a regular basis?",
            faqdetails: [
              "Daily 30 mins of prep time is required. You can choose to practice, read or watch videos. Additionally, on weekends, participants must attend 2 hours of live classes on both Saturday and Sunday for Product Management, as well as 3-hour live classes on Sunday for Project Management.",
            ],
          },
          {
            childfaqid: "cfaq7",
            secondhadding: "2.What is the duration of the program?",
            faqdetails: [
              "Every term is for 4-5 weeks approximately. And the overall Executive Program in Product Management and Project Management  duration is 11 months.",
            ],
          },
        ],
      },

      {
        manhadding: "Why IIT Guwahati ?",
        faqid: "faq5",
        faqtwo: [
          {
            childfaqid: "cfaq10",
            secondhadding:
              "1.How's the Executive Program in Product Management and Project Management Program different?",
            faqdetails: [
              <>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  This program is distinguished by three key elements:
                </Typography>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  1. <b>Iterative Mastery Approach:</b> IITG's exclusive
                  Iterative Mastery Approach ensures development through a
                  step-by-step methodology, specifically designed to welcome
                  individuals from diverse backgrounds. Whether you're seeking a
                  lateral move in the industry or looking to broaden your
                  skills, our program is tailored to empower you on your unique
                  learning journey.
                </Typography>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  <Typography sx={{ marginBottom: "0.5rem !important" }}>
                    2. <b>Versatile Skill Development Model:</b> IIT G's unique
                    framework ensures that every Product Management and Project
                    Management concept is taught in the context of real-world
                    industry problems. Our program goes beyond theory,
                    emphasizing practical case studies to prepare students for
                    the challenges they'll face in the professional arena.
                  </Typography>
                </Typography>
                <Typography sx={{ marginBottom: "4px !important" }}>
                  3. <b>Accredian Career Center:</b> Aligned with our mission to
                  cultivate the Data leaders of tomorrow, the Accredian Career
                  Center empowers you to confidently launch your Data Science
                  career.
                </Typography>
              </>,
            ],
          },
          {
            childfaqid: "cfaq11",
            secondhadding: "2.Tell me more about Career Assistance?",
            faqdetails: [
              "Accredian offers career assistance through Product Management Career Launchpad. The student gets hands-on experience as a Product Leader with rigorous resume and Profile building sessions. You will also learn to create a strong LinkedIn presence and develop top notch interview skills.",
            ],
          },
          {
            childfaqid: "cfaq12",
            secondhadding: "3. What's the refund policy?",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Go through our Student Policy{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  <Link
                    to="/policies/iitg-policy"
                    style={{ textDecoration: "none" }}
                  >
                    link
                  </Link>
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
    ],

    brochurectaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/eppmpm.webp",
    brochurectahadding: "Download EPPMPMI®️ Program Brochure!",
    curriculumctahadding: "Download EPPMDT®️ Program Curriculum !",
    curriculumctaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/eppmdt.webp",
    curriculumctatext: "Product Management",
    displayone: "none",
    displaytwo: "block",
    freemodulesdesplay: "none",
    jobandaddon: "none",
    careerassistance: "none",
    ml: "-53px",
    ctasource: "Product Management Brochure",
    programname:
      "Executive Program in Product Management and Project Management",
    ctapdf:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IITG-PMPMI-Brochure.pdf",
  },

  //new program DSPMI
  {
    id: "executive-program-in-dspmi",
    category: "2",
    university: "iitg",
    vertical: "Project Management",
    brochureHeader:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/iitg.png",
    backgroundimage:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/program-page-banner-image/iita.webp",
    mobbackground:
      "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 99%)",
    programlevel: "Beginner",
    hadding:
      "Executive Program in Data Science and Project Management with E&ICT Academy, IIT Guwahati ",
    title: "IITG - Executive Program in Data Science and Project Management",
    programduration: "12 Months | Live Instructor led | Weekend Online",
    programdescription:
      "Embark on a 12- term learning journey in Data Analytics, Machine Learning, Project Management.",
    callnumber: "90796-53292",
    stacksone: "E&ICT Academy",
    stacksonedescription: "IIT Guwahati",
    stackstwo: "Live Classes",
    stackstwodescription: "12 Months",
    stacksthree: "Next Cohort",
    stacksthreedescription: "August 2024",
    stackslast: "Career Services ",
    stackslastdescription: "by Accredian",
    progarminclud1: "12 Terms - Analytics, ML, Project Management",
    progarminclud2: "Duration: 12 Months | Weekend Online",
    progarminclud3: "Live Instructor led classes",
    progarminclud4: "2-day Campus Immersion at IIT Guwahati *",
    progarminclud5: "Capstone projects",
    progarminclud6: "No Cost EMI upto 12 months",
    progarminclud7: "Placement Support & Assistance",
    progarminclud8: "Product Demo Day",
    progarminclud9: "1:1 career mentorship sessions",
    progarminclud10: "Learn from the top industry professionals",
    progarminclud11: "Certification from E&ICT, IIT Guwahati",
    whoshouldjoin:
      "Professionals aiming to enhance their skills in both Data Science and Project Management should join this program. This includes individuals working in technology, analytics, business, and related fields, as well as those seeking to transition into roles that involve both data science and project management.",
    Jobopportunities:
      "Completing this course opens doors to diverse job opportunities. Graduates can pursue roles such as Data Scientist, Project Manager, Business Analyst, and more. The versatile skill set acquired in this program equips individuals to excel in various sectors, meeting the demands of dynamic and evolving professional landscapes.",
    suchitquotes:
      "Data Science and Project Management: a dynamic duo that turns information into innovation, and plans into powerful, data-driven actions.",
    suchitinfo: "-Suchit Majumdar, Chief Product Officer, Accredian",
    Programsyllabustrmtext: "12 Terms",
    Programsyllabustremds: "(12  Months)",
    Programsyllabusclasstext: "190+ hours of",
    Programsyllabuscareetext: "6 career",
    growt: "80%",
    scholarshipseats: "3",
    nonscholarshipseats: "7",
    setsction: "block",
    accd: [
      {
        hadding: "Term 1 : Data Analysis with Python",
        week: "4 weeks",
        accodingid: "trem1",
        tremone: [
          "Data Science Fundamentals  ",
          // "Recap: Python for Data Science",
          // "Recap: Statistics for Data Science",
          "Data Operations with Numpy",
          "Data Manipulation with Pandas",
        ],
      },
      {
        hadding: "Term 2 : Data Visualization Techniques",
        week: "4 weeks",
        accodingid: "trem2",
        tremone: [
          "Introduction to Data Visualization",
          "Data Visualization using Matplotlib",
          "Hands-on Pandas for Rapid Visualization",
          "Seaborn for Data Visualization",
        ],
      },
      {
        hadding: "Term 3 :  EDA & Data Storytelling",
        week: "4 weeks",
        accodingid: "trem3",
        tremone: [
          "Introduction to Exploratory Data Analysis",
          "EDA Framework Deep Dive",
          "Scientific Exploration of Industry Data (Case Study)",
          "Student Presentations & Insight Delivery",
        ],
      },
      {
        hadding: "Minor Capstone Project",
        week: "",
        accodingid: "trem4",
        tremone: [
          "In a simulated environment, you get to work with a major Telecom company that seeks help from your team of Data Scientists to resolve a major marketing hurdle. Get a near real world exposure of working on industry problems within data science teams",
        ],
      },
      {
        hadding: "Term 4 : Machine Learning Foundation",
        week: "4 weeks",
        accodingid: "trem5",
        tremone: [
          "Introduction to Machine Learning (ML)",
          "Linear Regression",
          "Logistic Regression",
          "Model Evaluation Techniques",
        ],
      },
      {
        hadding: "Term 5 : Machine Learning Intermediate",
        week: "4 weeks",
        accodingid: "trem6",
        tremone: [
          "Decision Trees",
          "Random Forests",
          "Dimensionality Reduction Using Principal Component Analysis(PCA)",
          "Naïve Bayes Classifier",
        ],
      },
      {
        hadding: "Term 6 : Machine Learning Advanced",
        week: "4 weeks",
        accodingid: "trem7",
        tremone: [
          "KNN (K- Nearest neighbors)",
          "K-means Clustering",
          "Ensemble Learning",
          "Optimization",
        ],
      },
      {
        hadding: "Major Capstone Project",
        week: "",
        accodingid: "trem8",
        tremone: [
          "Experience a real world simulation of a company Employee Retention Program that needs to be solved using Data Science and Machine Learning.Work with a team of Data Scientists and experience real-world problem solving through Data Science",
        ],
      },
      {
        hadding: "Term 7 : Project Management Fundamentals",
        week: "4 weeks",
        accodingid: "trem9",
        tremone: ["Introduction to Project Management", "Project Initiation"],
      },
      {
        hadding: "Term 8 : Project Planning & Execution",
        week: "4 weeks",
        accodingid: "trem10",
        tremone: ["Project Planning", "Project Execution"],
      },
      {
        hadding: "Term 9 :  Risk Management & Agile Project Management",
        week: "4 weeks",
        accodingid: "trem11",
        tremone: ["Project Risk Management", "Agile Project Management"],
      },
      {
        hadding: "Term 10 : Contract, Procurement, Closure",
        week: "4 weeks",
        accodingid: "trem12",
        tremone: [
          "Project Procurement and Contract Management",
          "Project Closure and Evaluation",
        ],
      },
      {
        hadding: "Term 11 : Project Leadership & Advanced Topics",
        week: "4 weeks",
        accodingid: "trem13",
        tremone: [
          "Leadership and Soft Skills for Project Managers",
          "Advanced Topics in Project Management",
        ],
      },
      {
        hadding: "Term 12: Capstone Project",
        week: "4 weeks",
        accodingid: "trem14",
        tremone: [
          "Professional Development and Certification",
          "Capstone Project",
        ],
      },
    ],
    programtoolhadding: "Tools you will learn",
    progaming: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/cpm/t6.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/19.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/20.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/21.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/3.webp",
      // "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/6.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/7.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/8.webp",
    ],
    toolandassinment: "Topic wise Assignments",
    toolandpackge: "Capstone Project Demo Day",
    faculty: [
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Deepesh.webp",
        name: "Deepesh",
        college: "IIM-Calcutta",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Nishkam.webp",
        name: "Nishkam",
        college: "Paypal",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Gaurav-Trivedi-1.webp",
        name: "Gaurav",
        college: "IIT Guwahati",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/prahlad.webp",
        name: "Prahlad",
        college: "Paypal",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/sai.webp",
        name: "Sai",
        college: "Google",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/Harihara.webp",
        name: "Harihara",
        college: "Amazon",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/shailendra.webp",
        name: "Shailendra",
        college: "Spinny",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/rajib.webp",
        name: "Rajib",
        college: "Walmart",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/PM-Faculty/Ankur.webp",
        name: "Ankur",
        college: "Archer",
      },
    ],
    cc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/IIT-certificate/epdspmi.webp",
    mc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/PM-certificate/merit/cpm.webp",
    certifictetstate: (
      <Typography sx={{ fontSize: "13.5px", textAlign: "center" }}>
        Professionals upskilled in Data Science & Project Management skillset
      </Typography>
    ),
    compnyimg: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw6.webp",
    ],
    programadvantage: [
      {
        pdid: "btpa1",
        hadding: "Learning Resources & Technical Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/1.webp",
        details: [
          {
            texthdding:
              "Dedicated resource center with 1000+ resources to ease your learning journey. Daily technical support to resolve your queries.",
            desc: "desc",
          },
        ],
        classname: "btpaimageone",
      },
      {
        pdid: "btpa2",
        hadding: "Industry Immersion with Top Product Leaders",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/2.webp",
        details: [
          {
            haddingtext: "Monthly Speaker Connect",
            texthdding:
              "At Accredian, we bring in leading Project Management professionals from around the globe for monthly lectures. This provides you with practical insights and prepares you for intense competition in the field of Data Science and Project Management.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
          {
            haddingtext: "International Summits",
            texthdding:
              "We regularly arrange Project Week Summits every six months, where we invite leaders from across the world. The latest summit was the India Product Week.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa3",
        hadding: "Placement Assistance",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/3.webp",
        details: [
          {
            texthdding:
              "1.Carefully crafted program such as career launchpad which will help the candidates to polish their resume & LinkedIn profiles.",
            desc: "descthre",
          },
          {
            texthdding: "2.Participate in Mock interviews and become job ready",
            desc: "desctwo",
          },
          {
            texthdding:
              "3.Utilise a dedicated job opening portal to find your perfect role.",
            desc: "desctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa4",
        hadding: "Lifelong Community Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/4.webp",
        details: [
          {
            texthdding:
              "Lifetime access to the resources, content and community support to clear your doubts.",
            desc: "lastdesc",
          },
        ],
        classname: "btpaimageone",
      },
    ],
    steponehadding: "Fill the application form",
    steponedes: "Apply by filling a simple online application form",
    steptwohadding: "Eligibility Test",
    steptwodes:
      "Attempt the Eligibility Test and get shortlisted for the admission procedure.",
    steptreehadding: "Start Learning",
    steptreedes:
      "Get your offer letter and access to your dashboard and begin your journey with starter kits.",
    programtotalfee: "2,20,000",
    mothemi12: "16,217",
    mothemi18: "11,244",
    mothemi24: "8,737",
    emi: "EMIs Starting 5k/month",
    studentreviews: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/PM/6.webp",
    ],
    faq: [
      {
        manhadding: "Program Pre-Requisites",
        faqid: "faq1",
        faqtwo: [
          {
            childfaqid: "cfaq1",
            secondhadding:
              "1.Is prior experience in Data Science or Project Management required for program enrollment?",
            faqdetails: [
              "No, the program is designed to be inclusive of all levels of experience. All topics will be covered from the basics, making it suitable for individuals from any field of work.",
            ],
          },
          {
            childfaqid: "cfaq2",
            secondhadding:
              "2.What is the minimum system configuration required?",
            faqdetails: [
              "Any latest processor (i3/i5/i7) with 2.0GHz clock speed and RAM 4GB (required), 8GB(recommended).",
            ],
          },
        ],
      },
      {
        manhadding: "Syllabus & Projects Covered",
        faqid: "faq2",
        faqtwo: [
          {
            childfaqid: "cfaq3",
            secondhadding: "1.How many projects can I anticipate working on?",
            faqdetails: [
              "In Executive Program in Data Science and Project Management, you will have access to more than 35 case studies, Major Project, and Capstone Project to provide a better understanding of the real-world applications of Data Science and Project Management concepts.",
            ],
          },
          {
            childfaqid: "cfaq4",
            secondhadding:
              "2.What's the difference between case studies & projects?",
            faqdetails: [
              "Case studies are targeted towards concept validation and limited to only a single concept. However, projects are targeted to overall learning and are more of a combination of multiple topics.",
            ],
          },
          {
            childfaqid: "cfaq5",
            secondhadding:
              "3.What all topics are covered in the Executive Program in Data Science and Project Management Program",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Download our latest program{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  brochure
                </Typography>{" "}
                &{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  curriculum.
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
      {
        manhadding: "Time Commitment",
        faqid: "faq3",
        faqtwo: [
          {
            childfaqid: "cfaq6",
            secondhadding:
              "1.How much time does one need to spend on a regular basis?",
            faqdetails: [
              "Daily 30 mins of prep time is required. You can choose to practice, read or watch videos. Additionally, on weekends, participants must attend 2 hours of live classes on both Saturday and Sunday for Data Science, as well as 3-hour live classes on Sunday for Project Management.",
            ],
          },
          {
            childfaqid: "cfaq7",
            secondhadding: "2.What is the duration of the program?",
            faqdetails: [
              "The duration of the program is 12 months, every term is approximately 4-5 weeks long.",
            ],
          },
        ],
      },

      {
        manhadding: "Why IIT Guwahati ?",
        faqid: "faq5",
        faqtwo: [
          {
            childfaqid: "cfaq10",
            secondhadding:
              "1.How's the Executive Program in Data Science and Project Management program different?",
            faqdetails: [
              <>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  This program is distinguished by three key elements:
                </Typography>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  1. <b>Iterative Mastery Approach:</b> IITG's exclusive
                  Iterative Mastery Approach ensures development through a
                  step-by-step methodology, specifically designed to welcome
                  individuals from diverse backgrounds. Whether you're seeking a
                  lateral move in the industry or looking to broaden your
                  skills, our program is tailored to empower you on your unique
                  learning journey.
                </Typography>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  <Typography sx={{ marginBottom: "0.5rem !important" }}>
                    2. <b>Versatile Skill Development Model:</b> IIT G's unique
                    framework ensures that every Data Science and Project
                    Management concept is taught in the context of real-world
                    industry problems. Our program goes beyond theory,
                    emphasizing practical case studies to prepare students for
                    the challenges they'll face in the professional arena.
                  </Typography>
                </Typography>
                <Typography sx={{ marginBottom: "4px !important" }}>
                  3. <b>Accredian Career Center:</b> Aligned with our mission to
                  cultivate the Data leaders of tomorrow, the Accredian Career
                  Center empowers you to confidently launch your Data Science
                  career.
                </Typography>
              </>,
            ],
          },
          {
            childfaqid: "cfaq11",
            secondhadding: "2.Tell me more about Career Assistance?",
            faqdetails: [
              "Accredian provides career assistance through the Career Launchpad program. Students gain hands-on experience as leaders in their chosen field with rigorous resume and portfolio building sessions. The program also covers creating a strong online presence and developing top-notch interview skills.",
            ],
          },
          {
            childfaqid: "cfaq12",
            secondhadding: "3. What's the refund policy?",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Go through our Student Policy{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  <Link
                    to="/policies/iitg-policy"
                    style={{ textDecoration: "none" }}
                  >
                    link
                  </Link>
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
    ],

    brochurectaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/epdspmi.webp",
    brochurectahadding: "Download EPDSPMI Program Brochure!",
    curriculumctahadding: "Download EPPMDS Program Curriculum !",
    curriculumctaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/epdspmi.webp",
    curriculumctatext: "Product Management",
    displayone: "none",
    displaytwo: "block",
    freemodulesdesplay: "none",
    jobandaddon: "none",
    careerassistance: "none",
    ml: "-53px",
    ctasource: "Project Management Brochure",
    programname: "Executive Program in Data Science and Project Management",
    ctapdf:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IIT-Executive-Program-DS-%26-PI-11-months.pdf",
  },
  // ------- Professional Certificate Program in Business Analytics for Managers -------
  {
    id: "professional-certificate-program-in-business-analytics-for-managers",
    category: "27",
    university: "iitg",
    vertical: "business analytics",
    brochureHeader:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/iitg.png",
    backgroundimage:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/program-page-banner-image/iita.webp",
    mobbackground:
      "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 99%)",
    programlevel: "Beginner",
    hadding:
      "Professional Certificate Program in Business Analytics for Managers",
    title:
      "IITG - Professional Certificate Program in Business Analytics for Managers",
    programduration: "6 Months | Live Instructor led | Weekend Online",
    programdescription:
      "Embark on an 5-term learning journey in Data Analytics, ML & Business Analytics.",
    callnumber: "90796-53292",
    stacksone: "E&ICT Academy",
    stacksonedescription: "IIT Guwahati",
    stackstwo: "Live Classes",
    stackstwodescription: "6 Months",
    stacksthree: "Next Cohort",
    stacksthreedescription: "August 2024",
    stackslast: "Career Services ",
    stackslastdescription: "by Accredian",
    progarminclud1: "5 Terms - Data Analytics, ML & Business Analytics",
    progarminclud2: "Duration: 6 Months | Weekend Online",
    progarminclud3: "Live Instructor led classes",
    progarminclud4: "Daily doubt clearing",
    progarminclud5: "Capstone projects",
    progarminclud6: "No Cost EMI upto 12 months",
    progarminclud7: "Placement Support & Assistance",
    progarminclud9: "1:1 career mentorship sessions",
    progarminclud10: "Learn from the top industry professionals",
    progarminclud11: "Certification from E&ICT, IIT Guwahati",
    whoshouldjoin:
      "Tailored for technology, business, and analytics professionals, as well as those transitioning into data science and business analysis roles, our Executive Program is ideal for skill advancement in these dynamic fields.",
    Jobopportunities:
      "After completing this course, participants can explore lucrative job opportunities in roles such as Data Scientist, Business Manager, Business Analyst, and more, across diverse industries, leveraging their enhanced skills in Data Science and Business Analytics.",
    suchitquotes:
      "Every individual from various backgrounds must learn Analytics to outshine in the competitive world today!",
    suchitinfo: "-Suchit Majumdar, Chief Data Science Mentor, Accredian.    ",
    Programsyllabustrmtext: "5 Terms",
    Programsyllabustremds: "(5 Terms)",
    Programsyllabusclasstext: "75+ hours of",
    Programsyllabuscareetext: "8 career",
    growt: "80%",
    scholarshipseats: "2",
    nonscholarshipseats: "5",
    setsction: "block",
    accd: [
      {
        hadding: "Term1: Foundation of Business Analytics",
        week: "4 weeks",
        accodingid: "term1",
        tremone: [
          "Introduction to Business Analytics",
          " Data Analytics Fundamentals for Business Analysts",
          " Statistics Basics for Business",
          " Advanced Business Statistics",
        ],
      },
      {
        hadding: "Term 2: Excel Mastery for Business Insights",
        week: "4 weeks",
        accodingid: "term2",
        tremone: [
          ": Excel fundamentals for Business Analysis",
          " Excel Functions & Conditions",
          " Excel Pivot Tables & Reporting",
          " Excel Modelling",
        ],
      },
      {
        hadding: "Term 3: SQL Essentials for Business Intelligence",
        week: "4 weeks",
        accodingid: "term3",
        tremone: [
          " Database Basics for Analysts",
          " SQL Fundamentals for Business Analysis",
          " SQL Operations",
          " Advanced SQL",
        ],
      },
      {
        hadding: "Term 4: Business Analytics with Power BI",
        week: "5 weeks",
        accodingid: "term4",
        tremone: [
          "Power BI for Business reporting",
          "Connecting Data Sources",
          " Data Modelling in Power BI",
          " Functions in Power BI",
          " Basics of Report Building",
        ],
      },
      {
        hadding: "Term 5: Applied Industry Analytics: Case Studies",
        week: "4 weeks",
        accodingid: "term5",
        tremone: [
          " Business Analytics in BFSI",
          " Marketing & Telecom Analytics",
          " Healthcare & Manufacturing Analytics",
          " Ecommerce & Supply Chain Analytics",
        ],
      },
    ],
    programtoolhadding: "Tools you will learn",
    progaming: [
      // "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/7.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/9.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/7.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/9.webp",
    ],
    toolandassinment: "Topic wise Assignments",
    toolandpackge: "3+ Tools and Packages",
    faculty: [
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Deepesh.webp",
        name: "Deepesh",
        college: "IIM-Calcutta",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Nishkam.webp",
        name: "Nishkam",
        college: "Paypal",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/amit-goyal.webp",
        name: "Amit",
        college: "Paytm",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Gaurav-Trivedi-1.webp",
        name: "Gaurav",
        college: "IIT Guwahati",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Lavi.webp",
        name: "Lavi",
        college: "Google",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/subhodeep.webp",
        name: "Subhodeep",
        college: "Jio",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Neelmani.webp",
        name: "Neelmani",
        college: "Gojek",
      },
    ],
    cc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/IIT-certificate/dsba.webp",
    mc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/PM-certificate/merit/cpm.webp",
    certifictetstate: (
      <Typography sx={{ fontSize: "13.5px", textAlign: "center" }}>
        Professionals upskilled in Data Science & Business Analytics skillset
      </Typography>
    ),
    compnyimg: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw6.webp",
    ],
    programadvantage: [
      {
        pdid: "btpa1",
        hadding: " Learning Resources & Technical Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/1.webp",
        details: [
          {
            texthdding:
              "Dedicated resource center with 1000+ resources to ease your learning journey. Daily technical support to resolve your queries.",
            desc: "desc",
          },
        ],
        classname: "btpaimageone",
      },
      {
        pdid: "btpa2",
        hadding: " Industry Immersion with Top Data Science Leaders",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/2.webp",
        details: [
          {
            haddingtext: "Monthly Speaker Connect",
            texthdding:
              "At Accredian, we feature monthly lectures led by leading professionals worldwide. This offers you practical insights and readies you for the intense competition in the field.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
          {
            haddingtext: "International Summits",
            texthdding:
              "Every six months, we usually organize Data Science Summits inviting leaders from one entire geography. Most recent one being SEA - South East Asia Conference.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa3",
        hadding: "Placement Assistance",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/3.webp",
        details: [
          {
            texthdding:
              "1.Carefully crafted program, such as the ‘Career Launchpad,’ is tailored to assist candidates in refining their resumes & profiles.",
            desc: "descthre",
          },
          {
            texthdding: "2.Participate in Mock interviews and become job ready",
            desc: "desctwo",
          },
          {
            texthdding:
              "3.Utilise a dedicated job opening portal to find your perfect role.",
            desc: "desctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa4",
        hadding: "Lifelong Community Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/4.webp",
        details: [
          {
            texthdding:
              "Lifetime access to the resources, content and community support to clear your doubts.",
            desc: "lastdesc",
          },
        ],
        classname: "btpaimageone",
      },
    ],
    steponehadding: "Fill the application form",
    steponedes: "Apply by filling a simple online application form",
    steptwohadding: "Eligibility Test",
    steptwodes:
      "Attempt the Eligibility Test and get shortlisted for the admission procedure.",
    steptreehadding: "Start Learning",
    steptreedes:
      "Get your offer letter and access to your dashboard and begin your journey with starter kits.",
    programtotalfee: "1,20,000",
    mothemi12: "9,984",
    mothemi18: "6,922",
    mothemi24: "5,379",
    emi: "EMIs Starting 5k/month",
    studentreviews: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/6.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/7.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/8.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/9.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/10.webp",
    ],
    faq: [
      {
        manhadding: "Program Pre-Requisites",
        faqid: "faq1",
        faqtwo: [
          {
            childfaqid: "cfaq1",
            secondhadding:
              "1.Do I need to have prior Business Analytics experience to enroll in the program?",
            faqdetails: [
              "No, the program is designed to be inclusive of all levels of experience. All topics will be covered from the basics, making it suitable for individuals from any field of work.",
            ],
          },
          {
            childfaqid: "cfaq2",
            secondhadding:
              "2.What is the minimum system configuration required?",
            faqdetails: [
              "Any latest processor (i3/i5/i7) with 2.0GHz clock speed and RAM 4GB (required), 8GB(recommended).",
            ],
          },
        ],
      },
      {
        manhadding: "Syllabus & Projects Covered",
        faqid: "faq2",
        faqtwo: [
          {
            childfaqid: "cfaq3",
            secondhadding: "1.How many projects can I anticipate working on?",
            faqdetails: [
              "In Professional Certificate Program in Business Analytics for Managers you will have access to more than 35 case studies, Capstone Projects to provide a better understanding of the real-world applications of Business Analytics.",
            ],
          },
          {
            childfaqid: "cfaq4",
            secondhadding:
              "2.What's the difference between case studies & projects?",
            faqdetails: [
              "Case studies are targeted towards concept validation and limited to only a single concept. However, projects are targeted to overall learning and are more of a combination of multiple topics.",
            ],
          },
          {
            childfaqid: "cfaq5",
            secondhadding:
              "3.What all topics are covered in the Professional Certificate Program in Business Analytics for Managers",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Download our latest program{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  brochure
                </Typography>{" "}
                &{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  curriculum.
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
      {
        manhadding: "Time Commitment",
        faqid: "faq3",
        faqtwo: [
          {
            childfaqid: "cfaq6",
            secondhadding:
              "1.How much time does one need to spend on a regular basis?",
            faqdetails: [
              "Daily 30 mins of prep time is required. You can choose to practice, read or watch videos. Additionally, on weekends one must attend 2 hours of live classes on both Saturday & Sunday.",
            ],
          },
          {
            childfaqid: "cfaq7",
            secondhadding: "2.What is the duration of the program?",
            faqdetails: [
              "Every term is for 4-5 weeks approximately. And the overall Professional Certificate Program in Business Analytics for Managers duration is 6 months.",
            ],
          },
        ],
      },

      {
        manhadding: "Why IIT Guwahati ?",
        faqid: "faq5",
        faqtwo: [
          {
            childfaqid: "cfaq10",
            secondhadding:
              "1.How's the Professional Certificate Program in Business Analytics for Managers program different?",
            faqdetails: [
              <>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  This program is distinguished by three key elements:
                </Typography>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  1. <b>Iterative Mastery Approach:</b> IITG's exclusive
                  Iterative Mastery Approach ensures development through a
                  step-by-step methodology, specifically designed to welcome
                  individuals from diverse backgrounds. Whether you're seeking a
                  lateral move in the industry or looking to broaden your
                  skills, our program is tailored to empower you on your unique
                  learning journey.
                </Typography>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  <Typography sx={{ marginBottom: "0.5rem !important" }}>
                    2. <b>Versatile Skill Development Model:</b> IIT G's unique
                    framework ensures that every Data Science and BA concept is
                    taught in the context of real-world industry problems. Our
                    program goes beyond theory, emphasizing practical case
                    studies to prepare students for the challenges they'll face
                    in the professional arena.
                  </Typography>
                </Typography>
                <Typography sx={{ marginBottom: "4px !important" }}>
                  3. <b>Accredian Career Center:</b> Aligned with our mission to
                  cultivate the Data leaders of tomorrow, the Accredian Career
                  Center empowers you to confidently launch your Data Science
                  career.
                </Typography>
              </>,
            ],
          },
          {
            childfaqid: "cfaq11",
            secondhadding: "2.Tell me more about Career Assistance?",
            faqdetails: [
              "Accredian provides career assistance through the Career Launchpad program. Students gain hands-on experience as leaders in their chosen field with rigorous resume and portfolio building sessions. The program also covers creating a strong online presence and developing top-notch interview skills.",
            ],
          },
          {
            childfaqid: "cfaq12",
            secondhadding: "3. What's the refund policy?",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Go through our Student Policy{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  <Link
                    to="/policies/iitg-policy"
                    style={{ textDecoration: "none" }}
                  >
                    link
                  </Link>
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
    ],

    brochurectaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/epdadb.webp",
    brochurectahadding: "Download EPDSBA®️ Program Brochure!",
    curriculumctahadding: "Download EPDSBA®️ Program Curriculum !",
    curriculumctaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/epdadb.webp",
    curriculumctatext: "Business Analytics",
    displayone: "block",
    displaytwo: "none",
    freemodulesdesplay: "none",
    jobandaddon: "none",
    careerassistance: "none",
    ml: "-53px",
    ctasource: "Business Analytics Brochure",
    programname:
      "Professional Certificate Program in Business Analytics for Managers",
    ctapdf:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/Professional-Certification-Program-in-BA-5-months.pdf",
  },
  // ------- Professional Certificate Program in Digital Transformation -------
  {
    id: "professional-certificate-program-in-digital-transformation",
    category: "27",
    university: "iitg",
    vertical: "digital transformation",
    brochureHeader:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/iitg.png",
    backgroundimage:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/program-page-banner-image/iita.webp",
    mobbackground:
      "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 99%)",
    programlevel: "Beginner",
    hadding: "Professional Certificate Program in Digital Transformation",
    title: "IITG - Professional Certificate Program in Digital Transformation",
    programduration: "6 Months | Live Instructor led | Weekend Online",
    programdescription:
      "Elevate your organization's growth with insights into digital transformation strategies from a top-tier institution with Executive Alumni Status",
    callnumber: "90796-53292",
    stacksone: "E&ICT Academy",
    stacksonedescription: "IIT Guwahati",
    stackstwo: "Live Classes",
    stackstwodescription: "6 Months",
    stacksthree: "Next Cohort",
    stacksthreedescription: "August 2024",
    stackslast: "Career Services ",
    stackslastdescription: "by Accredian",
    progarminclud1: "5 Terms - Data Analytics, ML & Business Analytics",
    progarminclud2: "Duration: 6 Months | Weekend Online",
    progarminclud3: "Live Instructor led classes",
    progarminclud4: "Daily doubt clearing",
    progarminclud5: "Capstone projects",
    progarminclud6: "No Cost EMI upto 12 months",
    progarminclud7: "Placement Support & Assistance",
    progarminclud9: "1:1 career mentorship sessions",
    progarminclud10: "Learn from the top industry professionals",
    progarminclud11: "Certification from E&ICT, IIT Guwahati",
    whoshouldjoin:
      "Tailored for technology, business, and analytics professionals, as well as those transitioning into data science and business analysis roles, our Executive Program is ideal for skill advancement in these dynamic fields.",
    Jobopportunities:
      "After completing this course, participants can explore lucrative job opportunities in roles such as Data Scientist, Business Manager, Business Analyst, and more, across diverse industries, leveraging their enhanced skills in Data Science and Business Analytics.",
    suchitquotes:
      "Every individual from various backgrounds must learn Analytics to outshine in the competitive world today!",
    suchitinfo: "-Suchit Majumdar, Chief Data Science Mentor, Accredian.",
    Programsyllabustrmtext: "5 Terms",
    Programsyllabustremds: "(5 Terms)",
    Programsyllabusclasstext: "70+ hours of",
    Programsyllabuscareetext: "8 career",
    growt: "80%",
    scholarshipseats: "2",
    nonscholarshipseats: "5",
    setsction: "block",
    accd: [
      {
        hadding: "Term1: Foundation of Business Analytics",
        week: "4 weeks",
        accodingid: "term1",
        tremone: [
          "Introduction to Business Analytics",
          " Data Analytics Fundamentals for Business Analysts",
          " Statistics Basics for Business",
          " Advanced Business Statistics",
        ],
      },
      {
        hadding: "Term 2: Excel Mastery for Business Insights",
        week: "4 weeks",
        accodingid: "term2",
        tremone: [
          ": Excel fundamentals for Business Analysis",
          " Excel Functions & Conditions",
          " Excel Pivot Tables & Reporting",
          " Excel Modelling",
        ],
      },
      {
        hadding: "Term 3: SQL Essentials for Business Intelligence",
        week: "4 weeks",
        accodingid: "term3",
        tremone: [
          " Database Basics for Analysts",
          " SQL Fundamentals for Business Analysis",
          " SQL Operations",
          " Advanced SQL",
        ],
      },
      {
        hadding: "Term 4: Business Analytics with Power BI",
        week: "5 weeks",
        accodingid: "term4",
        tremone: [
          "Power BI for Business reporting",
          "Connecting Data Sources",
          " Data Modelling in Power BI",
          " Functions in Power BI",
          " Basics of Report Building",
        ],
      },
      {
        hadding: "Term 5: Applied Industry Analytics: Case Studies",
        week: "4 weeks",
        accodingid: "term5",
        tremone: [
          " Business Analytics in BFSI",
          " Marketing & Telecom Analytics",
          " Healthcare & Manufacturing Analytics",
          " Ecommerce & Supply Chain Analytics",
        ],
      },
    ],
    programtoolhadding: "Tools you will learn",
    progaming: [
      // "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/7.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/9.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/7.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/9.webp",
    ],
    toolandassinment: "Topic wise Assignments",
    toolandpackge: "3+ Tools and Packages",
    faculty: [
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Deepesh.webp",
        name: "Deepesh",
        college: "IIM-Calcutta",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Nishkam.webp",
        name: "Nishkam",
        college: "Paypal",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/amit-goyal.webp",
        name: "Amit",
        college: "Paytm",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Gaurav-Trivedi-1.webp",
        name: "Gaurav",
        college: "IIT Guwahati",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Lavi.webp",
        name: "Lavi",
        college: "Google",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/subhodeep.webp",
        name: "Subhodeep",
        college: "Jio",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Neelmani.webp",
        name: "Neelmani",
        college: "Gojek",
      },
    ],
    cc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/IIT-certificate/dsba.webp",
    mc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/PM-certificate/merit/cpm.webp",
    certifictetstate: (
      <Typography sx={{ fontSize: "13.5px", textAlign: "center" }}>
        Professionals upskilled in Data Science & Business Analytics skillset
      </Typography>
    ),
    compnyimg: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/PM/sw5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw6.webp",
    ],
    programadvantage: [
      {
        pdid: "btpa1",
        hadding: " Learning Resources & Technical Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/1.webp",
        details: [
          {
            texthdding:
              "Dedicated resource center with 1000+ resources to ease your learning journey. Daily technical support to resolve your queries.",
            desc: "desc",
          },
        ],
        classname: "btpaimageone",
      },
      {
        pdid: "btpa2",
        hadding: " Industry Immersion with Top Data Science Leaders",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/2.webp",
        details: [
          {
            haddingtext: "Monthly Speaker Connect",
            texthdding:
              "At Accredian, we feature monthly lectures led by leading professionals worldwide. This offers you practical insights and readies you for the intense competition in the field.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
          {
            haddingtext: "International Summits",
            texthdding:
              "Every six months, we usually organize Data Science Summits inviting leaders from one entire geography. Most recent one being SEA - South East Asia Conference.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa3",
        hadding: "Placement Assistance",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/3.webp",
        details: [
          {
            texthdding:
              "1.Carefully crafted program, such as the ‘Career Launchpad,’ is tailored to assist candidates in refining their resumes & profiles.",
            desc: "descthre",
          },
          {
            texthdding: "2.Participate in Mock interviews and become job ready",
            desc: "desctwo",
          },
          {
            texthdding:
              "3.Utilise a dedicated job opening portal to find your perfect role.",
            desc: "desctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa4",
        hadding: "Lifelong Community Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/4.webp",
        details: [
          {
            texthdding:
              "Lifetime access to the resources, content and community support to clear your doubts.",
            desc: "lastdesc",
          },
        ],
        classname: "btpaimageone",
      },
    ],
    steponehadding: "Fill the application form",
    steponedes: "Apply by filling a simple online application form",
    steptwohadding: "Eligibility Test",
    steptwodes:
      "Attempt the Eligibility Test and get shortlisted for the admission procedure.",
    steptreehadding: "Start Learning",
    steptreedes:
      "Get your offer letter and access to your dashboard and begin your journey with starter kits.",
    programtotalfee: "1,20,000",
    mothemi12: "9,984",
    mothemi18: "6,922",
    mothemi24: "5,379",
    emi: "EMIs Starting 5k/month",
    studentreviews: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/6.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/7.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/8.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/9.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/10.webp",
    ],
    faq: [
      {
        manhadding: "Program Pre-Requisites",
        faqid: "faq1",
        faqtwo: [
          {
            childfaqid: "cfaq1",
            secondhadding:
              "1.Do I need to have prior Digital Transformation experience to enroll in the program?",
            faqdetails: [
              "No, the program is designed to be inclusive of all levels of experience. All topics will be covered from the basics, making it suitable for individuals from any field of work.",
            ],
          },
          {
            childfaqid: "cfaq2",
            secondhadding:
              "2.What is the minimum system configuration required?",
            faqdetails: [
              "Any latest processor (i3/i5/i7) with 2.0GHz clock speed and RAM 4GB (required), 8GB(recommended).",
            ],
          },
        ],
      },
      {
        manhadding: "Syllabus & Projects Covered",
        faqid: "faq2",
        faqtwo: [
          {
            childfaqid: "cfaq3",
            secondhadding: "1.How many projects can I anticipate working on?",
            faqdetails: [
              "In Professional Certificate Program in Digital Transformation you will have access to more than 35 case studies, Capstone Projects to provide a better understanding of the real-world applications of Digital Transformation.",
            ],
          },
          {
            childfaqid: "cfaq4",
            secondhadding:
              "2.What's the difference between case studies & projects?",
            faqdetails: [
              "Case studies are targeted towards concept validation and limited to only a single concept. However, projects are targeted to overall learning and are more of a combination of multiple topics.",
            ],
          },
          {
            childfaqid: "cfaq5",
            secondhadding:
              "3.What all topics are covered in the Professional Certificate Program in Digital Transformation",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Download our latest program{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  brochure
                </Typography>{" "}
                &{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  curriculum.
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
      {
        manhadding: "Time Commitment",
        faqid: "faq3",
        faqtwo: [
          {
            childfaqid: "cfaq6",
            secondhadding:
              "1.How much time does one need to spend on a regular basis?",
            faqdetails: [
              "Daily 30 mins of prep time is required. You can choose to practice, read or watch videos. Additionally, on weekends one must attend 2 hours of live classes on both Saturday & Sunday.",
            ],
          },
          {
            childfaqid: "cfaq7",
            secondhadding: "2.What is the duration of the program?",
            faqdetails: [
              "Every term is for 4-5 weeks approximately. And the overall Professional Certificate Program in Digital Transformation duration is 6 months.",
            ],
          },
        ],
      },
      {
        manhadding: "Why IIT Guwahati ?",
        faqid: "faq5",
        faqtwo: [
          {
            childfaqid: "cfaq10",
            secondhadding:
              "1.How's the Professional Certificate Program in Digital Transformation program different?",
            faqdetails: [
              <>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  This program is distinguished by three key elements:
                </Typography>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  1. <b>Iterative Mastery Approach:</b> IITG's exclusive
                  Iterative Mastery Approach ensures development through a
                  step-by-step methodology, specifically designed to welcome
                  individuals from diverse backgrounds. Whether you're seeking a
                  lateral move in the industry or looking to broaden your
                  skills, our program is tailored to empower you on your unique
                  learning journey.
                </Typography>
                <Typography sx={{ marginBottom: "0.5rem !important" }}>
                  <Typography sx={{ marginBottom: "0.5rem !important" }}>
                    2. <b>Versatile Skill Development Model:</b> IIT G's unique
                    framework ensures that every Data Science and BA concept is
                    taught in the context of real-world industry problems. Our
                    program goes beyond theory, emphasizing practical case
                    studies to prepare students for the challenges they'll face
                    in the professional arena.
                  </Typography>
                </Typography>
                <Typography sx={{ marginBottom: "4px !important" }}>
                  3. <b>Accredian Career Center:</b> Aligned with our mission to
                  cultivate the Data leaders of tomorrow, the Accredian Career
                  Center empowers you to confidently launch your Data Science
                  career.
                </Typography>
              </>,
            ],
          },
          {
            childfaqid: "cfaq11",
            secondhadding: "2.Tell me more about Career Assistance?",
            faqdetails: [
              "Accredian provides career assistance through the Career Launchpad program. Students gain hands-on experience as leaders in their chosen field with rigorous resume and portfolio building sessions. The program also covers creating a strong online presence and developing top-notch interview skills.",
            ],
          },
          {
            childfaqid: "cfaq12",
            secondhadding: "3. What's the refund policy?",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Go through our Student Policy{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  <Link
                    to="/policies/iitg-policy"
                    style={{ textDecoration: "none" }}
                  >
                    link
                  </Link>
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
    ],

    brochurectaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/epdadb.webp",
    brochurectahadding: "Download EPDSBA®️ Program Brochure!",
    curriculumctahadding: "Download EPDSBA®️ Program Curriculum !",
    curriculumctaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/epdadb.webp",
    curriculumctatext: "Digital Transformation",
    displayone: "block",
    displaytwo: "none",
    freemodulesdesplay: "none",
    jobandaddon: "none",
    careerassistance: "none",
    ml: "-53px",
    ctasource: "Digital Transformation Brochure",
    programname: "Professional Certificate Program in Digital Transformation",
    ctapdf:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/Professional-Certification-Program-in-BA-5-months.pdf",
  },
];
