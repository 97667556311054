import React, { useEffect, useState } from "react";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import {

    Backdrop,
  } from "@mui/material";
const email = localStorage.getItem("email");
function Logout() {
    const [open, setOpen] = useState('');
    const LoaderOpen = () => {
        setOpen(true);
      };
      const LoaderClose = () => {
        setOpen(false);
      };
    useEffect(() => {
        LoaderOpen();

        axios({
            method: "POST",
            url: `${process.env.REACT_APP_API_ENDPOINT_PHP_PRODUCTION}/home_site_backend/redis-backend.php`,
        
            data: {
              username: email,
              type: "logout",
            },
          }).then(function (response) {
            if (response.status == 200) {
              localStorage.removeItem("user_id");
              localStorage.clear();
              window.location.href = "https://accredian.com/?customer-logout=true";
            }
            
          });
        
      }, []);
      return (
        <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" /> Please Wait
      </Backdrop>
      );
    }
    export default Logout;