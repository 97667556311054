import React, { useRef, useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  Container,
  CardMedia,
} from "@mui/material";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import {Iitprogram } from "../../data/Iitprogram";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import useStyles from "../../Assets/js/ProgramPage/style";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Swal from 'sweetalert2';
import axios from "axios";
import { useLocation,useSearchParams } from "react-router-dom";
import Career_Transition from "./CTA_Modal/career_transition";
const currencies = [

  {
    value: "Data Science",
    label: "Data Science",
  },
  {
    value: "Product Management",
    label: "Product Management",
  },
  {
    value: "General Management",
    label: "General Management",
  },
  // {
  //   value: "HR Analytics",
  //   label: "HR Analytics",
  // },
  // {
  //   value: "Sales and Marketing",
  //   label: "Sales and Marketing",
  // },
  {
    value: "Digital Transformation",
    label: "Digital Transformation",
  },
  {
    value: "Business Management",
    label: "Business Management",
  },
  // {
  //   value: "Operations & Supply Chain Management",
  //   label: "Operations & Supply Chain Management",
  // },
];
const PmRctDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "733px",
  },
}));
function PmRctDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 3,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

PmRctDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const RctDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "733px",
  },
}));
function RctDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: -2,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

RctDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const RcbDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "733px",
  },
}));
function RcbDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

RcbDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
export default function ProgramSectionThree() {
  const { id } = useParams();
  const [data, setData] = useState();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    let ans = Iitprogram.filter((obj) => {
      return obj.id == id;
    });
    //console.log(ans);
    setData(ans);
    AOS.init({ duration: 2000});
  }, [id]);
  const careertransitionsds = [
    {
      wid: "1",
      studentname: "Radhika Mirani",
      studentimage:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/DS/Radhika-Mirani/Radhika.webp",
      course: "GCD",
      coureseduration: "(6 months)",
      fristcompnydegintion: "Software Developer",
      secondcompnydegintion: "Data Analyst",
      fristcompnyimge:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/DS/Radhika-Mirani/tcs.webp",
      secondcompnyimge:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/DS/Radhika-Mirani/tiger.webp",
      youtublink: "https://www.youtube.com/embed/cqMXzFYJDko",
    },
    {
      wid: "2",
      studentname: "Sourav Ghosh",
      studentimage:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/DS/Sourav-Ghosh/Sourav.webp",
      course: "GCDAI",
      coureseduration: "(10 months)",
      fristcompnydegintion: "Sr.Data Engineer",
      secondcompnydegintion: "Asc.Data Engineer",
      fristcompnyimge:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/DS/Sourav-Ghosh/Philips.webp",
      secondcompnyimge:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/DS/Sourav-Ghosh/boeing.webp",
      youtublink: "https://www.youtube.com/embed/5Sguo_1dJQo",
    },
    {
      wid: "3",
      studentname: "Santosh Dogra",
      studentimage:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/DS/Santosh-Dogra/Santosh.webp",
      course: "GCDAI",
      coureseduration: "(10 months)",
      fristcompnydegintion: "Sales process Head",
      secondcompnydegintion: "Head- XSell - PWA",
      fristcompnyimge:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/DS/Santosh-Dogra/Axis.webp",
      secondcompnyimge:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/DS/Santosh-Dogra/Edelweiss.webp",
      youtublink: "https://www.youtube.com/embed/dhVIylHDBGk",
    },
    {
      wid: "4",
      studentname: "Rahul Ghosh",
      studentimage:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/DS/Rahul-Ghosh/rahul.webp",
      course: "GCDAI",
      coureseduration: "(10 months)",
      fristcompnydegintion: "Sr.Project Manager",
      secondcompnydegintion: "Engineering Leader",
      fristcompnyimge:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/DS/Rahul-Ghosh/delta.webp",
      secondcompnyimge:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/DS/Rahul-Ghosh/well.webp",
      youtublink: "https://www.youtube.com/embed/Y-Kbj1AQxf8",
    },
    {
      wid: "5",
      studentname: "Ankita Bhanushali",
      studentimage:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/DS/Ankita-Bhanushali/ankita.webp",
      course: "GCD",
      coureseduration: "(6 months)",
      fristcompnydegintion: "Executive Analytics",
      secondcompnydegintion: "Sr.Executive Analytics",
      fristcompnyimge: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/DS/Ankita-Bhanushali/kantar.webp",
      secondcompnyimge:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/DS/Ankita-Bhanushali/first-source.webp",
      youtublink: "https://www.youtube.com/embed/0L53sGyHmf4",
    },
    {
      wid: "6",
      studentname: "Jojo Jacob",
      studentimage:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/DS/Jojo-Jacob/jojo.webp",
      course: "GCDAI",
      coureseduration: "(10 months)",
      fristcompnydegintion: "Senior Administrative Officer",
      secondcompnydegintion: "Consultant Cloud Computing",
      fristcompnyimge:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/DS/Jojo-Jacob/army.webp",
      secondcompnyimge:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/DS/Jojo-Jacob/Dell.webp",
      youtublink: "https://www.youtube.com/embed/0L53sGyHmf4",
    },
    {
      wid: "7",
      studentname: "Archit Jain",
      studentimage:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/DS/Archit-Jain/archit.webp",
      course: "GCD",
      coureseduration: "(6 months)",
      fristcompnydegintion: "Fresher",
      secondcompnydegintion: "Business Intelligence Enginee",
      fristcompnyimge: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/DS/Archit-Jain/withe.webp",
      secondcompnyimge:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/DS/Archit-Jain/amazon.webp",
      youtublink: "https://www.youtube.com/embed/147XhLmW1Xo",
    },
    {
      wid: "8",
      studentname: "Aditya Gundu",
      studentimage:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/DS/Aditya-Gundu/aditya.webp",
      course: "GCDAI",
      coureseduration: "(10 months)",
      fristcompnydegintion: "Sr Engineer Consultant (Software Dev)",
      secondcompnydegintion: "Sr Engineer Consultant (DS)",
      fristcompnyimge:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/DS/Aditya-Gundu/verizon.webp",
      secondcompnyimge:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/DS/Aditya-Gundu/verizon.webp",
      youtublink: "https://www.youtube.com/embed/3X2mMJcrCjw",
    },
  ];
  const careertransitionspm = [
    {
      pmwid: "1",
      studentname: "Abhilash Nandamuri",
      studentimage:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/PM/Abhilash-Nandamuri/Abhilash-Nandamuri.webp",
      course: "GCPM",
      coureseduration: "(6 months)",
      fristcompnydegintion: "Management Trainee",
      secondcompnydegintion: "Finance and Product",
      fristcompnyimge:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/PM/Abhilash-Nandamuri/bprl.webp",
      secondcompnyimge:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/PM/Abhilash-Nandamuri/Saint-Gobain.webp",
      youtublink: "https://www.youtube.com/embed/midw6kgf2vA",
    },
    {
      pmwid: "2",
      studentname: "Dhan Bahadur Singh",
      studentimage:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/PM/Dhan-Bahadur-Singh/Dhan-Bahadur.webp",
      course: "GCPM",
      coureseduration: "(10 months)",
      fristcompnydegintion: "Product Manager",
      secondcompnydegintion: "Chief Manager",
      fristcompnyimge:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/PM/Dhan-Bahadur-Singh/nwg.webp",
      secondcompnyimge:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/PM/Dhan-Bahadur-Singh/icici.webp",
      youtublink: "https://www.youtube.com/embed/IXELmc4LqAk",
    },
    {
      pmwid: "3",
      studentname: "Divya Rao",
      studentimage:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/PM/Divya-Rao/Divya-Rao.webp",
      course: "PGPPMDS",
      coureseduration: "(12 months)",
      fristcompnydegintion: "Product Manager",
      secondcompnydegintion: "Senior Product Manager",
      fristcompnyimge:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/PM/Divya-Rao/ibm.webp",
      secondcompnyimge:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/PM/Divya-Rao/Syniverse.webp",
      youtublink: "https://www.youtube.com/embed/6YZ3EzepGfs",
    },
    {
      pmwid: "4",
      studentname: "Ranjith Malapaty",
      studentimage:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/PM/Ranjith-Malapaty/RanjithM.webp",
      course: "PGPPM",
      coureseduration: "(10 months)",
      fristcompnydegintion: "Sr. Software Engineering Manager",
      secondcompnydegintion: "Sr. Technical Product Manager",
      fristcompnyimge:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/PM/Ranjith-Malapaty/hubble.webp",
      secondcompnyimge:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/PM/Ranjith-Malapaty/GE.webp",
      youtublink: "https://www.youtube.com/embed/4zFrZQKv0gU",
    },
    {
      pmwid: "5",
      studentname: "Sawan Tandon",
      studentimage:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/PM/Sawan-Tandon/Sawan-Tandon.webp",
      course: "GCPM",
      coureseduration: "(6 months)",
      fristcompnydegintion: "Business Development Manager",
      secondcompnydegintion: "Product Manager",
      fristcompnyimge:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/PM/Sawan-Tandon/bajaj.webp",
      secondcompnyimge:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/PM/Sawan-Tandon/bc.webp",
      youtublink: "https://www.youtube.com/embed/wASsz5j9or8",
    },

    {
      pmwid: "6",
      studentname: "Sriram Viswanathan",
      studentimage:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/PM/Sriram-Viswanathan/sriram.webp",
      course: "PGPPM",
      coureseduration: "(10 months)",
      fristcompnydegintion: "Software Department Manager",
      secondcompnydegintion: "Product Manager",
      fristcompnyimge:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/PM/Sriram-Viswanathan/dm.webp",
      secondcompnyimge:
        "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/PM/Sriram-Viswanathan/huge.webp",
      youtublink: "https://www.youtube.com/embed/Q3L_Ul0rWyE",
    },
  ];
  const [dsitemsToShow, setdsItemsToShow] = useState(4);
  const dsshowmore = () => {
    setdsItemsToShow(careertransitionsds.length);
  };

  const dsshowless = () => {
    setdsItemsToShow(4);
  };
  const [pmitemsToShow, setpmItemsToShow] = useState(4);
  const pmshowmore = () => {
    setpmItemsToShow(careertransitionspm.length);
  };

  const pmshowless = () => {
    setpmItemsToShow(4);
  };
  const sliderRef = useRef();
  const RcsliderRef = useRef();
  const RcgotoNext = () => {
    RcsliderRef.current.slickNext();
  };
  const RcgotoPrev = () => {
    RcsliderRef.current.slickPrev();
  };
  const ossliderRef = useRef();
  const osgotoNext = () => {
    ossliderRef.current.slickNext();
  };
  const osgotoPrev = () => {
    ossliderRef.current.slickPrev();
  };
  const deskgotoNext = () => {
    sliderRef.current.slickNext();
  };
  const deskgotoPrev = () => {
    sliderRef.current.slickPrev();
  };
  const settings = {
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,

    autoplay: true,
    autoplaySpeed: 6000,

    responsive: [
      {
        breakpoint: 1124,
        settings: {
          // infinite: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          // autoplay: true,
          autoplaySpeed: 6000,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
      {
        breakpoint: 900,
        settings: {
          infinite: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
    ],
  };
  const classes = useStyles();
  const [program, setProgram] = useState("");

  const handleChange = (event) => {
    setProgram(event.target.value);
  };
  
  const [Rcbopen, RcbsetOpen] = useState(false);
  const handleClickOpenRcb = () => {
    RcbsetOpen(true);
  };
  const RcbhandleClose = () => {
    RcbsetOpen(false);
  };
  const [Rctopen, RctsetOpen] = useState(false);
  const [Rctinfo, Rctsetinfo] = useState(false);
  const RcthandleClickOpen = (crt) => {
    //console.log(crt);
    Rctsetinfo(crt);
    RctsetOpen(true);
  };
  const RcthandleClose = () => {
    RctsetOpen(false);
  };
  const [PmRctopen, PmRctsetOpen] = useState(false);
  const [PmRctinfo, PmRctsetinfo] = useState(false);
  const PmRcthandleClickOpen = (pmcrt) => {
    //console.log(pmcrt);
    PmRctsetinfo(pmcrt);
    PmRctsetOpen(true);
  };
  const PmRcthandleClose = () => {
    PmRctsetOpen(false);
  };
  const [open, setOpen] = useState(false);
  const LoaderClose = () => {
    setOpen(false);
  };
  const LoaderOpen = () => {
    setOpen(true);
  };
  const [rcb, setRcb] = useState({
    mobile: "",
    name: "",
    newemail: "",
    program: "",
    ctaname: "",
    ctapage: "",
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
  });
  const rcbhandleChange = (e) => {
    setRcb({ ...rcb, [e.target.name]: e.target.value });
  };
  const options = {
    headers: {
      "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    },
  };
  var ctapage = location.pathname.replace("/", "");
  var utm_source = searchParams.get("utm_source")==undefined?"null":searchParams.get("utm_source"); 
  var utm_medium = searchParams.get("utm_medium") ==undefined?"null":searchParams.get("utm_medium") ;
  var utm_campaign = searchParams.get("utm_campaign") ==undefined?"null":searchParams.get("utm_campaign") ;
  const submitForm = (e) => {
    e.preventDefault();
    LoaderOpen();
    const sendRcbctaData = {
      phone: rcb.mobile,
      name: rcb.name,
      email: rcb.newemail,
      program: program,
      source: "career transition",
      page: ctapage,
      utm_source: utm_source,
      utm_medium: utm_medium,
      utm_campaign: utm_campaign,
      form_id:"career_transition",
      type:"CTA"
    };

    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/common_publish/commonPublish`,
        JSON.stringify(sendRcbctaData),
        options
      )
      .then((result) => {
        // //console.log(result.data.status);
        LoaderClose();
        RcbhandleClose();
     
        if (result.data.status == "200") {
          Swal.fire({
            title: "Success",
            text: "Your request submitted successfully. Our expert will call you soon.",
            icon: "success",
            confirmButtonText: "OK",
}).then((conf) => {
            if (conf.isConfirmed) {
             window.open("https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/Career-Handbook.pdf", "_blank");
            }
          });
        
          setRcb("");
        
        } else {
          Swal.fire({
            title: "Warning",
            text: "Something went wrong. Please try again!",
            icon: "Warning",
            confirmButtonText: "OK",
          });
        }
      });
  };
  return (
    <>
      {data &&
        data.map((Iitprogram) => (
          <Box sx={{}} key={Iitprogram.compnyimg}>
            <Box sx={{ }} >
              <Container fixed>
                <Typography
                  variant="h4"
                  component="div"
                  align="center"
                  sx={{ fontSize: "38px", fontWeight: "bold", pb: 6 }}
                >
                  Our students work at
                </Typography>
              </Container>
              <Box sx={{ mb: 6, mt: 1 }}>
              <Slider
                    ref={ossliderRef}
                    {...settings}
                    style={{ overflowX: "hidden" }}
                  >
                    {Iitprogram.compnyimg &&
                      Iitprogram.compnyimg.map((proimg) => (
                        <Grid  lg={2} xs={1} sm={1} md={1}>
                          <CardMedia
                            component="img"
                            image={proimg}
                            alt="us"
                            sx={{
                              width:{lg:"150px"},
                              ml:{lg:3,sm:2,md:2,xs:0},
                              height:{lg:"43px",xs:"80px"},
                              objectFit: "contain",
                            }}
                          />
                        </Grid>
                      ))}
                  </Slider>
                  <Button
                     
                     onClick={() => osgotoPrev()}
                     sx={{mt:{lg:-27,md:-13,sm:-14,xs:-15},ml:{lg:-7.5,md:9,sm:16,xs:4},display:{lg:"none"}, '&:hover': {backgroundColor:"transparent",}}}
                   >
                     <ArrowBackIosIcon></ArrowBackIosIcon>
                   </Button>
                   <Button
                    
                     onClick={() => osgotoNext()}
                     sx={{float:"right",mt:{lg:-25,md:-8,sm:-7,xs:-8},mr:{lg:-5.4,md:2,sm:12,xs:2},display:{lg:"none"},'&:hover': {backgroundColor:"transparent",}}}
                   >
                     <ArrowForwardIosIcon></ArrowForwardIosIcon>   
                   </Button>
              </Box>
            </Box>
            {/*  Mobile version For studan work section */}
           
            {/*  Mobile version For studan work section */}
            <Box sx={{ display: { xs: "none", lg: "block",sm:"block",md:"block" } }} >
              <Container fixed>
                <Typography
                  variant="h4"
                  component="div"
                  align="center"
                  sx={{ fontSize: "38px", fontWeight: "bold", pb: 6 }}
                >
                  Recent Career transitions
                </Typography>
                <Box sx={{ display: Iitprogram.displayone }}>
                  <Grid lg={12} sm={12} md={12} container>
                    {careertransitionsds.slice(0, dsitemsToShow).map((crt) => (
                          <Grid
                        
                          lg={6}
                          sm={12} md={12}
                          sx={{
                            boxShadow: "0 0 15px 0 rgb(0 0 0 / 10%)",
                            borderRadius:"8px",
                            maxWidth:{lg: "47% !important",sm: "100% !important",md: "100% !important"},
                           
                            mr:3,
                            mb: 3,
                          
                          }}
                          key={crt.studentname}
                         
                        >
                          <Grid  lg={12} sm={12} md={12} container>
                            <Grid
                            lg={6}
                            sm={6} md={6} 
                              sx={{
                                background:
                                  "linear-gradient(438deg, #0029ff 75%, rgba(0, 0, 0, 0) 60%)",
                                height: "286px",
                                borderRadius:"8px",
                              }}
                            >
                              <CardMedia
                                component="img"
                                image={crt.studentimage}
                                alt="us"
                                sx={{
                                  borderRadius: "50%",
                                  width: "120px",
                                  height: "120px",
                                  ml:5,
                                  mt:6,
                                 
                                }}
                              />
                              <Typography
                                variant="h4"
                                component="div"
                                align="center"
                                sx={{
                                  fontSize: "17px",
                                  fontWeight: "bold",
                                  color: "#fff",
                                  ml:{lg:-7,sm:-15,md:-19},
                                  mt:3
                                }}
                              >
                                {crt.course}
                              </Typography>
                            
                            </Grid>
                            <Grid  lg={6}
                         sm={6} md={6} >
                              <Typography
                                variant="h4"
                                component="div"
                                sx={{
                                  fontSize: "25px",
                                  fontWeight: "bold",
                                  ml: Iitprogram.ml,
                                  mt: 2,
                                }}
                              >
                                {crt.studentname}
                              </Typography>
                              <Grid lg={12} sm={12} md={12} container sx={{ mt: 5, ml: -3 }}>
                                <Grid lg={5} sm={5} md={5}>
                                  <Typography
                                    variant="h4"
                                    component="div"
                                    sx={{ fontSize: "16px" }}
                                  >
                                    {crt.fristcompnydegintion}
                                  </Typography>
                                </Grid>
                                <Grid  lg={2} sm={2} md={2}>
                                  <center>
                                    <ArrowRightAltIcon
                                      sx={{
                                        color: "#007bff",
                                        fontSize: "2.5rem",
                                      }}
                                    />
                                  </center>
                                </Grid>
                                <Grid lg={5} sm={5} md={5}>
                                  <Typography
                                    variant="h4"
                                    component="div"
                                    sx={{
                                      fontSize: "16px",
                                      ml: 4,
                                      height: "58px",
                                      width: "112px",
                                    }}
                                  >
                                    {crt.secondcompnydegintion}
                                  </Typography>
                                </Grid>
                              </Grid>
  
                              <Grid lg={12} sm={12} md={12} container sx={{ mt: 5, ml: -2 }}>
                                <Grid  lg={3.5} sm={3.5} md={3.5}>
                                  <CardMedia
                                    component="img"
                                    image={crt.fristcompnyimge}
                                    alt="us"
                                    sx={{}}
                                  />
                                </Grid>
                                <Grid lg={3.5} sm={3.5} md={3.5}>
                                  <CardMedia
                                    component="img"
                                    image={crt.secondcompnyimge}
                                    alt="us"
                                    sx={{ ml: 12 }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid lg={12} sm={12} md={12} container sx={{ mt: 5 }}>
                                <Grid  lg={8} sm={8} md={8}>
                                  <Typography
                                    variant="h4"
                                    component="div"
                                    sx={{
                                      fontSize: "16px",
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => RcthandleClickOpen(crt)}
                                  >
                                    Watch my success story
                                  </Typography>
                                </Grid>
                                <Grid lg={2}  sm={2} md={2}>
                                  <CardMedia
                                    component="img"
                                    image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/play.webp"
                                    alt="us"
                                    sx={{ mt: -2 }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                    ))}
                    <Box sx={{ ml:{lg:128,sm:50,md:90} }}>
                      {dsitemsToShow === 4 ? (
                        <Typography
                          onClick={dsshowmore}
                          sx={{
                            textTransform: "capitalize",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          View More{" "}
                        </Typography>
                      ) : (
                        <Typography
                          onClick={dsshowless}
                          variant="contained"
                          sx={{
                            textTransform: "capitalize",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          View less
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                </Box>
                <Box sx={{ display: Iitprogram.displaytwo }} >
                  <Grid lg={12} sm={12} md={12}  container>
                    {careertransitionspm.slice(0, pmitemsToShow).map((pmcrt) => (
                        <Grid
                         
                          lg={6}
                         sm={12} md={12} 
                          sx={{
                            boxShadow: "0 0 15px 0 rgb(0 0 0 / 10%)",
                            maxWidth:{lg: "47% !important",sm: "100% !important",md: "100% !important"},
                           
                            mr:3,
                            mb: 3,
                          }}
                          key={pmcrt.studentname}
                         
                        >
                          <Grid  lg={12} sm={12} md={12} container>
                            <Grid
                              lg={6}
                              sm={6} md={6} 
                              sx={{
                                background:
                                  "linear-gradient(438deg, #0029ff 75%, rgba(0, 0, 0, 0) 60%)",
                                height: "286px",
                              }}
                            >
                              <CardMedia
                                component="img"
                                image={pmcrt.studentimage}
                                alt="us"
                                sx={{
                                  borderRadius: "50%",
                                  width: "120px",
                                  height: "120px",
                                  py: 5,
                                  px: 6,
                                }}
                              />
                              <Typography
                                variant="h4"
                                component="div"
                                align="center"
                                sx={{
                                  fontSize: "17px",
                                  fontWeight: "bold",
                                  color: "#fff",
                                  ml:{lg:-5,sm:-15,md:-19},
                                }}
                              >
                                {pmcrt.course}
                              </Typography>
                              <Typography
                                variant="h4"
                                component="div"
                                align="center"
                                sx={{
                                  fontSize: "17px",
                                  fontWeight: "bold",
                                  color: "#fff",
                                  ml:{lg:-5,sm:-15,md:-19},
                                }}
                              >
                                {pmcrt.coureseduration}
                              </Typography>
                            </Grid>
                            <Grid     lg={6}
                         sm={6} md={6} >
                              <Typography
                                variant="h4"
                                component="div"
                                sx={{
                                  fontSize: "25px",
                                  fontWeight: "bold",
                                  ml: Iitprogram.ml,
                                  mt: 2,
                                }}
                              >
                                {pmcrt.studentname}
                              </Typography>
                              <Grid
                                
                                lg={12}
                                sm={12}
                                md={12}
                                container
                                sx={{ mt: 5, ml: -3 }}
                              >
                                <Grid lg={5} sm={5} md={5}> 
                                  <Typography
                                    variant="h4"
                                    component="div"
                                    sx={{ fontSize: "16px" }}
                                  >
                                    {pmcrt.fristcompnydegintion}
                                  </Typography>
                                </Grid>
                                <Grid lg={2} sm={2} md={2}>
                                  <center>
                                    <ArrowRightAltIcon
                                      sx={{
                                        color: "#007bff",
                                        fontSize: "2.5rem",
                                      }}
                                    />
                                  </center>
                                </Grid>
                                <Grid lg={5}  sm={5} md={5}>
                                  <Typography
                                    variant="h4"
                                    component="div"
                                    sx={{
                                      fontSize: "16px",
                                      ml: 4,
                                      height: "58px",
                                      width: "112px",
                                    }}
                                  >
                                    {pmcrt.secondcompnydegintion}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                              
                                lg={12}
                                 sm={12} md={12}
                                container
                                sx={{ mt: 5, ml: -2 }}
                              >
                                <Grid lg={3.5} sm={3.5} md={3.5}>
                                  <CardMedia
                                    component="img"
                                    image={pmcrt.fristcompnyimge}
                                    alt="us"
                                    sx={{}}
                                  />
                                </Grid>
                                <Grid lg={3.5} sm={3.5} md={3.5}>
                                  <CardMedia
                                    component="img"
                                    image={pmcrt.secondcompnyimge}
                                    alt="us"
                                    sx={{ ml: 12 }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid  lg={12} sm={12} md={12} container sx={{ mt: 5 }}>
                                <Grid lg={8} md={8} sm={8}>
                                  <Typography
                                    variant="h4"
                                    component="div"
                                    sx={{
                                      fontSize: "16px",
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => PmRcthandleClickOpen(pmcrt)}
                                  >
                                    Watch my success story
                                  </Typography>
                                </Grid>
                                <Grid item lg={2}>
                                  <CardMedia
                                    component="img"
                                    image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/play.webp"
                                    alt="us"
                                    sx={{ mt: -2 }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    <Box sx={{ ml:{lg:131,sm:50,md:90} }}>
                      {pmitemsToShow === 4 ? (
                        <Typography
                          onClick={pmshowmore}
                          sx={{
                            textTransform: "capitalize",
                            textDecoration: "underline",
                          }}
                        >
                          View More{" "}
                        </Typography>
                      ) : (
                        <Typography
                          onClick={pmshowless}
                          variant="contained"
                          sx={{
                            textTransform: "capitalize",
                            textDecoration: "underline",
                          }}
                        >
                          View less
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                </Box>
                <center>
                  <Button
                    variant="contained"
                    sx={{ mb: 5, mt: 4 }}
                    className={classes.careertransitions}
                    onClick={handleClickOpenRcb}
                  >
                    Learn More About Career Transitions
                  </Button>
                </center>
              </Container>
            </Box>
            {/*  Mobile version For Career  section */}
            <Box sx={{ display: { xs: "block", lg: "none",sm:"none",md:"none" } }}     >
              <Container fixed>
                <Typography
                  variant="h4"
                  component="div"
                  align="center"
                  sx={{ fontSize: "25px", fontWeight: "bold", pb: 6 }}
                >
                  Recent Career transitions
                </Typography>
                <Box sx={{ display: Iitprogram.displayone }}>
                  <Grid xs={12} sm={12} md={12} container>
                    <Slider
                      ref={RcsliderRef}
                      {...settings}
                      style={{ overflowX: "hidden" }}
                    >
                      {careertransitionsds.map((crt) => (
                        <Grid
                         
                          xs={12}
                          sm={12}
                          md={10}
                          sx={{
                            boxShadow: "0 0 15px 0 rgb(0 0 0 / 10%)",

                            mb: 3,
                          }}
                          key={crt.studentname}
                        >
                          <Grid item xs={12} container>
                            <Grid
                             xs={12}
                             sm={12}
                             md={12}
                              sx={{
                                background:
                                  "linear-gradient(175deg, #0029ff 84%, rgba(0, 0, 0, 0) 84%)",
                                height: "230px",
                              }}
                            >
                              <Box display="flex">
                                <Box>
                                  <CardMedia
                                    component="img"
                                    image={crt.studentimage}
                                    alt="us"
                                    sx={{
                                      borderRadius: "50%",
                                      width: "150px",
                                      height: "150px",
                                      mt: 5,
                                      mb: 3,
                                      ml: 3,
                                    }}
                                  />
                                </Box>
                                <Box sx={{ my: "auto", mx: 3 }}>
                                  <Typography
                                    variant="h4"
                                    component="div"
                                    align="center"
                                    sx={{
                                      fontSize: "23px",
                                      fontWeight: "bold",
                                      color: "#fff",
                                    }}
                                  >
                                    {crt.course}
                                  </Typography>
                                  <Typography
                                    variant="h4"
                                    component="div"
                                    align="center"
                                    sx={{
                                      fontSize: "23px",
                                      fontWeight: "bold",
                                      color: "#fff",
                                    }}
                                  >
                                    {crt.coureseduration}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid   xs={12}
                          sm={12}
                          md={12}>
                              <Typography
                                variant="h4"
                                component="div"
                                sx={{
                                  fontSize: "25px",
                                  fontWeight: "bold",
                                  ml: 2,
                                }}
                              >
                                {crt.studentname}
                              </Typography>
                              <Grid
                                xs={12}
                                sm={12}
                                md={12}
                                container
                                sx={{ mt: 3, ml: 2 }}
                              >
                                <Grid item xs={5}>
                                  <Typography
                                    variant="h4"
                                    component="div"
                                    sx={{ fontSize: "20px" }}
                                  >
                                    {crt.fristcompnydegintion}
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <center>
                                    <ArrowRightAltIcon
                                      sx={{
                                        color: "#007bff",
                                        fontSize: "2.5rem",
                                      }}
                                    />
                                  </center>
                                </Grid>
                                <Grid item xs={5}>
                                  <Typography
                                    variant="h4"
                                    component="div"
                                    sx={{
                                      fontSize: "20px",
                                      ml: 4,
                                      height: "58px",
                                      width: "112px",
                                    }}
                                  >
                                    {crt.secondcompnydegintion}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                               xs={12}
                               sm={12}
                               md={12}
                                container
                                sx={{ ml: 2, mt: 3 }}
                              >
                                <Grid item xs={3.5}>
                                  <CardMedia
                                    component="img"
                                    image={crt.fristcompnyimge}
                                    alt="us"
                                    sx={{}}
                                  />
                                </Grid>
                                <Grid item xs={3.5}>
                                  <CardMedia
                                    component="img"
                                    image={crt.secondcompnyimge}
                                    alt="us"
                                    sx={{ ml: 14 }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid
                            xs={12}
                            sm={12}
                            md={12}
                                container
                                sx={{ mt: 5, ml: 3, mb: 2 }}
                              >
                                <Grid item xs={8}>
                                  <Typography
                                    variant="h4"
                                    component="div"
                                    sx={{
                                      fontSize: "23px",
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => RcthandleClickOpen(crt)}
                                  >
                                    Watch my success story
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <CardMedia
                                    component="img"
                                    image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/play.webp"
                                    alt="us"
                                    sx={{ mt: -2 }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Slider>
            
                  </Grid>
                </Box>
                <Box sx={{ display: Iitprogram.displaytwo }} >
                  <Grid item xs={12} container>
                    <Slider
                      ref={sliderRef}
                      {...settings}
                      style={{ overflowX: "hidden" }}
                    >
                      {careertransitionspm.map((pmcrt) => (
                        <Grid
                          item
                          xs={12}
                          sx={{
                            boxShadow: "0 0 15px 0 rgb(0 0 0 / 10%)",

                            mb: 3,
                          }}
                          key={pmcrt.studentname}
                        >
                          <Grid xs={12} sm={12} md={12} container>
                            <Grid
                           xs={12}
                           sm={12}
                           md={10}
                              sx={{
                                background:
                                  "linear-gradient(175deg, #0029ff 84%, rgba(0, 0, 0, 0) 84%)",
                                height: "230px",
                              }}
                            >
                              <Box display="flex">
                                <Box>
                                  <CardMedia
                                    component="img"
                                    image={pmcrt.studentimage}
                                    alt="us"
                                    sx={{
                                      borderRadius: "50%",
                                      width: "150px",
                                      height: "150px",
                                      mt: 5,
                                      mb: 3,
                                      ml: 3,
                                    }}
                                  />
                                </Box>
                                <Box sx={{ my: "auto", mx: 3 }}>
                                  <Typography
                                    variant="h4"
                                    component="div"
                                    align="center"
                                    sx={{
                                      fontSize: "23px",
                                      fontWeight: "bold",
                                      color: "#fff",
                                    }}
                                  >
                                    {pmcrt.course}
                                  </Typography>
                                  <Typography
                                    variant="h4"
                                    component="div"
                                    align="center"
                                    sx={{
                                      fontSize: "23px",
                                      fontWeight: "bold",
                                      color: "#fff",
                                    }}
                                  >
                                    {pmcrt.coureseduration}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid   xs={12}
                          sm={12}
                          md={12}>
                              <Typography
                                variant="h4"
                                component="div"
                                sx={{
                                  fontSize: "25px",
                                  fontWeight: "bold",
                                  ml: 2,
                                }}
                              >
                                {pmcrt.studentname}
                              </Typography>
                              <Grid
                                    xs={12}
                                    sm={12}
                                    md={12}
                                container
                                sx={{ mt: 3, ml: 2 }}
                              >
                                <Grid item xs={5}>
                                  <Typography
                                    variant="h4"
                                    component="div"
                                    sx={{ fontSize: "20px" }}
                                  >
                                    {pmcrt.fristcompnydegintion}
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <center>
                                    <ArrowRightAltIcon
                                      sx={{
                                        color: "#007bff",
                                        fontSize: "2.5rem",
                                      }}
                                    />
                                  </center>
                                </Grid>
                                <Grid item xs={5}>
                                  <Typography
                                    variant="h4"
                                    component="div"
                                    sx={{
                                      fontSize: "20px",
                                      ml: 4,
                                      height: "58px",
                                      width: "112px",
                                    }}
                                  >
                                    {pmcrt.secondcompnydegintion}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                xs={12}
                                sm={12}
                                md={12}
                                container
                                sx={{ ml: 2, mt: 3 }}
                              >
                                <Grid item xs={3.5}>
                                  <CardMedia
                                    component="img"
                                    image={pmcrt.fristcompnyimge}
                                    alt="us"
                                    sx={{}}
                                  />
                                </Grid>
                                <Grid item xs={3.5}>
                                  <CardMedia
                                    component="img"
                                    image={pmcrt.secondcompnyimge}
                                    alt="us"
                                    sx={{ ml: 14 }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid
                            xs={12}
                            sm={12}
                            md={12}
                                container
                                sx={{ mt: 5, ml: 3, mb: 2 }}
                              >
                                <Grid item xs={8}>
                                  <Typography
                                    variant="h4"
                                    component="div"
                                    sx={{
                                      fontSize: "23px",
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => PmRcthandleClickOpen(pmcrt)}
                                  >
                                    Watch my success story
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <CardMedia
                                    component="img"
                                    image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Recent-Career-transitions/play.webp"
                                    alt="us"
                                    sx={{ mt: -2 }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Slider>
                  </Grid>
                </Box>
                <center>
                  <Button
                    variant="contained"
                    sx={{ mb: 5, mt: 4 }}
                    className={classes.careertransitions}
                    onClick={handleClickOpenRcb}
                  >
                    Learn More About Career Transitions
                  </Button>
                </center>
              </Container>
            </Box>

            {/* End  Mobile version For Career  section */}
            <Box sx={{ }}>
              <Box sx={{ display: Iitprogram.freemodulesdesplay }} >
                <Container fixed>
                  <Typography
                    variant="h4"
                    component="div"
                  
                    sx={{ fontSize:{lg: "38px",sm: "38px",md: "38px",xs: "35px"}, fontWeight: "bold", pb: 6,textAlign:{lg:"left",md:"center",sm:"center",xs:"center"} }}
                  >
                Add-on Modules
              
                  </Typography>
                  <Box sx={{ pb: 6,mr:{lg:4}}}>
                    <Grid  lg={12} xs={12} sm={12} md={12} container>

                      <Grid
                    
                        lg={4}
                        xs={12}
                        sm={12} 
                        md={12}
                        sx={{
                          boxShadow: "rgb(100 100 111 / 20%) 0px 7px 29px 0px",
                          border: "solid 0.5px #0091ff",
                          borderRadius: "8px",
                          height: "450px",
                          maxWidth:{lg:"30% !important",md:"45% !important"} ,
                         mb:4,
                          
                        }}
                       
                      >
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Modules/poewr.webp"
                          alt="us"
                          sx={{
                            mx: "auto",
                            width: "100px",
                            height: "100px",
                            my:8
                          }}
                        />
                        <Typography
                          variant="h4"
                          component="div"
                          sx={{
                            textTransform: "capitalize",
                            fontWeight: "bold",
                            color: "#003c69",
                            fontSize: "25px",
                            textAlign: "center",
                            pb: 7,
                          }}
                        >
                          Power BI Course
                        </Typography>
                        <Typography
                          variant="h4"
                          component="div"
                          sx={{
                            textTransform: "capitalize",

                            fontSize: "17px",
                            textAlign: "center",
                            pb: 8,
                            mx: 3,
                          }}
                        >
                          Learn Power BI to render decision making easier with
                          amazing dashboards.
                        </Typography>
                      </Grid>
                      <Grid
                     
                        lg={4}
                      
                        xs={12}
                        sm={12} 
                        md={12}
                        sx={{
                          boxShadow: "rgb(100 100 111 / 20%) 0px 7px 29px 0px",
                          border: "solid 0.5px #0091ff",
                          borderRadius: "8px",
                          height: "450px",
                          maxWidth:{lg:"30% !important",md:"45% !important"} ,
                          ml:{lg:6,md:3} ,
                          mb:4
                        }}
                       
                      >
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Modules/sqlbd.webp"
                          alt="us"
                          sx={{
                            mx: "auto",
                            width: "100px",
                            height: "100px",
                            my:8
                          }}
                        />
                        <Typography
                          variant="h4"
                          component="div"
                          sx={{
                            textTransform: "capitalize",
                            fontWeight: "bold",
                            color: "#003c69",
                            fontSize: "25px",
                            textAlign: "center",
                            pb: 7,
                          }}
                        >
                         SQL Course
                        </Typography>
                        <Typography
                          variant="h4"
                          component="div"
                          sx={{
                            textTransform: "capitalize",

                            fontSize: "17px",
                            textAlign: "center",
                            pb: 8,
                            mx: 3,
                          }}
                        >
                         Learn SQL in order to handle structured data in relational databases.
                        </Typography>
                      </Grid>
                     
                      <Grid
                        
                        lg={4}
                        xs={12}
                        sm={12} 
                        md={12}
                        sx={{
                          boxShadow: "rgb(100 100 111 / 20%) 0px 7px 29px 0px",
                          border: "solid 0.5px #0091ff",
                          borderRadius: "8px",
                          height: "450px",
                          maxWidth:{lg:"30% !important",md:"45% !important"} ,
                          ml:{lg:6} ,
                          mb:4,
                          display: Iitprogram.addmodultwo,
                        }}
                       
                      >
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Modules/team.webp"
                          alt="us"
                          sx={{
                            mx: "auto",
                            width: "100px",
                            height: "100px",
                            my:8
                          }}
                        />
                        <Typography
                          variant="h4"
                          component="div"
                          sx={{
                            textTransform: "capitalize",
                            fontWeight: "bold",
                            color: "#003c69",
                            fontSize: "25px",
                            textAlign: "center",
                            pb: 7,
                          }}
                        >
                          Industry Mentorship
                        </Typography>
                        <Typography
                          variant="h4"
                          component="div"
                          sx={{
                            textTransform: "capitalize",

                            fontSize: "17px",
                            textAlign: "center",
                            pb: 3,
                            mx: 3,
                          }}
                        >
                          Gain practical knowledge from Top Data Scientists
                        </Typography>
                        <Grid  lg={12} xs={12} md={12} sm={12}  container sx={{ mx:{lg:6,md:6,xs:6}}}>
                          <Grid lg={2} xs={2} sm={2} md={2} sx={{ml:{lg:0,md:0,xs:0,sm:6}}}>
                            <CardMedia
                              component="img"
                              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Modules/microsoft.webp"
                              alt="us"
                              sx={{ mx: 2, width: "35px" }}
                            />
                          </Grid>
                          <Grid lg={2} xs={2} sm={2} md={2}>
                            <CardMedia
                              component="img"
                              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Modules/Amazon_icon.webp"
                              alt="us"
                              sx={{ mx: 2, width: "35px" }}
                            />
                          </Grid>
                          <Grid lg={2} xs={2} sm={2} md={2} >
                            <CardMedia
                              component="img"
                              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Modules/google.webp"
                              alt="us"
                              sx={{ mx: 2, width: "35px" }}
                            />
                          </Grid>
                          <Grid lg={2} xs={2} sm={2} md={2}>
                            <CardMedia
                              component="img"
                              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Modules/meta.webp"
                              alt="us"
                              sx={{ mx: 2, width: "35px" }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Container>
              </Box>
            </Box>
            {/*   Mobile version For free module  section */}

            {/*  End Mobile version For free module  section */}
            <Career_Transition open={Rcbopen} handleClose={RcbhandleClose} program={Iitprogram.vertical}/>
            <PmRctDialog
              onClose={RcthandleClose}
              aria-labelledby={PmRctinfo.pmwid}
              open={PmRctopen}
              info={PmRctinfo}
            >
              <PmRctDialogTitle
                id={PmRctinfo.pmwid}
                onClose={PmRcthandleClose}
              ></PmRctDialogTitle>

              <DialogContent sx={{ p: 0 }}>
                <iframe
                  src={PmRctinfo.youtublink}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullscreen=""
                  className={classes.studentvideos}
                ></iframe>
              </DialogContent>
            </PmRctDialog>
            <RctDialog
              onClose={RcthandleClose}
              aria-labelledby={Rctinfo.wid}
              open={Rctopen}
              info={Rctinfo}
            >
              <RctDialogTitle
                id={Rctinfo.wid}
                onClose={RcthandleClose}
              ></RctDialogTitle>

              <DialogContent sx={{ p: 0 }}>
                <iframe
                  src={Rctinfo.youtublink}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullscreen=""
                  className={classes.studentvideos}
                ></iframe>
              </DialogContent>
            </RctDialog>
          </Box>
        ))}
    </>
  );
}
