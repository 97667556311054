import Footer from '../Footer/Footer'
import { ProgramNavbar } from '../Navbar'

const ProgramWrapper = ({ children }) => {
  return (
    <>
      <ProgramNavbar />
      {children}
      <Footer />
    </>
  )
}

export default ProgramWrapper