import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  CardMedia,
  Typography,
  Grid,
  Button,
  Box,
  Container,
} from "@mui/material";

import useStyles from "../../Assets/js/Successstories/Successstories";
import Swal from "sweetalert2";
import axios from "axios";
import {
  useParams,
  useSearchParams,
  useLocation,
  Link,
} from "react-router-dom";
import { styled } from "@mui/material/styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "../../Assets/css/Successstories/SuccessstoriesSectionFive.css";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { Webinardata } from "../../data/webinardata/webinardata";

export default function Webinar() {
  const { id } = useParams();
  const [program, setProgram] = useState("---- Select Program ----");
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [Rcbopen, RcbsetOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [data, setData] = useState();
  // const registerCount=()=>{
  //   window.fbq('track', 'Register Now');
  // }

  useEffect(() => {
    document.title = "Webinar | Accredian";
  }, []);

  useEffect(() => {
    let ans = Webinardata.filter((obj) => {
      return obj.id == id;
    });
    //console.log(ans);
    setData(ans);
    localStorage.setItem("pagename", ans[0].programname);
 
  }, [id]);
  const newsliderRef = useRef();
  const next = () => {
    newsliderRef.current.slickNext();
  };
  const previous = () => {
    newsliderRef.current.slickPrev();
  };
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,

    autoplay: false,
    autoplaySpeed: 6000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
      {
        breakpoint: 600,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
    ],
  };
  useEffect(() => {
    // Button is displayed after scrolling for 900 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 200) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);
  const classes = useStyles();

  return (
    <>
      {data &&
        data.map((webdata) => (
          <>
            <Box sx={{ background: "#332F2F", mb: 3 }}>
              <Container>
                <Box sx={{ ml: { lg: 3 } }}>
                  <Box
                    sx={{
                      pt: 4,
                      display: {
                        lg: "block",
                        sm: "block",
                        md: "block",
                        xs: "none",
                      },
                    }}
                  >
                    <CardMedia
                      component="img"
                      image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accredainnew.webp"
                      alt="green iguana"
                      sx={{
                        display: { xs: "block", lg: "block" },
                        width: { lg: 300, xs: 250, sm: 300, md: 300 },
                        py: 1,
                        pt: 3,
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      pt: 4,
                      display: {
                        lg: "none",
                        sm: "none",
                        md: "none",
                        xs: "block",
                      },
                    }}
                  >
                    <center>
                      <CardMedia
                        component="img"
                        image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accredainnew.webp"
                        alt="green iguana"
                        sx={{
                          width: { lg: 300, xs: 208, sm: 300, md: 300 },
                          py: 1,
                          pt: 3,
                        }}
                      />
                    </center>
                  </Box>
                  <Box display="flex">
                    <Box sx={{ pt: 4 }}>
                      <Typography
                        variant="h3"
                        component="div"
                        sx={{
                          my: 1,
                          fontWeight: "bold",
                          color: "#fff",
                          marginBottom: "0px !important",
                          fontSize: {
                            lg: "75px",
                            xs: "60px",
                            md: "75px",
                            sm: "100px",
                          },
                          fontFamily: "Nunito Sans",
                          textAlign: {
                            lg: "unset",
                            xs: "center",
                            sm: "unset",
                            md: "unset",
                          },
                        }}
                      >
                        {webdata.programname}
                      </Typography>
                      <Typography
                        variant="h3"
                        component="div"
                        sx={{
                          mb: 4,
                          fontWeight: "bold",
                          color: "#fff",
                          fontFamily: "Nunito Sans",
                          fontSize: {
                            lg: "65px",
                            xs: "40px",
                            md: "65px",
                            sm: "85px",
                          },
                          textAlign: {
                            lg: "unset",
                            xs: "center",
                            sm: "unset",
                            md: "unset",
                          },
                        }}
                      >
                        MasterClass
                      </Typography>
                      <Typography
                        variant="h3"
                        component="div"
                        sx={{
                          mb: 4,
                          fontWeight: "bold",
                          color: "#FAB400",
                          fontSize: {
                            lg: "55px",
                            sm: "65px",
                            md: "55px",
                            xs: "40px",
                          },
                          fontFamily: "Nunito Sans",
                          textAlign: {
                            lg: "unset",
                            xs: "center",
                            sm: "unset",
                            md: "unset",
                          },
                        }}
                      >
                        1 Hour-Online
                      </Typography>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{
                          mb: 4,
                          color: "#fff",
                          fontSize: {
                            lg: "30px",
                            sm: "30px",
                            md: "30px",
                            xs: "25px",
                          },
                          width: {
                            lg: "500px",
                            md: "500px",
                            sm: "600px",
                            xs: "350px",
                          },
                          fontWeight: 300,
                          fontStyle: "normal",
                          fontFamily: "Nunito Sans",
                          textAlign: {
                            lg: "unset",
                            xs: "center",
                            sm: "unset",
                            md: "unset",
                          },
                        }}
                      >
                        {webdata.programdicription}
                      </Typography>
                      <Button
                        variant="contained"
                        sx={{
                          color: "#000 ",
                          backgroundColor: "#fff ",
                          borderColor: "#fff ",
                          borderRadius: "5px ",
                          ml:{
                            xs:"10px"
                          },
                          mr:{
                            xs:"10px"
                          },
                          fontSize: {
                            lg: "21px",
                            sm: "17px",
                            md: "15px",
                            xs: "15px",
                          },
                          width: {
                            lg: "227px",
                            xs: "150px",
                            sm: "235px",
                            md: "227px",
                          },
                          height: {
                            lg: "58px",
                            xs: "48px",
                            sm: "60px",
                            md: "50px",
                          },
                          fontWeight: "bold ",
                          textTransform: "none ",
                          fontFamily: "Nunito Sans",
                          mb: 3,

                          "&:hover": {
                            color: "#000 ",
                            backgroundColor: "#fff ",
                            borderColor: "#fff ",
                            borderRadius: "5px ",
                            fontSize: {
                              lg: "21px",
                              sm: "17px",
                              md: "15px",
                              xs: "15px",
                            },
                            width: {
                              lg: "227px",
                              xs: "150px",
                              sm: "235px",
                              md: "227px",
                            },
                            height: {
                              lg: "58px",
                              xs: "48px",
                              sm: "60px",
                              md: "50px",
                            },
                            fontWeight: "bold ",
                            textTransform: "none ",
                            fontFamily: "Nunito Sans",
                          },
                        }}
                      >
                        Sunday 11 AM
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          color: "#000 ",
                          backgroundColor: "#fff ",
                          borderColor: "#fff ",
                          borderRadius: "5px ",
                          fontSize: {
                            lg: "21px",
                            sm: "19px",
                            md: "15px",
                            xs: "17px",
                          },
                          width: {
                            lg: "170px",
                            xs: "150px",
                            sm: "210px",
                            md: "227px",
                          },
                          height: {
                            lg: "58px",
                            xs: "48px",
                            sm: "60px",
                            md: "50px",
                          },
                          fontWeight: "bold ",
                          textTransform: "none ",
                          ml: 1,

                          mb: 3,
                          fontFamily: "Nunito Sans",
                          "&:hover": {
                            color: "#000 ",
                            backgroundColor: "#fff ",
                            borderColor: "#fff ",
                            borderRadius: "5px ",
                            fontSize: {
                              lg: "21px",
                              sm: "19px",
                              md: "15px",
                              xs: "17px",
                            },
                            width: {
                              lg: "170px",
                              xs: "150px",
                              sm: "210px",
                              md: "227px",
                            },
                            height: {
                              lg: "58px",
                              xs: "48px",
                              sm: "60px",
                              md: "50px",
                            },
                            fontWeight: "bold ",
                            textTransform: "none ",
                            fontFamily: "Nunito Sans",
                            ml: 1,
                          },
                        }}
                      >
                        1 Hour Live
                      </Button>
                    <Box sx={{ pb: 3 }}>
                        <Link to={"/payment"}>
                          <Button
                            variant="contained"
                            sx={{
                              color: "#332F2F ",
                              backgroundColor: "#F3C317 ",
                              borderColor: "#F3C317 ",
                              borderRadius: "50px ",
                              width: "434px",
                              height: "75px",
                              fontSize: {
                                lg: "45px",
                                sm: "24px",
                                md: "30px",
                                xs: "24px",
                              },
                              width: {
                                lg: "421px",
                                xs: "350px",
                                sm: "450px",
                                md: "460px",
                              },
                              height: {
                                lg: "75px",
                                xs: "59px",
                                sm: "66px",
                                md: "64px",
                              },
                              ml: { lg: -1.2, xs: -0.5, sm: -2.2, md: -2.2 },

                              fontWeight: "bold ",
                              textTransform: "none ",
                              fontFamily: "Nunito Sans",

                              "&:hover": {
                                color: "#332F2F ",
                                backgroundColor: "#F3C317 ",
                                borderColor: "#F3C317 ",
                                borderRadius: "50px ",
                                fontSize: {
                                  lg: "45px",
                                  sm: "30px",
                                  md: "30px",
                                  xs: "30px",
                                },
                                width: {
                                  lg: "421px",
                                  xs: "369px",
                                  sm: "480px",
                                  md: "460px",
                                },
                                height: {
                                  lg: "75px",
                                  xs: "59px",
                                  sm: "66px",
                                  md: "64px",
                                },
                                ml: { lg: -1.2, xs: -0.5, sm: -2.2, md: -2.2 },
                                fontWeight: "bold ",
                                textTransform: "none ",
                                fontFamily: "Nunito Sans",
                              },
                            }}

                            // onClick={registerCount}
                          >
                            REGISTER NOW
                          </Button>
                        </Link>
                      </Box>
                      </Box>
                    <Box sx={{ mt: -15 }}>
                      <CardMedia
                        component="img"
                        image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/manv.webp"
                        alt="us"
                        sx={{
                          height: "741px",
                          display: {
                            xs: "none",
                            lg: "block",
                            md: "block",
                            sm: "none",
                          },
                          ml: 5,
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Container>
            </Box>
            <Box>
              <Box sx={{ mt: 5, mb: 5 }}>
                <Typography
                  variant="h3"
                  component="div"
                  sx={{
                    color: "#000",
                    fontWeight: "bold",
                    marginBottom: "0px !important",
                    fontSize: {
                      lg: "60px",
                      sm: "40px",
                      md: "45px",
                      xs: "38px",
                    },
                    textAlign: "center",
                    fontFamily: "Nunito Sans",
                  }}
                >
                  What will you learn?
                </Typography>
              </Box>
              <Box>
                <Container>
                  <Grid lg={12} md={12} xs={12} container>
                    <Grid lg={6} sm={12} md={6} xs={12} sx={{ mb: 3 }}>
                      <Box
                        sx={{
                          width: { lg: 510, sm: 710, md: 450, xs: 360 },
                          height: { lg: 220, sm: 220, md: 280, xs: 300 },
                          background: "#F7F7F7",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          borderRadius: 5,
                          display: {
                            lg: "flex",
                            sm: "flex",
                            md: "flex",
                            xs: "block",
                          },
                          mb: 3,
                          ml: { lg: 3 },
                        }}
                      >
                        <Box
                          sx={{
                            display: {
                              lg: "block",
                              xs: "none",
                              sm: "block",
                              md: "block",
                            },
                          }}
                        >
                          <CardMedia
                            component="img"
                            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/1.webp"
                            alt="green iguana"
                            sx={{
                              width: "72px",
                              height: "72px",
                              mx: 3,
                              mt: 10,
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: {
                              lg: "none",
                              xs: "block",
                              sm: "none",
                              md: "none",
                            },
                          }}
                        >
                          <center>
                            <CardMedia
                              component="img"
                              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/1.webp"
                              alt="green iguana"
                              sx={{
                                width: "72px",
                                height: "72px",
                                mx: 3,
                                pt: 2,
                              }}
                            />
                          </center>
                        </Box>
                        <Box sx={{ pt: 5, pb: 5 }}>
                          <Typography
                            variant="h3"
                            component="div"
                            sx={{
                              color: "#000",
                              fontWeight: "bold",
                              marginBottom: "0px !important",
                              fontSize: "25px",
                              height: {
                                lg: "60px",
                                sm: "60px",
                                md: "60px",
                                xs: "40px",
                              },
                              fontFamily: "Nunito Sans",
                              textAlign: {
                                lg: "unset",
                                xs: "center",
                                sm: "center",
                                md: "unset",
                              },
                            }}
                          >
                            {webdata.cardhadding1}
                          </Typography>
                          <Typography
                            variant="p"
                            component="div"
                            sx={{
                              height: {
                                lg: "80px",
                                sm: "100px",
                                md: "90px",
                                xs: "140px",
                              },

                              color: "#000",
                              fontFamily: "Nunito Sans",
                              marginBottom: "0px !important",
                              fontSize: "20px",
                              textAlign: {
                                lg: "unset",
                                xs: "center",
                                sm: "center",
                                md: "unset",
                              },
                            }}
                          >
                            {webdata.carddicription1}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid lg={6} sm={12} md={6} xs={12} sx={{ mb: 3 }}>
                      <Box
                        sx={{
                          width: { lg: 510, sm: 710, md: 450, xs: 360 },
                          height: { lg: 220, sm: 220, md: 280, xs: 300 },
                          background: "#F7F7F7",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          borderRadius: 5,
                          display: {
                            lg: "flex",
                            sm: "flex",
                            md: "flex",
                            xs: "block",
                          },
                          mb: 3,
                          ml: { lg: 3 },
                        }}
                      >
                        <Box
                          sx={{
                            display: {
                              lg: "block",
                              xs: "none",
                              sm: "block",
                              md: "block",
                            },
                          }}
                        >
                          <CardMedia
                            component="img"
                            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/badge.png"
                            alt="green iguana"
                            sx={{
                              width: "72px",
                              height: "72px",
                              mx: 3,
                              mt: 10,
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: {
                              lg: "none",
                              xs: "block",
                              sm: "none",
                              md: "none",
                            },
                          }}
                        >
                          <center>
                            <CardMedia
                              component="img"
                              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/badge.png"
                              alt="green iguana"
                              sx={{
                                width: "72px",
                                height: "72px",
                                mx: 3,
                                pt: 2,
                              }}
                            />
                          </center>
                        </Box>
                        <Box sx={{ pt: 5, pb: 5 }}>
                          <Typography
                            variant="h3"
                            component="div"
                            sx={{
                              color: "#000",
                              fontWeight: "bold",
                              marginBottom: "0px !important",
                              fontSize: "25px",
                              height: {
                                lg: "60px",
                                sm: "60px",
                                md: "60px",
                                xs: "73px",
                              },
                              fontFamily: "Nunito Sans",
                              textAlign: {
                                lg: "unset",
                                xs: "center",
                                sm: "center",
                                md: "unset",
                              },
                            }}
                          >
                            {webdata.cardhadding2}
                          </Typography>
                          <Typography
                            variant="p"
                            component="div"
                            sx={{
                              height: {
                                lg: "80px",
                                sm: "100px",
                                md: "90px",
                                xs: "75px",
                              },

                              color: "#000",
                              fontFamily: "Nunito Sans",
                              marginBottom: "0px !important",
                              fontSize: "18px",
                              textAlign: {
                                lg: "unset",
                                xs: "center",
                                sm: "center",
                                md: "unset",
                              },
                            }}
                          >
                            {webdata.carddicription2}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid lg={6} sm={12} md={6} xs={12} sx={{ mb: 3 }}>
                      <Box
                        sx={{
                          width: { lg: 510, sm: 710, md: 450, xs: 360 },
                          height: { lg: 220, sm: 220, md: 280, xs: 300 },
                          background: "#F7F7F7",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          borderRadius: 5,
                          display: {
                            lg: "flex",
                            sm: "flex",
                            md: "flex",
                            xs: "block",
                          },
                          mb: 3,
                          ml: { lg: 3 },
                        }}
                      >
                        <Box
                          sx={{
                            display: {
                              lg: "block",
                              xs: "none",
                              sm: "block",
                              md: "block",
                            },
                          }}
                        >
                          <CardMedia
                            component="img"
                            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/3.webp"
                            alt="green iguana"
                            sx={{
                              width: "72px",
                              height: "72px",
                              mx: 3,
                              mt: 10,
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: {
                              lg: "none",
                              xs: "block",
                              sm: "none",
                              md: "none",
                            },
                          }}
                        >
                          <center>
                            <CardMedia
                              component="img"
                              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/3.webp"
                              alt="green iguana"
                              sx={{
                                width: "72px",
                                height: "72px",
                                mx: 3,
                                pt: 2,
                              }}
                            />
                          </center>
                        </Box>
                        <Box sx={{ pt: 5, pb: 5 }}>
                          <Typography
                            variant="h3"
                            component="div"
                            sx={{
                              color: "#000",
                              fontWeight: "bold",
                              marginBottom: "0px !important",
                              fontSize: "25px",
                              height: {
                                lg: "60px",
                                sm: "60px",
                                md: "60px",
                                xs: "73px",
                              },
                              fontFamily: "Nunito Sans",
                              textAlign: {
                                lg: "unset",
                                xs: "center",
                                sm: "center",
                                md: "unset",
                              },
                            }}
                          >
                            {webdata.cardhadding3}
                          </Typography>
                          <Typography
                            variant="p"
                            component="div"
                            sx={{
                              height: {
                                lg: "80px",
                                sm: "100px",
                                md: "90px",
                                xs: "75px",
                              },

                              color: "#000",
                              fontFamily: "Nunito Sans",
                              marginBottom: "0px !important",
                              fontSize: "18px",
                              textAlign: {
                                lg: "unset",
                                xs: "center",
                                sm: "center",
                                md: "unset",
                              },
                            }}
                          >
                            {webdata.carddicription3}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid lg={6} sm={12} md={6} xs={12} sx={{ mb: 3 }}>
                      <Box
                        sx={{
                          width: { lg: 510, sm: 710, md: 450, xs: 360 },
                          height: { lg: 220, sm: 220, md: 280, xs: 300 },
                          background: "#F7F7F7",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          borderRadius: 5,
                          display: {
                            lg: "flex",
                            sm: "flex",
                            md: "flex",
                            xs: "block",
                          },
                          mb: 3,
                          ml: { lg: 3 },
                        }}
                      >
                        <Box
                          sx={{
                            display: {
                              lg: "block",
                              xs: "none",
                              sm: "block",
                              md: "block",
                            },
                          }}
                        >
                          <CardMedia
                            component="img"
                            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/4.webp"
                            alt="green iguana"
                            sx={{
                              width: "72px",
                              height: "72px",
                              mx: 3,
                              mt: 10,
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: {
                              lg: "none",
                              xs: "block",
                              sm: "none",
                              md: "none",
                            },
                          }}
                        >
                          <center>
                            <CardMedia
                              component="img"
                              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/4.webp"
                              alt="green iguana"
                              sx={{
                                width: "72px",
                                height: "72px",
                                mx: 3,
                                pt: 2,
                              }}
                            />
                          </center>
                        </Box>
                        <Box sx={{ pt: 5, pb: 5 }}>
                          <Typography
                            variant="h3"
                            component="div"
                            sx={{
                              color: "#000",
                              fontWeight: "bold",
                              marginBottom: "0px !important",
                              fontSize: "25px",
                              height: {
                                lg: "60px",
                                sm: "60px",
                                md: "60px",
                                xs: "73px",
                              },
                              fontFamily: "Nunito Sans",
                              textAlign: {
                                lg: "unset",
                                xs: "center",
                                sm: "center",
                                md: "unset",
                              },
                            }}
                          >
                            {webdata.cardhadding4}
                          </Typography>
                          <Typography
                            variant="p"
                            component="div"
                            sx={{
                              height: {
                                lg: "80px",
                                sm: "100px",
                                md: "90px",
                                xs: "75px",
                              },

                              color: "#000",
                              fontFamily: "Nunito Sans",
                              marginBottom: "0px !important",
                              fontSize: "18px",
                              textAlign: {
                                lg: "unset",
                                xs: "center",
                                sm: "center",
                                md: "unset",
                              },
                            }}
                          >
                            {webdata.carddicription4}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Container>
              </Box>

              <Box xs={{ mb: 3 }}>
                <Container>
                  <Grid lg={12} md={12} xs={12} container>
                    <Grid
                      lg={4}
                      sm={12}
                      md={6}
                      xs={12}
                      sx={{ mb: { lg: 3, md: 5, sm: 3, xs: 3 } }}
                    >
                      <CardMedia
                        component="img"
                        image={webdata.cardimage1}
                        alt="green iguana"
                        sx={{
                          width: {
                            lg: "373px",
                            sm: "710px",
                            md: "473px",
                            xs: "357px",
                          },
                          height: {
                            lg: "218px",
                            sm: "418px",
                            md: "290px",
                            xs: "218px",
                          },
                        }}
                      />
                    </Grid>
                    <Grid
                      lg={4}
                      sm={12}
                      md={6}
                      xs={12}
                      sx={{ mb: { lg: 3, md: 5, sm: 3, xs: 3 } }}
                    >
                      <CardMedia
                        component="img"
                        image={webdata.cardimage2}
                        alt="green iguana"
                        sx={{
                          width: {
                            lg: "373px",
                            sm: "710px",
                            md: "473px",
                            xs: "357px",
                          },
                          height: {
                            lg: "218px",
                            sm: "418px",
                            md: "290px",
                            xs: "218px",
                          },
                        }}
                      />
                    </Grid>
                    <Grid
                      lg={4}
                      sm={12}
                      md={6}
                      xs={12}
                      sx={{ mb: { lg: 3, md: 5, sm: 3, xs: 3 } }}
                    >
                      <CardMedia
                        component="img"
                        image={webdata.cardimage3}
                        alt="green iguana"
                        sx={{
                          width: {
                            lg: "373px",
                            sm: "710px",
                            md: "473px",
                            xs: "357px",
                          },
                          height: {
                            lg: "218px",
                            sm: "418px",
                            md: "290px",
                            xs: "218px",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            </Box>
            <Box sx={{ background: "#332F2F", mb: 3, mt: 3 }}>
              <Container>
                <Box sx={{ pt: 5, mb: { lg: 5, xs: 2 } }}>
                  <Typography
                    variant="h3"
                    component="div"
                    sx={{
                      my: 1,
                      fontWeight: "bold",
                      color: "#fff",
                      marginBottom: "0px !important",
                      fontSize: {
                        lg: "60px",
                        xs: "38px",
                        sm: "38px",
                        md: "38px",
                      },
                      textAlign: "center",
                      fontFamily: "Nunito Sans",
                    }}
                  >
                    Who is this masterclass for?
                  </Typography>
                </Box>

                <Grid lg={12} md={12} xs={12} container>
                  <Grid lg={4} md={4} sx={{ mb: 3 }}>
                    <Box sx={{ pt: 5, pb: 5, px: 2 }}>
                      <Typography
                        variant="h3"
                        component="div"
                        sx={{
                          color: "#fff",
                          fontWeight: "bold",
                          marginBottom: "0px !important",
                          fontSize: { lg: "35px", xs: "25px" },
                          height: { lg: "150px", xs: "60px" },
                          textAlign: "center",
                          fontFamily: "Nunito Sans",
                          mb: 4,
                        }}
                      >
                        {webdata.masterclasshadding1}
                      </Typography>
                      <Typography
                        variant="9"
                        component="div"
                        sx={{
                          height: "80px",

                          color: "#fff",

                          marginBottom: "0px !important",
                          fontSize: { lg: "22px", xs: "19px" },
                          textAlign: "center",
                          fontFamily: "Nunito Sans",
                          mb: { lg: 12 },
                          pb: { xs: 5, lg: 12 },
                        }}
                      >
                        {webdata.masterclassdicription1}
                      </Typography>
                    </Box>
                    <CardMedia
                      component="img"
                      image={webdata.masterclassimage1}
                      alt="green iguana"
                      sx={{
                        width: {
                          lg: "373px",
                          sm: "710px",
                          md: "313px",
                          xs: "357px",
                        },
                        height: {
                          lg: "218px",
                          sm: "418px",
                          md: "218px",
                          xs: "218px",
                        },
                        objectFit: "unset",
                        mb: { lg: 5 },
                      }}
                    />
                  </Grid>
                  <Grid lg={4} md={4} sx={{ mb: 3 }}>
                    <Box sx={{ pt: 5, pb: 5, px: 2 }}>
                      <Typography
                        variant="h3"
                        component="div"
                        sx={{
                          color: "#fff",
                          fontWeight: "bold",
                          marginBottom: "0px !important",
                          fontSize: { lg: "35px", xs: "25px" },
                          height: { lg: "150px", xs: "30px" },
                          textAlign: "center",
                          fontFamily: "Nunito Sans",
                          mb: 4,
                        }}
                      >
                        {webdata.masterclasshadding2}
                      </Typography>
                      <Typography
                        variant="9"
                        component="div"
                        sx={{
                          height: "80px",

                          color: "#fff",
                          fontFamily: "Nunito Sans",
                          marginBottom: "0px !important",
                          fontSize: { lg: "22px", xs: "19px" },
                          textAlign: "center",
                          mb: { lg: 12 },
                          pb: { xs: 5, lg: 12 },
                        }}
                      >
                        {webdata.masterclassdicription2}
                      </Typography>
                    </Box>
                    <CardMedia
                      component="img"
                      image={webdata.masterclassimage2}
                      alt="green iguana"
                      sx={{
                        width: {
                          lg: "373px",
                          sm: "710px",
                          md: "313px",
                          xs: "357px",
                        },
                        height: {
                          lg: "218px",
                          sm: "418px",
                          md: "218px",
                          xs: "218px",
                        },
                        objectFit: "unset",
                        mb: { lg: 5 },
                      }}
                    />
                  </Grid>
                  <Grid lg={4} md={4} sx={{ mb: 3 }}>
                    <Box sx={{ pt: 5, pb: 5, px: 2 }}>
                      <Typography
                        variant="h3"
                        component="div"
                        sx={{
                          color: "#fff",
                          fontWeight: "bold",
                          marginBottom: "0px !important",
                          fontSize: { lg: "35px", xs: "25px" },
                          height: { lg: "150px", xs: "30px" },
                          textAlign: "center",
                          fontFamily: "Nunito Sans",
                          mb: 4,
                        }}
                      >
                        {webdata.masterclasshadding3}
                      </Typography>
                      <Typography
                        variant="9"
                        component="div"
                        sx={{
                          height: "80px",

                          color: "#fff",

                          marginBottom: "0px !important",
                          fontSize: { lg: "22px", xs: "19px" },
                          textAlign: "center",
                          fontFamily: "Nunito Sans",
                          mb: { lg: 12 },
                          pb: { xs: 5, lg: 12 },
                        }}
                      >
                        {webdata.masterclassdicription3}
                      </Typography>
                    </Box>
                    <CardMedia
                      component="img"
                      image={webdata.masterclassimage3}
                      alt="green iguana"
                      sx={{
                        width: {
                          lg: "373px",
                          sm: "710px",
                          md: "313px",
                          xs: "357px",
                        },
                        height: {
                          lg: "218px",
                          sm: "418px",
                          md: "218px",
                          xs: "218px",
                        },
                        objectFit: "unset",
                        mb: { lg: 5 },
                      }}
                    />
                  </Grid>
                </Grid>
              </Container>
            </Box>
            
            <Box sx={{ mb: 3, mt: 3 }}>
              <Container>
                <CardMedia
                  component="img"
                  image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/11.webp"
                  alt="green iguana"
                  sx={{
                    height: {
                      lg: "680px",
                      md: "580px",
                      sm: "680px",
                      xs: "216px",
                    },
                    width: "auto",
                    mb: { lg: 5 },
                  }}
                />
              </Container>
            </Box>
            <Box
              sx={{
                background: "#332F2F",
                display: "flex",
                justifyContent:"center",
                alignItems:"center",
                px: 12,
              }}
            >
              <Box sx={{ py: 2 }}>
                <Link to={"/payment"}>
                    <Button
                      sx={{
                        background: "#F3C317",
                        textTransform: "capitalize",
                        width: "256px",
                        borderRadius: "7px",
                        color: "#000",
                        p: 1,
                        fontSize: "18px",
                        fontFamily: "Nunito Sans",
                        fontWeight: "bold",
                        "&:hover": {
                          background: "#F3C317",
                          textTransform: "capitalize",
                          width: "256px",
                          borderRadius: "7px",
                          p: 1,
                          fontSize: "18px",
                          color: "#000",
                          fontFamily: "Nunito Sans",
                          fontWeight: "bold",
                        },
                      }}
                      variant="contained"

                      // onClick={registerCount}
                    >
                      Register Now at{" "}
                      <CurrencyRupeeIcon sx={{ fontSize: "13px" }} />{" "}
                      <del>699</del>{" "}
                      <CurrencyRupeeIcon sx={{ fontSize: "13px" }} />
                      199
                  </Button>
                </Link>
              </Box>
            </Box>
            <Box sx={{ py: 5 }} className={classes.susses}>
              <Container fixed>
                <Grid xs={12} md={12} sm={12} lg={12}>
                  <Typography
                    variant="h3"
                    component="div"
                    sx={{
                      pt: 2,
                      fontWeight: "bold",
                      color: "#F1962B",
                      fontFamily: "Nunito Sans",
                      textAlign: {
                        lg: "unset",
                        xs: "center",
                        sm: "unset",
                        md: "unset",
                      },
                    }}
                    className={classes.alumnihadding}
                  >
                    {" "}
                    Why Learners Love
                  </Typography>
                  <Typography
                    variant="h3"
                    component="div"
                    sx={{
                      pt: 2,
                      fontWeight: "bold",
                      color: "#055FA0",
                      fontFamily: "Nunito Sans",
                      textAlign: {
                        lg: "unset",
                        xs: "center",
                        sm: "unset",
                        md: "unset",
                      },
                    }}
                    className={classes.alumnihadding}
                  >
                    {" "}
                    ACCREDIAN
                  </Typography>
                </Grid>
                <Grid
                  lg={12}
                  xs={12}
                  md={12}
                  sm={12}
                  container
                  sx={{ py: { lg: 3, md: 3, sm: 3, xs: 3 } }}
                >
                  <Grid lg={6} xs={12} sm={12} md={12}>
                    <Box
                      sx={{
                        display: "flex",
                        mb: { lg: 2, md: 3, sm: 3, xs: 3 },
                      }}
                    >
                      <CardMedia
                        component="img"
                        image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Success-Stories/new1.webp"
                        sx={{
                          width: { lg: "12%", md: "20%", sm: "20%", xs: "18%" },
                          objectFit: "unset",
                        }}
                      />
                      <Typography
                        sx={{
                          ml: { lg: 4, md: 4, sm: 4, xs: 2 },
                          mt: { lg: 2, md: 5, sm: 5, xs: 2 },
                          fontSize: { md: "32px", sm: "25px", lg: "20px" },
                          fontFamily: "Nunito Sans",
                        }}
                      >
                        Top Faculty
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", mb: 2 }}>
                      <CardMedia
                        component="img"
                        image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Success-Stories/new2.webp"
                        sx={{
                          width: { lg: "12%", md: "20%", sm: "20%", xs: "18%" },
                          objectFit: "unset",
                        }}
                      />
                      <Typography
                        sx={{
                          ml: { lg: 4, md: 4, sm: 4, xs: 2 },
                          mt: { lg: 2, md: 5, sm: 5, xs: 2 },
                          fontSize: { md: "32px", sm: "25px", lg: "20px" },
                          fontFamily: "Nunito Sans",
                        }}
                      >
                        360 Degree - Career Launchpad
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", mb: 2 }}>
                      <CardMedia
                        component="img"
                        image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Success-Stories/new3.webp"
                        sx={{
                          width: { lg: "12%", md: "20%", sm: "20%", xs: "18%" },
                          objectFit: "unset",
                        }}
                      />
                      <Typography
                        sx={{
                          ml: { lg: 4, md: 4, sm: 4, xs: 2 },
                          mt: { lg: 2, md: 5, sm: 5, xs: 2 },
                          fontSize: { md: "32px", sm: "25px", lg: "20px" },
                          fontFamily: "Nunito Sans",
                        }}
                      >
                        Interview Preparation
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    lg={6}
                    xs={12}
                    md={12}
                    sm={12}
                    className={classes.imagsilder}
                    sx={{ mt: { lg: 0, md: 5, sm: 5, xs: 5 } }}
                  >
                    {/* <Slider ref={(newsliderRef) => (Slider = newsliderRef)} {...settings}> */}
                    <Slider ref={newsliderRef} {...settings}>
                      <Grid
                        lg={12}
                        xs={12}
                        md={12}
                        sm={12}
                        sx={{ ml: { md: 6, lg: 0, sm: 4 } }}
                      >
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Success-Stories/Testimonials1.webp"
                          sx={{
                            width: { lg: "99%", md: "93%", sm: "93%" },
                            border: "1px solid #007bff",
                            objectFit: "unset",
                          }}
                        />
                      </Grid>
                      <Grid
                        lg={12}
                        xs={12}
                        md={12}
                        sm={12}
                        sx={{ ml: { md: 6, lg: 0, sm: 3 } }}
                      >
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Success-Stories/Testimonials2.webp"
                          sx={{
                            width: { lg: "99%", md: "93%", sm: "93%" },
                            border: "1px solid #007bff",
                            objectFit: "unset",
                          }}
                        />
                      </Grid>
                      <Grid
                        lg={12}
                        xs={12}
                        md={12}
                        sm={12}
                        sx={{ ml: { md: 6, lg: 0, sm: 3 } }}
                      >
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Success-Stories/Testimonials3.webp"
                          sx={{
                            width: { lg: "99%", md: "93%", sm: "93%" },
                            border: "1px solid #007bff",
                            objectFit: "unset",
                          }}
                        />
                      </Grid>
                      <Grid
                        lg={12}
                        xs={12}
                        md={12}
                        sm={12}
                        sx={{ ml: { md: 6, lg: 0, sm: 3 } }}
                      >
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Success-Stories/Testimonials4.webp"
                          sx={{
                            width: { lg: "99%", md: "93%", sm: "93%" },
                            border: "1px solid #007bff",
                            objectFit: "unset",
                          }}
                        />
                      </Grid>
                      <Grid
                        lg={12}
                        xs={12}
                        md={12}
                        sm={12}
                        sx={{ ml: { md: 6, lg: 0, sm: 3 } }}
                      >
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Success-Stories/Testimonials5.webp"
                          sx={{
                            width: { lg: "99%", md: "93%", sm: "93%" },
                            border: "1px solid #007bff",
                            objectFit: "unset",
                          }}
                        />
                      </Grid>
                      <Grid
                        lg={12}
                        xs={12}
                        md={12}
                        sm={12}
                        sx={{ ml: { md: 6, lg: 0, sm: 3 } }}
                      >
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Success-Stories/Testimonials6.webp"
                          sx={{
                            width: { lg: "99%", md: "93%", sm: "93%" },
                            border: "1px solid #007bff",
                            objectFit: "unset",
                          }}
                        />
                      </Grid>
                    </Slider>
                    <Button
                      onClick={previous}
                      sx={{
                        mt: { md: -35, sm: -35, xs: -20 },
                        ml: { lg: -7.5, md: -1.5, sm: -5, xs: -4 },
                        "&:hover": { backgroundColor: "transparent" },
                      }}
                    >
                      <ArrowBackIosIcon></ArrowBackIosIcon>
                    </Button>
                    <Button
                      onClick={next}
                      sx={{
                        float: "right",
                        mt: { md: -19, sm: -18, xs: -11 },
                        mr: { md: -7, sm: -6.4, xs: -5 },
                        "&:hover": { backgroundColor: "transparent" },
                      }}
                    >
                      <ArrowForwardIosIcon></ArrowForwardIosIcon>
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            </Box>

            <Box>
              {isVisible && (
                <Box sx={{ mt: 11 }}>
                  <Box
                    sx={{
                      background: "#332F2F",
                      height: {
                        lg: "100px",
                        sm: "100px",
                        md: "100px",
                        xs: "170px",
                      },

                      position: "fixed",
                      bottom: 0,
                      left: 0,
                      right: 0,
                    }}
                  >
                    <Container fixed>
                      <Grid
                        container
                        item
                        lg={12}
                        xs={12}
                        md={12}
                        sm={12}
                        sx={{ mx: { lg: 3, md: 3 } }}
                      >
                        <Grid lg={2} xs={12} sm={2} md={2}>
                          <CardMedia
                            component="img"
                            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/manv.webp"
                            alt="green iguana"
                            sx={{
                              width: "172px",
                              display: {
                                lg: "block",
                                sm: "block",
                                md: "block",
                                xs: "none",
                              },
                              objectFit: "unset",
                              mt: -10,
                              ml: { sm: -12.5, lg: 0, xs: 0, md: -10 },
                            }}
                          />
                        </Grid>
                        <Grid lg={5} xs={12} sm={6} md={5} sx={{ pt: 2 }}>
                          <Typography
                            sx={{
                              color: "#fff",
                              textDecoration: "none",
                              marginBottom: "0rem!important",
                              fontWeight: "bold",
                              fontSize: {
                                lg: "23px",
                                md: "23px",
                                sm: "24px",
                                xs: "24px",
                              },
                              ml: { lg: 13 },
                              fontFamily: "Nunito Sans",
                              textAlign: { xs: "center", sm: "unset" },
                            }}
                          >
                            {webdata.programname} Masterclass
                          </Typography>
                          <Typography
                            sx={{
                              color: "#fff",
                              textDecoration: "none",
                              marginBottom: "0rem!important",
                              fontSize: {
                                lg: "16px",
                                md: "16px",
                                sm: "16px",
                                xs: "16px",
                              },
                              ml: { lg: 15.5 },
                              fontFamily: "Nunito Sans",
                              textAlign: { xs: "center", sm: "unset" },
                            }}
                          >
                            21st January 2024 Sunday 11 AM
                          </Typography>
                          <Typography
                            sx={{
                              color: "#F3C317",
                              textDecoration: "none",
                              marginBottom: "0rem!important",
                              fontSize: {
                                lg: "10px",
                                md: "10px",
                                sm: "10px",
                                xs: "14px",
                              },
                              ml: { lg: 28.5 },
                              fontFamily: "Nunito Sans",
                              textAlign: { xs: "center", sm: "unset" },
                            }}
                          >
                            Only for Working Professionals
                          </Typography>
                        </Grid>
                        <Grid
                          lg={3}
                          xs={12}
                          sm={3}
                          md={3}
                          sx={{ ml: { lg: 3, md: 3, xs: 10, sm: 2 }, pt: 2 }}
                        >
                          <Link to={"/payment"}>
                            <Button
                              sx={{
                                background: "#F3C317",
                                textTransform: "capitalize",
                                width: "226px",
                                borderRadius: "7px",
                                color: "#000",
                                p: 1,
                                fontSize: "15px",
                                fontFamily: "Nunito Sans",
                                fontWeight: "bold",
                                "&:hover": {
                                  background: "#F3C317",
                                  textTransform: "capitalize",
                                  width: "226px",
                                  borderRadius: "7px",
                                  p: 1,
                                  fontSize: "15px",
                                  color: "#000",
                                  fontFamily: "Nunito Sans",
                                  fontWeight: "bold",
                                },
                              }}
                              variant="contained"

                              // onClick={registerCount}
                            >
                              Register Now at{" "}
                              <CurrencyRupeeIcon sx={{ fontSize: "13px" }} />{" "}
                              <del>699</del>{" "}
                              <CurrencyRupeeIcon sx={{ fontSize: "13px" }} />
                              199
                            </Button>
                          </Link>
                        </Grid>
                      </Grid>
                    </Container>
                  </Box>
                </Box>
              )}
              ;
            </Box>
          </>
        ))}
    </>
  );
}
