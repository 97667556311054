import { makeStyles } from "@mui/styles";
export default makeStyles((theme) => ({
  DownloadBrochure:{
    background:"linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",textTransform:'capitalize !important',width:"250px ",fontSize:"19px !important",borderRadius:"9px !important",
    '&:hover': {
      background:"linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",textTransform:'capitalize !important',width:"250px ",fontSize:"19px !important",borderRadius:"9px",

  },
  },
  DownloadBrochureone:{
    background:"linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",textTransform:'capitalize !important',width:"349px ",fontSize:"24px !important",borderRadius:"9px !important",
    '&:hover': {
      background:"linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",textTransform:'capitalize !important',width:"349px ",fontSize:"24px !important",borderRadius:"9px",

  },
  },
  DownloadBrochuretwo:{
    background:"linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",textTransform:'none !important',width:"359px ",fontSize:"24px !important",borderRadius:"9px !important",
    '&:hover': {
      background:"linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",textTransform:'none !important',width:"359px ",fontSize:"24px !important",borderRadius:"9px",

  },
  },
  explorenow:{
    background:"linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",textTransform:'none !important',width:"180px !important",fontSize:"18px !important",borderRadius:"9px !important",
    '&:hover': {
      background:"linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",textTransform:'none !important',width:"180px !important",fontSize:"18px !important",borderRadius:"9px",
     
  },
  "@media (max-width: 700px)": {
    background:"linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",textTransform:'none !important',width:"180px !important",fontSize:"18px !important",borderRadius:"9px !important",
    '&:hover': {
      background:"linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",textTransform:'none !important',width:"180px !important",fontSize:"18px !important",borderRadius:"9px",
     
  },
  },
  },
  careertransitions:{
    background:"linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",textTransform:'none !important',width:"459px !important",fontSize:"24px !important",borderRadius:"9px !important",
    '&:hover': {
      background:"linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",textTransform:'none !important',width:"459px !important",fontSize:"24px !important",borderRadius:"9px",

  },
  "@media (max-width: 700px)": {
    background:"linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",textTransform:'none !important',width:"366px !important",fontSize:"17px !important",borderRadius:"9px !important",
    '&:hover': {
      background:"linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",textTransform:'none !important',width:"366px !important",fontSize:"17px !important",borderRadius:"9px",

  },
  },
  },
ApplyScroship:{
    backgroundColor:"#BD3381 !important",textTransform:'capitalize !important',width:"250px",fontSize:"19px !important",borderRadius:"9px!important",
    '&:hover': {
      backgroundColor:"#BD3381 !important",textTransform:'capitalize !important',width:"250px",fontSize:"19px !important",borderRadius:"9px !important",

  },
  },
  cardoneimage:{
    width: "50px",
    height:"50px",
    padding: "16px",
    background:"linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",
    borderRadius:"50%",
    "@media (max-width: 700px)": {
      width: "50px",
      height:"50px",
      padding: "16px",
      background:"linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",
      borderRadius:"50%"
    },
  },
  cardtwoimage:{
    width: "50px",
    height:"50px",
    padding: "16px",
    background:"linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 202, 255, 1) 0%, rgba(0, 255, 142, 1) 100%)",
    borderRadius:"50%",
    "@media (max-width: 700px)": {
      width: "50px",
      height:"50px",
      padding: "16px",
      background:"linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 202, 255, 1) 0%, rgba(0, 255, 142, 1) 100%)",
      borderRadius:"50%"
    },
  },
  cardthreeimage:{
    width: "50px",
    height:"50px",
    padding: "16px",
    background:"linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(255, 187, 0, 1) 0%, rgba(255, 0, 170, 1) 100%)",
    borderRadius:"50%",
    "@media (max-width: 700px)": {
      width: "50px",
      height:"50px",
      padding: "16px",
      background:"linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(255, 187, 0, 1) 0%, rgba(255, 0, 170, 1) 100%)",
      borderRadius:"50%"
    },
  },
  cardfourimage:{
    width: "50px",
    height:"50px",
    padding: "16px",
    background:"linear-gradient(90deg, rgba(40,242,226,1) 0%, rgba(67,193,228,1) 35%, rgba(37,195,227,1) 100%)",
    borderRadius:"50%",
    "@media (max-width: 700px)": {
      width: "50px",
      height:"50px",
      padding: "16px",
      background:"linear-gradient(90deg, rgba(40,242,226,1) 0%, rgba(67,193,228,1) 35%, rgba(37,195,227,1) 100%)",
      borderRadius:"50%"
    },
  },
  cardoneimagelarg:{
    width: "80px",
    height:"80px",
    padding: "28px",
    background:"linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",
    borderRadius:"50%"
  },
  btpaimageone:{
    width:"150px",
    height:"150px",
  },
  checkel:{
    color:"#0091ff !important",
    backgroundColor:"#fff !important",
    borderColor:"#fff !important",
    borderRadius:"8px !important",
    width:"183px !important",
    fontWeight: "bold !important",
    textTransform:"none !important",
  },
  sussesstroyimag: {
    width: "360px !important",
    height: "225px !important",
    border:"0.5px solid #007bff",
    "@media (max-width: 700px)": {
      width: "345px !important",
      height: "216px !important",
      border:"0.5px solid #007bff",
    },
  },
  seconbuttonprv: {
    marginTop: "-283px !important",
    marginLeft: "-66px !important",
    "@media (max-width: 700px)": {
      marginTop: "-250px !important",
      marginLeft: "-34px !important",
    },
  },

 
  seconbuttonnext: {
    float: "right",
    marginTop: "-144px !important",
    marginRight: "-44px !important",
    "@media (max-width: 700px)": {
      marginRight: "-39px !important",
      marginTop: "-133px !important",
    },
  },
  opbuttonnext:{
    "@media (max-width: 700px)": {
      marginLeft: "347px !important",
      marginTop: "-266px !important",
    },
  },
  opbuttonprv:{
    "@media (max-width: 700px)": {
      marginLeft: "-25px !important",
      marginTop: "-266px !important",
    },
  },
  Rcbuttonnext:{
    "@media (max-width: 700px)": {
      marginLeft: "347px !important",
      marginTop: "-566px !important",
    },
  },
  Rcbuttonprv:{
    "@media (max-width: 700px)": {
      marginLeft: "-25px !important",
      marginTop: "-560px !important",
    },
  },
  wpbuttonnext:{
    "@media (max-width: 700px)": {
      marginLeft: "347px !important",
      marginTop: "-436px !important",
    },
  },
  wpbuttonprv:{
    "@media (max-width: 700px)": {
      marginLeft: "-25px !important",
      marginTop: "-436px !important",
    },
  },
  fcbuttonnext:{
    "@media (max-width: 700px)": {
      marginLeft: "330px !important",
      marginTop: "-436px !important",
    },
  },
  fcbuttonprv:{
    "@media (max-width: 700px)": {
      marginLeft: "-10px !important",
      marginTop: "-436px !important",
    },
  },
  osbuttonnext:{
    "@media (max-width: 700px)": {
      marginLeft: "365px !important",
      marginTop: "-165px !important",
    },
  },
  osbuttonprv:{
    "@media (max-width: 700px)": {
      marginLeft: "-2px !important",
      marginTop: "-123px !important",
    },
  },
  ptbuttonnext:{
    "@media (max-width: 700px)": {
      marginLeft: "350px !important",
      marginTop: "-220px !important",
    },
  },
  ptbuttonprv:{
    "@media (max-width: 700px)": {
      marginLeft: "-2px !important",
      marginTop: "-175px !important",
    },
  },
  root:{
    "& .MuiPaper-root-MuiAccordion-root": {
                 
      boxShadow: "rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset",
     
    }
  },
  sepText:{
    // display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    flex: 1,
    '&,&:before ': {
      content: '',
    flex: 1,
    width:"1px",
   background:"currentColor",
 
    },
    '&,&:after ': {
      content: '',
    
      width: "1px",
      height: "281px",
      background: "currentColor",
      marginBottom:"87px !important",
 
    },
  },
  mccertificte:{
    height:"500px",
    "@media (max-width: 700px)": {
      height:"300px",
    },

  },
  studentvideos:{
    width:"560px" ,
    height:"315px",
    "@media (max-width: 700px)": {
      height:"300px",
      width:"300px" ,
    },
  },
  curriculumcta:{
 marginTop:"104px !important",
    "@media (max-width: 700px)": {
      marginTop:"60px !important",
    },
  },
}));
