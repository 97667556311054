import { makeStyles } from "@mui/styles";
export default makeStyles((theme) => ({
  homsection: {
    paddingTop: "100px!important",

    "@media (max-width: 700px)": {
      paddingTop: "0px !important",
      marginTop: "-48px",
    },
  },
  fristsetionimag: {
    "@media (max-width: 700px)": {
      display: "none !important",
    },
  },
  haddingfont: {
    "@media (max-width: 700px)": {
      textAlign: "center",
      fontSize: "33px !important",
      marginTop: "115px !important",
    },
  },
  descriptionfont: {
    fontStyle: "italic",
    fontSize: "22px",
    "@media (max-width: 700px)": {
      textAlign: "center",
      fontSize: "19px !important",
    },
  },
  degintionfont: {
    "@media (max-width: 700px)": {
      textAlign: "center",
      fontSize: "15px !important",
    },
    fontSize: "26px",
    fontWeight: "500"
  },
  sussbutton: {
    textTransform: "capitalize !important",
    "@media (max-width: 700px)": {
      display: "block !important",
      marginLeft: "auto !important",
      marginRight: "auto !important",
    },
  },
  sectwo: {
    borderBottom: "3px solid #1C6994",
    transform: "rotate(0.16deg)",
    width: "578px",
    "@media (max-width: 700px)": {
      width: "339px",
    },
  },
  logosize: {
    width: "70%",
    "@media (max-width: 700px)": {
      width: "100%",
    },
  },
}));
