import React, { useEffect } from "react";
import "../Assets/css/resource.css";
import Wrapper from "../Components/Wrappers/Wrapper";
import { Link } from "react-router-dom";

function ResourceThankyou() {

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <Wrapper>
      <div className="bg-gray-100 flex justify-center py-16 px-4">
        <div className="max-w-[85rem] w-full flex justify-center xl:flex-row flex-col gap-16 items-center">
          <div className="">
            <div className="w-full md:max-w-xl xl:max-w-[420px] drop-shadow-lg bg-white p-6">
              <div className="w-full">
                <h1 className="text-4xl sm:text-6xl font-bold text-black mb-3 mt-2">Awesome!</h1>
              </div>
              <div className="w-full">
                <h2 className="text-3xl sm:text-[40px] leading-tight font-bold text-black">You have taken a step in the RIGHT DIRECTION.</h2>
              </div>
              <div className="w-full">
                <p className="text-sm sm:text-base mt-4 max-w-sm">Here's how you can explore & download more resources on our page.</p>
              </div>
              <div className="w-full mt-2">
                <Link to={localStorage.getItem("pagelink")}>
                  <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded sm:text-base text-sm">
                    Click here
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="">
            <div className="w-full">
              <iframe title="Introduction Video" src={localStorage.getItem("youtublink")} className="w-[90vw] h-[60vw] md:w-[60vw] md:h-[35vw] xl:w-[35vw] xl:h-[40vh]"></iframe>
            </div>
          </div>
        </div>
      </div>

    </Wrapper>
  );
}
export default ResourceThankyou;
